import React, { useEffect, useState } from "react"
import Card from "@mui/material/Card"
import { Skeleton } from "@mui/material"

const SkeletonCard = () => {
  return (
    <Card sx={{ height: "100%" }}>
      <Skeleton variant="rectangle" width={"100%"} height={"100%"} />
    </Card>
  )
}

export default SkeletonCard
