import React from "react"
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  Select,
  Skeleton,
  Typography,
} from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import { getCurrencyStringFromNumber } from "../../Util/Util"

const CustomOrderOptionSelect = ({
  title,
  option,
  // setOption,
  options,
  helpText,
  my = 1,
  updateOptions,
  orderData,
  isStaff,
  onChange = (event) => {
    let newOption = options.find((x) => {
      return x.value === event.target.value
    })
    // setOption(newOption)
    updateOptions([newOption])
  },
}) => {
  if (!option) {
    return <Skeleton />
  }

  return (
    <Box my={my}>
      <FormControl fullWidth>
        {/*<InputLabel id="size-select-label" shrink>*/}
        {/*  {title}*/}
        {/*</InputLabel>*/}

        <FormLabel
          style={{
            backgroundColor: "#fff",
            paddingLeft: "10px",
            paddingRight: "5px",
          }}
        >
          <Typography
            variant="caption"
            style={{
              fontFamily: "Josefin Sans",
            }}
          >
            {title}
          </Typography>
        </FormLabel>

        <Select
          value={option?.value}
          label="Fin Box"
          fullWidth
          disabled={orderData?.locked && !isStaff}
          style={{ color: "black" }}
          onChange={onChange}
        >
          {options.map((option, i) => {
            return (
              <MenuItem
                key={i}
                value={option.value}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>{option.value}</span>
                {option.price > 0 && (
                  <span>{` ${getCurrencyStringFromNumber(
                    option.price,
                    true
                  )}`}</span>
                )}
              </MenuItem>
            )
          })}
        </Select>
        <FormHelperText>{option?.help_text || helpText}</FormHelperText>
      </FormControl>
    </Box>
  )
}

export default CustomOrderOptionSelect
