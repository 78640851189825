import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import Axios from "../../Util/Axios"
import {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_INFO,
  TOAST_SEVERITY_SUCCESS,
} from "../../components/core/Toast/Toast"
import { useParams } from "react-router"
import CoreCarousel from "../components/core/Carousel/CoreCarousel"
import { getCurrencyStringFromNumber } from "../../Util/Util"
import { useDispatch, useSelector } from "react-redux"
import { updateCartItemsAction, updateCartLoaded } from "../../slices/cartSlice"
import Cookies from "js-cookie"
import { updateToastData } from "../../slices/toastSlice"
import ReactPixel from "react-facebook-pixel"
import { useNavigate } from "react-router-dom"
import "./ProductPage.css"
import ContactModal from "../Contact/ContactModal"
import { Helmet } from "react-helmet"
const useStyles = makeStyles({
  // productTitle: {
  //   fontSize: "2em",
  //   fontWeight: "bold",
  // },
  addCartButton: {
    marginTop: "10px",
    fontSize: "1em",
  },
  "@global": {
    ".image-gallery-image img": {
      objectFit: "contain",
      maxHeight: "500px", // Set desired max height here
      width: "auto !important", // Set to auto to maintain aspect ratio
      maxWidth: "100%",
    },
  },
})
const ProductPage = () => {
  const { id } = useParams()
  const [productData, setProductData] = useState(false)
  const [selectedProductVariant, setSelectedProductVariant] = useState(null)
  const [contactModalActive, setContactModalActive] = useState(false)
  const [allowedColors, setAllowedColors] = useState([])
  const dataUrl = `/api/product-detail/`
  const navigate = useNavigate()
  const [quantity, setQuantity] = useState(1)

  const dispatch = useDispatch()
  const { cart } = useSelector((state) => state)
  const cartItems = cart.cart_items
  const updateAllowedColors = (productData, selectedSize) => {
    let allowedColors = new Set()

    productData.product_variants.forEach((productVariant) => {
      if (productVariant.size_name === selectedSize) {
        allowedColors.add(productVariant.color_name)
      }
    })
    setAllowedColors(allowedColors)
  }

  const updateSelectedProductVariant = (sizeName, colorName) => {
    let newProductVariant = productData.product_variants.find(
      (productVariant) => {
        return (
          productVariant.size_name === sizeName &&
          productVariant.color_name === colorName
        )
      }
    )
    if (!newProductVariant) {
      newProductVariant = productData.product_variants.find(
        (productVariant) => {
          return productVariant.size_name === sizeName
        }
      )
    }
    setSelectedProductVariant(newProductVariant)
  }

  const getData = () => {
    setProductData(null)
    const url = `${dataUrl}${id}/`
    Axios.get(url)
      .then(({ data }) => {
        let defaultProductVariant = data.default_product_variant
        if (data.type === "apparel") {
          setSelectedProductVariant(defaultProductVariant)
          updateAllowedColors(data, defaultProductVariant.size_name)
        }
        setProductData(data)

        document.documentElement.scrollTop = 0
      })
      .catch((e) => {
        dispatch(
          updateToastData({
            message: e,
            severity: TOAST_SEVERITY_ERROR,
          })
        )
      })
  }

  useEffect(() => {
    getData()
  }, [])
  const classes = useStyles()

  let image = ""
  if (selectedProductVariant?.product_variant_images.length > 0) {
    image = selectedProductVariant?.product_variant_images[0]?.url
  } else if (productData?.product_images?.length > 0) {
    image = productData?.product_images[0]?.url
  }

  if (!productData) {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{productData?.name}</title>
          <meta property="og:image" content={image} />
          <meta
            name="description"
            content={`Marlin Surfboards: ${productData?.description}`}
          />
        </Helmet>
        <Box
          sx={{
            flexGrow: 1,
            p: 2,
            pt: 15,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={3} style={{ maxWidth: "1200px" }}>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rectangular" height={500} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="text" height={40} />
              <Box my={5}>
                <Skeleton variant="text" height={40} />
              </Box>
              <Box my={5}>
                <Skeleton variant="text" height={40} />
              </Box>
              <Box my={5}>
                <Skeleton variant="text" height={40} />
              </Box>
              <Box my={5}>
                <Skeleton variant="text" height={40} />
              </Box>
              <Skeleton variant="text" height={100} />
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    )
  }
  const isApparel = productData?.type === "apparel"

  const updateCart = () => {
    const cartId = Cookies.get("cart_id")

    let data = {}
    let price = 0
    if (isApparel) {
      data = {
        product_variant_id: selectedProductVariant.id,
      }
      price = selectedProductVariant.price
    } else {
      let existingCartItem = cartItems.find((cartItem) => {
        return cartItem.product?.id.toString() === productData?.id.toString()
      })

      if (existingCartItem) {
        dispatch(
          updateToastData({
            message: "Item already in cart.",
            severity: TOAST_SEVERITY_INFO,
          })
        )
        return
      }

      data = {
        product_id: productData.id,
      }
      price = productData.price
    }
    data["quantity"] = quantity
    Axios.post(`/api/cart-detail/${cartId}/add_item/`, { data })
      .then(({ data }) => {
        if (price > 0) {
          ReactPixel.track("AddToCart", {
            value: price,
            currency: "USD",
          })
        }

        dispatch(
          updateToastData({
            message: "Item added to cart.",
            severity: TOAST_SEVERITY_SUCCESS,
          })
        )
        dispatch(updateCartItemsAction(data.cart_items))
        // dispatch(addToCartAction(data))
      })
      .catch((e) => {
        dispatch(
          updateToastData({
            message: e,
            severity: TOAST_SEVERITY_ERROR,
          })
        )
      })
  }

  const addToCart = () => {
    let cartId = Cookies.get("cart_id")
    if (!cartId) {
      Axios.post("/api/cart-detail/")
        .then(({ data }) => {
          Cookies.set("cart_id", data.id)
          dispatch(updateCartLoaded(true))
          updateCart()
        })
        .catch((e) => {
          dispatch(
            updateToastData({
              message: "Error creating cart",
              severity: TOAST_SEVERITY_ERROR,
            })
          )
        })
    } else {
      updateCart()
    }
  }
  const sold = productData?.status === "sold"
  let variantImages = productData.product_variants.flatMap((productVariant) =>
    productVariant.product_variant_images.map((image) => ({
      productVariant,
      imageUrl: image.url,
    }))
  )
  let uniqueUrls = new Set()

  // filter out the objects with duplicate urls
  variantImages = variantImages.filter((item) => {
    if (!uniqueUrls.has(item.imageUrl)) {
      uniqueUrls.add(item.imageUrl)
      return true
    }
    return false
  })

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          pt: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={10} style={{ maxWidth: "1200px" }}>
          <Grid item xs={12} sm={6}>
            <CoreCarousel
              images={
                isApparel
                  ? selectedProductVariant.product_variant_images
                  : productData.product_images
              }
            />
            {variantImages.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginTop: 50,
                }}
              >
                {variantImages.map((item) => (
                  <img
                    width={70}
                    className={"variantImage"}
                    src={item.imageUrl}
                    alt="product image"
                    onClick={() => {
                      setSelectedProductVariant(item.productVariant)
                    }}
                  />
                ))}
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" gutterBottom>
              {productData.name}
            </Typography>
            {productData.dimensions_string && (
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontFamily: "Josefin Sans", fontSize: "1rem" }}
                >
                  {productData.dimensions_string}
                </Typography>
              </Box>
            )}
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                style={{ fontFamily: "Josefin Sans", fontWeight: "bold" }}
              >
                {isApparel
                  ? getCurrencyStringFromNumber(selectedProductVariant.price)
                  : getCurrencyStringFromNumber(productData.price)}
              </Typography>
            </Box>

            {isApparel && (
              <Box my={5}>
                <FormControl fullWidth>
                  <InputLabel id="size-select-label">Size</InputLabel>
                  <Select
                    labelId="size-select-label"
                    id="size-select"
                    label={"size"}
                    value={selectedProductVariant.size_name}
                    onChange={(e) => {
                      updateSelectedProductVariant(
                        e.target.value,
                        selectedProductVariant.color_name
                      )

                      updateAllowedColors(productData, e.target.value)
                    }}
                  >
                    {productData.sizes.map((size, i) => (
                      <MenuItem value={size} key={i}>
                        {size}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            {isApparel && (
              <Box my={5}>
                <FormControl fullWidth>
                  <InputLabel id="color-select-label">Color</InputLabel>
                  <Select
                    label={"color"}
                    labelId="color-select-label"
                    id="color-select"
                    value={selectedProductVariant.color_name}
                    onChange={(e) => {
                      updateSelectedProductVariant(
                        selectedProductVariant.size_name,
                        e.target.value
                      )
                    }}
                  >
                    {productData.colors.map((color, i) => {
                      const disabled = !allowedColors.has(color)
                      return (
                        <MenuItem disabled={disabled} value={color} key={i}>
                          {color}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
            {isApparel && (
              <Box my={5}>
                {/* Add margin on the Y-axis */}
                <TextField
                  id="quantity"
                  label="Quantity"
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  fullWidth
                />
              </Box>
            )}
            <Typography sx={{ fontFamily: "Josefin Sans" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: productData.description,
                }}
              />
            </Typography>
            <Box my={5}>
              {/* Add margin on the Y-axis */}
              <Button
                variant="contained"
                color={sold ? "secondary" : "primary"}
                className={classes.addCartButton}
                sx={{
                  fontFamily: "Josefin Sans",
                  fontWeight: "bold",
                  paddingTop: 1.5,
                }}
                fullWidth
                onClick={() => {
                  if (!sold) {
                    addToCart()
                  } else {
                    Cookies.remove(`${productData?.preset}_customer_facing_id`)
                    navigate(
                      `/custom-surfboard-order?preset_id=${productData?.preset}`
                    )
                  }
                }}
              >
                {sold ? "Open in Board Builder" : "Add to Cart"}
              </Button>
              {!sold && !isApparel && (
                <Box pt={2}>
                  <Button
                    variant="contained"
                    color={"secondary"}
                    className={classes.addCartButton}
                    fullWidth
                    onClick={() => {
                      setContactModalActive(true)
                    }}
                  >
                    Contact
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ContactModal
        active={contactModalActive}
        setActive={setContactModalActive}
        starterMessage={`Hey Marlin,\n\nI'm interested in the ${productData?.name}. When can I come look at it?\n\nhttps://marlin.surf/surfboard-inventory/${productData?.id}`}
      />
    </React.Fragment>
  )
}

export default ProductPage
