export const getCurrencyStringFromNumber = (
  amount,
  removeTrailingZeros = false
) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })
  let formattedAmount = currencyFormatter.format(amount)

  if (removeTrailingZeros && formattedAmount.endsWith(".00")) {
    formattedAmount = formattedAmount.substring(0, formattedAmount.length - 3)
  }

  return formattedAmount
}

export const numberWithCommas = (number) => {
  if (!number) {
    return number
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const getFeetOptions = (
  startFeet,
  startInches,
  endFeet,
  endInches,
  step
) => {
  // Convert the starting and ending lengths to inches
  const startTotalInches = startFeet * 12 + startInches
  const endTotalInches = endFeet * 12 + endInches

  // Calculate the number of options
  const numOptions = Math.ceil((endTotalInches - startTotalInches) / step) + 1

  // Generate the array of length options
  return Array.from({ length: numOptions }, (_, i) => {
    const totalInches = startTotalInches + i * step
    const feet = Math.floor(totalInches / 12)
    const inches = totalInches % 12
    const fraction = inches % 1
    const wholeInches = Math.floor(inches)

    let type
    if (totalInches < 86) {
      type = "Shortboard"
    } else if (totalInches >= 86 && totalInches <= 100) {
      type = "Midlength"
    } else {
      type = "Longboard"
    }

    return {
      type: type,
      value: `${feet}' ${wholeInches}${
        fraction > 0 ? ` ${fraction * 16}/${step * 16}"` : `"`
      }`,
      total_inches: totalInches,
    }
  })
}

const getFraction = (decimal) => {
  const gcd = (a, b) => (b ? gcd(b, a % b) : a)
  const len = decimal.toString().length - 2
  let denominator = Math.pow(10, len)
  let numerator = decimal * denominator
  const divisor = gcd(numerator, denominator)
  numerator /= divisor
  denominator /= divisor
  return `${Math.floor(numerator)}/${Math.floor(denominator)}`
}
const getInchesOptions = (startInches, endInches, step) => {
  // Calculate the number of options
  const numOptions = Math.ceil((endInches - startInches) / step) + 1

  // Generate the array of length options
  return Array.from({ length: numOptions }, (_, i) => {
    const totalInches = startInches + i * step
    const wholeInches = Math.floor(totalInches)
    const fractionInches = totalInches - wholeInches
    const fraction = fractionInches !== 0 ? getFraction(fractionInches) : ""

    // Check if the total inches is above the minimum

    return {
      value: `${wholeInches}${fraction !== "" ? ` ${fraction}"` : `"`}`,
    }
  }).filter((option) => option !== null)
}

// const getWidthOptions = (lengthOption) => {
//   if (lengthOption?.extra_data?.total_inches >= 100) {
//     return getInchesOptions(22, 23.5, 0.125)
//   } else {
//     return getInchesOptions(19, 23, 0.25)
//   }
// }
// const getThicknessOptions = (lengthOption) => {
//   if (lengthOption?.extra_data?.total_inches >= 100) {
//     return getInchesOptions(2.75, 3.5, 0.125)
//   } else {
//     return getInchesOptions(2.25, 3.5, 0.125)
//   }
// }
//
// let widthOptions = getWidthOptions(lengthOption)
//
// let thicknessOptions = getThicknessOptions(lengthOption)

// const lengthOptions = getLengthOptions(4, 10, 6)

export const getDimensionsFromOptions = (options) => {
  let length = options.find((x) => {
    return ["length", "length"].includes(x.type)
  }).value
  let width = options.find((x) => {
    return ["width_small", "width_large"].includes(x.type)
  }).value
  let thickness = options.find((x) => {
    return ["thickness_small", "thickness_large"].includes(x.type)
  }).value
  return { length, width, thickness }
}

export const createFilePreview = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
