import React, { useEffect, useState } from "react"
import { Box, Tooltip } from "@mui/material"

const ColorBox = ({ colorOption, clickable, handleColorSelect, selected }) => {
  useEffect(() => {}, [])
  let border = null
  let boxShadow = "1px 1px 10px rgba(0, 0, 0, 0.5)"

  if (colorOption.value === "#ffffff") {
    border = "1px solid #F0EAD6"
  }

  return (
    <Tooltip title={colorOption?.extra_data?.name} key={colorOption.value}>
      <Box
        sx={{
          width: 60,
          height: 60,
          bgcolor: colorOption.value,
          border: border,
          m: 1,
          borderRadius: 1,
          cursor: clickable ? "pointer" : null,
          transition: "0.3s",
          boxShadow: selected ? boxShadow : null,
          "&:hover": {
            boxShadow: boxShadow,
          },
        }}
        onClick={() => {
          if (clickable) {
            handleColorSelect(colorOption)
          }
        }}
      />
    </Tooltip>
  )
}

export default ColorBox
