import React, { useEffect, useState } from "react"
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import Axios from "../../../../Util/Axios"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material"
import DataTablePaginate from "./DataTablePaginate"
import {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_INFO,
  TOAST_SEVERITY_SUCCESS,
} from "../../../../components/core/Toast/Toast"
import DataTableSearchBar from "./DataTableSearchBar/DataTableSearchBar"
import { getCurrencyStringFromNumber } from "../../../../Util/Util"
import { useDispatch, useSelector } from "react-redux"
import { updateToastData } from "../../../../slices/toastSlice"
import Cookies from "js-cookie"
import {
  updateCartItemsAction,
  updateCartLoaded,
} from "../../../../slices/cartSlice"
import ReactPixel from "react-facebook-pixel"

const ProductTable = ({
  title,
  extraParams = "",
  dataUrl,
  description,
  path,
  showSearch = true,
  actions = [],
  bannerUrl,
}) => {
  const [cards, setCards] = useState([])
  const [loading, setLoading] = useState(true)
  let [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const [totalPages, setTotalPages] = useState(0)
  let currentPage = searchParams.get("page") || 1
  currentPage = parseInt(currentPage)
  const navigate = useNavigate()
  const location = useLocation()

  const { cart } = useSelector((state) => state)
  const cartItems = cart.cart_items || []

  const getData = (page, searchTerm) => {
    if (!searchTerm) {
      searchTerm = searchParams.get("search") || ""
    }
    setLoading(true)
    setCards([])
    const url = `${dataUrl}?page=${page}&search=${searchTerm}${extraParams}`
    Axios.get(url)
      .then(({ data }) => {
        setTotalPages(data.total_pages)
        setCards(data.results)
        setLoading(false)
        document.documentElement.scrollTop = 0
      })
      .catch((e) => {
        setLoading(false)
        dispatch(
          updateToastData({
            message: "Error getting data",
            severity: TOAST_SEVERITY_ERROR,
          })
        )
      })
  }

  useEffect(() => {
    getData(currentPage)
  }, [location])

  const updateCart = (productData) => {
    const cartId = Cookies.get("cart_id")
    let existingCartItem = cartItems.find((cartItem) => {
      return cartItem.product?.id.toString() === productData?.id.toString()
    })

    if (existingCartItem) {
      dispatch(
        updateToastData({
          message: "Item already in cart.",
          severity: TOAST_SEVERITY_INFO,
        })
      )
      return
    }

    let data = {
      product_id: productData.id,
    }

    data["quantity"] = 1
    Axios.post(`/api/cart-detail/${cartId}/add_item/`, { data })
      .then(({ data }) => {
        if (productData.price > 0) {
          ReactPixel.track("AddToCart", {
            value: productData.price,
            currency: "USD",
          })
        }

        dispatch(
          updateToastData({
            message: "Item added to cart.",
            severity: TOAST_SEVERITY_SUCCESS,
          })
        )
        dispatch(updateCartItemsAction(data.cart_items))
        // dispatch(addToCartAction(data))
      })
      .catch((e) => {
        dispatch(
          updateToastData({
            message: e,
            severity: TOAST_SEVERITY_ERROR,
          })
        )
      })
  }

  const addToCart = (productData) => {
    let cartId = Cookies.get("cart_id")

    if (!cartId) {
      Axios.post("/api/cart-detail/")
        .then(({ data }) => {
          Cookies.set("cart_id", data.id)
          dispatch(updateCartLoaded(true))
          updateCart(productData)
        })
        .catch((e) => {
          dispatch(
            updateToastData({
              message: "Error creating cart",
              severity: TOAST_SEVERITY_ERROR,
            })
          )
        })
    } else {
      updateCart(productData)
    }
  }

  return (
    <Box pl={5} pr={5} pt={2}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>

        <Typography
          variant="h6"
          color="text.secondary"
          sx={{ fontFamily: "Josefin Sans" }}
        >
          {description}
        </Typography>
        {bannerUrl && (
          <Box mt={3}>
            <img src={bannerUrl} width={"100%"} />
          </Box>
        )}
      </Box>

      <Box sx={{ pt: 3, pb: 3, pr: 2, pl: 2 }}>
        {showSearch && <DataTableSearchBar getData={getData} />}

        <Box sx={{ py: 2 }} />

        <Grid container spacing={2}>
          {!loading &&
            cards.map((rowData) => {
              const sold = rowData.status === "sold"
              return (
                <Grid item xs={12} sm={6} md={3} key={rowData.id}>
                  <Link
                    to={`/${path}/${rowData.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        mt: 1,
                        height: 450,
                        transition: "transform 0.15s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.03)",
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        sx={{ height: 200, objectFit: "contain", flexGrow: 1 }}
                        image={rowData.display_image}
                        alt={rowData.name}
                      />
                      <CardContent>
                        <Typography
                          sx={{ fontFamily: "Josefin Sans" }}
                          variant="h6"
                          noWrap
                        >
                          {`${sold ? "[SOLD] " : ""}${rowData.name}`}
                        </Typography>
                        {rowData.dimensions_string && (
                          <Typography
                            variant="subtitle1"
                            sx={{ fontFamily: "Josefin Sans" }}
                            color="text.secondary"
                          >
                            {rowData.dimensions_string}
                          </Typography>
                        )}
                        <Typography
                          variant="subtitle1"
                          sx={{ fontFamily: "Josefin Sans" }}
                          color="text.secondary"
                        >
                          {rowData.type === "apparel" &&
                            getCurrencyStringFromNumber(rowData.display_price)}
                          {rowData.type !== "apparel" &&
                            getCurrencyStringFromNumber(rowData.price)}
                        </Typography>
                        {rowData.type === "inventory" && (
                          <Button
                            variant="contained"
                            color={sold ? "secondary" : "primary"}
                            fullWidth
                            style={{
                              fontFamily: "Josefin Sans",
                              paddingTop: 11,
                              fontWeight: "Bold",
                            }}
                            onClick={(event) => {
                              event.preventDefault()
                              if (!sold) {
                                addToCart(rowData)
                              } else {
                                Cookies.remove(
                                  `${rowData?.preset}_customer_facing_id`
                                )
                                navigate(
                                  `/custom-surfboard-order?preset_id=${rowData?.preset}`
                                )
                              }
                            }}
                          >
                            {sold ? "Open in Board builder" : "Add to Cart"}
                          </Button>
                        )}
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              )
            })}
          {loading &&
            Array.from(new Array(6)).map((_, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box sx={{ width: "100%", mx: "auto" }}>
                  <Skeleton variant="rectangular" width="100%" height={200} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Box>
              </Grid>
            ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 5,
          }}
        >
          <DataTablePaginate
            currentPage={currentPage}
            getData={getData}
            totalPages={totalPages}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ProductTable
