import * as React from "react"

const HybridDiamondSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 932.953

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M165.045,922.322l-74.898-26.484 c0,0-65.249-125.613-68.249-484.343c-3-358.728,143-394.109,143-394.109S310.9,52.767,307.9,411.495 c-3.002,358.729-67.404,484.688-67.404,484.688L165.045,922.322z" />
      </g>
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={264.587} y1={129.928} x2={264.587} y2={821.928} />
          <line x1={65.503} y1={129.928} x2={65.503} y2={821.928} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={149.963} y1={22.448} x2={149.963} y2={917.31} />
          <line x1={180.127} y1={22.448} x2={180.127} y2={917.31} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.045} y1={17.386} x2={165.045} y2={922.322} />
        </g>
      )}
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path d=" M98.076,886.796c0,0-62.015-123.438-64.777-473.002c-2.76-349.558,131.6-384.035,131.6-384.035S299.26,64.236,296.5,413.794 c-2.764,349.56-63.758,473.002-63.758,473.002l-67.398,24.847L98.076,886.796z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M90.146,895.838 c0,0-65.249-125.613-68.249-484.343l142.344-79.478L307.9,411.495c-3.002,358.729-67.404,484.688-67.404,484.688l-75.451,26.141 L90.146,895.838z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M90.146,895.838 c0,0-65.249-125.613-68.249-484.343c0,0,129.161-178.826,286.002,0c-3.003,358.729-67.404,484.688-67.404,484.688l-75.451,26.141 L90.146,895.838z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M90.591,895.838 c0,0-65.249-125.613-68.249-484.343c0,0,52.178-9.528,91.511-33.721c39.333-24.192,51.463-60.135,51.463-60.135 s15.33,36.024,48.025,58.753c35.797,24.883,95.002,35.104,95.002,35.104c-3.002,358.729-67.404,484.688-67.404,484.688 l-75.896,26.141L90.591,895.838z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.146,895.838 c0,0-36.271-69.827-55.97-254.032l131.167-37.842l130.419,37.842c-19.65,177.795-55.267,254.377-55.267,254.377l-75.451,26.141 L90.146,895.838z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.146,895.838 c0,0-41.654-83.861-58.569-280.096l133.766,81.372l132.811-79.464c-15.911,192.66-57.658,278.532-57.658,278.532l-75.451,26.141 L90.146,895.838z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path d="M90.146,895.838 c0,0-36.271-69.827-55.97-254.032c0,0,60.565-2.754,94.565-19.343s36.602-27.476,36.602-27.476s13.287,19.916,30.787,26.654 c24.373,9.388,99.632,20.163,99.632,20.163c-19.65,177.795-55.267,254.377-55.267,254.377l-75.451,26.141L90.146,895.838z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M89.419,896.026 c0,0-36.271-69.827-55.97-254.032c0,0,89.565-4.827,107.065,20.057c17.5,24.883,24.384,42.151,24.384,42.151 s10.15-22.752,34.492-42.171c25.352-20.227,95.645-20.037,95.645-20.037c-19.65,177.795-55.268,254.377-55.268,254.377 l-74.724,25.951L89.419,896.026z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.146,895.838 c0,0-32.837-63.217-52.992-228.029c-1.027-8.4-2.022-17.067-2.978-26.003c0,0,125.94-85.025,261.586,0c0,0-1.711,15.03-2.605,22.27 c-20.026,161.939-52.66,232.107-52.66,232.107l-75.598,27.294L90.146,895.838z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M173.396,859.474c0,2.891-2.129,5.319-4.559,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V716.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.559,2.433,4.559,5.318V859.474z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M173.396,859.474c0,2.891-2.129,5.319-4.559,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V716.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.559,2.433,4.559,5.318V859.474z" />
          <path d="M75.386,745.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,745.686z" />
          <path d="M272.169,743.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,743.104z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M75.386,745.989c-0.304,2.887-2.583,5.015-5.166,4.559 l-6.384-0.912c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.013,5.167-4.559l6.381,0.913 c2.583,0.304,4.255,3.038,3.951,5.924L75.386,745.989z" />
          <path d="M272.169,743.404c0.455,2.889-1.37,5.624-3.8,6.079l-6.385,1.063 c-2.582,0.456-4.861-1.52-5.317-4.408l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.078l6.381-1.063 c2.432-0.455,4.863,1.521,5.318,4.406L272.169,743.404z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,745.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,745.686z" />
          <path d="M272.169,743.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,743.104z" />
          <path d="M173.094,880.479c0,2.889-2.129,5.317-4.559,5.317H162 c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.317,4.559-5.317h6.535c2.583,0,4.559,2.43,4.559,5.317V880.479z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,745.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,745.686z" />
          <path d="M106.658,832.299c0,2.891-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.431-5.415-5.319v-60.021c0-2.889,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V832.299z" />
          <path d="M272.169,743.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,743.104z" />
          <path d="M248.695,830.78c0,2.888-2.525,5.317-5.414,5.317h-7.761 c-3.067,0-5.414-2.431-5.414-5.317v-60.022c0-2.887,2.528-5.317,5.414-5.317h7.761c3.068,0,5.414,2.432,5.414,5.317V830.78z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M173.396,859.474c0,2.891-2.129,5.319-4.559,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V716.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.559,2.433,4.559,5.318V859.474z" />
          <path d="M75.386,745.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,745.686z" />
          <path d="M106.658,832.299c0,2.891-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.431-5.415-5.319v-60.021c0-2.889,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V832.299z" />
          <path d="M272.169,743.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,743.104z" />
          <path d="M248.695,830.78c0,2.888-2.525,5.317-5.414,5.317h-7.761 c-3.067,0-5.414-2.431-5.414-5.317v-60.022c0-2.887,2.528-5.317,5.414-5.317h7.761c3.068,0,5.414,2.432,5.414,5.317V830.78z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,745.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,745.686z" />
          <path d="M106.658,832.299c0,2.891-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.431-5.415-5.319v-60.021c0-2.889,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V832.299z" />
          <path d="M272.169,743.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,743.104z" />
          <path d="M248.695,830.78c0,2.888-2.525,5.317-5.414,5.317h-7.761 c-3.067,0-5.414-2.431-5.414-5.317v-60.022c0-2.887,2.528-5.317,5.414-5.317h7.761c3.068,0,5.414,2.432,5.414,5.317V830.78z" />
          <path d="M173.094,880.479c0,2.889-2.129,5.317-4.559,5.317H162 c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.317,4.559-5.317h6.535c2.583,0,4.559,2.43,4.559,5.317V880.479z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          x={116.899}
          y={492.133}
          width={96}
          height={91.001}
          href={logoUrl}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d=" M106.409,877.643c0,0-55-108.666-62.131-459.458C41.749,76.26,164.898,42.537,164.898,42.537S288.05,76.261,285.52,418.184 c-6.742,341.882-61.11,460.125-61.11,460.125l-59.364,20.333L106.409,877.643z" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d=" M90.146,895.838c0,0-63.404-96.426-68.249-484.343c-4.479-358.712,143-394.109,143-394.109S310.9,52.767,307.9,411.495 c-3.002,358.729-67.404,484.688-67.404,484.688l-75.451,28.971L90.146,895.838z" />
      </g>
    </svg>
  )
}
export default HybridDiamondSvg
