import React, { useEffect, useState } from "react"
import NavBar from "../NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material"
import CartItemJsx from "./CartItemJsx"
import { getCurrencyStringFromNumber } from "../../Util/Util"
import CheckoutButton from "./CheckoutButton"
import Cookies from "js-cookie"
import Axios from "../../Util/Axios"
import { updateCartLoaded } from "../../slices/cartSlice"
import { updateToastData } from "../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../../components/core/Toast/Toast"
import Page from "../Page/Page"
import { Box } from "@mui/system"
const CartPage = () => {
  const { cart } = useSelector((state) => state)
  const cartItems = cart.cart_items || []
  const [subtotal, setSubtotal] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    const newSubtotal = cartItems.reduce((sum, cartItem) => {
      let price

      if (cartItem.product_variant) {
        price = parseFloat(cartItem.product_variant.price)
      } else if (cartItem.product) {
        price = parseFloat(cartItem.product.price)
      }
      return sum + price * cartItem.quantity
    }, 0)

    setSubtotal(newSubtotal)
  }, [cartItems])

  useEffect(() => {
    let cartId = Cookies.get("cart_id")
    if (!cartId) {
      Axios.post("/api/cart-detail/")
        .then(() => {
          dispatch(updateCartLoaded(true))
        })
        .catch((e) => {
          dispatch(
            updateToastData({
              message: e,
              severity: TOAST_SEVERITY_ERROR,
            })
          )
        })
    }
  }, [])

  const cartItemTable = () => {
    if (!cart.cart_loaded) {
      return [...Array(5)].map((_, i) => (
        <TableBody key={i}>
          <TableRow>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" />
            </TableCell>
          </TableRow>
        </TableBody>
      ))
    }
    return (
      <React.Fragment>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartItems.map((cartItem) => {
            return <CartItemJsx key={cartItem.id} cartItem={cartItem} />
          })}
        </TableBody>
      </React.Fragment>
    )
  }

  let cartEmpty = cart.cart_loaded && cartItems.length === 0

  return (
    <Page>
      <div
        style={{
          overflow: "hidden",
        }}
      >
        <Grid container spacing={3} style={{ padding: "2em" }}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              gutterBottom
              style={{
                fontFamily: "Lobster",
              }}
            >
              Your Cart
            </Typography>
          </Grid>
          {!cartEmpty && (
            <React.Fragment>
              <Grid item xs={8}>
                <TableContainer component={Paper}>
                  <Table>{cartItemTable()}</Table>
                </TableContainer>
              </Grid>
              <Grid item xs={4}>
                <Card
                  style={{
                    padding: "2em",
                    height: "fit-content",
                    backgroundColor: "#f6f6f6",
                  }}
                >
                  <Typography
                    align="right"
                    variant="h5"
                    style={{
                      marginBottom: "1.5em",
                      fontWeight: "bold",
                      fontFamily: "Josefin Sans",
                    }}
                  >
                    Subtotal: {getCurrencyStringFromNumber(subtotal)}
                  </Typography>
                  <Box pb={2}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                      style={{ fontFamily: "Josefin Sans" }}
                    >
                      Surfboards must be picked up in Santa Barbara, CA. I'll
                      email you to arrange a time as soon as possible.
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    gutterBottom
                    style={{ fontFamily: "Josefin Sans" }}
                  >
                    Apparel items will be shipped to the address you provide at
                    checkout.
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                    style={{ fontFamily: "Josefin Sans" }}
                  >
                    *Free shipping on Apparel items. Tax calculated at checkout.
                  </Typography>
                  <CheckoutButton cart={cart} />
                </Card>
              </Grid>
            </React.Fragment>
          )}
          {cartEmpty && (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "60vh" }}
            >
              <Grid item xs={8}>
                <Paper
                  elevation={3}
                  style={{ padding: "2em", textAlign: "center" }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{
                      fontFamily: "Josefin Sans",
                    }}
                  >
                    Your cart is currently empty.
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Josefin Sans",
                    }}
                    variant="body1"
                    color="textSecondary"
                  >
                    It looks like you haven't added anything to your cart yet.
                    Go add a board or some cool shirts.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </Page>
  )
}

export default CartPage
