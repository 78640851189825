import React, { useEffect, useState } from "react"
import { Box, Dialog } from "@mui/material"
import ColorBox from "./ColorBox"

const CustomOrderColorModal = ({
  active,
  setActive,
  colorOptions,
  handleColorSelect,
}) => {
  return (
    <Dialog
      onClose={() => {
        setActive(false)
      }}
      open={active}
    >
      {/*<DialogTitle>Pick a color</DialogTitle>*/}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          p: 1,
          m: 1,
          maxWidth: 300,
          bgcolor: "background.paper",
        }}
      >
        {colorOptions.map((cO) => {
          return (
            <ColorBox
              key={cO.id}
              colorOption={cO}
              clickable={true}
              handleColorSelect={handleColorSelect}
            />
          )
        })}
      </Box>
    </Dialog>
  )
}

export default CustomOrderColorModal
