import React, { forwardRef, useEffect, useRef, useState } from "react"
import { Alert, AlertTitle, Box, Card, CardContent, Grid } from "@mui/material"
import SurfboardSvg from "./SurfboardSvg"
import { makeStyles } from "@mui/styles"
import { setLayout } from "../../../soft_dashboard/context"
import Button from "@mui/material/Button"

const CustomOrderSurfboards = ({
  selectedLogoOption,
  selectedDeckPatchOption,
  selectedDeckColorOption,
  selectedBottomColorOption,
  selectedTailPatchOption,
  selectedFinBoxColorOption,
  selectedFinSetupOption,
  selectedNoseShapeOption,
  selectedTailShapeOption,
  selectedPinLineColorOption,
  selectedLengthOption,
  selectedWidthOption,
  selectedStringerTypeOption,
  selectedNumberOfStringersOption,
}) => {
  const surfboardDeckSvgRef = useRef(null)
  const surfboardBottomSvgRef = useRef(null)

  const useStyles = makeStyles((theme) => ({
    grid: {
      position: "sticky",
      top: "60px",
      [theme.breakpoints.down("sm")]: {
        position: "static",
      },
    },
  }))
  const classes = useStyles()

  function svgToPngDataUrl(svgRef, callback) {
    const svgEl = svgRef.current

    // Convert SVG to Data URL
    const svgData = new XMLSerializer().serializeToString(svgEl)
    const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" })
    const svgUrl = URL.createObjectURL(svgBlob)

    const img = new Image()
    img.onload = function () {
      const canvas = document.createElement("canvas")
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext("2d")
      ctx.drawImage(img, 0, 0)
      const pngDataUrl = canvas.toDataURL("image/png")

      callback(pngDataUrl)

      URL.revokeObjectURL(svgUrl)
    }
    img.src = svgUrl
  }

  const handleExportToPng = (ref) => {
    svgToPngDataUrl(ref, (pngDataUrl) => {
      // Now you can use the pngDataUrl, for example:
      const link = document.createElement("a")
      link.href = pngDataUrl
      link.download = "exported_image.png"
      link.click()
    })
  }

  return (
    <Grid item xs={12} md={7} className={classes.grid}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={6} sm={6}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <SurfboardSvg
                  svgRef={surfboardDeckSvgRef}
                  style={{ width: "100%", height: "80vh" }}
                  selectedLogoOption={selectedLogoOption}
                  selectedDeckPatchOption={selectedDeckPatchOption}
                  selectedDeckColorOption={selectedDeckColorOption}
                  selectedBottomColorOption={selectedBottomColorOption}
                  selectedTailPatchOption={selectedTailPatchOption}
                  selectedFinBoxColorOption={selectedFinBoxColorOption}
                  selectedFinSetupOption={selectedFinSetupOption}
                  selectedNoseShapeOption={selectedNoseShapeOption}
                  selectedTailShapeOption={selectedTailShapeOption}
                  selectedPinLineColorOption={selectedPinLineColorOption}
                  selectedLengthOption={selectedLengthOption}
                  selectedWidthOption={selectedWidthOption}
                  selectedStringerTypeOption={selectedStringerTypeOption}
                  selectedNumberOfStringersOption={
                    selectedNumberOfStringersOption
                  }
                  deck={true}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <SurfboardSvg
                  svgRef={surfboardBottomSvgRef}
                  style={{ width: "100%", height: "80vh" }}
                  selectedLogoOption={selectedLogoOption}
                  selectedDeckPatchOption={selectedDeckPatchOption}
                  selectedDeckColorOption={selectedDeckColorOption}
                  selectedBottomColorOption={selectedBottomColorOption}
                  selectedTailPatchOption={selectedTailPatchOption}
                  selectedFinBoxColorOption={selectedFinBoxColorOption}
                  selectedFinSetupOption={selectedFinSetupOption}
                  selectedNoseShapeOption={selectedNoseShapeOption}
                  selectedTailShapeOption={selectedTailShapeOption}
                  selectedPinLineColorOption={selectedPinLineColorOption}
                  selectedLengthOption={selectedLengthOption}
                  selectedWidthOption={selectedWidthOption}
                  selectedStringerTypeOption={selectedStringerTypeOption}
                  selectedNumberOfStringersOption={
                    selectedNumberOfStringersOption
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Alert severity="info">
            <AlertTitle
              style={{
                fontFamily: "Josefin Sans",
              }}
            >
              Mockups
            </AlertTitle>
            Finbox placement and outline not completely accurate.
          </Alert>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default CustomOrderSurfboards
