import React from "react"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"
import SkeletonCard from "../../core/SkeletonCard/SkeletonCard"
import { getCurrencyStringFromNumber } from "../../../Util/Util"
import DescriptionItem from "../../core/DescriptionItem/DescriptionItem"
import { Link } from "react-router-dom"

const StaffCartItems = ({ cartData }) => {
  if (!cartData) {
    return <SkeletonCard />
  }

  const cartItems = cartData.cart_items || []

  return (
    <Grid item>
      <Card id="delete-account">
        <SoftBox pt={3} px={2}>
          <SoftTypography variant="h6" fontWeight="medium">
            Cart Items
          </SoftTypography>
        </SoftBox>
        <SoftBox pt={1} pb={2} px={2}>
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            {cartItems.map((cartItem) => {
              let price = cartItem.product?.price
              let displayImage = cartItem.product?.display_image
              if (cartItem.product_variant) {
                price = cartItem.product_variant.price
                displayImage = cartItem.product_variant.display_image
              }

              let product =
                cartItem.product || cartItem.product_variant?.product

              return (
                <SoftBox
                  key={cartItem.id}
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor="grey-100"
                  borderRadius="lg"
                  p={3}
                  mb={0} // mb={1}
                  mt={2}
                >
                  <SoftBox
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    alignItems={"center"}
                  >
                    <SoftBox pr={3}>
                      <img
                        src={displayImage}
                        alt={"Display Image"}
                        style={{ width: "60px" }}
                      />
                    </SoftBox>
                    <SoftBox width="100%" display="flex" flexDirection="column">
                      <DescriptionItem
                        spacing={"tight"}
                        keyJsx={
                          <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            #{cartItem.id} {cartItem.paid ? "[PAID]" : ""}
                          </SoftTypography>
                        }
                        valueJsx={
                          <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            {getCurrencyStringFromNumber(
                              price * cartItem.quantity
                            )}
                          </SoftTypography>
                        }
                      />
                      {/*</SoftBox>*/}
                      <SoftBox mb={1} lineHeight={0}>
                        <DescriptionItem
                          spacing={"tight"}
                          keyJsx={
                            <SoftTypography variant="caption" color="text">
                              Name
                            </SoftTypography>
                          }
                          valueJsx={
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                            >
                              <Link to={`/staff/products/${product?.id}/`}>
                                {product?.name}
                              </Link>
                            </SoftTypography>
                          }
                        />
                        <DescriptionItem
                          spacing={"tight"}
                          keyJsx={
                            <SoftTypography variant="caption" color="text">
                              Type
                            </SoftTypography>
                          }
                          valueJsx={
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                            >
                              {cartItem.type_display}
                            </SoftTypography>
                          }
                        />
                        <DescriptionItem
                          spacing={"tight"}
                          keyJsx={
                            <SoftTypography variant="caption" color="text">
                              Quantity
                            </SoftTypography>
                          }
                          valueJsx={
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                            >
                              {cartItem.quantity}
                            </SoftTypography>
                          }
                        />
                        <DescriptionItem
                          spacing={"tight"}
                          keyJsx={
                            <SoftTypography variant="caption" color="text">
                              Price
                            </SoftTypography>
                          }
                          valueJsx={
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                            >
                              {getCurrencyStringFromNumber(price)}
                            </SoftTypography>
                          }
                        />
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              )
            })}
          </SoftBox>
        </SoftBox>
      </Card>
    </Grid>
  )
}

export default StaffCartItems
