import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { Link } from "react-router-dom"
import NavBar from "../NavBar/NavBar"
import Axios from "../../Util/Axios"
import { darkBlue } from "../../assets/userTheme"

const Home = () => {
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [customOrderAnchorEl, setCustomOrderAnchorEl] = useState(null)
  const [displayPresets, setDisplayPresets] = useState([])
  const handleCustomOrderMenuOpen = (event) => {
    setCustomOrderAnchorEl(event.currentTarget)
  }
  const handleCustomOrderMenuClose = () => {
    setCustomOrderAnchorEl(null)
  }

  let videoSource =
    // "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/marlinsurfgrainless.mp4"
    // "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/marlinvideobright.mp4"
    "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/marlinsplash2.mp4"
  // const videoSource = null
  // const videoSource =
  //   "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/marlinsurf.mp4"
  let fallbackImage =
    "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/masklogo.jpg"
  const textShadow = "3px 3px 4px #000000"
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))
  if (isMobile) {
    fallbackImage =
      // "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/masklogo.jpg"
      "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/blacknwhitesurf.PNG"
    videoSource = null
    // videoSource = null
  }

  const handleVideoLoaded = () => {
    setVideoLoaded(true)
  }

  function setVH() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  window.addEventListener("resize", setVH)
  setVH()

  useEffect(() => {
    Axios.get("/api/preset-list/?type=display").then(({ data }) => {
      setDisplayPresets(data.results)
    })
  }, [])

  return (
    <React.Fragment>
      <NavBar opacity={0.9} />
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // minHeight: "100vh",
          height: "calc(var(--vh, 1vh) * 100 - 54px)",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        {!videoLoaded && (
          <img
            src={fallbackImage}
            alt="Fallback Image"
            style={{
              position: "absolute",
              width: "100%",
              // left: "50%",
              // top: "50%",
              height: "100%",
              objectFit: "cover",
              // transform: "translate(-50%, -50%)",
              zIndex: "-1",
            }}
          />
        )}
        <video
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            maxHeight: "100%",
            zIndex: "-1",
            opacity: videoLoaded ? 1 : 0,
            transition: "opacity 0.5s ease",
          }}
          autoPlay
          loop
          playsInline
          muted
          onLoadedData={handleVideoLoaded}
        >
          <source src={videoSource} type="video/mp4" />
        </video>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          style={{
            textShadow: "3px 3px 4px #000000",
            color: "white",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          Marlin Surfboards
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ textShadow, color: "white" }}
        >
          Custom made in Santa Barbara, CA
        </Typography>
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "white",
            }}
            onClick={handleCustomOrderMenuOpen}
          >
            <Box
              style={{
                color: "black",
                textDecoration: "none",
                fontFamily: "Josefin Sans",
                fontWeight: "bold",
                verticalAlign: "middle", // added this line
                display: "block", // ensures that padding and other block level styles apply
                paddingTop: 4,
              }}
            >
              {/*<Link*/}
              {/*  to="/custom-surfboard-order"*/}
              {/*  style={{*/}
              {/*    color: "black",*/}
              {/*    textDecoration: "none",*/}
              {/*    fontFamily: "Josefin Sans",*/}
              {/*    fontWeight: "bold",*/}
              {/*    verticalAlign: "middle", // added this line*/}
              {/*    display: "block", // ensures that padding and other block level styles apply*/}
              {/*    paddingTop: 4,*/}
              {/*  }}*/}
              {/*>*/}
              Custom Order
              {/*</Link>*/}
            </Box>
          </Button>

          <Menu
            anchorEl={customOrderAnchorEl}
            open={Boolean(customOrderAnchorEl)}
            onClose={handleCustomOrderMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {displayPresets.map((preset) => {
              return (
                <MenuItem
                  key={preset.id}
                  sx={{
                    fontFamily: "Lobster",
                    color: "black",
                    fontSize: "1.2rem",
                    paddingLeft: 5,
                    paddingRight: 5,
                    "&:hover": {
                      color: "white",
                      backgroundColor: darkBlue,
                    },
                  }}
                  component={Link}
                  to={`/custom-surfboard-order?preset_id=${preset.id}`}
                  onClick={handleCustomOrderMenuClose}
                >
                  {preset.name}
                </MenuItem>
              )
            })}

            {/* Add additional dropdown items here */}
          </Menu>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: "20px", background: "black" }}
          >
            <Link
              to="/about"
              style={{
                color: "white",
                textDecoration: "none",
                fontFamily: "Josefin Sans",
                fontWeight: "bold",
                verticalAlign: "middle", // added this line
                display: "block", // ensures that padding and other block level styles apply
                paddingTop: 4,
              }}
            >
              About Marlin
            </Link>
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default Home
