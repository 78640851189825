import React, { useEffect, useState } from "react"
import { Box, IconButton, Typography } from "@mui/material"
import CustomOrderOptionSelect from "../CustomOrderOptionSelect"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import Collapse from "@mui/material/Collapse"

const Blank = ({
  updateOptions,
  selectedStringerTypeOption,
  setSelectedStringerTypeOption,
  stringerTypeOptions,
  selectedNumberOfStringersOption,
  setSelectedNumberOfStringersOption,
  numberOfStringersOptions,
  selectedFoamDensityOption,
  setSelectedFoamDensityOption,
  foamDensityOptions,
  orderData,
  isStaff,
}) => {
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <React.Fragment>
      <Box
        pt={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Blank</Typography>
        <IconButton onClick={handleClick}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>

      <Collapse in={open}>
        <CustomOrderOptionSelect
          isStaff={isStaff}
          orderData={orderData}
          updateOptions={updateOptions}
          title={"Stringer Type"}
          option={selectedStringerTypeOption}
          setOption={setSelectedStringerTypeOption}
          options={stringerTypeOptions}
        />
        <CustomOrderOptionSelect
          isStaff={isStaff}
          orderData={orderData}
          updateOptions={updateOptions}
          title={"Number of Stringers"}
          option={selectedNumberOfStringersOption}
          setOption={setSelectedNumberOfStringersOption}
          options={numberOfStringersOptions}
        />
        <CustomOrderOptionSelect
          isStaff={isStaff}
          orderData={orderData}
          updateOptions={updateOptions}
          title={"Foam Density"}
          option={selectedFoamDensityOption}
          setOption={setSelectedFoamDensityOption}
          options={foamDensityOptions}
        />
      </Collapse>
    </React.Fragment>
  )
}

export default Blank
