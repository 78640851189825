import React, { useEffect, useState } from "react"
import { Button } from "@mui/material"
import { useDispatch } from "react-redux"
import Axios from "../../Util/Axios"
import Cookies from "js-cookie"
import { updateToastData } from "../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../../components/core/Toast/Toast"

const CheckoutButton = ({ cart }) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {}, [])
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "1em", backgroundColor: "primary", paddingTop: 9 }}
        fullWidth
        disabled={loading}
        onClick={() => {
          setLoading(true)
          const cartId = Cookies.get("cart_id")
          Axios.post(`/api/cart-detail/${cartId}/create_checkout_session/`)
            .then(({ data }) => {
              setLoading(false)
              window.open(data.url, "_self")
            })
            .catch((e) => {
              dispatch(
                updateToastData({
                  message: "Unable to create checkout. Please try again.",
                  severity: TOAST_SEVERITY_ERROR,
                })
              )
              setLoading(false)
            })
        }}
      >
        Checkout
      </Button>
    </React.Fragment>
  )
}

export default CheckoutButton
