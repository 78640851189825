import React from "react"
import NavBar from "../NavBar/NavBar"
import ProductPage from "../ProductPage/ProductPage"
import Page from "../Page/Page"

const ApparelPage = () => {
  return (
    <Page>
      <ProductPage />
    </Page>
  )
}

export default ApparelPage
