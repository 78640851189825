import Card from "@mui/material/Card"

import SoftBox from "soft_dashboard/components/SoftBox"
import SoftTypography from "soft_dashboard/components/SoftTypography"

import OrdersOverviewItem from "./OrderOverviewItem"
import SkeletonCard from "../../core/SkeletonCard/SkeletonCard"
import React from "react"

const OrdersOverview = ({ statsData }) => {
  if (!statsData) {
    return <SkeletonCard />
  }
  return (
    <Card className="h-100">
      <SoftBox pt={3} px={3}>
        <SoftTypography variant="h6" fontWeight="medium">
          Orders Overview
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <OrdersOverviewItem
          color="info"
          icon="surfing"
          title="Custom Boards"
          thisMonthsOrders={statsData?.monthly_custom_orders}
          thisMonthRevenue={statsData?.monthly_custom_revenue}
          totalOrders={statsData?.total_custom_orders}
          totalRevenue={statsData?.total_custom_revenue}
        />
        <OrdersOverviewItem
          color="success"
          icon="cyclone"
          title="Inventory & Apparel"
          thisMonthsOrders={statsData?.monthly_apparel_and_inventory_orders}
          thisMonthRevenue={statsData?.monthly_apparel_and_inventory_revenue}
          totalOrders={statsData?.total_apparel_and_inventory_orders}
          totalRevenue={statsData?.total_apparel_and_inventory_revenue}
        />
        {/*<OrdersOverviewItem*/}
        {/*  color="primary"*/}
        {/*  icon="person"*/}
        {/*  thisMonthsOrders={statsData?.monthly_apparel_orders}*/}
        {/*  thisMonthRevenue={statsData?.monthly_apparel_revenue}*/}
        {/*  totalOrders={statsData?.total_apparel_orders}*/}
        {/*  lastItem={true}*/}
        {/*/>*/}
      </SoftBox>
    </Card>
  )
}

export default OrdersOverview
