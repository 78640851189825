import React from "react"
import { Carousel } from "react-responsive-carousel"
import "./CoreCarousel.css"

const CoreCarousel = ({ images }) => {
  if (!images) {
    return <React.Fragment />
  }

  return (
    <Carousel
      // className="text-center center-thumbnails"
      showArrows={false}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        return <React.Fragment />
      }}
      renderThumbs={(children) => {
        return children.map((child, i) => {
          return (
            <div
              key={i}
              // style={{
              //   border: "none",
              // }}
              // style={{
              //   border: "none",
              //   boxShadow: true
              //     ? "0px 0px 10px 2px rgba(0, 0, 0, 0.1)"
              //     : "none",
              //   outline: "none",
              // }}
            >
              {child}
            </div>
          )
        })
      }}
    >
      {images.map((image, i) => {
        return (
          <div
            key={i}
            style={{
              textAlign: "center",
            }}
          >
            <img
              alt=""
              style={{
                maxWidth: 600,
              }}
              src={image.url}
            />
            {/*<p className="legend">Legend 1</p>*/}
          </div>
        )
      })}
    </Carousel>
  )
}

export default CoreCarousel
