import React, { useState } from "react"
import SoftBox from "../../../../soft_dashboard/components/SoftBox"
import IconButton from "@mui/material/IconButton"
import Axios from "../../../../Util/Axios"
import Toast, {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../../../core/Toast/Toast"
import CloseIcon from "@mui/icons-material/Close"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { updateToastData } from "../../../../slices/toastSlice"

const ProductDraggedImage = ({ productImage, setProductData, productData }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <SoftBox p={2} position="relative">
      <img
        height={100}
        src={productImage.url}
        className="App-logo"
        alt="logo"
      />
      <IconButton
        style={{
          position: "absolute",
          right: -4,
          top: -4,
          color: loading ? "grey" : "red",
        }}
        disabled={loading}
        onMouseDown={(event) => {
          if (loading) {
            return
          }

          setLoading(true)
          Axios.delete(`/api/staff-product-image/${productImage.id}/`, navigate)
            .then((x) => {
              dispatch(
                updateToastData({
                  message: "Image deleted.",
                  severity: TOAST_SEVERITY_SUCCESS,
                })
              )
              setLoading(false)
              setProductData({
                ...productData,
                product_images: productData.product_images.filter((x) => {
                  return x.id !== productImage.id
                }),
              })
            })
            .catch((e) => {
              dispatch(
                updateToastData({
                  message: "Image deleted.",
                  severity: TOAST_SEVERITY_ERROR,
                })
              )
              setLoading(false)
            })
        }}
      >
        <CloseIcon />
      </IconButton>
    </SoftBox>
  )
}

export default ProductDraggedImage
