import React from "react"
import SkeletonCard from "../../core/SkeletonCard/SkeletonCard"
import Card from "@mui/material/Card"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"
import DescriptionItem from "../../core/DescriptionItem/DescriptionItem"
import { getCurrencyStringFromNumber } from "../../../Util/Util"
import Grid from "@mui/material/Grid"
import SoftBadge from "../../../soft_dashboard/components/SoftBadge"

const ProductVariants = ({ productData }) => {
  if (!productData) {
    return <SkeletonCard />
  }
  const productVariants = productData?.product_variants || []

  if (productVariants.length === 0) {
    return <React.Fragment />
  }

  return (
    <Grid item>
      <Card>
        <SoftBox pt={3} px={2}>
          <SoftTypography variant="h6" fontWeight="medium">
            Product Variants
          </SoftTypography>
        </SoftBox>
        <SoftBox pt={1} pb={2} px={2}>
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            {productVariants.map((productVariant, i) => {
              return (
                <SoftBox
                  key={i}
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor="grey-100"
                  borderRadius="lg"
                  p={3}
                  mb={0}
                  mt={2}
                >
                  <SoftBox width="100%" display="flex" flexDirection="column">
                    <DescriptionItem
                      spacing={"tight"}
                      keyJsx={
                        <SoftBox display="flex" alignItems="center">
                          {productVariant.product_variant_images.map(
                            (productVariantImage) => {
                              return (
                                <img
                                  width={50}
                                  height={50}
                                  src={productVariantImage.url}
                                  alt={productVariantImage.name}
                                />
                              )
                            }
                          )}
                          <SoftBox ml={1} display="flex" flexDirection="column">
                            {productVariant.archived && (
                              <SoftBadge
                                variant="gradient"
                                badgeContent={"Archived"}
                                color={"warning"}
                                size="xs"
                                container
                              />
                            )}
                            <SoftTypography
                              variant="button"
                              fontWeight="medium"
                            >
                              {productVariant.name}
                            </SoftTypography>

                            <SoftTypography variant="caption">
                              External ID: {productVariant.external_id}
                            </SoftTypography>
                            <SoftTypography variant="caption">
                              Cost: ${productVariant.cost}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      }
                      valueJsx={
                        <SoftTypography
                          variant="button"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          {getCurrencyStringFromNumber(productVariant.price)}
                        </SoftTypography>
                      }
                    />
                  </SoftBox>
                </SoftBox>
              )
            })}
          </SoftBox>
        </SoftBox>
      </Card>
    </Grid>
  )
}

export default ProductVariants
