import React from "react"
import TextField from "@mui/material/TextField"

const CustomTextField = ({ children, ...props }) => {
  return (
    <TextField
      {...props}
      InputLabelProps={{
        ...props.InputLabelProps,
        shrink: true,
      }}
    >
      {children} // Render any children inside the TextField
    </TextField>
  )
}

export default CustomTextField
