import React, { useEffect, useState } from "react"
import DashboardLayout from "../../../soft_dashboard/examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "../../../soft_dashboard/examples/Navbars/DashboardNavbar"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import Footer from "../../core/Footer/Footer"
import { useNavigate } from "react-router-dom"
import Axios from "../../../Util/Axios"
import Card from "@mui/material/Card"
import DescriptionItem from "../../core/DescriptionItem/DescriptionItem"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"
import Button from "@mui/material/Button"
import {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../../core/Toast/Toast"
import Typography from "@mui/material/Typography"
import { DropzoneDialog } from "react-mui-dropzone"
import ImageDrag from "../../core/ImageDrag/ImageDrag"
import SkeletonCard from "../../core/SkeletonCard/SkeletonCard"
import GalleryGalleryImage from "./GalleryDraggedImage"
import { useDispatch } from "react-redux"
import { updateToastData } from "../../../slices/toastSlice"
import { uploadFileToAws } from "../../../Util/Aws"

const StaffGalleryPage = ({}) => {
  const navigate = useNavigate()

  const [dropzoneModalActive, setDropzoneModalActive] = useState(false)

  const [galleryImages, setGalleryImages] = useState(null)

  const dataUrl = "/api/staff-site-configuration/1/"
  const dispatch = useDispatch()
  useEffect(() => {
    Axios.get(`${dataUrl}action_get_gallery_images/`, navigate).then(
      ({ data }) => {
        setGalleryImages(data)
      }
    )
  }, [])

  if (!galleryImages) {
    return (
      <DashboardLayout>
        <DashboardNavbar pageTitle={"Gallery"} /> <SkeletonCard />{" "}
      </DashboardLayout>
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={"Gallery"} />
      <Card>
        <SoftBox pt={2} px={2}>
          <DescriptionItem
            keyJsx={
              <SoftTypography variant="h6" fontWeight="medium">
                Gallery Images
              </SoftTypography>
            }
            valueJsx={
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <SoftBox display="flex" alignItems="center" ml={2}>
                  <Button
                    variant="contained"
                    color={"primary"}
                    sx={{ mt: 1, width: "100%" }}
                    disabled={dropzoneModalActive}
                    onClick={() => {
                      setDropzoneModalActive(true)
                    }}
                  >
                    <Typography variant="caption" style={{ color: "white" }}>
                      Upload Images
                    </Typography>
                  </Button>
                </SoftBox>
              </SoftBox>
            }
          />
        </SoftBox>
        <SoftBox pt={1} pb={2} px={2}>
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            <DropzoneDialog
              open={dropzoneModalActive}
              acceptedFiles={[
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/bmp",
              ]}
              onSave={(files) => {
                let promises = []
                for (let file of files) {
                  promises.push(uploadFileToAws(file))
                }

                Axios.all(promises)
                  .then((resps) => {
                    let url = `${dataUrl}action_add_gallery_images/`
                    Axios.post(
                      url,
                      {
                        urls: resps,
                      },
                      navigate
                    )
                      .then(({ data }) => {
                        setDropzoneModalActive(false)
                        setGalleryImages(data)
                        dispatch(
                          updateToastData({
                            message: "Successfully Uploaded Files",
                            severity: TOAST_SEVERITY_SUCCESS,
                          })
                        )
                      })
                      .catch((e) => {
                        console.log("ERROR", e)
                        dispatch(
                          updateToastData({
                            message: e,
                            severity: TOAST_SEVERITY_ERROR,
                          })
                        )
                      })
                  })
                  .catch((e) => {
                    dispatch(
                      updateToastData({
                        message: e,
                        severity: TOAST_SEVERITY_ERROR,
                      })
                    )
                  })
              }}
              showPreviews={true}
              maxFileSize={50000000}
              onClose={() => {
                setDropzoneModalActive(false)
              }}
            />
          </SoftBox>
        </SoftBox>
        <ImageDrag
          draggedImages={galleryImages}
          setDraggedImages={setGalleryImages}
          setPositionUpdated={() => {
            Axios.post(`${dataUrl}action_save_gallery_image_positions/`, {
              gallery_images_data: galleryImages,
            })
              .then((x) => {
                dispatch(
                  updateToastData({
                    message: "Position Saved.",
                    severity: TOAST_SEVERITY_SUCCESS,
                  })
                )
              })
              .catch((e) => {
                dispatch(
                  updateToastData({
                    message: e,
                    severity: TOAST_SEVERITY_ERROR,
                  })
                )
              })
          }}
          imageComponent={(imageObject) => {
            return (
              <GalleryGalleryImage
                galleryImage={imageObject}
                galleryImages={galleryImages}
                setGalleryImages={setGalleryImages}
              />
            )
          }}
        />
      </Card>

      <Footer />
    </DashboardLayout>
  )
}

export default StaffGalleryPage
