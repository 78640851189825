import React from "react"
import { useSearchParams } from "react-router-dom"
import { Pagination } from "@mui/material"

const DataTablePaginate = ({ currentPage, totalPages, getData }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  return (
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={(event, value) => {
        searchParams.set("page", value)
        setSearchParams(searchParams)
        getData(value)
      }}
    />
  )
}

export default DataTablePaginate
