import React, { useState } from "react"
import { Box, Collapse, IconButton, Typography } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import CustomOrderOptionSelect from "../CustomOrderOptionSelect"

const Shape = ({
  updateOptions,
  selectedFinSetupOption,
  setSelectedFinSetupOption,
  finSetupOptions,
  selectedNoseShapeOption,
  setSelectedNoseShapeOption,
  noseShapeOptions,
  selectedTailShapeOption,
  setSelectedTailShapeOption,
  tailShapeOptions,
  selectedRockerOption,
  setSelectedRockerOption,
  rockerOptions,
  orderData,
  isStaff,
}) => {
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <Box
        pt={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Shape</Typography>
        <IconButton onClick={handleClick}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>

      <Collapse in={open}>
        <CustomOrderOptionSelect
          isStaff={isStaff}
          orderData={orderData}
          updateOptions={updateOptions}
          title={"Nose Shape"}
          option={selectedNoseShapeOption}
          setOption={setSelectedNoseShapeOption}
          options={noseShapeOptions}
        />
        <CustomOrderOptionSelect
          isStaff={isStaff}
          orderData={orderData}
          updateOptions={updateOptions}
          title={"Tail Shape"}
          option={selectedTailShapeOption}
          setOption={setSelectedTailShapeOption}
          options={tailShapeOptions}
        />
        <CustomOrderOptionSelect
          isStaff={isStaff}
          orderData={orderData}
          updateOptions={updateOptions}
          title={"Fin Setup"}
          option={selectedFinSetupOption}
          setOption={setSelectedFinSetupOption}
          options={finSetupOptions}
        />
        <CustomOrderOptionSelect
          isStaff={isStaff}
          orderData={orderData}
          updateOptions={updateOptions}
          title={"Rocker"}
          option={selectedRockerOption}
          setOption={setSelectedRockerOption}
          options={rockerOptions}
        />
      </Collapse>
    </React.Fragment>
  )
}

export default Shape
