import React from "react"
import SoftBadge from "../../soft_dashboard/components/SoftBadge"

export const PRODUCT_STATUS_DRAFT = "draft"
export const PRODUCT_STATUS_LIVE = "live"
export const PRODUCT_STATUS_SOLD = "sold"
export const PRODUCT_STATUS_ARCHIVED = "archived"

const ProductStatusBadge = ({ status, statusDisplay }) => {
  let color = "light"
  if (status === PRODUCT_STATUS_DRAFT) {
    color = "light"
  } else if (status === PRODUCT_STATUS_LIVE) {
    color = "success"
  } else if (status === PRODUCT_STATUS_SOLD) {
    color = "success"
  } else if (status === PRODUCT_STATUS_ARCHIVED) {
    color = "warning"
  }

  return (
    <SoftBadge
      variant="gradient"
      badgeContent={statusDisplay}
      color={color}
      size="xs"
      container
    />
  )
}

export default ProductStatusBadge
