import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom"
import DashboardLayout from "../../soft_dashboard/examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "../../soft_dashboard/examples/Navbars/DashboardNavbar"
import SoftBox from "../../soft_dashboard/components/SoftBox"

import Footer from "../core/Footer/Footer"
import Axios from "../../Util/Axios"
import DescriptionItem from "../core/DescriptionItem/DescriptionItem"
import SoftTypography from "../../soft_dashboard/components/SoftTypography"
import Card from "@mui/material/Card"
import CustomTextField from "../core/CustomTextField/CustomTextField"
import Button from "@mui/material/Button"
import { updateToastData } from "../../slices/toastSlice"
import {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../core/Toast/Toast"
import { useDispatch } from "react-redux"

const StaffSettings = ({ prop }) => {
  const [siteConfiguration, setSiteConfiguration] = useState(false)
  const [accessToken, setAccessToken] = useState("")
  const [loading, setLoading] = useState(false)
  const dataUrl = `/api/staff-site-configuration/1/`
  const dispatch = useDispatch()

  useEffect(() => {
    Axios.get(dataUrl).then(({ data }) => {
      setSiteConfiguration(data)
      setAccessToken(data.instagram_long_lived_access_token)
    })
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar
        pageTitle={
          <SoftBox display="flex" alignItems="center">
            <SoftBox>Settings</SoftBox>
          </SoftBox>
        }
      />
      <SoftBox mt={4} mb={1.5}>
        <Card sx={{ height: "100%" }}>
          <SoftBox p={2}>
            <DescriptionItem
              keyJsx={
                <div>
                  <SoftTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                  >
                    Instagram Access Token
                  </SoftTypography>

                  <SoftTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                  >
                    Expires:{" "}
                    {
                      siteConfiguration?.instagram_long_lived_access_token_expires_at
                    }
                  </SoftTypography>
                </div>
              }
              valueJsx={
                <div
                  style={{ display: "flex", gap: "16px", alignItems: "center" }}
                >
                  <CustomTextField
                    disabled={loading}
                    value={accessToken}
                    fullWidth={true}
                    onChange={(event) => {
                      setAccessToken(event.target.value)
                    }}
                  ></CustomTextField>
                  <Button
                    variant="contained"
                    color={"info"}
                    disabled={loading}
                    fullWidth
                    onClick={() => {
                      setLoading(true)
                      Axios.patch(dataUrl, {
                        instagram_long_lived_access_token: accessToken,
                        instagram_long_lived_access_token_expires_at: null,
                      })
                        .then(({ data }) => {
                          setSiteConfiguration({
                            ...siteConfiguration,
                            instagram_long_lived_access_token_expires_at:
                              data.instagram_long_lived_access_token_expires_at,
                          })
                          setLoading(false)
                          dispatch(
                            updateToastData({
                              message: "Instagram Access Token Updated",
                              severity: TOAST_SEVERITY_SUCCESS,
                            })
                          )
                        })
                        .catch((e) => {
                          setLoading(false)
                          dispatch(
                            updateToastData({
                              message: "Instagram Access Token not Updated",
                              severity: TOAST_SEVERITY_ERROR,
                            })
                          )
                        })
                    }}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}

export default StaffSettings
