import React from "react"
import { CircularProgress } from "@mui/material"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"

const TextButton = ({
  onClick,
  children,
  loading,
  disabled,
  variant = "h6",
  fontWeight = "medium",
}) => {
  if (loading) {
    return <CircularProgress />
  }
  let color = "info"
  if (disabled) {
    color = "secondary"
  }
  return (
    <SoftTypography
      onClick={() => {
        if (!disabled && onClick) {
          onClick()
        }
      }}
      style={{
        cursor: !disabled ? "pointer" : null,
      }}
      color={color}
      variant={variant}
      fontWeight={fontWeight}
    >
      {children}
    </SoftTypography>
  )
}

export default TextButton
