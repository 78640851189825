import * as React from "react"

const PerformanceSwallowSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 973.455

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M97.746,914.464 c0,0-48.81-176.452-48.81-412.846c0-381.031,116.005-481.915,116.005-481.915s116.01,98.786,116.01,479.577 c0,268.733-55.766,415.222-55.766,415.222l-60.242-21.274L97.746,914.464z" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={18}
        opacity={overlapOpacity}
      >
        <path d=" M105.902,900.261c0,0-45.786-167.396-45.786-397.279c0-361.646,104.825-468.646,104.825-468.646s104.83,102.916,104.83,466.374 c0,261.334-51.696,399.519-51.696,399.519l-53.132-18.494L105.902,900.261z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M97.915,914.895 c0,0-56.673-195.5-46.672-518.375l113.178-80.53l114.226,80.53c11.428,372.04-53.571,518.375-53.571,518.375l-60.132-21.667 L97.915,914.895z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M97.746,914.464 c0,0-58.504-204.736-46.503-517.944c0.001,0,102.697-181.192,227.403,0c13.596,318.708-53.461,517.982-53.461,517.982 l-60.242-21.274L97.746,914.464z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M97.746,914.464 c0,0-60.504-223.236-46.15-517.944c0,0,41.487-9.654,72.761-34.167c31.274-24.513,40.918-60.931,40.918-60.931 s12.188,36.501,38.186,59.53c28.464,25.213,74.537,35.568,74.537,35.568c15.244,315.208-52.813,517.982-52.813,517.982 l-60.242-21.274L97.746,914.464z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M97.746,914.464 c0,0-23.504-72.729-38.077-226.871l105.642-30.605l105.047,30.605c-14.615,154.143-45.172,226.909-45.172,226.909l-60.24-21.274 L97.746,914.464z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M97.746,914.464 c0,0-23.504-79.236-38.145-226.871l105.709,44.734l105.114-44.734c-12.474,149.135-45.239,226.909-45.239,226.909l-60.24-21.274 L97.746,914.464z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path d="M97.746,914.464 c0,0-22.504-72.729-38.077-226.871c0,0,49.281-2.226,76.424-15.644c27.143-13.416,29.218-22.222,29.218-22.222 s10.607,16.109,24.578,21.56c19.456,7.592,79.536,16.306,79.536,16.306c-11.474,143.101-44.239,226.909-44.239,226.909 l-60.24-21.274L97.746,914.464z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M97.746,914.464 c0,0-23.504-77.736-38.605-227.544c0,0,73.398-3.903,87.368,16.223c13.97,20.126,19.465,34.092,19.465,34.092 s8.102-18.4,27.532-34.106c20.242-16.36,77.356-16.208,77.356-16.208c-12.912,140.808-45.678,227.582-45.678,227.582l-60.24-21.274 L97.746,914.464z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M97.746,914.464 c0,0-18.003-67.98-36.63-205.841c-0.819-6.795-0.682-13.804-1.445-21.032c-0.001,0.002,101.469-68.765,209.754,0.002 c0,0,0.713,12.001,0,17.855c-16.683,147.413-44.239,209.054-44.239,209.054l-60.24-21.274L97.746,914.464z" />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={242.207} y1={182.394} x2={242.207} y2={852.43} />
          <line x1={83.915} y1={182.394} x2={83.915} y2={852.43} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={153.069} y1={32.739} x2={153.069} y2={897.02} />
          <line x1={177.053} y1={32.739} x2={177.053} y2={897.02} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.061} y1={19.703} x2={165.061} y2={891.894} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M171.816,848.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V738.181c0-2.229,1.67-4.107,3.58-4.107h5.487c2.029,0,3.58,1.879,3.58,4.107V848.752z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,848.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V738.181c0-2.229,1.67-4.107,3.58-4.107h5.487c2.029,0,3.58,1.879,3.58,4.107V848.752z" />
          <path d="M94.858,760.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.23,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.35,3.102,4.577L94.858,760.854z" />
          <path d="M249.374,758.861c0.356,2.231-1.075,4.343-2.983,4.693 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.778c-0.357-2.23,1.072-4.343,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,758.861z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M94.858,761.088c-0.238,2.23-2.027,3.874-4.056,3.523 l-5.013-0.705c-2.028-0.235-3.339-2.348-3.101-4.577l5.248-46.014c0.24-2.23,2.029-3.871,4.057-3.52l5.011,0.703 c2.028,0.234,3.341,2.347,3.102,4.577L94.858,761.088z" />
          <path d="M249.374,759.093c0.356,2.229-1.075,4.344-2.983,4.697 l-5.014,0.818c-2.027,0.354-3.816-1.174-4.175-3.404l-6.204-45.779c-0.357-2.229,1.072-4.342,2.984-4.694l5.011-0.82 c1.909-0.351,3.817,1.175,4.176,3.403L249.374,759.093z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,760.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.23,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.35,3.102,4.577L94.858,760.854z" />
          <path d="M249.374,758.861c0.356,2.231-1.075,4.343-2.983,4.693 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.778c-0.357-2.23,1.072-4.343,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,758.861z" />
          <path d="M171.58,864.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.483c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V864.979z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,760.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.23,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.35,3.102,4.577L94.858,760.854z" />
          <path d="M119.414,827.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.362c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V827.761z" />
          <path d="M249.374,758.861c0.356,2.231-1.075,4.343-2.983,4.693 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.778c-0.357-2.23,1.072-4.343,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,758.861z" />
          <path d="M230.941,826.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.251-1.878-4.251-4.105v-46.365c0-2.23,1.985-4.105,4.251-4.105h6.096c2.408,0,4.25,1.877,4.25,4.105V826.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,848.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V738.181c0-2.229,1.67-4.107,3.58-4.107h5.487c2.029,0,3.58,1.879,3.58,4.107V848.752z" />
          <path d="M94.858,760.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.23,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.35,3.102,4.577L94.858,760.854z" />
          <path d="M119.414,827.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.362c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V827.761z" />
          <path d="M249.374,758.861c0.356,2.231-1.075,4.343-2.983,4.693 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.778c-0.357-2.23,1.072-4.343,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,758.861z" />
          <path d="M230.941,826.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.251-1.878-4.251-4.105v-46.365c0-2.23,1.985-4.105,4.251-4.105h6.096c2.408,0,4.25,1.877,4.25,4.105V826.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,760.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.23,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.35,3.102,4.577L94.858,760.854z" />
          <path d="M119.414,827.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.362c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V827.761z" />
          <path d="M249.374,758.861c0.356,2.231-1.075,4.343-2.983,4.693 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.778c-0.357-2.23,1.072-4.343,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,758.861z" />
          <path d="M230.941,826.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.251-1.878-4.251-4.105v-46.365c0-2.23,1.985-4.105,4.251-4.105h6.096c2.408,0,4.25,1.877,4.25,4.105V826.588z" />
          <path d="M171.58,864.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.483c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V864.979z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={535.58}
          width={96}
          height={92.504}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d="M114.652,886.511 c0,0-42.716-157.937-42.716-379.908c0-357.79,93.005-452.518,93.005-452.518s93.01,92.761,93.01,450.322 c0,252.341-47.051,380.82-47.051,380.82l-46.658-15.788L114.652,886.511z" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d=" M97.746,914.464c0,0-48.81-176.452-48.81-412.846c0-381.031,116.005-481.915,116.005-481.915s116.01,98.786,116.01,479.577 c0,268.733-55.766,415.222-55.766,415.222l-60.125-21.274L97.746,914.464z" />
      </g>
    </svg>
  )
}
export default PerformanceSwallowSvg
