import * as React from "react"

const HybridCrescentSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 932.953

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M90.146,919.587 c0,0-65.249-129.009-68.249-497.437c-3-368.426,143-404.764,143-404.764S310.9,53.724,307.9,422.15 c-3.002,368.428-67.404,497.792-67.404,497.792S165.551,880.697,90.146,919.587z" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path d=" M95.617,904.977c0,0-59.556-121.451-62.318-480.466c-2.76-359.008,131.6-394.417,131.6-394.417S299.26,65.503,296.5,424.511 c-2.764,359.011-61.691,481.092-61.691,481.092S159.242,873.352,95.617,904.977z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M90.146,919.587 c0,0-65.249-129.009-68.249-497.437l142.344-81.626L307.9,422.15c-3.002,368.428-67.404,497.792-67.404,497.792 S167.051,880.947,90.146,919.587z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M90.146,919.587 c0,0-65.249-129.009-68.249-497.437c0,0,129.161-183.66,286.002,0c-3.002,368.428-67.404,497.792-67.404,497.792 S170.301,881.197,90.146,919.587z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M90.591,919.587 c0,0-65.249-129.009-68.249-497.437c0,0,52.178-9.786,91.511-34.633c39.333-24.846,51.463-61.761,51.463-61.761 s15.33,36.998,48.026,60.341c35.797,25.556,95.002,36.052,95.002,36.052c-3.002,368.428-67.404,497.792-67.404,497.792 S167.689,880.947,90.591,919.587z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.146,919.587 c0,0-36.271-71.715-55.97-260.899l131.167-38.865l130.419,38.865c-19.65,182.602-55.267,261.254-55.267,261.254 S166.551,881.197,90.146,919.587z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.146,919.587 c0,0-36.271-71.715-55.97-260.899l131.167,56.803l130.419-56.803c-19.65,182.602-55.267,261.254-55.267,261.254 S168.301,881.197,90.146,919.587z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path d="M90.146,919.587 c0,0-36.271-71.715-55.97-260.899c0,0,60.565-2.829,94.565-19.866s36.602-28.219,36.602-28.219s13.287,20.455,30.787,27.376 c24.373,9.641,99.632,20.709,99.632,20.709c-19.65,182.602-55.267,261.254-55.267,261.254S165.801,880.697,90.146,919.587z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M89.419,919.78 c0,0-36.271-71.715-55.97-260.899c0,0,89.565-4.958,107.065,20.599c17.5,25.556,24.385,43.291,24.385,43.291 s10.15-23.367,34.491-43.311c25.352-20.772,95.645-20.579,95.645-20.579c-19.65,182.602-55.267,261.254-55.267,261.254 S167.346,880.584,89.419,919.78z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.146,919.587 c0,0-32.837-64.926-52.992-234.194c-1.027-8.628-2.022-17.529-2.978-26.705c0,0,125.94-87.325,261.586,0 c0,0-1.711,15.437-2.606,22.87c-20.026,166.318-52.66,238.384-52.66,238.384S164.242,880.227,90.146,919.587z" />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={264.587} y1={133.428} x2={264.587} y2={847.928} />
          <line x1={65.503} y1={133.428} x2={65.503} y2={847.928} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={149.963} y1={22.448} x2={149.963} y2={902.701} />
          <line x1={180.127} y1={22.448} x2={180.127} y2={902.701} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.045} y1={17.386} x2={165.045} y2={902.409} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M173.396,841.474c0,2.891-2.129,5.319-4.559,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V698.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.559,2.433,4.559,5.318V841.474z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M173.396,841.474c0,2.891-2.129,5.319-4.559,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V698.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.559,2.433,4.559,5.318V841.474z" />
          <path d="M75.386,727.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,727.686z" />
          <path d="M272.169,725.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,725.104z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M75.386,727.989c-0.304,2.887-2.583,5.015-5.166,4.559 l-6.384-0.912c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.013,5.167-4.559l6.381,0.913 c2.583,0.304,4.255,3.038,3.951,5.924L75.386,727.989z" />
          <path d="M272.169,725.404c0.455,2.889-1.37,5.624-3.8,6.079l-6.385,1.063 c-2.582,0.456-4.861-1.52-5.317-4.408l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.078l6.381-1.063 c2.432-0.455,4.863,1.521,5.318,4.406L272.169,725.404z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,727.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,727.686z" />
          <path d="M272.169,725.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,725.104z" />
          <path d="M173.094,862.479c0,2.889-2.129,5.317-4.559,5.317H162 c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.317,4.559-5.317h6.535c2.583,0,4.559,2.43,4.559,5.317V862.479z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,727.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,727.686z" />
          <path d="M106.658,814.299c0,2.891-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.431-5.415-5.319v-60.021c0-2.889,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V814.299z" />
          <path d="M272.169,725.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,725.104z" />
          <path d="M248.695,812.78c0,2.888-2.525,5.317-5.414,5.317h-7.761 c-3.067,0-5.414-2.431-5.414-5.317v-60.022c0-2.887,2.528-5.317,5.414-5.317h7.761c3.068,0,5.414,2.432,5.414,5.317V812.78z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M173.396,841.474c0,2.891-2.129,5.319-4.559,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V698.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.559,2.433,4.559,5.318V841.474z" />
          <path d="M75.386,727.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,727.686z" />
          <path d="M106.658,814.299c0,2.891-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.431-5.415-5.319v-60.021c0-2.889,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V814.299z" />
          <path d="M272.169,725.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,725.104z" />
          <path d="M248.695,812.78c0,2.888-2.525,5.317-5.414,5.317h-7.761 c-3.067,0-5.414-2.431-5.414-5.317v-60.022c0-2.887,2.528-5.317,5.414-5.317h7.761c3.068,0,5.414,2.432,5.414,5.317V812.78z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,727.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,727.686z" />
          <path d="M106.658,814.299c0,2.891-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.431-5.415-5.319v-60.021c0-2.889,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V814.299z" />
          <path d="M272.169,725.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,725.104z" />
          <path d="M248.695,812.78c0,2.888-2.525,5.317-5.414,5.317h-7.761 c-3.067,0-5.414-2.431-5.414-5.317v-60.022c0-2.887,2.528-5.317,5.414-5.317h7.761c3.068,0,5.414,2.432,5.414,5.317V812.78z" />
          <path d="M173.094,862.479c0,2.889-2.129,5.317-4.559,5.317H162 c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.317,4.559-5.317h6.535c2.583,0,4.559,2.43,4.559,5.317V862.479z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={492.133}
          width={96}
          height={91.001}
          href={logoUrl}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d=" M102.409,890.262c0,0-51-110.808-58.131-466.331C41.749,77.395,164.898,43.217,164.898,43.217s123.152,34.178,120.622,380.713 c-6.742,346.492-57.343,468.046-57.343,468.046S166.075,864.547,102.409,890.262z" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d=" M90.146,919.587c0,0-63.404-99.032-68.249-497.437c-4.479-368.41,143-404.764,143-404.764S310.9,53.724,307.9,422.15 c-3.002,368.428-67.404,497.792-67.404,497.792S165.551,881.469,90.146,919.587z" />
      </g>
    </svg>
  )
}
export default HybridCrescentSvg
