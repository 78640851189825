// import Inventory2TwoToneIcon from "@mui/icons-material/Inventory2TwoTone"
import Inventory2Icon from "@mui/icons-material/Inventory2"
import DashboardIcon from "@mui/icons-material/Dashboard"
import PublicIcon from "@mui/icons-material/Public"
import SellIcon from "@mui/icons-material/Sell"
import CollectionsIcon from "@mui/icons-material/Collections"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import SettingsIcon from "@mui/icons-material/Settings"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import SurfingIcon from "@mui/icons-material/Surfing"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
const routes = [
  {
    type: "collapse",
    name: "Public Site",
    key: "public-site",
    route: "/",
    icon: <PublicIcon />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/staff",
    icon: <DashboardIcon />,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    route: "/staff/orders?status=not_draft",
    icon: <Inventory2Icon />,
  },
  {
    type: "collapse",
    name: "Options",
    key: "options",
    route: "/staff/options",
    icon: <SurfingIcon />,
  },
  {
    type: "collapse",
    name: "Presets",
    key: "presets",
    route: "/staff/presets",
    icon: <ContentCopyIcon />,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    route: "/staff/products?page=1",
    icon: <SellIcon />,
  },
  {
    type: "collapse",
    name: "Gallery",
    key: "gallery",
    route: "/staff/gallery",
    icon: <CollectionsIcon />,
  },
  {
    type: "collapse",
    name: "Carts",
    key: "carts",
    route: "/staff/carts",
    icon: <ShoppingCartIcon />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/staff/settings",
    icon: <SettingsIcon />,
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/staff/profile",
  //   icon: <AccountBoxIcon />,
  // },
]

export default routes
