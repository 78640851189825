import React, { useEffect, useState } from "react"
import DashboardLayout from "../../../soft_dashboard/examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "../../../soft_dashboard/examples/Navbars/DashboardNavbar"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import Grid from "@mui/material/Grid"
import Footer from "../../core/Footer/Footer"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom"
import Axios from "../../../Util/Axios"
import ProductInfo from "./ProductInfo"
import ProductOptions from "./ProductOptions"
import ProductImagesJsx from "./ProductImages/ProductImagesJsx"
import ProductDescription from "./ProductDescription"
import ProductName from "./ProductName"
import ProductStatusBadge from "../ProductStatusBadge"
import ProductVariants from "./ProductVariants"

const StaffProductPage = ({ match }) => {
  const { id } = useParams()
  const [productData, setProductData] = useState("")
  const navigate = useNavigate()
  const dataUrl = `/api/staff-product-detail/${id}/`
  useEffect(() => {
    Axios.get(dataUrl, navigate).then(({ data }) => {
      setProductData(data)
    })
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar
        pageTitle={
          <SoftBox display="flex" alignItems="center">
            <SoftBox>
              Product #{productData?.id || ""} {productData?.name || ""}
            </SoftBox>
            <SoftBox ml={1}>
              {productData && (
                <ProductStatusBadge
                  status={productData.status}
                  statusDisplay={productData.status_display}
                />
              )}
            </SoftBox>
          </SoftBox>
        }
      />
      <SoftBox mt={4}>
        <SoftBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container direction="column" spacing={3}>
                <ProductName
                  productData={productData}
                  setProductData={setProductData}
                />
                <ProductImagesJsx
                  productData={productData}
                  setProductData={setProductData}
                />
                <ProductOptions productData={productData} />
                <ProductVariants productData={productData} />

                {/*<LineItemsJsx productData={productData} />*/}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container direction="column" spacing={3}>
                <ProductInfo
                  productData={productData}
                  setProductData={setProductData}
                />

                <ProductDescription
                  productData={productData}
                  setProductData={setProductData}
                />

                {/*<Grid item>*/}
                {/*  <Address productData={productData} />*/}
                {/*</Grid>*/}
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}

export default StaffProductPage
