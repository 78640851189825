import React from "react"
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Page from "../Page/Page"
import Contact from "../Contact/Contact"
import { TOAST_SEVERITY_SUCCESS } from "../../components/core/Toast/Toast"
import { updateToastData } from "../../slices/toastSlice"
import { useDispatch } from "react-redux"

const Faq = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const questions = [
    {
      question: "What’s the process of ordering a custom?",
      answer:
        "Either filling out the order form online here or contacting me directly is the best way to get started. I have a few models that I do fairly consistently for people but a lot of more unique, made to order shapes as well. I just need the details of your order along with a deposit of half of the final price up front to get started. I’ll keep you posted on progress through shaping and glassing, then let you know when it’s available for pick up.",
      url: "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/sander.jpg",
    },
    {
      question: "How long does a custom order take?",
      answer:
        "Can be a few days or a few weeks, depending on how busy my book is and the time of year. Average is around 1-3 weeks probably, I don’t like to keep orders sitting for too long. Ordering a blank with custom additions will usually add 1-2 weeks to turn around time.",
      url: "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/faqbannersurfresized.jpg",
    },

    {
      question: "Who glasses the boards?",
      answer:
        "They’re all done in house currently, but that may change in the future. Usually do a full 6 ounce fiberglass layup for durability and strength but can use different cloth weights on request. Also available for glassing if you’re shaping or have a shaped blank already.",
      url: "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/brushybrushy.jpg",
    },
    {
      question: "Do you ship?",
      answer:
        "For the most part, no. Freight for boards is extremely expensive and risky for damage. Local pickup is preferred, or delivery is available in Santa Barbara. Occasionally I can send boards on a road trip but that has to be done on a case by case basis.",

      url: "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/surferdudeagain.jpg",
    },
    {
      question: "Can you do inlays?",
      answer:
        "Yes, fabrics, flowers or various other things can be glassed into boards. I don’t stock much fabric or flowers so you will have to source the materials. Anything 1-3 mm thick and not soluble in resin should work.",
      url: "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/purpinlay.jpg",
    },
  ]

  return (
    <Page>
      <Box
        pb={isMobile ? 5 : 15}
        px={isMobile ? 2 : 10}
        sx={{ color: "black", minHeight: "100vh" }}
      >
        <Container>
          <Box pt={5} textAlign="left">
            {isMobile && (
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                sx={{ mb: 4, fontSize: "2rem" }}
              >
                FAQ
              </Typography>
            )}

            {!isMobile && (
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                sx={{ mb: 4 }}
              >
                FAQ
              </Typography>
            )}

            <img
              src="https://marlinsurf-assets.s3.us-west-1.amazonaws.com/DSC0035.jpg"
              alt="Description for accessibility"
              style={{
                maxWidth: isMobile ? "100%" : "90%",
                margin: "16px auto",
                display: "block",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              }}
            />
            {questions.map((faq, index) => (
              <Box key={index} mt={7}>
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    fontFamily: "Josefin Sans, sans-serif",
                    fontWeight: "Bold",
                  }}
                >
                  {faq.question}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Josefin Sans, Arial, sans-serif",
                    fontSize: isMobile ? "1.1rem" : null,
                  }}
                >
                  {faq.answer}
                </Typography>
                {faq.url && (
                  <Box pt={2}>
                    <img
                      src={faq.url}
                      alt={`Image related to ${faq.question}`}
                      style={{
                        maxWidth: isMobile ? "100%" : "90%",
                        margin: "16px auto",
                        display: "block",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                      }}
                    />
                  </Box>
                )}
              </Box>
            ))}
            <Box
              mt={7}
              sx={{
                color: "black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                pt: 5,
              }}
            >
              <Typography variant="h3" sx={{ marginBottom: 4 }}>
                Other Questions?
              </Typography>
              <Contact
                p={3}
                ml={isMobile ? 2 : 15} // Reduce margin for mobile
                mr={isMobile ? 2 : 15} // Reduce margin for mobile
                onSuccess={() => {
                  dispatch(
                    updateToastData({
                      message: "Message Sent Successfully",
                      severity: TOAST_SEVERITY_SUCCESS,
                    })
                  )
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </Page>
  )
}

export default Faq
