import React, { useState } from "react"
import SkeletonCard from "../../core/SkeletonCard/SkeletonCard"
import DescriptionItem from "../../core/DescriptionItem/DescriptionItem"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"
import Card from "@mui/material/Card"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import OrderStatusBadge from "../OrderStatusBadge"
import { getCurrencyStringFromNumber } from "../../../Util/Util"
import Typography from "@mui/material/Typography"
import SoftButton from "../../../soft_dashboard/components/SoftButton"
import Axios from "../../../Util/Axios"
import { Link, useNavigate } from "react-router-dom"
import { useParams } from "react-router"
import Button from "@mui/material/Button"

const OrderInfo = ({ orderData, setOrderData }) => {
  const { id } = useParams()

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  if (!orderData) {
    return <SkeletonCard />
  }
  let priceColor =
    orderData.price - orderData.total_refunded > orderData.total_paid
      ? "error"
      : "text"

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2}>
        <DescriptionItem
          keyJsx={
            <SoftTypography variant="h6" fontWeight="medium">
              Info
            </SoftTypography>
          }
          valueJsx={
            <Button
              component={Link}
              // to={action.route}
              variant="text"
              size="small"
              to={`/custom-surfboard-order-staff/${orderData?.customer_facing_id}/`}
              // color={action.color}
            >
              View on Public Site
              {/*{action.label}*/}
            </Button>
          }
        />
      </SoftBox>

      <SoftBox p={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          <DescriptionItem
            keyJsx={
              <SoftTypography
                display="block"
                variant="button"
                fontWeight="medium"
              >
                Status
              </SoftTypography>
            }
            valueJsx={
              <OrderStatusBadge
                status={orderData.status}
                statusDisplay={orderData.status_display}
              />
            }
          />
          {orderData.type === "custom" && (
            <DescriptionItem
              keyJsx={
                <SoftTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                >
                  Customer Facing ID
                </SoftTypography>
              }
              valueJsx={
                <SoftTypography variant="button" fontWeight="bold" color="text">
                  {orderData.customer_facing_id}
                </SoftTypography>
              }
            />
          )}
          {orderData?.external_id && (
            <DescriptionItem
              keyJsx={
                <SoftTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                >
                  External Id
                </SoftTypography>
              }
              valueJsx={
                <SoftTypography variant="button" fontWeight="bold" color="text">
                  {orderData.external_id}
                </SoftTypography>
              }
            />
          )}
          {orderData?.dreamship_status && orderData.type !== "custom" && (
            <DescriptionItem
              keyJsx={
                <SoftTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                >
                  Dreamship Status
                </SoftTypography>
              }
              valueJsx={
                <SoftTypography variant="button" fontWeight="bold" color="text">
                  {orderData.dreamship_status}
                </SoftTypography>
              }
            />
          )}
          <DescriptionItem
            keyJsx={
              <SoftTypography
                display="block"
                variant="button"
                fontWeight="medium"
              >
                Price
              </SoftTypography>
            }
            valueJsx={
              <SoftTypography
                variant="button"
                fontWeight="bold"
                color={priceColor}
              >
                {getCurrencyStringFromNumber(orderData.price)}
              </SoftTypography>
            }
          />

          <DescriptionItem
            keyJsx={
              <SoftTypography
                display="block"
                variant="button"
                fontWeight="medium"
              >
                Subtotal Paid
              </SoftTypography>
            }
            valueJsx={
              <SoftTypography variant="button" fontWeight="bold" color="text">
                {getCurrencyStringFromNumber(orderData.subtotal_paid)}
              </SoftTypography>
            }
          />
          <DescriptionItem
            keyJsx={
              <SoftTypography
                display="block"
                variant="button"
                fontWeight="medium"
              >
                Tax Paid
              </SoftTypography>
            }
            valueJsx={
              <SoftTypography variant="button" fontWeight="bold" color="text">
                {getCurrencyStringFromNumber(orderData.tax_paid)}
              </SoftTypography>
            }
          />
          <DescriptionItem
            keyJsx={
              <SoftTypography
                display="block"
                variant="button"
                fontWeight="medium"
              >
                Total Paid
              </SoftTypography>
            }
            valueJsx={
              <SoftTypography variant="button" fontWeight="bold" color="text">
                {getCurrencyStringFromNumber(orderData.total_paid)}
              </SoftTypography>
            }
          />
          <DescriptionItem
            keyJsx={
              <SoftTypography
                display="block"
                variant="button"
                fontWeight="medium"
              >
                Total Refunded
              </SoftTypography>
            }
            valueJsx={
              <SoftTypography variant="button" fontWeight="bold" color="text">
                {getCurrencyStringFromNumber(orderData.total_refunded)}
              </SoftTypography>
            }
          />

          {orderData.type === "custom" &&
            orderData?.status === "awaiting_confirmation" && (
              <SoftButton
                variant="gradient"
                color="success"
                disabled={loading}
                onClick={() => {
                  setLoading(true)
                  Axios.post(
                    `/api/staff-order-detail/${orderData.customer_facing_id}/action_approve/`,
                    navigate
                  )
                    .then(({ data }) => {
                      setLoading(false)
                      setOrderData(data)
                    })
                    .catch((e) => {
                      setLoading(false)
                    })
                }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  APPROVE
                </Typography>
              </SoftButton>
            )}
          {orderData.type === "custom" &&
            orderData?.status === "deposit_received_and_in_progress" && (
              <SoftButton
                variant="gradient"
                color="success"
                disabled={loading}
                onClick={() => {
                  setLoading(true)
                  Axios.post(
                    `/api/staff-order-detail/${orderData.customer_facing_id}/action_ready_for_pickup/`,
                    navigate
                  )
                    .then(({ data }) => {
                      setLoading(false)
                      setOrderData(data)
                    })
                    .catch((e) => {
                      setLoading(false)
                    })
                }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Ready for Pickup
                </Typography>
              </SoftButton>
            )}
          {orderData.type === "custom" &&
            orderData?.status === "ready_for_pickup" && (
              <SoftButton
                variant="gradient"
                color="success"
                disabled={loading}
                onClick={() => {
                  setLoading(true)
                  Axios.post(
                    `/api/staff-order-detail/${orderData.customer_facing_id}/action_complete/`,
                    navigate
                  )
                    .then(({ data }) => {
                      setLoading(false)
                      setOrderData(data)
                    })
                    .catch((e) => {
                      setLoading(false)
                    })
                }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Complete
                </Typography>
              </SoftButton>
            )}
          {/*<Button*/}
          {/*  variant="contained"*/}
          {/*  color={"secondary"}*/}
          {/*  sx={{ mt: 1, width: "100%" }}*/}
          {/*  // disabled={loading}*/}
          {/*  onClick={() => {*/}
          {/*    // sendMessage()*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Typography variant="h6" style={{ color: "white" }}>*/}
          {/*    Approve*/}
          {/*  </Typography>*/}
          {/*</Button>*/}
        </SoftBox>
      </SoftBox>
    </Card>
  )
}

export default OrderInfo
