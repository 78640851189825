import * as React from "react"

const PerformanceDiamondSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 973.455

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path
          d="M85.7,914.5c0,0-36.8-176.5-36.8-412.8c0-381,116-481.9,116-481.9s116,98.8,116,479.6
		c0,268.7-40.8,415.2-40.8,415.2l-75.2,14.7L85.7,914.5z"
        />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={18}
        opacity={overlapOpacity}
      >
        <path d=" M100.902,904.261c0,0-40.786-171.396-40.786-401.278c0-361.646,104.825-468.646,104.825-468.646s104.83,102.916,104.83,466.374 c0,261.334-42.696,403.519-42.696,403.519l-62.132,13.507L100.902,904.261z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            d="M85.9,914.9c0,0-44.7-195.5-34.7-518.4L164.4,316l114.2,80.5
		c11.4,372-38.6,518.4-38.6,518.4l-75.1,14.3L85.9,914.9z"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path
            d="M85.7,914.5c0,0-46.5-204.7-34.5-517.9c0,0,102.7-181.2,227.4,0
		c13.6,318.7-38.5,518-38.5,518l-75.2,14.7L85.7,914.5z"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            d="M85.7,914.5c0,0-48.5-223.2-34.2-517.9c0,0,41.5-9.7,72.8-34.2s40.9-60.9,40.9-60.9
		s12.2,36.5,38.2,59.5c28.5,25.2,74.5,35.6,74.5,35.6c15.2,315.2-37.8,518-37.8,518l-75.2,14.7L85.7,914.5z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M86.5,914.5c0,0-18.7-73.5-30.5-226.9L165.3,657L274,687.6
		c-11.6,155.7-34.5,226.9-34.5,226.9l-74.6,14.7L86.5,914.5z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M86.5,914.5c0,0-20.7-78.3-29.5-226.9l108.3,44.7L274,687.6
		c-10,148.8-34.5,226.9-34.5,226.9l-74.6,14.7L86.5,914.5z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path
            d="M86.5,914.5c0,0-17.8-69-30.5-226.9c0,0,51-2.2,79.1-15.6
		c28.1-13.4,30.2-22.2,30.2-22.2s11,16.1,25.4,21.6c20.1,7.6,83.3,16.3,83.3,16.3c-9.4,144.9-33.5,226.9-33.5,226.9l-75.6,14.7
		L86.5,914.5z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M86.5,914.5c0,0-20.2-76.4-30-227.6c0,0,74.9-3.9,89.4,16.2 c14.5,20.1,20.1,34.1,20.1,34.1s8.4-18.4,28.5-34.1c20.9-16.4,79-16.2,79-16.2c-10.1,140.4-33,227.6-33,227.6l-75.6,14.7 L86.5,914.5z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M86.5,914.5c0,0-16.1-64.4-29-205.8c-0.8-6.8-0.7-13.8-1.5-21c0,0,105-68.8,217,0
		c0,0-0.3,12-1,17.9c-13.5,149.1-31.5,209.1-31.5,209.1l-75.6,14.7L86.5,914.5z"
          />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={242.207} y1={182.394} x2={242.207} y2={879.979} />
          <line x1={83.915} y1={182.394} x2={83.915} y2={879.979} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={153.069} y1={32.739} x2={153.069} y2={926.02} />
          <line x1={177.053} y1={32.739} x2={177.053} y2={926.02} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.061} y1={19.703} x2={165.061} y2={929.229} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V863.752z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V863.752z" />
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M94.858,776.088c-0.238,2.23-2.027,3.874-4.056,3.523 l-5.013-0.705c-2.028-0.235-3.339-2.349-3.101-4.577l5.248-46.014c0.24-2.23,2.029-3.871,4.057-3.521l5.011,0.703 c2.028,0.234,3.341,2.347,3.102,4.577L94.858,776.088z" />
          <path d="M249.374,774.093c0.356,2.229-1.075,4.345-2.983,4.697 l-5.014,0.818c-2.027,0.354-3.816-1.175-4.175-3.404l-6.204-45.779c-0.357-2.229,1.072-4.342,2.984-4.693l5.011-0.82 c1.909-0.351,3.817,1.175,4.176,3.403L249.374,774.093z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M171.58,879.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.482c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V879.979z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V863.752z" />
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
          <path d="M171.58,879.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.482c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V879.979z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={535.58}
          width={96}
          height={92.504}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d="M109.652,894.511 c0,0-37.716-165.937-37.716-387.908c0-357.79,93.005-452.518,93.005-452.518s93.01,92.761,93.01,450.322 c0,252.341-39.051,389.819-39.051,389.819l-54.658,11.213L109.652,894.511z" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d="M91.746,914.464 c0,0-42.81-176.452-42.81-412.846c0-381.031,116.005-481.915,116.005-481.915s116.01,98.786,116.01,479.577 c0,268.732-45.766,415.222-45.766,415.222l-70.125,14.726L91.746,914.464z" />
      </g>
    </svg>
  )
}
export default PerformanceDiamondSvg
