import React, { useEffect, useState } from "react"
import CustomOrderOptionSelect from "../CustomOrderOptionSelect"
import { Box, IconButton, Typography } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import Collapse from "@mui/material/Collapse"

const FiberGlass = ({
  updateOptions,
  setSelectedDeckPatchOption,
  deckPatchOptions,
  selectedDeckPatchOption,
  selectedTailPatchOption,
  setSelectedTailPatchOption,
  tailPatchOptions,
  orderData,
  isStaff,
}) => {
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <React.Fragment>
      <Box
        pt={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Fiberglass</Typography>
        <IconButton onClick={handleClick}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse in={open}>
        <CustomOrderOptionSelect
          isStaff={isStaff}
          updateOptions={updateOptions}
          title={"Deck Patch"}
          option={selectedDeckPatchOption}
          setOption={setSelectedDeckPatchOption}
          options={deckPatchOptions}
          orderData={orderData}
        />
        <CustomOrderOptionSelect
          isStaff={isStaff}
          updateOptions={updateOptions}
          title={"Tail Patch"}
          option={selectedTailPatchOption}
          setOption={setSelectedTailPatchOption}
          options={tailPatchOptions}
          orderData={orderData}
        />
      </Collapse>
    </React.Fragment>
  )
}

export default FiberGlass
