import React, { useEffect, useState } from "react"
import Page from "../Page/Page"
import { Box, Button, Container, Grid, Typography } from "@mui/material"
import Cookies from "js-cookie"
import { useLocation, useNavigate } from "react-router-dom"
import Axios from "../../Util/Axios"
import { useParams } from "react-router"
import CustomOrderOptionSelect from "./CustomOrderOptionSelect"
import CustomOrderTextField from "./CustomOrderTextField"
import AddColorModal from "./AddColorModal"
import Shape from "./Shape/Shape."
import Blank from "./Blank/Blank"
import Logo from "./Logo/Logo"
import Colors from "./Colors/Colors"
import Extras from "./Extras/Extras"
import FiberGlass from "./FiberGlass/FiberGlass"
import CustomOrderComment from "./CustomOrderComment/CustomOrderComment"
import { getSurfboardVolume } from "./CustomOrderUtil"
import CustomOrderBanner from "./CustomOrderBanner"
import Invoice from "./Invoice"
import { useDispatch } from "react-redux"
import { updateToastData } from "../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../../components/core/Toast/Toast"
import CustomOrderAdditionalCharges from "./CustomOrderAdditionalCharges"
import ReactPixel from "react-facebook-pixel"
import CustomOrderSurfboards from "./BoardSvgs/CustomOrderSurfboards"
import CustomOrderButton from "./CustomOrderButton"

const CustomOrder = ({ isStaff, presetEditor }) => {
  const navigate = useNavigate()
  const params = useParams()
  let location = useLocation()
  const customerFacingId = params.customer_facing_id
  const editingPresetId = params.editing_preset_id
  // const id = params.id

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [presetName, setPresetName] = useState("")
  const [presetComment, setPresetComment] = useState(null)

  const [orderData, setOrderData] = useState(null)

  const [addColorModalActive, setAddColorModalActive] = useState(false)

  const [selectedLogoOption, setSelectedLogoOption] = useState(null)
  const [logoOptions, setLogoOptions] = useState([])

  const [abstractColorOption, setAbstractColorOption] = useState(null)
  const [selectedAbstractColorOption, setSelectedAbstractColorOption] =
    useState(null)

  const [selectedBottomColorOption, setSelectedBottomColorOption] =
    useState(null)
  const [bottomColorOptions, setBottomColorOptions] = useState([])

  const [selectedDeckColorOption, setSelectedDeckColorOption] = useState(null)
  const [deckColorOptions, setDeckColorOptions] = useState([])

  // const [selectedMidBandColorOption, setSelectedMidBandColorOption] =
  //   useState(null)
  // const [midBandColorOptions, setMidBandColorOptions] = useState([])
  //
  // const [selectedTailDipColorOption, setSelectedTailDipColorOption] =
  //   useState(null)
  //
  // const [tailDipColorOptions, setTailDipColorOptions] = useState([])
  // console.log(tailDipColorOptions)

  const [selectedPinLineColorOption, setSelectedPinLineColorOption] =
    useState(null)
  const [pinLineColorOptions, setPinLineColorOptions] = useState([])
  const [selectedFinBoxColorOption, setSelectedFinBoxColorOption] =
    useState(null)
  const [finBoxColorOptions, setFinBoxColorOptions] = useState([])

  const [selectedFinSetupOption, setSelectedFinSetupOption] = useState(null)
  const [finSetupOptions, setFinSetupOptions] = useState([])

  const [selectedNoseShapeOption, setSelectedNoseShapeOption] = useState(null)
  const [noseShapeOptions, setNoseShapeOptions] = useState([])

  const [selectedTailShapeOption, setSelectedTailShapeOption] = useState(null)
  const [tailShapeOptions, setTailShapeOptions] = useState([])

  const [selectedRockerOption, setSelectedRockerOption] = useState(null)
  const [rockerOptions, setRockerOptions] = useState([])

  const [selectedStringerTypeOption, setSelectedStringerTypeOption] =
    useState(null)
  const [stringerTypeOptions, setStringerTypeOptions] = useState([])

  const [selectedDeckPatchOption, setSelectedDeckPatchOption] = useState(null)
  const [deckPatchOptions, setDeckPatchOptions] = useState([])

  const [selectedTailPatchOption, setSelectedTailPatchOption] = useState(null)
  const [tailPatchOptions, setTailPatchOptions] = useState([])

  const [selectedFinishOption, setSelectedFinishOption] = useState(null)
  const [finishOptions, setFinishOptions] = useState([])

  const [selectedNumberOfStringersOption, setSelectedNumberOfStringersOption] =
    useState(null)
  const [numberOfStringersOptions, setNumberOfStringersOptions] = useState([])

  const [selectedFoamDensityOption, setSelectedFoamDensityOption] =
    useState(null)
  const [foamDensityOptions, setFoamDensityOptions] = useState([])

  const [selectedLengthOption, setSelectedLengthOption] = useState(null)
  const [lengthOptions, setLengthOptions] = useState([])

  const [selectedWidthOption, setSelectedWidthOption] = useState(null)
  const [widthOptionsSmall, setWidthOptionsSmall] = useState([])
  const [widthOptionsLarge, setWidthOptionsLarge] = useState([])

  const [selectedThicknessOption, setSelectedThicknessOption] = useState(null)
  const [thicknessOptionsSmall, setThicknessOptionsSmall] = useState([])
  const [thicknessOptionsLarge, setThicknessOptionsLarge] = useState([])

  const [selectedGlassOnFinOption, setSelectedGlassOnFinOption] = useState(null)
  const [glassOnFinsOption, setGlassOnFinsOption] = useState(null)
  const [presetId, setPresetId] = useState(null)

  const [presetTypeOptions, setPresetTypeOptions] = useState([
    {
      type: "display",
      value: "Display",
    },
    {
      type: "inventory",
      value: "Inventory",
    },
  ])
  const [selectedPresetType, setSelectedPresetType] = useState(null)

  const [loading, setLoading] = useState(true)

  const [colorsOpen, setColorsOpen] = useState(true)
  const [requiresApproval, setRequiresApproval] = useState(true)
  // const [presetOptions, setPresetOptions] = useState([])

  let surfboardVolume = getSurfboardVolume(
    selectedLengthOption?.extra_data?.total_inches,
    selectedWidthOption?.extra_data?.total_inches,
    selectedThicknessOption?.extra_data?.total_inches,
    selectedLengthOption?.extra_data?.type
  )

  const getIsMobile = () =>
    window.matchMedia && window.matchMedia("(max-width: 480px)").matches
  const isMobile = getIsMobile()

  const dispatch = useDispatch()
  const setSelectedOptions = (options) => {
    setSelectedWidthOption(null)
    setSelectedAbstractColorOption(null)
    setSelectedLengthOption(null)
    setSelectedFinSetupOption(null)
    setSelectedNoseShapeOption(null)
    setSelectedTailShapeOption(null)
    setSelectedRockerOption(null)
    setSelectedStringerTypeOption(null)
    setSelectedDeckPatchOption(null)
    setSelectedTailPatchOption(null)
    setSelectedFinishOption(null)
    setSelectedNumberOfStringersOption(null)
    setSelectedFoamDensityOption(null)
    setSelectedGlassOnFinOption(null)
    setSelectedLogoOption(null)
    setSelectedBottomColorOption(null)
    setSelectedDeckColorOption(null)
    setSelectedFinBoxColorOption(null)
    setSelectedAbstractColorOption(null)
    setSelectedPinLineColorOption(null)
    // setSelectedTailDipColorOption(null)
    setSelectedFinishOption(null)
    // setSelectedMidBandColorOption(null)

    for (let option of options) {
      if (option.type === "width_small" || option.type === "width_large") {
        setSelectedWidthOption(option)
      }

      if (
        option.type === "thickness_small" ||
        option.type === "thickness_large"
      ) {
        setSelectedThicknessOption(option)
      }

      if (option.type === "logo") {
        setSelectedLogoOption(option)
      }
      if (option.type === "abstract_color") {
        setSelectedAbstractColorOption(option)
      }
      if (option.type === "length") {
        setSelectedLengthOption(option)
      }
      if (option.type === "fin_setup") {
        setSelectedFinSetupOption(option)
      }
      if (option.type === "nose_shape") {
        setSelectedNoseShapeOption(option)
      }
      if (option.type === "tail_shape") {
        setSelectedTailShapeOption(option)
      }
      if (option.type === "rocker") {
        setSelectedRockerOption(option)
      }
      if (option.type === "stringer_type") {
        setSelectedStringerTypeOption(option)
      }
      if (option.type === "deck_patch") {
        setSelectedDeckPatchOption(option)
      }
      if (option.type === "tail_patch") {
        setSelectedTailPatchOption(option)
      }
      if (option.type === "finish") {
        setSelectedFinishOption(option)
      }
      if (option.type === "number_of_stringers") {
        setSelectedNumberOfStringersOption(option)
      }
      if (option.type === "foam_density") {
        setSelectedFoamDensityOption(option)
      }
      if (option.type === "fin_box_color") {
        setSelectedFinBoxColorOption(option)
      }
      if (option.type === "glass_on_fins") {
        setSelectedGlassOnFinOption(option)
      }
      if (option.type === "pin_line_color") {
        setSelectedPinLineColorOption(option)
      }
      if (option.type === "deck_color") {
        setSelectedDeckColorOption(option)
      }
      if (option.type === "bottom_color") {
        setSelectedBottomColorOption(option)
      }
    }
  }

  const updateOptions = (newOptions, optionsToDelete) => {
    if (loading) {
      return
    }

    let selectedOptions = [
      selectedAbstractColorOption,
      selectedBottomColorOption,
      selectedDeckColorOption,
      selectedPinLineColorOption,
      selectedFinBoxColorOption,
      selectedFinSetupOption,
      selectedNoseShapeOption,
      selectedTailShapeOption,
      selectedRockerOption,
      selectedStringerTypeOption,
      selectedDeckPatchOption,
      selectedTailPatchOption,
      selectedFinishOption,
      selectedNumberOfStringersOption,
      selectedFoamDensityOption,
      selectedLengthOption,
      selectedWidthOption,
      selectedThicknessOption,
      selectedLogoOption,
      selectedPresetType,
    ]
    selectedOptions = selectedOptions.filter((option) => option !== null)

    if (newOptions) {
      selectedOptions = selectedOptions.map((option) => {
        // Find a new option with the same id
        const newOption = newOptions.find(
          (newOption) => newOption?.type === option?.type
        )
        // If a new option was found, return it; otherwise, return the original option
        return newOption ? newOption : option
      })

      // Add new options not present in selectedOptions
      for (let newOption of newOptions) {
        if (!selectedOptions.find((option) => option?.id === newOption?.id)) {
          selectedOptions.push(newOption)
        }
      }
    }

    if (optionsToDelete) {
      selectedOptions = selectedOptions.filter((option) => {
        return !optionsToDelete.find((x) => {
          return x?.id === option.id
        })
      })
    }
    selectedOptions = selectedOptions.map((option) => {
      return option.id
    })

    if (selectedOptions.length > 0) {
      let data = {
        options: selectedOptions,
        name: name,
        phone: phone,
        email: email,
        preset_name: presetName,
        preset_id: presetId,
      }

      if (presetEditor) {
        Axios.post(
          `/api/staff-preset-detail/${editingPresetId}/action_update_options/`,
          data,
          navigate
        )
          .then(({ data }) => {
            setRequiresApproval(data.requires_approval)
            setSelectedOptions(data.options)
          })
          .catch((e) => {
            dispatch(
              updateToastData({
                message: "Error updating options. Please try again.",
                severity: TOAST_SEVERITY_ERROR,
              })
            )
          })
        return
      }

      if (!isStaff) {
        if (!customerFacingId) {
          Axios.post(`/api/order-detail/`, data, navigate)
            .then(({ data }) => {
              const queryParameters = new URLSearchParams(
                window.location.search
              )

              const preset_id = queryParameters.get("preset_id")

              if (preset_id) {
                setPresetId(preset_id)
                Cookies.set(
                  `${preset_id}_customer_facing_id`,
                  data.customer_facing_id
                )
              }

              navigate(`/custom-surfboard-order/${data.customer_facing_id}/`, {
                replace: true,
              })
              let orderData = data
              orderData.address.full_name = name
              orderData.address.phone = phone
              orderData.address.email = email

              setOrderData({
                ...orderData,
              })
              setSelectedOptions(data.options)
              // getAvailableOptions()
            })
            .catch((e) => {
              dispatch(
                updateToastData({
                  message: "Error creating order. Please try again.",
                  severity: TOAST_SEVERITY_ERROR,
                })
              )
              setLoading(false)
            })
        } else {
          Axios.post(
            `/api/order-detail/${customerFacingId}/action_update_options/`,
            data,
            navigate
          )
            .then(({ data }) => {
              setOrderData(data)
              setRequiresApproval(data.requires_approval)
              setSelectedOptions(data.options)
            })
            .catch((e) => {
              dispatch(
                updateToastData({
                  message: "Error updating options. Please try again.",
                  severity: TOAST_SEVERITY_ERROR,
                })
              )
            })
        }
      } else {
        Axios.post(
          `/api/staff-order-detail/${customerFacingId}/action_update_options/`,
          data,
          navigate
        )
          .then(({ data }) => {
            setOrderData(data)
            setRequiresApproval(data.requires_approval)
            setSelectedOptions(data.options)
          })
          .catch((e) => {
            dispatch(
              updateToastData({
                message: "Error updating options. Please try again.",
                severity: TOAST_SEVERITY_ERROR,
              })
            )
          })
      }
    }
  }

  const getData = () => {
    if (presetEditor) {
      Axios.get(`/api/staff-preset-detail/${editingPresetId}/`, navigate)
        .then(({ data }) => {
          // setPresetOptions(data.options)
          setSelectedOptions(data.options)
          setPresetName(data.name)
          setSelectedPresetType({
            type: data.type,
            value: data.type_display,
          })
          setPresetComment(data.comment)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
      return
    }
    if (isStaff) {
      setLoading(true)
      Axios.get(`/api/staff-order-detail/${customerFacingId}/`, navigate)
        .then(({ data }) => {
          setOrderData(data)
          setName(data?.address?.full_name || "")
          setEmail(data?.address?.email || "")
          setPhone(data?.address?.phone || "")
          setRequiresApproval(data.requires_approval)
          setSelectedOptions(data.options)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
      return
    }

    const queryParameters = new URLSearchParams(window.location.search)
    const preset_id = queryParameters.get("preset_id")

    if (preset_id) {
      setPresetId(preset_id)
      let existingId = Cookies.get(`${preset_id}_customer_facing_id`)

      if (existingId) {
        navigate(`/custom-surfboard-order/${existingId}/`, {
          replace: true,
        })
      } else {
        setLoading(true)

        Axios.get(`/api/preset-detail/${preset_id}/`, navigate)
          .then(({ data }) => {
            // setPresetOptions(data.options)
            setSelectedOptions(data.options)
            setName("")
            setPresetName(data.name)
            setPhone("")
            setEmail("")
            setLoading(false)
            // navigate(`/custom-surfboard-order/`, {
            //   replace: true,
            // })
          })
          .catch((e) => {
            setLoading(false)
          })
      }
    } else if (customerFacingId) {
      setLoading(true)
      Axios.get(`/api/order-detail/${customerFacingId}/`, navigate)
        .then(({ data }) => {
          setOrderData(data)
          setPresetName(data.preset_name)
          setName(data?.address?.full_name || "")
          setEmail(data?.address?.email || "")
          setPhone(data?.address?.phone || "")
          setRequiresApproval(data.requires_approval)
          setSelectedOptions(data.options)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    } else {
      Axios.get(`/api/preset-detail/?type=display`, navigate)
        .then(({ data }) => {
          setSelectedOptions(data.results[0].options)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    }
  }
  const getAvailableOptions = () => {
    Axios.get("/api/site-configuration/1/get_options/", navigate).then(
      ({ data }) => {
        setAbstractColorOption(data.abstract_color[0])
        setBottomColorOptions(data.bottom_color)
        setDeckColorOptions(data.deck_color)
        setDeckPatchOptions(data.deck_patch)
        setFinBoxColorOptions(data.fin_box_color)
        setFinSetupOptions(data.fin_setup)
        setFinishOptions(data.finish)
        setFoamDensityOptions(data.foam_density)
        setGlassOnFinsOption(data.glass_on_fins[0])
        setLengthOptions(data.length)
        setLogoOptions(data.logo)
        // setMidBandColorOptions(data.mid_band_color)
        setNoseShapeOptions(data.nose_shape)
        setNumberOfStringersOptions(data.number_of_stringers)
        setPinLineColorOptions(data.pin_line_color)
        setRockerOptions(data.rocker)

        setStringerTypeOptions(data.stringer_type)
        // setTailDipColorOptions(data.tail_dip_color)
        setTailPatchOptions(data.tail_patch)
        setTailShapeOptions(data.tail_shape)
        setThicknessOptionsLarge(data.thickness_large)
        setThicknessOptionsSmall(data.thickness_small)
        setWidthOptionsLarge(data.width_large)
        setWidthOptionsSmall(data.width_small)

        setLoading(false)
      }
    )
  }

  let allowedDeckColorOptions = []
  if (selectedBottomColorOption) {
    let whiteColorOption = deckColorOptions.find((x) => x.value === "#ffffff")
    let allowedColorOption = deckColorOptions.find(
      (x) => x.value === selectedBottomColorOption.value
    )
    if (whiteColorOption) {
      allowedDeckColorOptions.push(whiteColorOption)
      allowedDeckColorOptions.push(allowedColorOption)
    }
  }

  useEffect(() => {
    getData()
  }, [location])

  useEffect(() => {
    getAvailableOptions()

    let purchaseCookie = Cookies.get("purchase_data")
    if (purchaseCookie) {
      try {
        purchaseCookie = purchaseCookie
          .replace(/\\(.)/g, "$1")
          .replace(/054/g, ",")
        const data = JSON.parse(purchaseCookie)
        ReactPixel.track("Purchase", data)
        Cookies.remove("purchase_data")
      } catch (exception) {}
    }
  }, [])

  let widthOptions = []
  let thicknessOptions = []

  if (selectedLengthOption?.extra_data.total_inches < 100) {
    widthOptions = widthOptionsSmall
    thicknessOptions = thicknessOptionsSmall
  } else {
    widthOptions = widthOptionsLarge
    thicknessOptions = thicknessOptionsLarge
  }

  let finSetupOptionsToUse = finSetupOptions
  let noseShapeOptionsToUse = noseShapeOptions
  let tailShapeOptionsToUse = tailShapeOptions
  if (
    selectedNoseShapeOption?.value === "Hybrid" &&
    selectedTailShapeOption?.value === "Swallow"
  ) {
    finSetupOptionsToUse = finSetupOptions.filter((x) => {
      return ["Twin", "Quad"].includes(x.value)
    })
  }

  // Edit here, also edit get_options_list_from_request
  if (
    selectedLengthOption?.extra_data.total_inches < 66 ||
    selectedLengthOption?.extra_data.total_inches > 96
  ) {
    noseShapeOptionsToUse = noseShapeOptionsToUse.filter(
      (x) => x.value !== "Performance"
    )
  }

  if (selectedNoseShapeOption?.value === "Round") {
    tailShapeOptionsToUse = tailShapeOptionsToUse.filter(
      (x) => x.value !== "Squash" && x.value !== "Swallow"
    )
  }

  if (selectedLengthOption?.extra_data.total_inches >= 108) {
    if (selectedNoseShapeOption?.value === "Hybrid") {
      tailShapeOptionsToUse = tailShapeOptionsToUse.filter(
        (x) => x.value === "Swallow"
      )
    }
  }

  return (
    <Page>
      <Box sx={{ minHeight: "100vh" }}>
        <Container maxWidth={"xl"}>
          <Box
            pt={3}
            display="flex"
            alignItems="center"
            flexDirection="column"
            sx={{ position: "relative" }}
          >
            <Typography variant="h2">
              {presetEditor ? `Preset #${editingPresetId}` : "Custom Order"}
            </Typography>

            <Typography variant="h4" align="center" gutterBottom>
              {/*{selectedLengthOption?.extra_data?.type}*/}
              {presetName}
            </Typography>

            <Box pb={2}>
              <Button
                variant="text"
                color={"info"}
                // sx={{
                //   position: "absolute",
                //   right: 0,
                //   top: "60px",
                //   transform: "translateY(-50%)",
                // }}
                onClick={() => {
                  if (!presetEditor) {
                    setName("")
                    setEmail("")
                    setPhone("")
                    setOrderData(null)
                    Cookies.remove(
                      `${presetId || orderData?.preset_id}_customer_facing_id`
                    )
                    navigate(
                      `/custom-surfboard-order?preset_id=${
                        presetId || orderData?.preset_id
                      }`,
                      {
                        replace: true,
                      }
                    )
                  } else {
                    Axios.post(
                      `/api/staff-preset-detail/action_create_new_preset/`
                    ).then(({ data }) => {
                      setPresetName(data.name)
                      setSelectedPresetType({
                        type: data.type,
                        value: data.type_display,
                      })
                      setSelectedOptions(data.options)
                      navigate(
                        `/custom-surfboard-order-staff-preset/${data.id}/`,
                        {
                          replace: true,
                        }
                      )
                    })
                  }
                }}
              >
                {!orderData?.status || orderData?.status === "draft"
                  ? "Start Over"
                  : "Create New"}
              </Button>
            </Box>
          </Box>

          {/*<Box display="flex" justifyContent="center" alignItems="center">*/}
          <Box display="flex" justifyContent="center">
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              alignContent="flex-start"
              style={{ position: "relative" }}
            >
              {!isMobile && (
                <CustomOrderSurfboards
                  selectedLogoOption={selectedLogoOption}
                  selectedDeckPatchOption={selectedDeckPatchOption}
                  selectedDeckColorOption={selectedDeckColorOption}
                  selectedBottomColorOption={selectedBottomColorOption}
                  selectedTailPatchOption={selectedTailPatchOption}
                  selectedFinBoxColorOption={selectedFinBoxColorOption}
                  selectedFinSetupOption={selectedFinSetupOption}
                  selectedNoseShapeOption={selectedNoseShapeOption}
                  selectedTailShapeOption={selectedTailShapeOption}
                  selectedPinLineColorOption={selectedPinLineColorOption}
                  selectedLengthOption={selectedLengthOption}
                  selectedWidthOption={selectedWidthOption}
                  selectedStringerTypeOption={selectedStringerTypeOption}
                  selectedNumberOfStringersOption={
                    selectedNumberOfStringersOption
                  }
                />
              )}
              <Grid item xs={12} md={5}>
                <CustomOrderBanner orderData={orderData} />

                {presetEditor && (
                  <React.Fragment>
                    <CustomOrderTextField
                      orderData={orderData}
                      title={"Preset Name"}
                      text={presetName}
                      setText={setPresetName}
                      updateOptions={updateOptions}
                      isStaff={isStaff}
                    />

                    <CustomOrderOptionSelect
                      isStaff={isStaff}
                      orderData={orderData}
                      updateOptions={updateOptions}
                      title={"Preset Type"}
                      my={0}
                      option={selectedPresetType}
                      options={presetTypeOptions}
                      onChange={(event) => {
                        let newPresetType = event.target.value.toLowerCase()
                        Axios.patch(
                          `/api/staff-preset-detail/${editingPresetId}/`,
                          {
                            type: newPresetType,
                          }
                        ).then(({ data }) => {
                          setSelectedPresetType({
                            type: data.type,
                            value: data.type_display,
                          })
                        })
                      }}
                    />
                  </React.Fragment>
                )}

                <Box pb={1}>
                  <Typography variant="h4" gutterBottom>
                    Dimensions
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={4} sm={4}>
                    <CustomOrderOptionSelect
                      isStaff={isStaff}
                      orderData={orderData}
                      updateOptions={updateOptions}
                      title={"Length"}
                      my={0}
                      option={selectedLengthOption}
                      options={lengthOptions}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <CustomOrderOptionSelect
                      isStaff={isStaff}
                      orderData={orderData}
                      updateOptions={updateOptions}
                      my={0}
                      title={"Width"}
                      option={selectedWidthOption}
                      // setOption={setSelectedWidthOption}
                      options={widthOptions}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <CustomOrderOptionSelect
                      isStaff={isStaff}
                      orderData={orderData}
                      updateOptions={updateOptions}
                      my={0}
                      title={"Thickness"}
                      option={selectedThicknessOption}
                      // setOption={setSelectedThicknessOption}
                      options={thicknessOptions}
                    />
                  </Grid>
                </Grid>

                {surfboardVolume && (
                  <Box pl={1}>
                    <Typography
                      variant="caption"
                      gutterBottom
                      align="center"
                      style={{
                        fontFamily: "Josefin Sans",
                        color: "grey", // alignText: "center",
                      }}
                    >
                      Volume is approximately {surfboardVolume}L
                    </Typography>
                  </Box>
                )}
                <Shape
                  orderData={orderData}
                  isStaff={isStaff}
                  updateOptions={updateOptions}
                  selectedFinSetupOption={selectedFinSetupOption}
                  setSelectedFinSetupOption={setSelectedFinSetupOption}
                  finSetupOptions={finSetupOptionsToUse}
                  selectedNoseShapeOption={selectedNoseShapeOption}
                  setSelectedNoseShapeOption={setSelectedNoseShapeOption}
                  noseShapeOptions={noseShapeOptionsToUse}
                  selectedTailShapeOption={selectedTailShapeOption}
                  setSelectedTailShapeOption={setSelectedTailShapeOption}
                  tailShapeOptions={tailShapeOptionsToUse}
                  selectedRockerOption={selectedRockerOption}
                  setSelectedRockerOption={setSelectedRockerOption}
                  rockerOptions={rockerOptions}
                />

                <Blank
                  isStaff={isStaff}
                  orderData={orderData}
                  updateOptions={updateOptions}
                  selectedStringerTypeOption={selectedStringerTypeOption}
                  setSelectedStringerTypeOption={setSelectedStringerTypeOption}
                  stringerTypeOptions={stringerTypeOptions}
                  selectedNumberOfStringersOption={
                    selectedNumberOfStringersOption
                  }
                  setSelectedNumberOfStringersOption={
                    setSelectedNumberOfStringersOption
                  }
                  numberOfStringersOptions={numberOfStringersOptions}
                  selectedFoamDensityOption={selectedFoamDensityOption}
                  setSelectedFoamDensityOption={setSelectedFoamDensityOption}
                  foamDensityOptions={foamDensityOptions}
                />

                <FiberGlass
                  isStaff={isStaff}
                  orderData={orderData}
                  updateOptions={updateOptions}
                  setSelectedDeckPatchOption={setSelectedDeckPatchOption}
                  selectedDeckPatchOption={selectedDeckPatchOption}
                  deckPatchOptions={deckPatchOptions}
                  selectedTailPatchOption={selectedTailPatchOption}
                  setSelectedTailPatchOption={setSelectedTailPatchOption}
                  tailPatchOptions={tailPatchOptions}
                />
                <Logo
                  isStaff={isStaff}
                  orderData={orderData}
                  updateOptions={updateOptions}
                  selectedLogoOption={selectedLogoOption}
                  setSelectedLogoOption={setSelectedLogoOption}
                  logoOptions={logoOptions}
                />
                <Box pt={4}>
                  <Colors
                    isStaff={isStaff}
                    orderData={orderData}
                    open={colorsOpen}
                    setOpen={setColorsOpen}
                    updateOptions={updateOptions}
                    selectedFinBoxColorOption={selectedFinBoxColorOption}
                    setSelectedFinBoxColorOption={setSelectedFinBoxColorOption}
                    setAddColorModalActive={setAddColorModalActive}
                    finBoxColorOptions={finBoxColorOptions}
                    selectedBottomColorOption={selectedBottomColorOption}
                    setSelectedBottomColorOption={setSelectedBottomColorOption}
                    bottomColorOptions={bottomColorOptions}
                    selectedDeckColorOption={selectedDeckColorOption}
                    setSelectedDeckColorOption={setSelectedDeckColorOption}
                    deckColorOptions={deckColorOptions}
                    allowedDeckColorOptions={allowedDeckColorOptions}
                    selectedPinLineColorOption={selectedPinLineColorOption}
                    setSelectedPinLineColorOption={
                      setSelectedPinLineColorOption
                    }
                    pinLineColorOptions={pinLineColorOptions}
                    selectedAbstractColorOption={selectedAbstractColorOption}
                    setSelectedAbstractColorOption={
                      setSelectedAbstractColorOption
                    }
                    abstractColorOption={abstractColorOption}
                  />
                </Box>
                <Box pt={3}>
                  <Extras
                    isStaff={isStaff}
                    orderData={orderData}
                    updateOptions={updateOptions}
                    selectedGlassOnFinOption={selectedGlassOnFinOption}
                    setSelectedGlassOnFinOption={setSelectedGlassOnFinOption}
                    glassOnFinsOption={glassOnFinsOption}
                  />
                </Box>
                {/*{!isMobile && (*/}

                <CustomOrderAdditionalCharges orderData={orderData} />

                {!presetEditor && (
                  <React.Fragment>
                    <Box mb={1} mt={2}>
                      <CustomOrderTextField
                        orderData={orderData}
                        title={"Name"}
                        text={name}
                        setText={setName}
                        updateOptions={updateOptions}
                        isStaff={isStaff}
                      />
                    </Box>

                    <Box mb={1}>
                      <CustomOrderTextField
                        orderData={orderData}
                        title={"Email"}
                        text={email}
                        setText={setEmail}
                        updateOptions={updateOptions}
                        isStaff={isStaff}
                      />
                    </Box>
                    <Box>
                      <CustomOrderTextField
                        orderData={orderData}
                        title={"Phone"}
                        text={phone}
                        setText={setPhone}
                        updateOptions={updateOptions}
                        isStaff={isStaff}
                      />
                    </Box>
                  </React.Fragment>
                )}

                <Box mt={2}>
                  <CustomOrderComment
                    orderData={orderData}
                    setOrderData={setOrderData}
                    isStaff={false}
                    senderEmail={email}
                    requiresApproval={requiresApproval}
                    setRequiresApproval={setRequiresApproval}
                    customerFacingId={customerFacingId}
                    presetEditor={presetEditor}
                    editingPresetId={editingPresetId}
                    presetComment={presetComment}
                    setPresetComment={setPresetComment}
                  />
                </Box>
                {orderData && (
                  <Box mb={5}>
                    <Invoice orderData={orderData} />
                  </Box>
                )}

                <Box mb={0} mt={0}>
                  <CustomOrderButton
                    isStaff={isStaff}
                    orderData={orderData}
                    setOrderData={setOrderData}
                    requiresApproval={requiresApproval}
                    customerFacingId={customerFacingId}
                    // email={email}
                  />
                </Box>

                {/*)}*/}
              </Grid>
              {isMobile && (
                <React.Fragment>
                  <CustomOrderSurfboards
                    selectedLogoOption={selectedLogoOption}
                    selectedDeckPatchOption={selectedDeckPatchOption}
                    selectedDeckColorOption={selectedDeckColorOption}
                    selectedBottomColorOption={selectedBottomColorOption}
                    selectedTailPatchOption={selectedTailPatchOption}
                    selectedFinBoxColorOption={selectedFinBoxColorOption}
                    selectedFinSetupOption={selectedFinSetupOption}
                    selectedNoseShapeOption={selectedNoseShapeOption}
                    selectedTailShapeOption={selectedTailShapeOption}
                    selectedPinLineColorOption={selectedPinLineColorOption}
                    selectedLengthOption={selectedLengthOption}
                    selectedWidthOption={selectedWidthOption}
                    selectedStringerTypeOption={selectedStringerTypeOption}
                    selectedNumberOfStringersOption={
                      selectedNumberOfStringersOption
                    }
                  />
                </React.Fragment>
              )}
            </Grid>
          </Box>
        </Container>
      </Box>

      <AddColorModal
        orderData={orderData}
        colorsOpen={colorsOpen}
        setColorsOpen={setColorsOpen}
        active={addColorModalActive}
        setActive={setAddColorModalActive}
        selectedBottomColorOption={selectedBottomColorOption}
        setSelectedBottomColorOption={setSelectedBottomColorOption}
        bottomColorOptions={bottomColorOptions}
        deckColorOptions={deckColorOptions}
        selectedDeckColorOption={selectedDeckColorOption}
        setSelectedDeckColorOption={setSelectedDeckColorOption}
        selectedPinLineColorOption={selectedPinLineColorOption}
        setSelectedPinLineColorOption={setSelectedPinLineColorOption}
        pinLineColorOptions={pinLineColorOptions}
        selectedAbstractColorOption={selectedAbstractColorOption}
        setSelectedAbstractColorOption={setSelectedAbstractColorOption}
        abstractColorOption={abstractColorOption}
        updateOptions={updateOptions}
      />
    </Page>
  )
}

export default CustomOrder
