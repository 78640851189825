import React, { useEffect, useState } from "react"
import SoftBox from "../../soft_dashboard/components/SoftBox"
import ImageDrag from "../../components/core/ImageDrag/ImageDrag"
import OrderOptionJsx from "../../components/Order/StaffOrderPage/OrderOptions/OrderOptionJsx"
import Collapse from "@mui/material/Collapse"
import { Box, IconButton, Skeleton, Typography } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import Axios from "../../Util/Axios"
import { updateToastData } from "../../slices/toastSlice"
import {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../../components/core/Toast/Toast"
import { useDispatch } from "react-redux"
import SkeletonCard from "../../components/core/SkeletonCard/SkeletonCard"
import Card from "@mui/material/Card"

const StaffOptionPageGroup = ({ optionType, title }) => {
  const [optionsInMemory, setOptionsInMemory] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (optionsInMemory.length === 0 && open) {
      setLoading(true)
      Axios.get(
        `/api/staff-site-configuration/1/get_options/?type=${optionType}`
      )
        .then(({ data }) => {
          setLoading(false)
          setOptionsInMemory(data[optionType])
        })
        .catch((e) => {
          setLoading(false)
        })
    }
    if (!open) {
      setOptionsInMemory([])
    }
  }, [open])
  return (
    <SoftBox pt={2}>
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <Box
          pt={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">{title}</Typography>
          <IconButton
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </div>
      <Collapse in={open}>
        {loading && (
          <Card>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Card>
        )}

        {!loading && (
          <ImageDrag
            draggedImages={optionsInMemory}
            setDraggedImages={setOptionsInMemory}
            setPositionUpdated={() => {
              const dataUrl = "/api/staff-site-configuration/1/"
              Axios.post(`${dataUrl}action_save_option_positions/`, {
                options: optionsInMemory,
              })
                .then((x) => {
                  // setPositionUpdated(false)
                  dispatch(
                    updateToastData({
                      message: "Position Saved.",
                      severity: TOAST_SEVERITY_SUCCESS,
                    })
                  )
                })
                .catch((e) => {
                  dispatch(
                    updateToastData({
                      message: e,
                      severity: TOAST_SEVERITY_ERROR,
                    })
                  )
                })
            }}
            direction={"vertical"}
            imageComponent={(optionData) => {
              return <OrderOptionJsx optionData={optionData} editable={true} />
            }}
          />
        )}
      </Collapse>
    </SoftBox>
  )
}

export default StaffOptionPageGroup
