import * as React from "react"
const LongboardCrescentSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 239
  let originalHeight = 1000

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path
          d="M122.754,19.631c0,0-49.241-1.193-67.896,41.865c-18.655,43.058-39.696,156.616-39.696,404.338
		c0,183.623,1.192,242.083,16.703,347.614c21.689,147.396,56.073,156.508,56.073,156.508c30.729-23.212,65.942-0.109,65.942-0.109
		s33.188-9.109,54.121-156.507c14.968-105.531,16.16-163.991,16.16-347.614c0-247.722-20.607-361.063-38.611-404.229
		c-18.113-43.058-63.449-41.865-63.449-41.865"
        />
      </g>
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="StringerTripleLeft"
            x1="182.623"
            y1="56.291"
            x2="182.623"
            y2="923.17"
          />
          <line
            id="StringerTripleRight_1_"
            x1="57.353"
            y1="56.291"
            x2="57.353"
            y2="923.17"
          />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1="110.063" y1="20.499" x2="110.063" y2="959.995" />
          <line x1="127.96" y1="20.499" x2="127.96" y2="959.995" />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1="119.5" y1="20.499" x2="119.5" y2="959.995" />
        </g>
      )}
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path
          d="M119.934,29.393c0,0-42.406-1.193-58.46,40.998
		C45.422,112.69,27.417,223.753,27.417,466.377c0,179.827,0.977,237.094,14.317,340.456
		c20.808,142.951,47.475,150.335,47.475,150.335c32.755-17.252,62.333,0,62.333,0s26.96-5.975,46.049-150.335
		c13.666-103.362,14.75-160.629,14.75-340.456c0-242.624-18.763-353.687-35.249-395.986c-16.377-42.191-57.917-40.998-57.917-40.998
		"
        />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            d="M118.957,728.199l-55.64-40.238l-47.396-31.996c0,0-2.17-209.978-0.977-244.9
		c1.191-34.924,1.302-41.323,1.302-41.323l56.94-49.132l46.313-38.286l46.638,42.625l56.616,44.794c0,0,0.108,6.399,1.301,41.323
		c1.193,34.925-0.976,244.9-0.976,244.9l-48.265,31.996l-55.314,40.455"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path
            d="M16.68,358.894c0,0,33.623-44.686,102.604-45.444c68.979-0.759,103.036,41.974,103.036,41.974
		l1.519,154.772l-2.387,146.854c0,0-47.397,45.444-101.521,47.614c-54.013,2.169-101.627-42.625-101.627-42.625
		S13.317,385.575,16.68,358.894z"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            d="M118.957,282.104c0,0,0.326,19.848-28.308,55.749c-28.636,35.901-74.403,31.779-74.403,31.779
		s-0.108,6.399-1.302,41.323c-1.193,34.925,0.977,244.902,0.977,244.902s15.184-2.388,52.386,11.931
		c37.202,14.317,51.193,60.521,51.193,60.521l0,0c0,0,13.991-46.203,51.192-60.521c37.2-14.316,52.387-11.931,52.387-11.931
		s2.169-209.979,0.976-244.902c-1.19-34.924-1.301-41.323-1.301-41.323s-45.879,4.122-74.404-31.779
		c-28.522-35.9-28.85-55.64-28.85-55.64"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M152.147,969.958c0,0,23.062-3.792,41.228-79.921c11.063-53.796,20.811-134.071,20.811-134.071
		l-95.011-59.653l-95.986,62.8c0,0,24.021,200.884,64.94,210.706C117.467,945.359,152.147,969.958,152.147,969.958z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M86.312,969.848c0,0-20.178-17.045-34.6-62.146c-18.503-57.868-31.128-191.648-31.128-191.648
		l98.916,71.583l99.132-71.476c0,0-11.171,124.404-23.753,166.812c-12.581,42.408-21.337,69.528-40.063,86.48
		C118.42,946.404,86.312,969.848,86.312,969.848z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path
            d="M88.495,968.532c-9.753,5.665-36.893-52.365-40.62-74.032c-14.208-25.056-22.843-137.884-22.843-137.884
		s11.714,0,46.313-10.849c34.601-10.846,48.156-52.817,48.156-52.817s12.039,41.974,46.638,52.817
		c34.601,10.849,47.939,10.087,47.939,10.087s-16.92,136.334-29.394,162.69c-13.017,27.657-20.229,48.807-31.183,51.301
		C153.503,969.847,124.706,947.5,88.495,968.532z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M86.527,969.849c0,0-15.187-8.459-29.395-46.854C42.924,884.492,25.787,782.648,24.92,768.44
		c-0.868-14.209-4.556-36.771-4.556-36.771s56.072,9.653,73.103,28.634c17.14,18.979,26.466,43.708,26.032,56.832
		c0,0,8.894-45.553,33.947-62.256c25.055-16.703,62.474-24.945,63.016-22.232c0.542,2.711-9.762,97.938-16.378,126.464
		c-6.725,28.634-20.824,78.959-31.887,95.771c-11.063,16.92-14.645,14.967-14.645,14.967S123.179,946.864,86.527,969.849z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M86.961,969.957c0,0-17.787-8.352-32.755-52.712c-14.858-44.36-29.5-144.36-31.018-159.327
		c0,0,40.89-26.898,95.117-26.248c54.229,0.651,97.288,21.367,97.288,21.367s-15.184,134.056-32.429,170.281
		c-15.957,41.849-29.284,46.527-29.284,46.527S120.714,946.414,86.961,969.957z"
          />
        </g>
      )}

      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          x="84.25"
          y="601.844"
          width="71.259"
          height="86.228"
          href={logoUrl}
        />
      </g>
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            d="M124.706,924.837c0,2.063-1.52,3.797-3.254,3.797h-4.989
		c-1.844,0-3.254-1.733-3.254-3.797V822.668c0-2.061,1.519-3.796,3.254-3.796h4.989c1.844,0,3.254,1.735,3.254,3.796V924.837z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M124.706,924.837c0,2.063-1.52,3.797-3.254,3.797h-4.989
		c-1.844,0-3.254-1.733-3.254-3.797V822.668c0-2.061,1.519-3.796,3.254-3.796h4.989c1.844,0,3.254,1.735,3.254,3.796V924.837z"
          />
          <path
            d="M54.749,820.065c-0.217,2.061-1.844,3.577-3.688,3.253
		l-4.558-0.65c-1.844-0.217-3.036-2.169-2.817-4.229l4.771-42.519c0.218-2.061,1.845-3.579,3.688-3.254l4.555,0.65
		c1.844,0.218,3.037,2.17,2.82,4.229L54.749,820.065z"
          />
          <path
            d="M195.205,818.222c0.325,2.063-0.979,4.015-2.712,4.338
		l-4.557,0.762c-1.845,0.323-3.472-1.085-3.797-3.146l-5.64-42.3c-0.325-2.061,0.976-4.013,2.712-4.338l4.555-0.76
		c1.735-0.325,3.473,1.085,3.797,3.146L195.205,818.222z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            d="M54.749,820.282c-0.217,2.061-1.844,3.579-3.688,3.254
		l-4.558-0.651c-1.844-0.217-3.036-2.169-2.817-4.229l4.771-42.519c0.218-2.061,1.845-3.578,3.688-3.254l4.555,0.651
		c1.844,0.217,3.037,2.169,2.82,4.229L54.749,820.282z"
          />
          <path
            d="M195.205,818.438c0.325,2.063-0.979,4.015-2.712,4.34l-4.557,0.76
		c-1.845,0.325-3.472-1.085-3.797-3.146l-5.64-42.3c-0.325-2.061,0.976-4.013,2.712-4.338l4.555-0.76
		c1.735-0.325,3.473,1.085,3.797,3.146L195.205,818.438z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M54.749,820.065c-0.217,2.061-1.844,3.577-3.688,3.253
		l-4.558-0.65c-1.844-0.217-3.036-2.169-2.817-4.229l4.771-42.519c0.218-2.061,1.845-3.579,3.688-3.254l4.555,0.65
		c1.844,0.218,3.037,2.17,2.82,4.229L54.749,820.065z"
          />
          <path
            d="M195.205,818.222c0.325,2.063-0.979,4.015-2.712,4.338
		l-4.557,0.762c-1.845,0.323-3.472-1.085-3.797-3.146l-5.64-42.3c-0.325-2.061,0.976-4.013,2.712-4.338l4.555-0.76
		c1.735-0.325,3.473,1.085,3.797,3.146L195.205,818.222z"
          />
          <path
            d="M124.489,933.405c0,2.062-1.521,3.796-3.254,3.796h-4.664
		c-1.846,0-3.254-1.734-3.254-3.796v-42.95c0-2.061,1.519-3.796,3.254-3.796h4.664c1.844,0,3.254,1.733,3.254,3.796V933.405z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M54.749,820.065c-0.217,2.061-1.844,3.577-3.688,3.253
		l-4.558-0.65c-1.844-0.217-3.036-2.169-2.817-4.229l4.771-42.519c0.218-2.061,1.845-3.579,3.688-3.254l4.555,0.65
		c1.844,0.218,3.037,2.17,2.82,4.229L54.749,820.065z"
          />
          <path
            d="M84.033,881.887c0,2.063-1.521,3.797-3.254,3.797h-4.663
		c-1.846,0-3.254-1.733-3.254-3.797v-42.841c0-2.062,1.518-3.797,3.254-3.797h4.663c1.844,0,3.254,1.735,3.254,3.797V881.887z"
          />
          <path
            d="M195.205,818.222c0.325,2.063-0.979,4.015-2.712,4.338
		l-4.557,0.762c-1.845,0.323-3.472-1.085-3.797-3.146l-5.64-42.3c-0.325-2.061,0.976-4.013,2.712-4.338l4.555-0.76
		c1.735-0.325,3.473,1.085,3.797,3.146L195.205,818.222z"
          />
          <path
            d="M169.392,880.803c0,2.063-1.519,3.796-3.254,3.796h-4.664
		c-1.844,0-3.254-1.733-3.254-3.796v-42.842c0-2.061,1.521-3.796,3.254-3.796h4.664c1.844,0,3.254,1.735,3.254,3.796V880.803z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M124.706,924.837c0,2.063-1.52,3.797-3.254,3.797h-4.989
		c-1.844,0-3.254-1.733-3.254-3.797V822.668c0-2.061,1.519-3.796,3.254-3.796h4.989c1.844,0,3.254,1.735,3.254,3.796V924.837z"
          />
          <path
            d="M54.749,820.065c-0.217,2.061-1.844,3.577-3.688,3.253
		l-4.558-0.65c-1.844-0.217-3.036-2.169-2.817-4.229l4.771-42.519c0.218-2.061,1.845-3.579,3.688-3.254l4.555,0.65
		c1.844,0.218,3.037,2.17,2.82,4.229L54.749,820.065z"
          />
          <path
            d="M84.033,881.887c0,2.063-1.521,3.797-3.254,3.797h-4.663
		c-1.846,0-3.254-1.733-3.254-3.797v-42.841c0-2.062,1.518-3.797,3.254-3.797h4.663c1.844,0,3.254,1.735,3.254,3.797V881.887z"
          />
          <path
            d="M195.205,818.222c0.325,2.063-0.979,4.015-2.712,4.338
		l-4.557,0.762c-1.845,0.323-3.472-1.085-3.797-3.146l-5.64-42.3c-0.325-2.061,0.976-4.013,2.712-4.338l4.555-0.76
		c1.735-0.325,3.473,1.085,3.797,3.146L195.205,818.222z"
          />
          <path
            d="M169.392,880.803c0,2.063-1.519,3.796-3.254,3.796h-4.664
		c-1.844,0-3.254-1.733-3.254-3.796v-42.842c0-2.061,1.521-3.796,3.254-3.796h4.664c1.844,0,3.254,1.735,3.254,3.796V880.803z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M54.749,820.065c-0.217,2.061-1.844,3.577-3.688,3.253
		l-4.558-0.65c-1.844-0.217-3.036-2.169-2.817-4.229l4.771-42.519c0.218-2.061,1.845-3.579,3.688-3.254l4.555,0.65
		c1.844,0.218,3.037,2.17,2.82,4.229L54.749,820.065z"
          />
          <path
            d="M84.033,881.887c0,2.063-1.521,3.797-3.254,3.797h-4.663
		c-1.846,0-3.254-1.733-3.254-3.797v-42.841c0-2.062,1.518-3.797,3.254-3.797h4.663c1.844,0,3.254,1.735,3.254,3.797V881.887z"
          />
          <path
            d="M195.205,818.222c0.325,2.063-0.979,4.015-2.712,4.338
		l-4.557,0.762c-1.845,0.323-3.472-1.085-3.797-3.146l-5.64-42.3c-0.325-2.061,0.976-4.013,2.712-4.338l4.555-0.76
		c1.735-0.325,3.473,1.085,3.797,3.146L195.205,818.222z"
          />
          <path
            d="M169.392,880.803c0,2.063-1.519,3.796-3.254,3.796h-4.664
		c-1.844,0-3.254-1.733-3.254-3.796v-42.842c0-2.061,1.521-3.796,3.254-3.796h4.664c1.844,0,3.254,1.735,3.254,3.796V880.803z"
          />
          <path
            d="M124.489,933.405c0,2.062-1.521,3.796-3.254,3.796h-4.664
		c-1.846,0-3.254-1.734-3.254-3.796v-42.95c0-2.061,1.519-3.796,3.254-3.796h4.664c1.844,0,3.254,1.733,3.254,3.796V933.405z"
          />
        </g>
      )}
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path
            d="M119.934,40.239c0,0-35.03-1.085-49.239,40.13
		c-14.316,41.215-33.08,149.675-33.08,386.66c0,175.705,3.902,232.538,15.727,333.514c15.618,128.091,37.536,143.625,37.536,143.625
		c29.333-13,59,0,59,0s19.516-12.492,36.869-146.012c12.146-100.979,15.618-155.423,15.618-331.128
		c0-236.984-18.33-344.687-32.974-385.9c-14.751-41.215-50.106-40.889-50.106-40.889"
          />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path
          d="M87.83,969.848
		c0,0-34.382-9.108-56.073-156.507C16.247,707.809,15.054,649.35,15.054,465.727c0-247.722,20.935-361.17,39.588-404.229
		c18.766-43.059,64.859-41.974,64.859-41.974s47.83-1.085,65.835,42.083c18.004,43.167,38.503,156.507,38.503,404.229
		c0,183.623-1.19,242.083-16.052,347.614c-20.933,147.396-54.121,156.508-54.121,156.508S119.934,946.502,87.83,969.848z"
        />
      </g>
    </svg>
  )
}
export default LongboardCrescentSvg
