import React, { useState } from "react"
import { Box, IconButton, Typography } from "@mui/material"
import { getCurrencyStringFromNumber } from "../../Util/Util"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import Collapse from "@mui/material/Collapse"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#f9f9f9",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },

  totalPrice: {
    fontSize: "32px",
    fontWeight: "bold",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

const Invoice = ({ orderData }) => {
  const classes = useStyles()

  let subTotal = 0

  if (orderData?.options.length > 0) {
    subTotal = orderData?.options.reduce((sum, option) => {
      const price = parseFloat(option.price)
      return isNaN(price) ? sum : sum + price
    }, 0)
    subTotal += orderData?.additional_charges.reduce(
      (sum, additionalCharge) => {
        const amount = parseFloat(additionalCharge.amount)
        return isNaN(amount) ? sum : sum + amount
      },
      0
    )
  }
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  if (!orderData) {
    return <React.Fragment />
  }

  return (
    <Box className={classes.container} mb={3}>
      <div />
      <Typography variant="h4" className={classes.totalPrice}>
        Subtotal: {getCurrencyStringFromNumber(subTotal, true)}
      </Typography>
      <div />
    </Box>
  )

  return (
    <React.Fragment>
      <Box
        pt={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Invoice</Typography>
        <IconButton onClick={handleClick}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse in={open}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "background.paper",
            borderRadius: "borderRadius",
            padding: 3,
            marginBottom: 3,
            boxShadow: 1,
          }}
        >
          <Typography
            variant="h5"
            style={{
              fontFamily: "Josefin Sans",
              fontWeight: "bold",
            }}
          >
            Subtotal: {getCurrencyStringFromNumber(subTotal, true)}
          </Typography>

          {(orderData?.options || []).map((option, i) => {
            return (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  // marginTop: 2,
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  padding: 1,
                  fontFamily: "Josefin Sans",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="body1"
                    color="text.primary"
                    style={{
                      fontFamily: "Josefin Sans",
                    }}
                  >
                    {option.type_display}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      fontFamily: "Josefin Sans",
                    }}
                  >
                    {option?.extra_data?.name || option.value}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  color="text.primary"
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Josefin Sans",
                  }}
                >
                  {getCurrencyStringFromNumber(option.price, true)}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Collapse>
    </React.Fragment>
  )
}

export default Invoice
