import React, { useEffect, useState } from "react"
import DashboardLayout from "../../soft_dashboard/examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "../../soft_dashboard/examples/Navbars/DashboardNavbar"
import SoftBox from "../../soft_dashboard/components/SoftBox"
import Footer from "../../components/core/Footer/Footer"
import Axios from "../../Util/Axios"
import OrderOptionJsx from "../../components/Order/StaffOrderPage/OrderOptions/OrderOptionJsx"
import Card from "@mui/material/Card"
import { Skeleton } from "@mui/material"
import ImageDrag from "../../components/core/ImageDrag/ImageDrag"
import GalleryGalleryImage from "../../components/Gallery/StaffGalleryPage/GalleryDraggedImage"
import StaffOptionPageGroup from "./StaffOptionPageGroup"

const StaffOptionPage = () => {
  const [optionsDict, setOptionsDict] = useState(null)
  const [optionValues, setOptionValues] = useState(null)

  useEffect(() => {
    Axios.get("/api/staff-site-configuration/1/get_option_values/").then(
      ({ data }) => {
        setOptionValues(data)
      }
    )
  }, [])

  if (!optionValues) {
    return (
      <DashboardLayout>
        <DashboardNavbar pageTitle={"Custom Order Options"} />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </DashboardLayout>
    )
  }
  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={"Options"} />
      <Card>
        <SoftBox py={3} px={2}>
          {/*{Object.keys(optionsDict).map((key) => {*/}
          {/*  if (key === "unknown") return null*/}
          {/*  let options = optionsDict[key]*/}
          {/*  let firstOption = options[0]*/}
          {/*  let title = firstOption.type_display*/}
          {/*  return (*/}
          {/*    <StaffCustomOrderOptionGroup title={title} options={options} />*/}
          {/*  )*/}
          {/*})}*/}

          {Object.keys(optionValues).map((optionType) => {
            let value = optionValues[optionType]
            if (optionType === "unknown") {
              return
            }
            return (
              <StaffOptionPageGroup
                title={value}
                key={optionType}
                optionType={optionType}
              />
            )
          })}
        </SoftBox>
      </Card>
      <Footer />
    </DashboardLayout>
  )
}

export default StaffOptionPage
