import React, { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import { useSearchParams } from "react-router-dom"
import CustomTextField from "../../../../../components/core/CustomTextField/CustomTextField"

const DataTableSearchBarFilter = ({ filter, getData, searchTerm }) => {
  const [value, setValue] = useState("")
  let [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    setValue(searchParams.get(filter.title_key) || "")
  }, [])

  let options = [
    {
      key: "",
      value: "Any",
    },
    ...filter.options,
  ]

  return (
    <CustomTextField
      label={filter.title}
      variant="outlined"
      size="normal"
      select
      value={value}
      style={{ minWidth: "150px" }}
      onChange={(e) => setValue(e.target.value)}
    >
      {options.map((option) => {
        return (
          <MenuItem
            value={option.key}
            onClick={() => {
              searchParams.set(filter.title_key, option.key)
              setSearchParams(searchParams)
              getData(1, searchTerm, filter.title_key, option.key)
            }}
          >
            {option.value}
          </MenuItem>
        )
      })}
    </CustomTextField>
  )
}

export default DataTableSearchBarFilter
