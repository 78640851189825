import React, { useEffect, useState } from "react"
import { InstagramGallery } from "instagram-gallery"
import "./InstagramGalleryWrapper.css"
import Axios from "../../../Util/Axios"
const InstagramGalleryWrapper = () => {
  const [accessToken, setAccessToken] = useState("")

  useEffect(() => {
    Axios.get("/api/site-configuration/1/").then(({ data }) => {
      setAccessToken(data.instagram_long_lived_access_token)
    })
  }, [])

  if (!accessToken) {
    return <React.Fragment />
  }

  return (
    <InstagramGallery
      accessToken={accessToken}
      count={24}
      pagination={false}
      // pagination={true}
    />
  )
}

export default InstagramGalleryWrapper
