import SoftBox from "soft_dashboard/components/SoftBox"
import SoftTypography from "soft_dashboard/components/SoftTypography"

import DashboardLayout from "soft_dashboard/examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "soft_dashboard/examples/Navbars/DashboardNavbar"

import React, { useState } from "react"
import Time from "../../core/Time/Time"
import Footer from "../../core/Footer/Footer"

import StaffDataTable from "../../core/StaffDataTable/StaffDataTable"
import ProductStatusBadge from "../ProductStatusBadge"
import { Skeleton } from "@mui/material"
import Axios from "../../../Util/Axios"
import Toast, {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../../core/Toast/Toast"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { updateToastData } from "../../../slices/toastSlice"

const StaffProductList = () => {
  const [syncingWithDreamship, setSyncingWithDreamship] = useState(false)
  const [creatingProduct, setCreatingProduct] = useState(false)
  const navigate = useNavigate()

  const productColumns = [
    {
      name: "thumbnail",
      align: "left",
    },
    {
      name: "id",
      align: "left",
    },
    {
      name: "external_id",
      align: "left",
    },
    {
      name: "name",
      align: "left",
    },
    {
      name: "created_at",
      align: "left",
    },
    {
      name: "type",
      align: "left",
    },
    {
      name: "status",
      align: "left",
    },
    {
      name: "action",
      align: "left",
    },
  ]
  const dispatch = useDispatch()
  const getRow = (productData) => {
    return {
      thumbnail: (
        <SoftBox ml={2}>
          {productData.display_image ? (
            <img
              width={75}
              height={75}
              src={productData.display_image}
              className="App-logo"
              alt="thumbnail"
            />
          ) : (
            <Skeleton width={75} height={75} />
          )}
        </SoftBox>
      ),
      id: (
        <SoftBox>
          <SoftTypography variant="button" fontWeight="medium">
            {productData.id}
          </SoftTypography>
        </SoftBox>
      ),
      external_id: (
        <SoftBox>
          <SoftTypography variant="button" fontWeight="medium">
            {productData.external_id || "None"}
          </SoftTypography>
        </SoftBox>
      ),
      name: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {productData.name}
        </SoftTypography>
      ),
      created_at: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          <Time>{productData.created_at}</Time>
        </SoftTypography>
      ),
      type: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {productData.type_display}
        </SoftTypography>
      ),
      status: (
        <SoftBox>
          <ProductStatusBadge
            status={productData.status}
            statusDisplay={productData.status_display}
          />
        </SoftBox>
      ),
      action: (
        <SoftBox ml={2}>
          <SoftTypography
            component="a"
            href={`/staff/products/${productData.id}`}
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            View
          </SoftTypography>
        </SoftBox>
      ),
    }
  }

  const dataUrl = "/api/staff-product-list/"

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <StaffDataTable
            title={"Products"}
            columns={productColumns}
            getRow={getRow}
            dataUrl={dataUrl}
            actions={[
              {
                name: "Sync with Dreamship",
                color: "secondary",
                loading: syncingWithDreamship,
                onAction: () => {
                  let url =
                    "/api/staff-product-list/action_sync_all_with_dreamship/"
                  setSyncingWithDreamship(true)
                  Axios.post(url, {}, navigate)
                    .then(({ data }) => {
                      setSyncingWithDreamship(false)
                      dispatch(
                        updateToastData({
                          message: "Sync Successful",
                          severity: TOAST_SEVERITY_SUCCESS,
                        })
                      )
                    })
                    .catch((e) => {
                      setSyncingWithDreamship(false)
                      dispatch(
                        updateToastData({
                          message: e,
                          severity: TOAST_SEVERITY_ERROR,
                        })
                      )
                    })
                },
              },
              {
                name: "Create Product",
                color: "primary",
                loading: creatingProduct,
                onAction: () => {
                  let url = "/api/staff-product-list/action_create_product/"
                  setCreatingProduct(true)
                  Axios.post(url, {}, navigate)
                    .then(({ data }) => {
                      setCreatingProduct(false)
                      navigate(`/staff/products/${data.id}/`)
                    })
                    .catch((e) => {
                      setCreatingProduct(false)
                      dispatch(
                        updateToastData({
                          message: e,
                          severity: TOAST_SEVERITY_ERROR,
                        })
                      )
                    })
                },
              },
            ]}
            filters={[
              {
                title: "Type",
                title_key: "type",
                options: [
                  {
                    key: "inventory",
                    value: "Inventory",
                  },
                  {
                    key: "apparel",
                    value: "Apparel",
                  },
                ],
              },
              {
                title: "Status",
                title_key: "status",
                options: [
                  {
                    key: "draft",
                    value: "Draft",
                  },
                  {
                    key: "live",
                    value: "Live",
                  },
                  {
                    key: "sold",
                    value: "Sold",
                  },
                  {
                    key: "archived",
                    value: "Archived",
                  },
                ],
              },
            ]}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}

export default StaffProductList
