// prop-types is a library for typechecking of props

// @mui material components
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import Icon from "@mui/material/Icon"

// Soft UI Dashboard React components
import SoftBox from "soft_dashboard/components/SoftBox"
import SoftTypography from "soft_dashboard/components/SoftTypography"
import { numberWithCommas } from "../../Util/Util"

function MiniStatsCard({
  bgColor = "white",
  title,
  count,
  percentage,
  icon,
  prefix,
}) {
  let percentageColor = "success"
  if (percentage?.startsWith("-")) {
    percentageColor = "error"
  }

  return (
    <Card>
      <SoftBox bgColor={bgColor} variant="gradient">
        <SoftBox p={2}>
          <Grid container alignItems="center">
            <Grid item>
              <SoftBox
                variant="gradient"
                bgColor={bgColor === "white" ? icon.color : "white"}
                color={bgColor === "white" ? "white" : "dark"}
                width="3rem"
                height="3rem"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
              >
                <Icon fontSize="small" color="inherit">
                  {icon.component}
                </Icon>
              </SoftBox>
            </Grid>

            <Grid item xs={8}>
              <SoftBox ml={2} lineHeight={1}>
                <SoftTypography
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                >
                  {title}
                </SoftTypography>
                <SoftTypography
                  variant="h5"
                  fontWeight="bold"
                  color={bgColor === "white" ? "dark" : "white"}
                >
                  {prefix}
                  {numberWithCommas(count)}{" "}
                  {percentage && (
                    <SoftTypography
                      variant="button"
                      color={percentageColor}
                      fontWeight="bold"
                    >
                      {percentage}
                    </SoftTypography>
                  )}
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  )
}

export default MiniStatsCard
