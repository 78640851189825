import React, { useState } from "react"
import { Box, Grid, IconButton, Typography } from "@mui/material"
import ColorBox from "./ColorBox"
import { getCurrencyStringFromNumber } from "../../Util/Util"
import ColorLensIcon from "@mui/icons-material/ColorLens"
import DeleteIcon from "@mui/icons-material/Delete"
import CustomOrderColorModal from "./CustomOrderColorModal"

const CustomOrderColorOption = ({
  title,
  helpText,
  parentColorOption,
  setChildColorOption,
  childColorOptions,
  childColorOption,
  colorOption,
  setColorOption,
  colorOptions,
  showColorPicker,
  showColorOptions,
  updateOptions,
  orderData,
  isStaff,
}) => {
  const [customOrderColorModalActive, setCustomOrderColorModalActive] =
    useState(false)
  const disabled = orderData?.locked && !isStaff
  const setColorOptionWrapper = (cO) => {
    let newOptions = []
    if (colorOption.type === "bottom_color") {
      if (childColorOption?.value !== "#ffffff") {
        let newChildOption = childColorOptions.find((x) => x.value === cO.value)
        setChildColorOption(newChildOption)
        newOptions.push(newChildOption)
      }
    }
    newOptions.push(cO)
    setColorOption(cO)
    updateOptions(newOptions)
  }

  const handleColorSelect = (color) => {
    if (!disabled) {
      setColorOptionWrapper(color)
      setCustomOrderColorModalActive(false)
    }
  }

  if (colorOption?.type === "deck_color" && !parentColorOption) {
    helpText += ". First select the bottom color"
  }

  if (colorOption?.price > 0) {
    title += ` ${getCurrencyStringFromNumber(colorOption.price)}`
  }

  if (!colorOption) {
    return <React.Fragment />
  }

  return (
    <Box my={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Box>
          <Typography
            variant="body1"
            style={{
              fontFamily: "Josefin Sans",
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Grid
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {title === "Fin Box Color" &&
            colorOptions.map((cO) => {
              return (
                <ColorBox
                  key={cO.id}
                  colorOption={cO}
                  clickable={!disabled}
                  selected={colorOption?.value === cO?.value}
                  handleColorSelect={setColorOptionWrapper}
                />
              )
            })}

          {showColorOptions &&
            colorOptions.map((cO) => {
              return (
                <ColorBox
                  key={cO.id}
                  colorOption={cO}
                  clickable={!disabled}
                  selected={colorOption?.value === cO?.value}
                  handleColorSelect={setColorOptionWrapper}
                />
              )
            })}
          {showColorPicker && (
            <React.Fragment>
              {colorOption && (
                <ColorBox colorOption={colorOption} selected={true} />
              )}
              <IconButton
                disabled={disabled}
                onClick={() => {
                  setCustomOrderColorModalActive(true)
                }}
              >
                <ColorLensIcon style={{ color: colorOption }} />
              </IconButton>
              <IconButton
                disabled={disabled}
                onClick={() => {
                  setColorOption(null)
                  updateOptions([], [colorOption, childColorOption])
                }}
                style={{
                  color: "#fc3158",
                }}
              >
                <DeleteIcon />
              </IconButton>

              <CustomOrderColorModal
                active={customOrderColorModalActive}
                setActive={setCustomOrderColorModalActive}
                colorOptions={colorOptions}
                handleColorSelect={handleColorSelect}
              />
            </React.Fragment>
          )}
          {colorOption?.type === "abstract_color" && (
            <IconButton
              disabled={disabled}
              onClick={() => {
                setColorOption(null)
                updateOptions([], [colorOption])
              }}
              style={{
                color: "#fc3158",
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {helpText && (
        <Box>
          <Typography
            variant={"caption"}
            style={{
              fontFamily: "Josefin Sans",
            }}
          >
            {helpText}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default CustomOrderColorOption
