import * as React from "react"

const PerformanceSquareSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  // let widthScale = getWidthScaleFactor(originalWidthInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 973.455

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M92.746,914.464 c0,0-43.81-176.452-43.81-412.846c0-381.031,116.005-481.915,116.005-481.915s116.01,98.786,116.01,479.577 c0,268.731-49.766,415.222-49.766,415.222" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path d=" M101.902,904.261c0,0-41.786-171.396-41.786-401.277c0-361.646,104.825-468.646,104.825-468.646s104.83,102.916,104.83,466.374 c0,261.334-46.696,402.52-46.696,402.52L101.902,904.261z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M92.915,914.895 c0,0-51.673-195.5-41.672-518.375l113.178-80.528l114.226,80.528c11.43,372.041-47.569,518.375-47.569,518.375" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M92.746,914.464 c0,0-53.504-204.735-41.503-517.944c0.001,0,102.697-181.19,227.403,0c13.596,318.709-47.461,517.982-47.461,517.982" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M92.746,914.464 c0,0-55.504-223.235-41.15-517.944c0,0,41.487-9.652,72.761-34.167s40.918-60.931,40.918-60.931s12.188,36.501,38.186,59.53 c28.464,25.213,74.537,35.567,74.537,35.567c15.244,315.209-46.813,517.981-46.813,517.981" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M93.537,914.473 c0,0-21.461-73.901-35.506-226.88l107.289-30.604l106.675,30.604c-14.253,156.646-41.474,226.909-41.474,226.909" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M93.537,914.473 c0,0-22.128-78.568-35.542-226.88l107.325,44.734l106.709-44.734c-13.62,148.646-41.508,226.909-41.508,226.909" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path d="M93.537,914.473 c0,0-21.128-69.234-35.506-226.88c0,0,48.983-2.226,77.063-15.644c28.08-13.416,30.227-22.224,30.227-22.224 s10.976,16.109,25.429,21.562c20.128,7.592,81.282,16.306,81.282,16.306c-12.622,145.312-41.51,226.909-41.51,226.909" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M93.537,914.473 c0,0-22.128-76.901-36.018-227.553c0,0,73.898-3.903,88.351,16.223c14.452,20.128,20.138,34.094,20.138,34.094 s8.383-18.4,28.483-34.106c20.94-16.358,77.991-16.208,77.991-16.208c-13.073,142.468-41.961,227.582-41.961,227.582" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M93.537,914.473 c0,0-20.128-65.901-34.009-205.85c-0.848-6.795-0.705-13.804-1.495-21.032c-0.001,0.002,101.973-68.765,213.996,0.002 c0,0-1.262,12.001-2,17.855c-14.954,154.454-39.508,209.054-39.508,209.054" />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={242.207} y1={182.394} x2={242.207} y2={874.439} />
          <line x1={83.915} y1={182.394} x2={83.915} y2={874.439} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={153.069} y1={32.739} x2={153.069} y2={914.502} />
          <line x1={177.053} y1={32.739} x2={177.053} y2={914.502} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.061} y1={19.703} x2={165.061} y2={914.502} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.105,3.58-4.105h5.487c2.029,0,3.58,1.879,3.58,4.105V863.752z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.105,3.58-4.105h5.487c2.029,0,3.58,1.879,3.58,4.105V863.752z" />
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.352,3.102,4.577L94.858,775.854z" />
          <path d="M249.374,773.861c0.356,2.229-1.075,4.343-2.983,4.691 l-5.014,0.823c-2.027,0.353-3.816-1.174-4.175-3.405l-6.204-45.776c-0.357-2.23,1.072-4.345,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M94.858,776.088c-0.238,2.23-2.027,3.874-4.056,3.523 l-5.013-0.705c-2.028-0.235-3.339-2.35-3.101-4.577l5.248-46.014c0.24-2.23,2.029-3.871,4.057-3.521l5.011,0.703 c2.028,0.234,3.341,2.347,3.102,4.577L94.858,776.088z" />
          <path d="M249.374,774.093c0.356,2.229-1.075,4.345-2.983,4.697 l-5.014,0.818c-2.027,0.354-3.816-1.176-4.175-3.404l-6.204-45.779c-0.357-2.229,1.072-4.342,2.984-4.692l5.011-0.82 c1.909-0.352,3.817,1.175,4.176,3.403L249.374,774.093z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.352,3.102,4.577L94.858,775.854z" />
          <path d="M249.374,773.861c0.356,2.229-1.075,4.343-2.983,4.691 l-5.014,0.823c-2.027,0.353-3.816-1.174-4.175-3.405l-6.204-45.776c-0.357-2.23,1.072-4.345,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M171.58,879.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.481c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V879.979z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.352,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109V796.4c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.229-1.075,4.343-2.983,4.691 l-5.014,0.823c-2.027,0.353-3.816-1.174-4.175-3.405l-6.204-45.776c-0.357-2.23,1.072-4.345,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.253-1.878-4.253-4.105v-46.365c0-2.229,1.985-4.104,4.253-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.105,3.58-4.105h5.487c2.029,0,3.58,1.879,3.58,4.105V863.752z" />
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.352,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109V796.4c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.229-1.075,4.343-2.983,4.691 l-5.014,0.823c-2.027,0.353-3.816-1.174-4.175-3.405l-6.204-45.776c-0.357-2.23,1.072-4.345,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.253-1.878-4.253-4.105v-46.365c0-2.229,1.985-4.104,4.253-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
          <path d="M171.58,879.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.482c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V879.979z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={535.58}
          width={96}
          height={92.504}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d="M110.652,894.511 c0,0-38.716-165.937-38.716-387.908c0-357.79,93.005-452.518,93.005-452.518s93.01,92.761,93.01,450.322 c0,252.341-43.051,389.819-43.051,389.819L110.652,894.511z" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d="M92.746,914.464 c0,0-43.81-176.452-43.81-412.846c0-381.031,116.005-481.915,116.005-481.915s116.01,98.786,116.01,479.577 c0,268.732-49.766,415.222-49.766,415.222L92.746,914.464z" />
      </g>
    </svg>
  )
}
export default PerformanceSquareSvg
