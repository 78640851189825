import React, { useEffect, useState } from "react"
import SoftBox from "../../soft_dashboard/components/SoftBox"
import Card from "@mui/material/Card"
import TransactionItem from "./TransactionItem"
import SoftTypography from "../../soft_dashboard/components/SoftTypography"
import SkeletonCard from "../core/SkeletonCard/SkeletonCard"
import TextButton from "../core/TextButton/TextButton"
import CreateTransactionModal from "./CreateTransactionModal"

const Transactions = ({ orderData, setOrderData }) => {
  const [createTransactionModalActive, setCreateTransactionModalActive] =
    useState(false)
  useEffect(() => {}, [])
  if (!orderData) {
    return <SkeletonCard />
  }

  return (
    <Card title={"Transactions"}>
      <SoftBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h6" fontWeight="medium">
          Transactions
        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="medium">
          <TextButton
            disabled={createTransactionModalActive}
            onClick={() => {
              setCreateTransactionModalActive(true)
            }}
          >
            Create
          </TextButton>
        </SoftTypography>
      </SoftBox>
      <SoftBox pt={1} pb={2} px={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          {orderData.transactions.map((transactionData) => {
            return (
              <TransactionItem
                transactionData={transactionData}
                orderData={orderData}
                setOrderData={setOrderData}
              ></TransactionItem>
            )
          })}
        </SoftBox>
      </SoftBox>
      <CreateTransactionModal
        active={createTransactionModalActive}
        setActive={setCreateTransactionModalActive}
        orderData={orderData}
        setOrderData={setOrderData}
      />
    </Card>
  )
}

export default Transactions
