import React from "react"
import Card from "@mui/material/Card"
import SoftBox from "../../soft_dashboard/components/SoftBox"
import SoftTypography from "../../soft_dashboard/components/SoftTypography"
import DescriptionItem from "../core/DescriptionItem/DescriptionItem"
import SkeletonCard from "../core/SkeletonCard/SkeletonCard"

const Address = ({ orderData }) => {
  if (!orderData?.address) {
    return <SkeletonCard />
  }

  let address = orderData.address

  const getAddressItem = (key, value) => {
    return (
      <DescriptionItem
        keyJsx={
          <SoftTypography display="block" variant="button" fontWeight="medium">
            {key}
          </SoftTypography>
        }
        valueJsx={
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {value}
          </SoftTypography>
        }
      />
    )
  }

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h6" fontWeight="medium">
          Address
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          {getAddressItem("Name", address.full_name)}
          {getAddressItem("Phone", address.phone)}
          {getAddressItem("Email", address.email)}
          {getAddressItem("Street 1", address.street1)}
          {getAddressItem("Street 2", address.street2)}
          {getAddressItem("City", address.city)}
          {getAddressItem("State", address.state)}
          {getAddressItem("Country", address.country)}
          {getAddressItem("Zip", address.zip)}
        </SoftBox>
      </SoftBox>
    </Card>
  )
}

export default Address
