import SoftBox from "soft_dashboard/components/SoftBox"
import SoftTypography from "soft_dashboard/components/SoftTypography"

import DashboardLayout from "soft_dashboard/examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "soft_dashboard/examples/Navbars/DashboardNavbar"

import React from "react"
import Time from "../../core/Time/Time"
import Footer from "../../core/Footer/Footer"

import StaffDataTable from "../../core/StaffDataTable/StaffDataTable"

const StaffCartList = () => {
  const cartColumns = [
    {
      name: "id",
      align: "left",
    },
    {
      name: "unpaid_cart_items",
      align: "left",
    },
    {
      name: "paid_cart_items",
      align: "left",
    },
    {
      name: "cart_items",
      align: "left",
    },
    {
      name: "updated_at",
      align: "left",
    },
    {
      name: "created_at",
      align: "left",
    },
    {
      name: "action",
      align: "left",
    },
  ]
  const getRow = (cart) => {
    return {
      id: (
        <SoftBox pl={2}>
          <SoftTypography variant="button" fontWeight="medium">
            {cart.id}
          </SoftTypography>
        </SoftBox>
      ),
      unpaid_cart_items: (
        <SoftBox>
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {cart.unpaid_cart_items}
          </SoftTypography>
        </SoftBox>
      ),
      paid_cart_items: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {cart.paid_cart_items}
        </SoftTypography>
      ),
      cart_items: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {cart.cart_items}
        </SoftTypography>
      ),
      updated_at: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          <Time>{cart.updated_at}</Time>
        </SoftTypography>
      ),
      created_at: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          <Time>{cart.created_at}</Time>
        </SoftTypography>
      ),

      action: (
        <SoftBox ml={2}>
          <SoftTypography
            component="a"
            href={`/staff/carts/${cart.id}`}
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            View
          </SoftTypography>
        </SoftBox>
      ),
    }
  }

  const dataUrl = "/api/staff-cart-list/"

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <StaffDataTable
            title={"Carts"}
            columns={cartColumns}
            getRow={getRow}
            dataUrl={dataUrl}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}

export default StaffCartList
