import React, { useEffect, useState } from "react"

// react-router components
import { useLocation, useNavigate } from "react-router-dom"
import ReactPixel from "react-facebook-pixel"
// @mui material components
import CssBaseline from "@mui/material/CssBaseline"

import Sidenav from "soft_dashboard/examples/Sidenav"
import ReactGA from "react-ga4"
import CookieConsent from "react-cookie-consent"
import { setMiniSidenav, useSoftUIController } from "soft_dashboard/context"
import AppRouter from "./AppRouter/AppRouter"
import { ThemeProvider } from "@mui/material/styles"
import staffTheme from "./soft_dashboard/assets/theme"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Axios from "./Util/Axios"
import Cookies from "js-cookie"
import { useDispatch } from "react-redux"
import { updateCartItemsAction, updateCartLoaded } from "./slices/cartSlice"
import Toast from "./components/core/Toast/Toast"

const App = () => {
  const [controller, dispatch] = useSoftUIController()
  const { miniSidenav, sidenavColor } = controller
  const [onMouseEnter, setOnMouseEnter] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const mainDispatch = useDispatch()

  const consentCookieName = "marlin_surf_consent_cookies"
  const consentCookie = Cookies.get(consentCookieName)

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false)
      setOnMouseEnter(true)
    }
  }

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true)
      setOnMouseEnter(false)
    }
  }

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      ReactPixel.init("2426026820903187")
      // ReactGA.initialize("G-X41T1NTJG5")

      ReactGA.initialize([
        {
          trackingId: "G-X41T1NTJG5",
        },
      ])
      ReactGA.ga("consent.revoke")
      if (!consentCookie) {
        ReactGA.ga("consent.revoke")
        ReactPixel.revokeConsent()
      }
      // ReactGA.pageview(window.location.pathname + window.location.search)
    }

    const cartId = Cookies.get("cart_id")

    if (cartId) {
      Axios.get(`/api/cart-detail/${cartId}/`, navigate)
        .then(({ data }) => {
          mainDispatch(updateCartItemsAction(data.cart_items))
          mainDispatch(updateCartLoaded(true))
        })
        .catch((e) => {
          // console.log(e)
          // dispatch(
          //   updateToastData({
          //     message: e,
          //     severity: TOAST_SEVERITY_ERROR,
          //   })
          // )
          // Cookies.remove("cart_id")
        })
    }
  }, [])

  // localStorage.removeItem("token")
  let token = localStorage.getItem("token")
  useEffect(() => {
    setIsAuthenticated(!!token)
  }, [token])
  return (
    <>
      {pathname.startsWith("/staff") && isAuthenticated && (
        <>
          <ThemeProvider theme={staffTheme}>
            <CssBaseline />
            <Sidenav
              color={sidenavColor}
              // brand={brand}
              brandName="Marlin Surfboards"
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </ThemeProvider>
        </>
      )}
      <Toast />
      <AppRouter />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName={consentCookieName}
        style={{
          background: "#FFFFFF",
          color: "#fff",
          fontSize: "14px",
          zIndex: 1201,
        }}
        buttonStyle={{
          background: "#0073ce",
          color: "white",
          fontSize: "14px",
          borderRadius: "2px",
        }}
        declineButtonStyle={{
          background: "transparent",
          borderRadius: "2px",
          border: "none",
          color: "black",
          fontSize: "14px",
        }}
        expires={150}
        enableDeclineButton
        declineButtonText="Decline"
        onAccept={() => {
          ReactPixel.grantConsent()
          ReactGA.ga("consent.grant")
        }}
        onDecline={() => {
          // Handle the decline action here
        }}
      >
        <div
          style={{
            color: "black",
          }}
        >
          This website uses cookies to enhance your experience. By accepting,
          you consent to the use of cookies.
          <span style={{ fontSize: "12px", marginLeft: "5px" }}>
            <a href="/cookies" style={{ color: "#0073ce" }}>
              View Cookie Policy
            </a>
          </span>
        </div>
      </CookieConsent>
    </>
  )
}
export default App
