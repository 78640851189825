import React from "react"
import Moment from "react-moment"

const Time = ({ children }) => {
  return (
    <Moment format="MM/DD/YYYY h:mm A" date={children}>
      {children}
    </Moment>
  )
}

export default Time
