import React from "react"
import { Box, Typography, List, ListItem, Divider } from "@mui/material"
import SoftBox from "../../soft_dashboard/components/SoftBox"
import IconButton from "@mui/material/IconButton"
import Axios from "../../Util/Axios"
import { updateToastData } from "../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../../components/core/Toast/Toast"
import CloseIcon from "@mui/icons-material/Close"
import DescriptionItem from "../../components/core/DescriptionItem/DescriptionItem"
import Grid from "@mui/material/Grid"
import SoftTypography from "../../soft_dashboard/components/SoftTypography"
import { getCurrencyStringFromNumber } from "../../Util/Util"

const CustomOrderAdditionalCharges = ({ orderData }) => {
  if (!orderData?.additional_charges?.length) {
    return null
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Additional Charges
      </Typography>
      <List>
        {orderData.additional_charges.map((additionalCharge) => (
          <Box
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor="grey-100"
            borderRadius="lg"
            // p={3}
            mb={0}
            mt={2}
            sx={{
              fontFamily: "Josefin Sans",
            }}
            position="relative" // Added relative position
          >
            <Box width="100%" display="flex" flexDirection="column">
              <DescriptionItem
                spacing={"tight"}
                keyJsx={
                  <Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          fontFamily: "Josefin Sans",
                        }}
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {additionalCharge.name}
                      </Typography>
                    </Grid>
                    {additionalCharge.description && (
                      <Grid item>
                        <Typography
                          sx={{
                            fontFamily: "Josefin Sans",
                          }}
                          variant="caption"
                          fontWeight="regular"
                        >
                          {additionalCharge.description}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                }
                valueJsx={
                  <Typography
                    variant="button"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {getCurrencyStringFromNumber(additionalCharge.amount)}
                  </Typography>
                }
              />
            </Box>
          </Box>
        ))}
      </List>
    </Box>
  )
}

export default CustomOrderAdditionalCharges
