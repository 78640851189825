import * as React from "react"
const HybridRoundSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 932.953

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M90.146,864.644 c0,0-65.249-121.152-68.249-467.143s143-380.115,143-380.115S310.9,51.511,307.9,397.5 c-3.002,345.99-67.404,467.476-67.404,467.476S176.99,1000.901,90.146,864.644z" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path d=" M100.813,862.31c0,0-64.752-125.441-67.514-462.592c-2.76-337.145,131.6-370.398,131.6-370.398S299.26,62.572,296.5,399.717 c-2.764,337.147-67.525,462.593-67.525,462.593S176.479,979.982,100.813,862.31z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M307.9,397.5 c-3.002,345.99-67.404,467.476-67.404,467.476s-63.506,135.926-150.35-0.333c0,0-65.249-121.152-68.249-467.143l142.344-76.656 L307.9,397.5z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M307.9,397.5 c-3.002,345.99-67.404,467.476-67.404,467.476s-63.506,135.926-150.35-0.333c0,0-65.249-121.152-68.249-467.143 C21.898,397.5,151.059,225.024,307.9,397.5z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M308.345,397.5 c-3.002,345.99-67.403,467.476-67.403,467.476s-63.506,135.926-150.351-0.333c0,0-65.249-121.152-68.249-467.143 c0,0,52.178-9.19,91.511-32.524s51.463-58,51.463-58s15.33,34.745,48.025,56.667C249.141,387.643,308.345,397.5,308.345,397.5z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M295.764,619.632 c-19.65,171.482-55.268,245.345-55.268,245.345s-63.506,135.926-150.35-0.332c0,0-36.271-67.348-55.97-245.013l131.167-36.498 L295.764,619.632z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M295.764,619.632 c-19.65,171.482-55.268,245.345-55.268,245.345s-63.506,135.926-150.35-0.332c0,0-36.271-67.348-55.97-245.013l131.167,53.345 L295.764,619.632z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path d="M295.764,619.632 c-19.65,171.482-55.268,245.345-55.268,245.345s-63.506,135.926-150.35-0.332c0,0-36.271-67.348-55.97-245.013 c0,0,60.565-2.655,94.565-18.655s36.602-26.5,36.602-26.5s13.287,19.209,30.787,25.709 C220.504,609.238,295.764,619.632,295.764,619.632z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M295.035,619.813 c-19.65,171.482-55.268,245.346-55.268,245.346s-63.506,135.925-150.35-0.333c0,0-36.271-67.348-55.97-245.013 c0,0,89.565-4.654,107.065,19.346s24.385,40.653,24.385,40.653s10.149-21.942,34.49-40.673 C224.742,619.632,295.035,619.813,295.035,619.813z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M295.764,619.632 c0,0-1.712,14.497-2.606,21.479c-20.026,156.189-52.66,223.865-52.66,223.865s-63.506,135.926-150.35-0.332 c0,0-32.837-60.973-52.992-219.933c-1.027-8.104-2.022-16.462-2.978-25.08C34.177,619.632,160.117,537.625,295.764,619.632z" />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={264.587} y1={127.928} x2={264.587} y2={796.595} />
          <line x1={65.503} y1={127.928} x2={65.503} y2={796.595} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={149.963} y1={22.448} x2={149.963} y2={922.322} />
          <line x1={180.127} y1={22.448} x2={180.127} y2={922.322} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.045} y1={17.386} x2={165.045} y2={925.152} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M173.396,831.474c0,2.891-2.129,5.319-4.559,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V688.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.559,2.433,4.559,5.318V831.474z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M173.396,831.474c0,2.891-2.129,5.319-4.559,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V688.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.559,2.433,4.559,5.318V831.474z" />
          <path d="M75.386,717.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,717.686z" />
          <path d="M272.169,715.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,715.104z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M75.386,717.989c-0.304,2.887-2.583,5.015-5.166,4.559 l-6.384-0.912c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.013,5.167-4.559l6.381,0.913 c2.583,0.304,4.255,3.038,3.951,5.924L75.386,717.989z" />
          <path d="M272.169,715.404c0.455,2.889-1.37,5.624-3.8,6.079l-6.385,1.063 c-2.582,0.456-4.861-1.52-5.317-4.408l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.078l6.381-1.063 c2.432-0.455,4.863,1.521,5.318,4.406L272.169,715.404z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,717.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,717.686z" />
          <path d="M272.169,715.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,715.104z" />
          <path d="M173.094,852.479c0,2.889-2.129,5.317-4.559,5.317H162 c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.317,4.559-5.317h6.535c2.583,0,4.559,2.43,4.559,5.317V852.479z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,717.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,717.686z" />
          <path d="M106.658,804.299c0,2.891-2.529,                                     5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.431-5.415-5.319v-60.021c0-2.889,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V804.299z" />
          <path d="M272.169,715.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,715.104z" />
          <path d="M248.695,802.78c0,2.888-2.525,5.317-5.414,5.317h-7.761 c-3.067,0-5.414-2.431-5.414-5.317v-60.022c0-2.887,2.528-5.317,5.414-5.317h7.761c3.068,0,5.414,2.432,5.414,5.317V802.78z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M173.396,831.474c0,2.891-2.129,5.319-4.559,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V688.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.559,2.433,4.559,5.318V831.474z" />
          <path d="M75.386,717.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,717.686z" />
          <path d="M106.658,804.299c0,2.891-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.431-5.415-5.319v-60.021c0-2.889,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V804.299z" />
          <path d="M272.169,715.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,715.104z" />
          <path d="M248.695,802.78c0,2.888-2.525,5.317-5.414,5.317h-7.761 c-3.067,0-5.414-2.431-5.414-5.317v-60.022c0-2.887,2.528-5.317,5.414-5.317h7.761c3.068,0,5.414,2.432,5.414,5.317V802.78z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,717.686c-0.304,2.888-2.583,5.014-5.166,4.559 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,717.686z" />
          <path d="M106.658,804.299c0,2.891-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.431-5.415-5.319v-60.021c0-2.889,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V804.299z" />
          <path d="M272.169,715.104c0.455,2.888-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.887,1.367-5.622,3.801-6.077l6.381-1.064 c2.432-0.456,4.863,1.521,5.318,4.406L272.169,715.104z" />
          <path d="M248.695,802.78c0,2.888-2.525,5.317-5.414,5.317h-7.761 c-3.067,0-5.414-2.431-5.414-5.317v-60.022c0-2.887,2.528-5.317,5.414-5.317h7.761c3.068,0,5.414,2.432,5.414,5.317V802.78z" />
          <path d="M173.094,852.479c0,2.889-2.129,5.317-4.559,5.317H162 c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.317,4.559-5.317h6.535c2.583,0,4.559,2.43,4.559,5.317V852.479z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={492.133}
          width={96}
          height={91.001}
          href={logoUrl}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d=" M110.25,854.561c0,0-66.01-105.585-65.972-447.985C41.749,74.405,164.898,41.644,164.898,41.644S288.05,74.406,285.52,406.575 c-2.531,332.17-67.11,450.069-67.11,450.069S174.409,960.314,110.25,854.561z" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d=" M90.146,864.644c0,0-63.404-93.002-68.249-467.143c-4.479-345.974,143-380.115,143-380.115S310.9,51.511,307.9,397.5 c-3.002,345.99-67.404,467.476-67.404,467.476S174.742,1003.642,90.146,864.644z" />
      </g>
    </svg>
  )
}
export default HybridRoundSvg
