import React, { useEffect, useState } from "react"
import Modal from "../core/Modal/Modal"
import TextField from "@mui/material/TextField"
import { Checkbox, FormControlLabel, InputAdornment } from "@mui/material"
import Axios from "../../Util/Axios"
import SoftTypography from "../../soft_dashboard/components/SoftTypography"
import { getCurrencyStringFromNumber } from "../../Util/Util"
import SoftBox from "../../soft_dashboard/components/SoftBox"
import { updateToastData } from "../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../core/Toast/Toast"
import { useDispatch } from "react-redux"

const CreateTransactionModal = ({
  orderData,
  setOrderData,
  active,
  setActive,
}) => {
  const [amountSubtotal, setAmountSubtotal] = useState(0)
  const [amountTax, setAmountTax] = useState(0)
  const [isDepositChecked, setIsDepositChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const amountTotal = parseFloat(amountSubtotal) + parseFloat(amountTax)
  const dispatch = useDispatch()
  useEffect(() => {
    setAmountTax(0)
    setAmountSubtotal(0)
    setIsDepositChecked(false)
  }, [active])

  return (
    <Modal
      title={"Create Transaction"}
      confirmDisabled={loading || amountSubtotal <= 0}
      onConfirm={() => {
        setLoading(true)
        Axios.post(
          `/api/staff-order-detail/${orderData.customer_facing_id}/action_create_manual_transaction/`,
          {
            amount_tax: amountTax,
            amount_subtotal: amountSubtotal,
            is_deposit: isDepositChecked,
          }
        )
          .then(({ data }) => {
            setLoading(false)
            setOrderData(data)
            setActive(false)
          })
          .catch((e) => {
            setLoading(false)
            dispatch(
              updateToastData({
                message: e,
                severity: TOAST_SEVERITY_ERROR,
              })
            )
          })
      }}
      active={active}
      setActive={setActive}
      confirmButtonName={"Create"}
    >
      <SoftBox pb={3}>
        <SoftTypography variant={"h6"}>
          For if you want to add money you received on venmo or in cash.
        </SoftTypography>
      </SoftBox>

      <TextField
        disabled={loading}
        margin="normal"
        fullWidth
        label={"Amount Subtotal"}
        required
        value={amountSubtotal}
        type={"number"}
        InputProps={{
          inputProps: { min: 0 },
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onChange={(event) => {
          let newAmountSubtotal = event.target.value
          if (newAmountSubtotal < 0) {
            setAmountSubtotal(0)
          } else {
            setAmountSubtotal(event.target.value)
          }
        }}
      />
      <TextField
        disabled={loading}
        margin="normal"
        fullWidth
        label={"Amount Tax"}
        required
        value={amountTax}
        type={"number"}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onChange={(event) => {
          let newAmountTax = event.target.value
          if (newAmountTax < 0) {
            setAmountTax(0)
          } else {
            setAmountTax(event.target.value)
          }
        }}
      />
      <SoftBox pb={2} pt={2} pl={2}>
        <FormControlLabel
          value="start"
          control={
            <Checkbox
              checked={isDepositChecked}
              onChange={(event) => {
                setIsDepositChecked(event.target.checked)
              }}
            />
          }
          label="Is Deposit"
          labelPlacement="end"
        />
      </SoftBox>

      <SoftTypography>
        Total: {getCurrencyStringFromNumber(amountTotal)}
      </SoftTypography>
    </Modal>
  )
}

export default CreateTransactionModal
