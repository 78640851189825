/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { createTheme } from "@mui/material/styles"

// Soft UI Dashboard React base styles
import colors from "soft_dashboard/assets/theme/base/colors"
import breakpoints from "soft_dashboard/assets/theme/base/breakpoints"
import typography from "soft_dashboard/assets/theme/base/typography"
import boxShadows from "soft_dashboard/assets/theme/base/boxShadows"
import borders from "soft_dashboard/assets/theme/base/borders"
import globals from "soft_dashboard/assets/theme/base/globals"

// Soft UI Dashboard React helper functions
import boxShadow from "soft_dashboard/assets/theme/functions/boxShadow"
import hexToRgb from "soft_dashboard/assets/theme/functions/hexToRgb"
import linearGradient from "soft_dashboard/assets/theme/functions/linearGradient"
import pxToRem from "soft_dashboard/assets/theme/functions/pxToRem"
import rgba from "soft_dashboard/assets/theme/functions/rgba"

// Soft UI Dashboard React components base styles for @mui material components
import sidenav from "soft_dashboard/assets/theme/components/sidenav"
import list from "soft_dashboard/assets/theme/components/list"
import listItem from "soft_dashboard/assets/theme/components/list/listItem"
import listItemText from "soft_dashboard/assets/theme/components/list/listItemText"
import card from "soft_dashboard/assets/theme/components/card"
import cardMedia from "soft_dashboard/assets/theme/components/card/cardMedia"
import cardContent from "soft_dashboard/assets/theme/components/card/cardContent"
import button from "soft_dashboard/assets/theme/components/button"
import iconButton from "soft_dashboard/assets/theme/components/iconButton"
import inputBase from "soft_dashboard/assets/theme/components/form/inputBase"
import menu from "soft_dashboard/assets/theme/components/menu"
import menuItem from "soft_dashboard/assets/theme/components/menu/menuItem"
import switchButton from "soft_dashboard/assets/theme/components/form/switchButton"
import divider from "soft_dashboard/assets/theme/components/divider"
import tableContainer from "soft_dashboard/assets/theme/components/table/tableContainer"
import tableHead from "soft_dashboard/assets/theme/components/table/tableHead"
import tableCell from "soft_dashboard/assets/theme/components/table/tableCell"
import linearProgress from "soft_dashboard/assets/theme/components/linearProgress"
import breadcrumbs from "soft_dashboard/assets/theme/components/breadcrumbs"
import slider from "soft_dashboard/assets/theme/components/slider"
import avatar from "soft_dashboard/assets/theme/components/avatar"
import tooltip from "soft_dashboard/assets/theme/components/tooltip"
import appBar from "soft_dashboard/assets/theme/components/appBar"
import tabs from "soft_dashboard/assets/theme/components/tabs"
import tab from "soft_dashboard/assets/theme/components/tabs/tab"
import stepper from "soft_dashboard/assets/theme/components/stepper"
import step from "soft_dashboard/assets/theme/components/stepper/step"
import stepConnector from "soft_dashboard/assets/theme/components/stepper/stepConnector"
import stepLabel from "soft_dashboard/assets/theme/components/stepper/stepLabel"
import stepIcon from "soft_dashboard/assets/theme/components/stepper/stepIcon"
import select from "soft_dashboard/assets/theme/components/form/select"
import formControlLabel from "soft_dashboard/assets/theme/components/form/formControlLabel"
import formLabel from "soft_dashboard/assets/theme/components/form/formLabel"
import checkbox from "soft_dashboard/assets/theme/components/form/checkbox"
import radio from "soft_dashboard/assets/theme/components/form/radio"
import autocomplete from "soft_dashboard/assets/theme/components/form/autocomplete"
import input from "soft_dashboard/assets/theme/components/form/input"
import container from "soft_dashboard/assets/theme/components/container"
import popover from "soft_dashboard/assets/theme/components/popover"
import buttonBase from "soft_dashboard/assets/theme/components/buttonBase"
import icon from "soft_dashboard/assets/theme/components/icon"
import svgIcon from "soft_dashboard/assets/theme/components/svgIcon"
import link from "soft_dashboard/assets/theme/components/link"

export default createTheme({
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...container,
      },
    },
    MuiDrawer: { ...sidenav },
    MuiList: { ...list },
    MuiListItem: { ...listItem },
    MuiListItemText: { ...listItemText },
    MuiCard: { ...card },
    MuiCardMedia: { ...cardMedia },
    MuiCardContent: { ...cardContent },
    MuiButton: { ...button },
    MuiIconButton: { ...iconButton },
    MuiInputBase: { ...inputBase },
    MuiMenu: { ...menu },
    MuiMenuItem: { ...menuItem },
    MuiSwitch: { ...switchButton },
    MuiDivider: { ...divider },
    MuiTableContainer: { ...tableContainer },
    MuiTableHead: { ...tableHead },
    MuiTableCell: { ...tableCell },
    MuiLinearProgress: { ...linearProgress },
    MuiBreadcrumbs: { ...breadcrumbs },
    MuiSlider: { ...slider },
    MuiAvatar: { ...avatar },
    MuiTooltip: { ...tooltip },
    MuiAppBar: { ...appBar },
    MuiTabs: { ...tabs },
    MuiTab: { ...tab },
    MuiStepper: { ...stepper },
    MuiStep: { ...step },
    MuiStepConnector: { ...stepConnector },
    MuiStepLabel: { ...stepLabel },
    MuiStepIcon: { ...stepIcon },
    MuiSelect: { ...select },
    MuiFormControlLabel: { ...formControlLabel },
    MuiFormLabel: { ...formLabel },
    MuiCheckbox: { ...checkbox },
    MuiRadio: { ...radio },
    MuiAutocomplete: { ...autocomplete },
    MuiInput: { ...input },
    MuiOutlinedInput: { ...input },
    MuiFilledInput: { ...input },
    MuiPopover: { ...popover },
    MuiButtonBase: { ...buttonBase },
    MuiIcon: { ...icon },
    MuiSvgIcon: { ...svgIcon },
    MuiLink: { ...link },
  },
})
