import Typography from "@mui/material/Typography"
import React from "react"
import { Box } from "@mui/system"
import InstagramIcon from "@mui/icons-material/Instagram"
import IconButton from "@mui/material/IconButton"
import { Link } from "react-router-dom"
import { useMediaQuery, useTheme } from "@mui/material"

const Footer = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src="https://marlinsurf-assets.s3.us-west-1.amazonaws.com/marlin_logo_1200x627.png"
        alt="status icon"
        style={{
          height: isMobile ? "150px" : "200px",
        }}
      />
      <Box
        component="footer"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f5f5f5",
          // mt: theme.spacing(2),
          fontFamily: "Josefin Sans",
          // padding: isMobile ? theme.spacing(2, 1) : theme.spacing(2, 3),
          paddingTop: isMobile ? "20px" : "20px",
          paddingBottom: isMobile ? "0px" : "20px",
          gap: isMobile ? theme.spacing(2) : theme.spacing(0),
          textAlign: isMobile ? "center" : "inherit",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          style={{
            fontFamily: "Josefin Sans",
            // marginRight: isMobile ? "0px" : "10px",
          }}
        >
          Copyright ©{" "}
          <Link
            color="inherit"
            to="/"
            style={{
              fontFamily: "Josefin Sans",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            Marlin Surfboards
          </Link>{" "}
          {new Date().getFullYear()}
        </Typography>
        <Box pl={isMobile ? 0 : 2} pr={isMobile ? 0 : 2}>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            style={{
              fontFamily: "Josefin Sans",
            }}
          >
            <Link
              to="/privacy"
              style={{
                fontFamily: "Josefin Sans",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              Privacy Policy
            </Link>
          </Typography>
        </Box>

        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          style={{
            fontFamily: "Josefin Sans",
            marginRight: isMobile ? "0px" : "10px",
          }}
        >
          <Link
            to="/cookies"
            style={{
              fontFamily: "Josefin Sans",
              marginRight: isMobile ? "0px" : "10px",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            Cookie Policy
          </Link>
        </Typography>

        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          style={{
            fontFamily: "Josefin Sans",
            marginRight: isMobile ? "0px" : "10px",
          }}
        >
          <Link
            to="/contact"
            style={{
              fontFamily: "Josefin Sans",
              marginRight: isMobile ? "0px" : "10px",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            Contact
          </Link>
        </Typography>

        <Box>
          <IconButton
            component={Link}
            to="https://www.instagram.com/marlin.surf/"
          >
            <InstagramIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
