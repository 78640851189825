import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import SoftBox from "../../../../soft_dashboard/components/SoftBox"
import SoftTypography from "../../../../soft_dashboard/components/SoftTypography"
import SkeletonCard from "../../../core/SkeletonCard/SkeletonCard"
import { DropzoneDialog } from "react-mui-dropzone"
import DescriptionItem from "../../../core/DescriptionItem/DescriptionItem"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Axios from "../../../../Util/Axios"
import Toast, {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../../../core/Toast/Toast"
import { useNavigate } from "react-router-dom"
import ImageDrag from "../../../core/ImageDrag/ImageDrag"
import ProductDraggedImage from "./ProductDraggedImage"
import { useDispatch } from "react-redux"
import { updateToastData } from "../../../../slices/toastSlice"
import { uploadFileToAws } from "../../../../Util/Aws"

const ProductImagesJsx = ({ productData, setProductData }) => {
  const [dropzoneModalActive, setDropzoneModalActive] = useState(false)

  const [positionUpdated, setPositionUpdated] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [productImages, setProductImages] = useState([])

  useEffect(() => {
    if (productData) {
      setProductImages([...productData.product_images])
    }
  }, [productData])

  if (!productData) {
    return <SkeletonCard />
  }

  return (
    <Grid item>
      <Card>
        <SoftBox pt={2} px={2}>
          <DescriptionItem
            keyJsx={
              <SoftTypography variant="h6" fontWeight="medium">
                Product Images
              </SoftTypography>
            }
            valueJsx={
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <SoftBox display="flex" alignItems="center">
                  <Button
                    variant="contained"
                    color={"secondary"}
                    sx={{ mt: 1, width: "100%" }}
                    disabled={dropzoneModalActive || !positionUpdated}
                    onClick={() => {
                      let productImagesData = {}

                      for (let i = 0; i < productImages.length; i++) {
                        let draggedProductImage = productImages[i]
                        productImagesData[draggedProductImage.id] = i + 1
                      }

                      Axios.post(
                        `/api/staff-product-list/${productData.id}/action_save_product_image_positions/`,
                        {
                          product_images_data: productImagesData,
                        }
                      )
                        .then((x) => {
                          setPositionUpdated(false)
                          dispatch(
                            updateToastData({
                              message: "Position Saved.",
                              severity: TOAST_SEVERITY_SUCCESS,
                            })
                          )
                        })
                        .catch((e) => {
                          dispatch(
                            updateToastData({
                              message: e,
                              severity: TOAST_SEVERITY_ERROR,
                            })
                          )
                        })
                    }}
                  >
                    <Typography variant="caption" style={{ color: "white" }}>
                      Save Position
                    </Typography>
                  </Button>
                </SoftBox>
                <SoftBox display="flex" alignItems="center" ml={2}>
                  <Button
                    variant="contained"
                    color={"primary"}
                    sx={{ mt: 1, width: "100%" }}
                    disabled={dropzoneModalActive}
                    onClick={() => {
                      setDropzoneModalActive(true)
                    }}
                  >
                    <Typography variant="caption" style={{ color: "white" }}>
                      Upload Images
                    </Typography>
                  </Button>
                </SoftBox>
              </SoftBox>
            }
          />
        </SoftBox>
        <SoftBox pt={1} pb={2} px={2}>
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            <DropzoneDialog
              open={dropzoneModalActive}
              acceptedFiles={[
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/bmp",
              ]}
              onSave={(files) => {
                let promises = []
                for (let file of files) {
                  promises.push(uploadFileToAws(file))
                }

                Axios.all(promises)
                  .then((resps) => {
                    let url = `/api/staff-product-detail/${productData.id}/action_add_product_images/`
                    Axios.post(
                      url,
                      {
                        urls: resps,
                      },
                      navigate
                    )
                      .then(({ data }) => {
                        setDropzoneModalActive(false)
                        setProductData({ ...data })
                        dispatch(
                          updateToastData({
                            message: "Successfully Uploaded Files",
                            severity: TOAST_SEVERITY_SUCCESS,
                          })
                        )
                      })
                      .catch((e) => {
                        dispatch(
                          updateToastData({
                            message: e,
                            severity: TOAST_SEVERITY_ERROR,
                          })
                        )
                      })
                  })
                  .catch((e) => {
                    dispatch(
                      updateToastData({
                        message: e,
                        severity: TOAST_SEVERITY_ERROR,
                      })
                    )
                  })
              }}
              showPreviews={true}
              maxFileSize={50000000}
              onClose={() => {
                setDropzoneModalActive(false)
              }}
            />
          </SoftBox>
        </SoftBox>
        <ImageDrag
          draggedImages={productImages}
          setDraggedImages={setProductImages}
          setPositionUpdated={setPositionUpdated}
          imageComponent={(productImage) => {
            return (
              <ProductDraggedImage
                productImage={productImage}
                setProductData={setProductData}
                productData={productData}
              />
            )
          }}
        />
      </Card>
    </Grid>
  )
}

export default ProductImagesJsx
