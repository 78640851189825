import React, { useEffect, useState } from "react"
import { Alert, AlertTitle, Box } from "@mui/material"

const CustomOrderBanner = ({ orderData }) => {
  return (
    <React.Fragment>
      {orderData?.locked && orderData?.status === "awaiting_confirmation" && (
        <Box pb={3}>
          <Alert severity="info">
            <AlertTitle
              style={{
                fontFamily: "Josefin Sans",
              }}
            >
              Under Review
            </AlertTitle>
            Your order is currently being reviewed. You will get an email when
            it has been approved. At that point you can pay the deposit and I'll
            get started on your new board.
          </Alert>
        </Box>
      )}
      {(orderData?.status === "draft" || !orderData?.status) && (
        <Box pb={3}>
          <Alert severity="info">
            <AlertTitle
              style={{
                fontFamily: "Josefin Sans",
              }}
            >
              No Delivery
            </AlertTitle>
            Custom orders must be picked up in Santa Barbara, California.
          </Alert>
        </Box>
      )}
      {orderData?.locked && orderData?.status === "confirmed" && (
        <Box pb={3}>
          <Alert severity="info">
            <AlertTitle
              style={{
                fontFamily: "Josefin Sans",
              }}
            >
              Confirmed
            </AlertTitle>
            Your order has been confirmed. Please pay the 50% deposit and I'll
            get started on it.
          </Alert>
        </Box>
      )}
      {orderData?.locked &&
        orderData?.status === "deposit_received_and_in_progress" && (
          <Box pb={3}>
            <Alert severity="info">
              <AlertTitle
                style={{
                  fontFamily: "Josefin Sans",
                }}
              >
                In Progress
              </AlertTitle>
              I've received your deposit and I'm working on your board. I'll
              send you an email when I'm done with it.
            </Alert>
          </Box>
        )}
      {orderData?.locked && orderData?.status === "ready_for_pickup" && (
        <Box pb={3}>
          <Alert severity="success">
            <AlertTitle
              style={{
                fontFamily: "Josefin Sans",
              }}
            >
              Ready for pickup
            </AlertTitle>
            Your board is ready for pick up.
            {orderData?.balance > 0
              ? " Please pay the rest of the balance and I'll get it ready for you."
              : " Thanks for paying your balance."}
          </Alert>
        </Box>
      )}
    </React.Fragment>
  )
}

export default CustomOrderBanner
