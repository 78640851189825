import React from "react"
import { Box } from "@mui/system"

const DescriptionItem = ({ keyJsx, valueJsx, spacing = "normal" }) => {
  let py = 0
  if (spacing === "normal") {
    py = 1
  }
  if (spacing === "tight") {
    py = 0.5
  }

  return (
    <Box
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={py}
      pr={1}
      mb={0}
    >
      <Box lineHeight={1}>{keyJsx}</Box>
      <Box display="flex" alignItems="center">
        {valueJsx}
      </Box>
    </Box>
  )
}

export default DescriptionItem
