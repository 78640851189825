import * as React from "react"
const LongboardPinSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)

  let originalWidth = 305
  let originalHeight = 922

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path
          d="M154.646,18.213c0,0,45.336-1.081,63.449,37.94c18.004,39.12,38.611,141.835,38.611,366.333
		c0,166.411-1.193,219.388-16.16,315.027c-20.934,133.578-87.961,165.987-87.961,165.987"
        />
        <path
          d="M155.296,18.213c0,0-49.241-1.081-67.895,37.94C68.746,95.175,47.705,198.087,47.705,422.585
		c0,166.408,1.192,219.387,16.703,315.025c21.69,133.578,88.177,165.89,88.177,165.89"
        />
      </g>
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1="215.165" y1="51.435" x2="215.165" y2="826.712" />

          <line x1="89.895" y1="51.435" x2="89.895" y2="826.712" />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="StringerDoubleLeft"
            x1="142.606"
            y1="19"
            x2="142.606"
            y2="896.971"
          />

          <line
            id="StringerDoubleRight_1_"
            x1="160.503"
            y1="19"
            x2="160.503"
            y2="896.971"
          />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="StringerSingle"
            x1="152.042"
            y1="19"
            x2="152.042"
            y2="901.461"
          />
        </g>
      )}

      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            d="M151.5,660.352l-55.64-36.463l-47.396-28.998
                c0,0-2.17-190.291-0.977-221.942c1.192-31.651,1.302-37.449,1.302-37.449l56.94-44.526l46.313-34.697l46.639,38.627l56.614,40.596
                c0,0,0.108,5.798,1.302,37.449c1.192,31.648-0.976,221.942-0.976,221.942l-48.267,28.998l-55.313,36.66"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path
            d="M49.222,325.669c0,0,33.623-40.497,102.604-41.184
                c68.979-0.688,103.036,38.039,103.036,38.039l1.52,140.261l-2.387,133.088c0,0-47.396,41.184-101.519,43.149
                c-54.013,1.967-101.627-38.629-101.627-38.629S45.86,349.85,49.222,325.669z"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            d="M151.5,256.079c0,0,0.326,17.987-28.308,50.523
                C94.557,339.136,48.789,335.4,48.789,335.4s-0.108,5.799-1.302,37.449c-1.193,31.651,0.977,221.945,0.977,221.945
                s15.184-2.162,52.386,10.811c37.201,12.976,51.192,54.847,51.192,54.847l0,0c0,0,13.992-41.871,51.193-54.847
                s52.387-10.812,52.387-10.812s2.168-190.292,0.975-221.945c-1.19-31.649-1.301-37.449-1.301-37.449s-45.879,3.735-74.404-28.799
                c-28.522-32.535-28.85-50.424-28.85-50.424"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M152.585,903.5c0,0,47.166-29.302,71.584-96.201
                c14.582-49.932,22.559-121.784,22.559-121.784l-95.01-54.06l-95.986,56.913C55.731,688.366,71.751,866.042,152.585,903.5"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M152.585,903.5c0,0-49.167-28.396-76.381-113.681
                c-13.666-41.087-23.077-140.476-23.077-140.476l98.915,64.873l99.132-64.772c0,0-12.174,111.407-24.756,149.839
                C198.415,868.937,171.515,889.408,152.585,903.5"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path
            d="M152.585,903.5c0,0-39.167-23.562-60.496-74.058
                c-25.005-40.431-34.516-143.335-34.516-143.335s11.714,0,46.313-9.832c34.6-9.83,48.156-47.866,48.156-47.866
                s12.039,38.035,46.639,47.865c34.6,9.833,47.938,9.144,47.938,9.144s-10.201,102.083-33.486,144.873
                C196.349,866.344,163.538,901.239,152.585,903.5"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M152.585,903.5c0,0-46.288-33.777-60.496-68.573
                c-18.671-37.155-33.757-125.231-34.624-138.107c-0.868-12.877-4.556-33.324-4.556-33.324s56.074,8.75,73.103,25.95
                c17.138,17.201,26.464,39.611,26.03,51.506c0,0,8.895-41.285,33.947-56.422c25.055-15.138,62.475-22.608,63.016-20.149
                c0.543,2.458-9.762,88.757-16.377,114.607c-6.725,25.949-27.217,68.644-38.279,83.881C183.286,878.204,152.585,903.5,152.585,903.5
                "
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M152.585,903.5c0,0-43.063-29.302-62.69-76.642
                c-19.739-40.567-32.646-126.01-34.164-139.572c0,0,40.89-24.378,95.118-23.789c54.23,0.593,97.289,19.366,97.289,19.366
                s-13.229,109.397-34.719,149.648C190.763,880.844,152.585,903.5,152.585,903.5"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            d="M157.249,849.045c0,1.894-1.52,3.484-3.254,3.484h-4.99
                c-1.844,0-3.254-1.591-3.254-3.484v-93.774c0-1.892,1.519-3.483,3.254-3.483h4.99c1.844,0,3.254,1.592,3.254,3.483V849.045z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M157.249,849.045c0,1.894-1.52,3.484-3.254,3.484h-4.99
                c-1.844,0-3.254-1.591-3.254-3.484v-93.774c0-1.892,1.519-3.483,3.254-3.483h4.99c1.844,0,3.254,1.592,3.254,3.483V849.045z"
          />
          <path
            d="M87.292,752.883c-0.217,1.891-1.844,3.282-3.688,2.984
                l-4.557-0.597c-1.844-0.198-3.036-1.99-2.818-3.882L81,712.364c0.218-1.892,1.845-3.284,3.688-2.987l4.555,0.598
                c1.844,0.199,3.037,1.991,2.82,3.881L87.292,752.883z"
          />
          <path
            d="M227.747,751.189c0.326,1.893-0.977,3.684-2.711,3.981
                l-4.557,0.699c-1.845,0.297-3.472-0.997-3.797-2.889l-5.64-38.824c-0.326-1.892,0.976-3.683,2.711-3.981l4.556-0.696
                c1.735-0.3,3.473,0.995,3.797,2.887L227.747,751.189z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            d="M87.292,753.081c-0.217,1.892-1.844,3.284-3.688,2.986
                l-4.557-0.598c-1.844-0.199-3.036-1.991-2.818-3.882L81,712.563c0.218-1.892,1.845-3.284,3.688-2.987l4.555,0.598
                c1.844,0.199,3.037,1.992,2.82,3.882L87.292,753.081z"
          />
          <path
            d="M227.747,751.388c0.326,1.893-0.977,3.685-2.711,3.982
                l-4.557,0.697c-1.845,0.299-3.472-0.995-3.797-2.887l-5.64-38.825c-0.326-1.892,0.976-3.683,2.711-3.981l4.556-0.697
                c1.735-0.299,3.473,0.996,3.797,2.888L227.747,751.388z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M87.292,752.883c-0.217,1.891-1.844,3.282-3.688,2.984
                l-4.557-0.597c-1.844-0.198-3.036-1.99-2.818-3.882L81,712.364c0.218-1.892,1.845-3.284,3.688-2.987l4.555,0.598
                c1.844,0.199,3.037,1.991,2.82,3.881L87.292,752.883z"
          />
          <path
            d="M227.747,751.189c0.326,1.893-0.977,3.684-2.711,3.981
                l-4.557,0.699c-1.845,0.297-3.472-0.997-3.797-2.889l-5.64-38.824c-0.326-1.892,0.976-3.683,2.711-3.981l4.556-0.696
                c1.735-0.3,3.473,0.995,3.797,2.887L227.747,751.189z"
          />
          <path
            d="M157.032,856.909c0,1.892-1.52,3.484-3.254,3.484h-4.666
                c-1.845,0-3.254-1.593-3.254-3.484v-39.42c0-1.892,1.519-3.485,3.254-3.485h4.666c1.844,0,3.254,1.592,3.254,3.485V856.909z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M87.292,752.883c-0.217,1.891-1.844,3.282-3.688,2.984
                l-4.557-0.597c-1.844-0.198-3.036-1.99-2.818-3.882L81,712.364c0.218-1.892,1.845-3.284,3.688-2.987l4.555,0.598
                c1.844,0.199,3.037,1.991,2.82,3.881L87.292,752.883z"
          />
          <path
            d="M116.576,809.624c0,1.894-1.52,3.484-3.254,3.484h-4.663
                c-1.846,0-3.254-1.591-3.254-3.484v-39.321c0-1.893,1.518-3.484,3.254-3.484h4.663c1.844,0,3.254,1.592,3.254,3.484V809.624z"
          />
          <path
            d="M227.747,751.189c0.326,1.893-0.977,3.684-2.711,3.981
                l-4.557,0.699c-1.845,0.297-3.472-0.997-3.797-2.889l-5.64-38.824c-0.326-1.892,0.976-3.683,2.711-3.981l4.556-0.696
                c1.735-0.3,3.473,0.995,3.797,2.887L227.747,751.189z"
          />
          <path
            d="M201.935,808.629c0,1.893-1.52,3.483-3.254,3.483h-4.664
                c-1.844,0-3.254-1.591-3.254-3.483v-39.322c0-1.892,1.521-3.483,3.254-3.483h4.664c1.844,0,3.254,1.592,3.254,3.483V808.629z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M157.249,849.045c0,1.894-1.52,3.484-3.254,3.484h-4.99
                c-1.844,0-3.254-1.591-3.254-3.484v-93.774c0-1.892,1.519-3.483,3.254-3.483h4.99c1.844,0,3.254,1.592,3.254,3.483V849.045z"
          />
          <path
            d="M87.292,752.883c-0.217,1.891-1.844,3.282-3.688,2.984
                l-4.557-0.597c-1.844-0.198-3.036-1.99-2.818-3.882L81,712.364c0.218-1.892,1.845-3.284,3.688-2.987l4.555,0.598
                c1.844,0.199,3.037,1.991,2.82,3.881L87.292,752.883z"
          />
          <path
            d="M116.576,809.624c0,1.894-1.52,3.484-3.254,3.484h-4.663
                c-1.846,0-3.254-1.591-3.254-3.484v-39.321c0-1.893,1.518-3.484,3.254-3.484h4.663c1.844,0,3.254,1.592,3.254,3.484V809.624z"
          />
          <path
            d="M227.747,751.189c0.326,1.893-0.977,3.684-2.711,3.981
                l-4.557,0.699c-1.845,0.297-3.472-0.997-3.797-2.889l-5.64-38.824c-0.326-1.892,0.976-3.683,2.711-3.981l4.556-0.696
                c1.735-0.3,3.473,0.995,3.797,2.887L227.747,751.189z"
          />
          <path
            d="M201.935,808.629c0,1.893-1.52,3.483-3.254,3.483h-4.664
                c-1.844,0-3.254-1.591-3.254-3.483v-39.322c0-1.892,1.521-3.483,3.254-3.483h4.664c1.844,0,3.254,1.592,3.254,3.483V808.629z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M87.292,752.883c-0.217,1.891-1.844,3.282-3.688,2.984
                l-4.557-0.597c-1.844-0.198-3.036-1.99-2.818-3.882L81,712.364c0.218-1.892,1.845-3.284,3.688-2.987l4.555,0.598
                c1.844,0.199,3.037,1.991,2.82,3.881L87.292,752.883z"
          />
          <path
            d="M116.576,809.624c0,1.894-1.52,3.484-3.254,3.484h-4.663
                c-1.846,0-3.254-1.591-3.254-3.484v-39.321c0-1.893,1.518-3.484,3.254-3.484h4.663c1.844,0,3.254,1.592,3.254,3.484V809.624z"
          />
          <path
            d="M227.747,751.189c0.326,1.893-0.977,3.684-2.711,3.981
                l-4.557,0.699c-1.845,0.297-3.472-0.997-3.797-2.889l-5.64-38.824c-0.326-1.892,0.976-3.683,2.711-3.981l4.556-0.696
                c1.735-0.3,3.473,0.995,3.797,2.887L227.747,751.189z"
          />
          <path
            d="M201.935,808.629c0,1.893-1.52,3.483-3.254,3.483h-4.664
                c-1.844,0-3.254-1.591-3.254-3.483v-39.322c0-1.892,1.521-3.483,3.254-3.483h4.664c1.844,0,3.254,1.592,3.254,3.483V808.629z"
          />
          <path
            d="M157.032,856.909c0,1.892-1.52,3.484-3.254,3.484h-4.666
                c-1.845,0-3.254-1.593-3.254-3.484v-39.42c0-1.892,1.519-3.485,3.254-3.485h4.666c1.844,0,3.254,1.592,3.254,3.485V856.909z"
          />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          x="116.792"
          y="552.591"
          width="71.259"
          height="79.142"
          href={logoUrl}
        />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path
          d="M151.717,27.06c0,0,41.54-1.082,57.917,37.154
		c16.486,38.333,35.25,138.984,35.25,358.863c0,162.968-1.084,214.865-14.75,308.537c-19.09,130.828-82.049,160.709-82.049,160.709"
        />
        <path
          d="M152.476,27.06c0,0-42.407-1.082-58.46,37.154
		c-16.052,38.333-34.057,138.984-34.057,358.863c0,162.968,0.977,214.865,14.317,308.537
		c18.546,130.828,84.808,163.731,84.808,163.731"
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path
            d="M152.476,36.888c0,0-35.031-0.982-49.24,36.369
                c-14.316,37.351-33.08,135.643-33.08,350.409c0,159.233,3.903,210.74,15.727,302.247c19.523,128.781,66.161,152.256,66.161,152.256
                s49.891-31.458,67.245-154.415c12.146-91.512,15.619-140.853,15.619-300.086c0-214.767-18.33-312.371-32.974-349.722
                c-14.752-37.351-50.107-37.056-50.107-37.056"
          />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path
          d="M152.585,903.5c0,0-66.595-32.409-88.287-165.988
                c-15.51-95.638-16.703-148.617-16.703-315.027c0-224.498,20.934-327.311,39.588-366.333c18.765-39.021,64.859-38.038,64.859-38.038
                s47.83-0.983,65.835,38.137c18.004,39.12,38.502,141.834,38.502,366.332c0,166.408-1.19,219.386-16.051,315.026
                C219.397,871.188,152.585,903.5,152.585,903.5z"
        />
      </g>
    </svg>
  )
}
export default LongboardPinSvg
