import React from "react"
import SkeletonCard from "../../core/SkeletonCard/SkeletonCard"
import Card from "@mui/material/Card"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"
import DescriptionItem from "../../core/DescriptionItem/DescriptionItem"
import { getCurrencyStringFromNumber } from "../../../Util/Util"
import Grid from "@mui/material/Grid"
const ProductOptions = ({ productData }) => {
  if (!productData) {
    return <SkeletonCard />
  }
  const options = productData?.options || []

  if (options.length === 0) {
    return <React.Fragment />
  }

  return (
    <Grid item>
      <Card>
        <SoftBox pt={3} px={2}>
          <SoftTypography variant="h6" fontWeight="medium">
            Product Options
          </SoftTypography>
        </SoftBox>
        <SoftBox pt={1} pb={2} px={2}>
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            {options.map((option, i) => {
              return (
                <SoftBox
                  key={i}
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor="grey-100"
                  borderRadius="lg"
                  p={3}
                  mb={0}
                  mt={2}
                >
                  <SoftBox width="100%" display="flex" flexDirection="column">
                    <DescriptionItem
                      spacing={"tight"}
                      keyJsx={
                        <SoftTypography
                          variant="button"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          {option.type_display}: {option.value}
                        </SoftTypography>
                      }
                      valueJsx={
                        <SoftTypography
                          variant="button"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          {getCurrencyStringFromNumber(option.price)}
                        </SoftTypography>
                      }
                    />
                  </SoftBox>
                </SoftBox>
              )
            })}
          </SoftBox>
        </SoftBox>
      </Card>
    </Grid>
  )
}

export default ProductOptions
