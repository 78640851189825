import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import IconButton from "@mui/material/IconButton"
import Axios from "../../../Util/Axios"
import Toast, {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../../core/Toast/Toast"
import CloseIcon from "@mui/icons-material/Close"
import { Button, TextField } from "@mui/material"
import Typography from "@mui/material/Typography"
import { useDispatch } from "react-redux"
import { updateToastData } from "../../../slices/toastSlice"

const GalleryGalleryImage = ({
  galleryImage,
  galleryImages,
  setGalleryImages,
}) => {
  const [loading, setLoading] = useState(false)
  const [imageText, setImageText] = useState(galleryImage.text)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const dataUrl = `/api/staff-gallery-image/${galleryImage.id}/`

  return (
    <SoftBox p={2} position="relative">
      <img
        height={100}
        src={galleryImage.url}
        className="App-logo"
        alt="logo"
      />
      <IconButton
        style={{
          position: "absolute",
          right: -4,
          top: -4,
          color: loading ? "grey" : "red",
        }}
        disabled={loading}
        onMouseDown={(event) => {
          if (loading) {
            return
          }

          setLoading(true)
          Axios.delete(dataUrl, navigate)
            .then((x) => {
              dispatch(
                updateToastData({
                  message: "Image deleted.",
                  severity: TOAST_SEVERITY_SUCCESS,
                })
              )
              let updatedGalleryImages = galleryImages.filter((x) => {
                return x.id !== galleryImage.id
              })
              setGalleryImages(updatedGalleryImages)
              setLoading(false)
            })
            .catch((e) => {
              dispatch(
                updateToastData({
                  message: "Image deleted.",
                  severity: TOAST_SEVERITY_ERROR,
                })
              )
              setLoading(false)
            })
        }}
      >
        <CloseIcon />
      </IconButton>
      <SoftBox>
        <TextField
          variant="outlined"
          value={imageText}
          onChange={(e) => setImageText(e.target.value)}
          fullWidth
        />
        <Button
          variant="contained"
          color={"primary"}
          sx={{ mt: 1, width: "100%" }}
          disabled={loading}
          onClick={() => {
            Axios.patch(dataUrl, { text: imageText }, navigate)
              .then((x) => {
                dispatch(
                  updateToastData({
                    message: "Text Updated.",
                    severity: TOAST_SEVERITY_SUCCESS,
                  })
                )
              })
              .catch((e) => {
                dispatch(
                  updateToastData({
                    message: "Text could not be updated.",
                    severity: TOAST_SEVERITY_ERROR,
                  })
                )
              })
          }}
        >
          <Typography variant="caption" style={{ color: "white" }}>
            save
          </Typography>
        </Button>
      </SoftBox>
    </SoftBox>
  )
}

export default GalleryGalleryImage
