import * as React from "react"
import HybridSwallowSvgOver9 from "./HybridSwallowSvgOver9"
import LongboardSquareTailSvg from "./LongboardSquareTailSvg"
import FishSvg from "./FishSvg"
import { Skeleton } from "@mui/material"
import LongBoardDiamondTailSvg from "./LongBoardDiamondSvg"
import LongboardCrescentSvg from "./LongboardCrescentSvg"
import LongboardPinSvg from "./LongboardPinSvg"
import HybridDiamondSvg from "./HybridDiamondSvg"
import HybridCrescentSvg from "./HybridCrescentSvg"
import HybridRoundSvg from "./HybridRoundSvg"
import HybridSquashSvg from "./HybridSquashSvg"
import HybridSquareSvg from "./HybridSquareSvg"
import MegaFishSvg from "./MegaFishSvg"
import PerformanceCrescentSvg from "./PerformanceCrescentSvg"
import PerformanceDiamondSvg from "./PerformanceDiamondSvg"
import PerformancePinSvg from "./PerformancePinSvg"
import PerformanceRoundSvg from "./PerformanceRoundSvg"
import PerformanceSquareSvg from "./PerformanceSquareSvg"
import PerformanceSquashSvg from "./PerformanceSquashSvg"
import PerformanceSwallowSvg from "./PerformanceSwallowSvg"
import LongboardRoundSvg from "./LongboardRoundSvg"
import HybridPinSvg from "./HybridPinSvg"
import { useEffect, useState } from "react"

const SurfboardSvg = (props) => {
  const {
    selectedLengthOption,
    selectedWidthOption,
    selectedNoseShapeOption,
    selectedTailShapeOption,
    deck,
    selectedDeckColorOption,
    selectedStringerTypeOption,
    selectedBottomColorOption,
    selectedLogoOption,
    style,
  } = props

  const extendedProps = { ...props }
  const lengthInches = selectedLengthOption?.extra_data?.total_inches
  const widthInches = selectedWidthOption?.extra_data?.total_inches
  const noseShapeValue = selectedNoseShapeOption?.value
  const tailShapeValue = selectedTailShapeOption?.value
  const [logoUrl, setLogoUrl] = useState(selectedLogoOption?.extra_data?.url)

  function imageToBase64(url, callback) {
    fetch(url)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Failed to fetch image")
        }
        return response.blob()
      })
      .then((blob) => {
        const reader = new FileReader()
        reader.onloadend = function () {
          const base64data = reader.result
          callback(base64data)
        }
        reader.readAsDataURL(blob)
      })
      .catch((error) => {
        console.error("Error converting image to Base64:", error)
      })
  }

  useEffect(() => {
    if (selectedLogoOption) {
      setLogoUrl(selectedLogoOption?.extra_data?.url)

      // imageToBase64(selectedLogoOption?.extra_data?.url, (base64) => {
      //   console.log(base64) // This will give you the Base64 encoded string
      //   setLogoUrl(base64)
      // })
    }
  }, [selectedLogoOption])

  const getHeightScaleFactor = (originalHeightInches) => {
    return lengthInches / originalHeightInches
  }
  const getWidthScaleFactor = (originalWidthInches) => {
    return widthInches / originalWidthInches
  }

  let bottomColor = selectedBottomColorOption?.value || "#ffffff"
  let deckColor = selectedDeckColorOption?.value || "#ffffff"

  let backgroundColor = bottomColor
  let overlapOpacity
  let secondaryColor = deckColor
  let outlineOpacity = 1

  if (deck) {
    backgroundColor = deckColor
    secondaryColor = bottomColor
    overlapOpacity = 0.4
  } else {
    overlapOpacity = 0.4
  }

  if (bottomColor === "#ffffff" && deckColor === "#ffffff") {
    outlineOpacity = 1
  } else {
    outlineOpacity = 0
  }

  if (deckColor === "#ffffff") {
    if (!deck) {
      overlapOpacity = 0
    } else {
      overlapOpacity = 0.7
    }
  }

  let deckPatchOpacity = 0.4
  let deckPatchColor = deckColor
  let tailPatchColor = bottomColor
  if (deckColor === "#ffffff") {
    deckPatchColor = "#d4f9fa"
    deckPatchOpacity = 0.4
    if (bottomColor === "#ffffff") {
      tailPatchColor = "#d4f9fa"
    }
  }
  let tailPatchOpacity = 0.4

  let backgroundOpacity = 0.6

  let stringerColor =
    selectedStringerTypeOption?.extra_data?.hex_color || "#fff9c7"

  extendedProps.getHeightScaleFactor = getHeightScaleFactor
  extendedProps.getWidthScaleFactor = getWidthScaleFactor
  extendedProps.logoUrl = logoUrl
  extendedProps.deckPatchColor = deckPatchColor
  extendedProps.tailPatchColor = tailPatchColor
  extendedProps.bottomColor = bottomColor
  extendedProps.deckPatchOpacity = deckPatchOpacity
  extendedProps.overlapOpacity = overlapOpacity
  extendedProps.backgroundOpacity = backgroundOpacity
  extendedProps.tailPatchOpacity = tailPatchOpacity
  extendedProps.backgroundColor = backgroundColor
  extendedProps.secondaryColor = secondaryColor
  extendedProps.stringerColor = stringerColor
  extendedProps.outlineOpacity = outlineOpacity
  extendedProps.style = style

  if (lengthInches >= 58 && lengthInches <= 132) {
    if (noseShapeValue === "Round" && tailShapeValue === "Diamond") {
      return (
        <LongBoardDiamondTailSvg
          extendedProps={extendedProps}
          originalHeightInches={110}
          originalWidthInches={23}
        />
      )
    } else if (noseShapeValue === "Round" && tailShapeValue === "Crescent") {
      return (
        <LongboardCrescentSvg
          extendedProps={extendedProps}
          originalHeightInches={110}
          originalWidthInches={23}
        />
      )
    } else if (noseShapeValue === "Round" && tailShapeValue === "Pin") {
      return (
        <LongboardPinSvg
          extendedProps={extendedProps}
          originalHeightInches={110}
          originalWidthInches={23}
        />
      )
    } else if (noseShapeValue === "Round" && tailShapeValue === "Square") {
      return (
        <LongboardSquareTailSvg
          extendedProps={extendedProps}
          originalHeightInches={110}
          originalWidthInches={23}
        />
      )
    } else if (noseShapeValue === "Round" && tailShapeValue === "Round") {
      return (
        <LongboardRoundSvg
          extendedProps={extendedProps}
          originalHeightInches={110}
          originalWidthInches={23}
        />
      )
    }
  }

  if (lengthInches >= 66 && lengthInches <= 96) {
    if (noseShapeValue === "Performance" && tailShapeValue === "Crescent") {
      return (
        <PerformanceCrescentSvg
          extendedProps={extendedProps}
          originalHeightInches={74}
          originalWidthInches={20}
        />
      )
    } else if (
      noseShapeValue === "Performance" &&
      tailShapeValue === "Diamond"
    ) {
      return (
        <PerformanceDiamondSvg
          extendedProps={extendedProps}
          originalHeightInches={74}
          originalWidthInches={20}
        />
      )
    } else if (noseShapeValue === "Performance" && tailShapeValue === "Pin") {
      return (
        <PerformancePinSvg
          extendedProps={extendedProps}
          originalHeightInches={74}
          originalWidthInches={20}
        />
      )
    } else if (noseShapeValue === "Performance" && tailShapeValue === "Round") {
      return (
        <PerformanceRoundSvg
          extendedProps={extendedProps}
          originalHeightInches={74}
          originalWidthInches={20}
        />
      )
    } else if (
      noseShapeValue === "Performance" &&
      tailShapeValue === "Square"
    ) {
      return (
        <PerformanceSquareSvg
          extendedProps={extendedProps}
          originalHeightInches={74}
          originalWidthInches={20}
        />
      )
    } else if (
      noseShapeValue === "Performance" &&
      tailShapeValue === "Squash"
    ) {
      return (
        <PerformanceSquashSvg
          extendedProps={extendedProps}
          originalHeightInches={74}
          originalWidthInches={20}
        />
      )
    } else if (
      noseShapeValue === "Performance" &&
      tailShapeValue === "Swallow"
    ) {
      return (
        <PerformanceSwallowSvg
          extendedProps={extendedProps}
          originalHeightInches={74}
          originalWidthInches={20}
        />
      )
    }
  }

  if (lengthInches < 84) {
    if (noseShapeValue === "Hybrid" && tailShapeValue === "Swallow") {
      return (
        <FishSvg
          extendedProps={extendedProps}
          originalHeightInches={62}
          originalWidthInches={21.5}
        />
      )
    }
  }
  if (lengthInches >= 84 && lengthInches < 108) {
    if (noseShapeValue === "Hybrid" && tailShapeValue === "Swallow") {
      return (
        <MegaFishSvg
          extendedProps={extendedProps}
          originalHeightInches={86}
          originalWidthInches={22}
        />
      )
    }
  }

  if (lengthInches >= 108 && lengthInches <= 132) {
    if (noseShapeValue === "Hybrid" && tailShapeValue === "Swallow") {
      return (
        <HybridSwallowSvgOver9
          extendedProps={extendedProps}
          originalHeightInches={132}
          originalWidthInches={23}
        />
      )
    }
  }
  if (lengthInches >= 58 && lengthInches <= 108) {
    if (noseShapeValue === "Hybrid" && tailShapeValue === "Diamond") {
      return (
        <HybridDiamondSvg
          extendedProps={extendedProps}
          originalHeightInches={66}
          originalWidthInches={21}
        />
      )
    }
    if (noseShapeValue === "Hybrid" && tailShapeValue === "Crescent") {
      return (
        <HybridCrescentSvg
          extendedProps={extendedProps}
          originalHeightInches={66}
          originalWidthInches={21}
        />
      )
    }
    if (noseShapeValue === "Hybrid" && tailShapeValue === "Round") {
      return (
        <HybridRoundSvg
          extendedProps={extendedProps}
          originalHeightInches={66}
          originalWidthInches={21}
        />
      )
    }
    if (noseShapeValue === "Hybrid" && tailShapeValue === "Squash") {
      return (
        <HybridSquashSvg
          extendedProps={extendedProps}
          originalHeightInches={66}
          originalWidthInches={21}
        />
      )
    }

    if (noseShapeValue === "Hybrid" && tailShapeValue === "Square") {
      return (
        <HybridSquareSvg
          extendedProps={extendedProps}
          originalHeightInches={66}
          originalWidthInches={21}
        />
      )
    }
    if (noseShapeValue === "Hybrid" && tailShapeValue === "Pin") {
      return (
        <HybridPinSvg
          extendedProps={extendedProps}
          originalHeightInches={66}
          originalWidthInches={21}
        />
      )
    }
  }
}
export default SurfboardSvg
