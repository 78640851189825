import React, { useEffect, useState } from "react"
import DashboardLayout from "../../../soft_dashboard/examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "../../../soft_dashboard/examples/Navbars/DashboardNavbar"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import Grid from "@mui/material/Grid"
import Footer from "../../core/Footer/Footer"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom"
import Axios from "../../../Util/Axios"
import SkeletonCard from "../../core/SkeletonCard/SkeletonCard"
import StaffCartItems from "./StaffCartItems"

const StaffCartPage = ({ match }) => {
  const { id } = useParams()
  const [cartData, setCartData] = useState("")
  const navigate = useNavigate()
  const dataUrl = `/api/staff-cart-detail/${id}/`
  useEffect(() => {
    Axios.get(dataUrl, navigate)
      .then(({ data }) => {
        setCartData(data)
      })
      .catch((e) => {
        console.log("Error", e)
      })
  }, [])
  if (!cartData) {
    return <SkeletonCard />
  }
  return (
    <DashboardLayout>
      <DashboardNavbar
        pageTitle={
          <SoftBox display="flex" alignItems="center">
            <SoftBox>Cart {cartData?.id || ""}</SoftBox>
          </SoftBox>
        }
      />
      <SoftBox mt={4}>
        <SoftBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Grid container direction="column" spacing={3}>
                <StaffCartItems cartData={cartData} />
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}

export default StaffCartPage
