import { createTheme } from "@mui/material/styles"

export const darkBlue = "#152737"

export const userTheme = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: darkBlue, // Change as per your requirement.
    },
    secondary: {
      main: "#028090", // Change as per your requirement.
    },
  },
  typography: {
    fontFamily: "Josefin Sans, sans-serif", // This sets the default fontFamily
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Lobster",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        label: {
          // It targets the internal typography of the button.
          color: "white", // Change this to the color you want.
        },
        containedPrimary: {
          color: "white",
        },
        containedSecondary: {
          color: "white",
        },
      },
    },
  },
})
