import React, { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import SkeletonCard from "../../core/SkeletonCard/SkeletonCard"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"
import DescriptionItem from "../../core/DescriptionItem/DescriptionItem"
import TextButton from "../../core/TextButton/TextButton"
import Axios from "../../../Util/Axios"
import { useNavigate } from "react-router-dom"
import Toast, {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../../core/Toast/Toast"
import Typography from "@mui/material/Typography"
import { useDispatch } from "react-redux"
import { updateToastData } from "../../../slices/toastSlice"

const ProductDescription = ({ productData, setProductData }) => {
  const [description, setDescription] = useState("")
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (productData) {
      setDescription(productData.description)
    }
  }, [productData])
  const navigate = useNavigate()
  if (!productData) {
    return <SkeletonCard />
  }

  return (
    <Grid item>
      <Card sx={{ height: "100%" }}>
        <SoftBox pt={3} px={2}>
          <DescriptionItem
            keyJsx={
              <SoftTypography variant="h6" fontWeight="medium">
                Description
              </SoftTypography>
            }
            valueJsx={<React.Fragment />}
          />
        </SoftBox>
        <SoftBox pt={1} pb={2} px={2}>
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            <TextField
              disabled={loading}
              multiline={true}
              minRows={6}
              margin="normal"
              required
              value={description}
              onBlur={() => {
                setLoading(true)
                Axios.patch(
                  `/api/staff-product-detail/${productData.id}/`,
                  { description },
                  navigate
                )
                  .then(({ data }) => {
                    setLoading(false)
                    setProductData(data)
                    dispatch(
                      updateToastData({
                        message: "Description updated",
                        severity: TOAST_SEVERITY_SUCCESS,
                      })
                    )
                  })
                  .catch((e) => {
                    setLoading(false)
                    dispatch(
                      updateToastData({
                        message: e,
                        severity: TOAST_SEVERITY_ERROR,
                      })
                    )
                  })
              }}
              onChange={(event) => {
                setDescription(event.target.value)
              }}
            />
            <SoftBox mt={2}>
              <Typography variant="h6">Html Preview</Typography>
            </SoftBox>
            <Typography variant="caption">
              <div
                dangerouslySetInnerHTML={{
                  __html: productData.description,
                }}
              />
            </Typography>
          </SoftBox>
        </SoftBox>
      </Card>
    </Grid>
  )
}

export default ProductDescription
