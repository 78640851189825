import * as React from "react"
const LongboardRoundSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 305
  let originalHeight = 922

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path
          d="M121.024,886.521c0,0-39.146-31.527-56.073-144.249C49.44,645.004,48.248,591.123,48.248,421.881
		c0-228.321,21.041-332.985,39.696-372.671c18.654-39.686,65.076-38.688,65.076-38.688s47.504-0.999,65.617,38.688
		c18.004,39.786,38.611,144.25,38.611,372.57c0,169.242-1.192,223.124-16.16,320.389c-18.961,114.161-54.121,144.25-54.121,144.25
		S154.669,919.893,121.024,886.521z"
        />
      </g>
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1="215.709" y1="44.411" x2="215.709" y2="841.556" />
          <line x1="90.439" y1="44.411" x2="90.439" y2="841.556" />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1="143.149" y1="11.422" x2="143.149" y2="899.926" />
          <line x1="161.046" y1="11.422" x2="161.046" y2="899.926" />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1="152.586" y1="11.422" x2="152.586" y2="899.926" />
        </g>
      )}

      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            d="M152.043,663.697l-55.64-37.088L49.007,597.12c0,0-2.17-193.533-0.977-225.721
		c1.192-32.189,1.302-38.087,1.302-38.087l56.94-45.284l46.314-35.288l46.639,39.287l56.615,41.285c0,0,0.107,5.898,1.301,38.087
		c1.193,32.19-0.976,225.721-0.976,225.721L207.9,626.609l-55.314,37.289"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path
            d="M49.766,323.315c0,0,33.623-41.187,102.604-41.885c68.979-0.7,103.036,38.687,103.036,38.687l1.519,142.652
                l-2.387,135.352c0,0-47.397,41.886-101.521,43.885c-54.013,1.999-101.626-39.286-101.626-39.286S46.403,347.907,49.766,323.315z"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            d="M152.043,252.539c0,0,0.326,18.293-28.308,51.382c-28.635,33.09-74.403,29.291-74.403,29.291
                s-0.108,5.898-1.302,38.087c-1.193,32.19,0.977,225.723,0.977,225.723s15.184-2.201,52.386,10.996
                c37.202,13.195,51.193,55.78,51.193,55.78l0,0c0,0,13.991-42.584,51.191-55.78c37.201-13.195,52.388-10.996,52.388-10.996
                s2.169-193.535,0.976-225.723c-1.19-32.188-1.301-38.087-1.301-38.087s-45.879,3.799-74.404-29.291
                c-28.522-33.088-28.85-51.282-28.85-51.282"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M186.484,886.896c0,0,25.054-25.988,40.144-73.301c13-51.232,20.644-124.306,20.644-124.306l-95.011-54.981
                l-95.986,57.881c0,0,17.688,158.583,64.75,194.335C156.797,918.894,186.484,886.896,186.484,886.896z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M118.69,884.328c0,0-20.384-22.279-33.893-55.188c-13.666-41.785-31.128-176.639-31.128-176.639
                l98.917,65.977l99.132-65.877c0,0-11.171,114.66-23.753,153.747c-12.581,39.086-18.699,47.941-28.087,66.848
                C199.878,873.196,161.958,924.742,118.69,884.328z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path
            d="M120.915,886.42c-16.737-16.304-19.089-25.289-33.297-48.482c-14.208-23.092-29.501-148.05-29.501-148.05
                s11.714,0,46.313-9.998c34.599-9.996,48.156-48.681,48.156-48.681s12.039,38.686,46.639,48.681
                c34.6,9.998,47.938,9.298,47.938,9.298s-16.92,125.657-29.394,149.947c-11.645,23.514-23.445,38.91-31.021,47.385
                C186.751,886.521,154.648,919.28,120.915,886.42z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M121.024,886.521c0,0-16.438-11.579-30.646-46.966C76.169,804.067,58.876,713.88,58.009,700.784
                c-0.868-13.097-4.556-33.889-4.556-33.889s56.074,8.896,73.103,26.391c17.138,17.494,26.463,40.286,26.03,52.383
                c0,0,8.895-41.986,33.947-57.38c25.055-15.396,62.474-22.994,63.016-20.492c0.542,2.499-9.762,90.268-16.378,116.56
                c-6.726,26.392-21.229,68.968-32.293,84.463c-11.063,15.594-14.235,17.601-14.235,17.601
                C152.404,919.921,121.024,886.521,121.024,886.521"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M120.048,886.521c0,0-15.729-15.131-32.755-48.583
                c-14.859-40.887-29.501-133.055-31.021-146.85c0,0,40.891-24.791,95.119-24.191c54.229,0.6,97.288,19.693,97.288,19.693
                s-17.144,123.577-34.389,156.967c-17.354,33.287-22.986,38.957-22.986,38.957S158.468,922.427,120.048,886.521z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            d="M157.792,844.935c0,1.902-1.521,3.5-3.254,3.5h-4.989
                c-1.844,0-3.254-1.598-3.254-3.5v-94.167c0-1.9,1.519-3.499,3.254-3.499h4.989c1.844,0,3.254,1.599,3.254,3.499V844.935z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M157.792,844.935c0,1.902-1.521,3.5-3.254,3.5h-4.989
                c-1.844,0-3.254-1.598-3.254-3.5v-94.167c0-1.9,1.519-3.499,3.254-3.499h4.989c1.844,0,3.254,1.599,3.254,3.499V844.935z"
          />
          <path
            d="M87.835,748.368c-0.217,1.899-1.844,3.298-3.688,2.999l-4.557-0.6
                c-1.844-0.2-3.036-2-2.818-3.897l4.771-39.188c0.218-1.9,1.845-3.299,3.688-3l4.555,0.6c1.844,0.201,3.037,2.001,2.82,3.898
                L87.835,748.368z"
          />
          <path
            d="M228.291,746.668c0.324,1.902-0.979,3.701-2.712,3.999
                l-4.558,0.703c-1.844,0.298-3.471-1.001-3.795-2.901l-5.641-38.986c-0.325-1.899,0.977-3.699,2.712-3.998l4.556-0.7
                c1.734-0.3,3.471,0.999,3.795,2.898L228.291,746.668z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            d="M87.835,748.568c-0.217,1.899-1.844,3.3-3.688,3l-4.557-0.602
                c-1.844-0.199-3.036-1.998-2.818-3.896l4.771-39.189c0.218-1.899,1.845-3.298,3.688-2.999l4.555,0.601
                c1.844,0.199,3.037,1.999,2.82,3.897L87.835,748.568z"
          />
          <path
            d="M228.291,746.868c0.324,1.9-0.979,3.7-2.712,4l-4.558,0.701
                c-1.844,0.3-3.471-1.001-3.795-2.901l-5.641-38.986c-0.325-1.899,0.977-3.699,2.712-3.999l4.556-0.7c1.734-0.3,3.471,1,3.795,2.899
                L228.291,746.868z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M87.835,748.368c-0.217,1.899-1.844,3.298-3.688,2.999l-4.557-0.6
                c-1.844-0.2-3.036-2-2.818-3.897l4.771-39.188c0.218-1.9,1.845-3.299,3.688-3l4.555,0.6c1.844,0.201,3.037,2.001,2.82,3.898
                L87.835,748.368z"
          />
          <path
            d="M228.291,746.668c0.324,1.902-0.979,3.701-2.712,3.999
                l-4.558,0.703c-1.844,0.298-3.471-1.001-3.795-2.901l-5.641-38.986c-0.325-1.899,0.977-3.699,2.712-3.998l4.556-0.7
                c1.734-0.3,3.471,0.999,3.795,2.898L228.291,746.668z"
          />
          <path
            d="M157.575,852.832c0,1.899-1.521,3.499-3.255,3.499h-4.664
                c-1.845,0-3.254-1.6-3.254-3.499v-39.587c0-1.899,1.519-3.499,3.254-3.499h4.664c1.845,0,3.255,1.598,3.255,3.499V852.832z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M87.835,748.368c-0.217,1.899-1.844,3.298-3.688,2.999l-4.557-0.6
                c-1.844-0.2-3.036-2-2.818-3.897l4.771-39.188c0.218-1.9,1.845-3.299,3.688-3l4.555,0.6c1.844,0.201,3.037,2.001,2.82,3.898
                L87.835,748.368z"
          />
          <path
            d="M117.119,805.348c0,1.902-1.52,3.5-3.254,3.5h-4.663
                c-1.846,0-3.254-1.598-3.254-3.5v-39.484c0-1.9,1.518-3.501,3.254-3.501h4.663c1.844,0,3.254,1.601,3.254,3.501V805.348z"
          />
          <path
            d="M228.291,746.668c0.324,1.902-0.979,3.701-2.712,3.999
                l-4.558,0.703c-1.844,0.298-3.471-1.001-3.795-2.901l-5.641-38.986c-0.325-1.899,0.977-3.699,2.712-3.998l4.556-0.7
                c1.734-0.3,3.471,0.999,3.795,2.898L228.291,746.668z"
          />
          <path
            d="M202.479,804.349c0,1.901-1.52,3.5-3.254,3.5h-4.664
                c-1.845,0-3.254-1.599-3.254-3.5v-39.485c0-1.899,1.52-3.5,3.254-3.5h4.664c1.844,0,3.254,1.601,3.254,3.5V804.349z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M157.792,844.935c0,1.902-1.521,3.5-3.254,3.5h-4.989
                c-1.844,0-3.254-1.598-3.254-3.5v-94.167c0-1.9,1.519-3.499,3.254-3.499h4.989c1.844,0,3.254,1.599,3.254,3.499V844.935z"
          />
          <path
            d="M87.835,748.368c-0.217,1.899-1.844,3.298-3.688,2.999l-4.557-0.6
                c-1.844-0.2-3.036-2-2.818-3.897l4.771-39.188c0.218-1.9,1.845-3.299,3.688-3l4.555,0.6c1.844,0.201,3.037,2.001,2.82,3.898
                L87.835,748.368z"
          />
          <path
            d="M117.119,805.348c0,1.902-1.52,3.5-3.254,3.5h-4.663
                c-1.846,0-3.254-1.598-3.254-3.5v-39.484c0-1.9,1.518-3.501,3.254-3.501h4.663c1.844,0,3.254,1.601,3.254,3.501V805.348z"
          />
          <path
            d="M228.291,746.668c0.324,1.902-0.979,3.701-2.712,3.999
                l-4.558,0.703c-1.844,0.298-3.471-1.001-3.795-2.901l-5.641-38.986c-0.325-1.899,0.977-3.699,2.712-3.998l4.556-0.7
                c1.734-0.3,3.471,0.999,3.795,2.898L228.291,746.668z"
          />
          <path
            d="M202.479,804.349c0,1.901-1.52,3.5-3.254,3.5h-4.664
                c-1.845,0-3.254-1.599-3.254-3.5v-39.485c0-1.899,1.52-3.5,3.254-3.5h4.664c1.844,0,3.254,1.601,3.254,3.5V804.349z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M87.835,748.368c-0.217,1.899-1.844,3.298-3.688,2.999l-4.557-0.6
                c-1.844-0.2-3.036-2-2.818-3.897l4.771-39.188c0.218-1.9,1.845-3.299,3.688-3l4.555,0.6c1.844,0.201,3.037,2.001,2.82,3.898
                L87.835,748.368z"
          />
          <path
            d="M117.119,805.348c0,1.902-1.52,3.5-3.254,3.5h-4.663
                c-1.846,0-3.254-1.598-3.254-3.5v-39.484c0-1.9,1.518-3.501,3.254-3.501h4.663c1.844,0,3.254,1.601,3.254,3.501V805.348z"
          />
          <path
            d="M228.291,746.668c0.324,1.902-0.979,3.701-2.712,3.999
                l-4.558,0.703c-1.844,0.298-3.471-1.001-3.795-2.901l-5.641-38.986c-0.325-1.899,0.977-3.699,2.712-3.998l4.556-0.7
                c1.734-0.3,3.471,0.999,3.795,2.898L228.291,746.668z"
          />
          <path
            d="M202.479,804.349c0,1.901-1.52,3.5-3.254,3.5h-4.664
                c-1.845,0-3.254-1.599-3.254-3.5v-39.485c0-1.899,1.52-3.5,3.254-3.5h4.664c1.844,0,3.254,1.601,3.254,3.5V804.349z"
          />
          <path
            d="M157.575,852.832c0,1.899-1.521,3.499-3.255,3.499h-4.664
                c-1.845,0-3.254-1.6-3.254-3.499v-39.587c0-1.899,1.519-3.499,3.254-3.499h4.664c1.845,0,3.255,1.598,3.255,3.499V852.832z"
          />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          x="117.336"
          y="547.237"
          width="71.259"
          height="79.474"
          href={logoUrl}
        />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path
          d="M126.461,876.73c0,0-36.023-24.118-51.641-140.558
                C61.479,640.905,60.503,588.124,60.503,422.38c0-223.622,18.005-325.987,34.057-364.973c16.053-38.887,57.701-37.787,57.701-37.787
                s41.54-1.1,57.917,37.787c16.486,38.986,35.249,141.351,35.249,364.973c0,165.744-1.084,218.525-14.75,313.793
                c-21.716,125.043-50.382,139.44-50.382,139.44S156.295,906.839,126.461,876.73z"
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path
            d="M153.02,29.616c0,0-35.031-1-49.24,36.987
                C89.464,104.591,70.7,204.556,70.7,422.981c0,161.944,3.106,214.441,14.93,307.509c9.401,95.812,43.498,133.489,43.498,133.489
                c26.833,33.028,47.25,0.23,47.25,0.23s30.584-33.582,43.453-136.035c12.146-93.07,15.618-143.25,15.618-305.194
                c0-218.426-18.33-317.692-32.973-355.678c-14.752-37.987-50.107-37.687-50.107-37.687"
          />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path
          d="M120.915,886.42
                c0,0-42.537-35.113-56.073-144.25c-15.51-97.267-16.703-151.148-16.703-320.389c0-228.321,20.934-332.883,39.588-372.571
                c18.764-39.688,64.859-38.688,64.859-38.688s47.83-1,65.835,38.787c18.004,39.787,38.503,144.25,38.503,372.571
                c0,169.242-1.191,223.123-16.052,320.391c-17.244,116.489-54.121,144.249-54.121,144.249S155.189,920.204,120.915,886.42z"
        />
      </g>
    </svg>
  )
}
export default LongboardRoundSvg
