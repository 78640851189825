import React from "react"
import ProductTable from "../components/core/DataTable/ProductTable"
import Page from "../Page/Page"

const InventoryList = () => {
  const dataUrl = "/api/product-list/"

  return (
    <Page>
      <ProductTable
        title={"Surfboards"}
        // getRow={getRow}
        // bannerUrl={
        //   // "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/purplebanner.png"
        //   // "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/yellowboard.jpg"
        // }
        path={"surfboard-inventory"}
        dataUrl={dataUrl}
        extraParams={"&type=inventory"}
        description={"Stock surfboards available for purchase and pick up."}
      />
    </Page>
  )
}

export default InventoryList
