import * as React from "react"

const PerformancePinSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 973.455

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M165.061,952.609 c0,0-116.125-16.382-116.125-450.991c0-394.599,116.005-481.915,116.005-481.915s116.01,98.786,116.01,479.577 C280.951,936.228,165.061,952.609,165.061,952.609z" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={18}
        opacity={overlapOpacity}
      >
        <path d=" M164.898,940.728c0,0-104.75-31.494-104.782-437.746C60.087,136.228,164.941,34.335,164.941,34.335s103.078,102.92,104.83,466.374 C271.742,909.478,164.898,940.728,164.898,940.728z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M165.061,952.609 c0,0-136.818-26.382-112.817-556.09l112.178-80.528l113.226,80.528C300.742,927.728,165.061,952.609,165.061,952.609" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M165.061,952.609 c0,0-135.318-20.882-112.817-556.09c0.001,0,100.697-181.191,225.403,0C301.242,926.728,165.061,952.609,165.061,952.609" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M165.061,952.609 c0,0-135.318-20.382-113.466-556.088c0,0,41.487-9.653,72.761-34.167s40.917-60.931,40.917-60.931s12.188,36.501,38.187,59.53 c28.465,25.211,74.538,35.565,74.538,35.565C300.242,929.728,165.061,952.609,165.061,952.609" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M165.061,952.609 c0,0-82.4-11.882-108.034-272.451l108.298-32.548l107.682,32.548C244.207,942.728,165.061,952.609,165.061,952.609" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M165.061,952.609 c0,0-82.4-13.382-108.034-272.451l108.298,47.57l107.682-47.57C249.429,933.728,165.061,952.609,165.061,952.609" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path d="M165.061,952.609 c0,0-82.4-11.882-108.034-272.45c0,0,50.006-2.368,78.079-16.636c28.073-14.27,30.219-23.633,30.219-23.633 s10.972,17.132,25.42,22.927c20.123,8.073,82.262,17.341,82.262,17.341C249.429,933.728,165.061,952.609,165.061,952.609" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M165.061,952.609 c0,0-82.4-14.382-108.635-272.29c0,0,73.95-4.15,88.399,17.252c14.448,21.401,20.132,36.255,20.132,36.255 s8.38-19.568,28.477-36.271c20.935-17.396,78.971-17.235,78.971-17.235C249.429,933.728,165.061,952.609,165.061,952.609" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M165.061,952.609 c0,0-79.146-15.882-105.572-250.088c-0.848-7.227-1.669-14.68-2.46-22.365c-0.001,0.002,103.983-73.128,215.978,0.002 c0,0-2.471,11.845-3.208,18.069C244.207,937.728,165.061,952.609,165.061,952.609" />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={244.207} y1={182.394} x2={244.207} y2={834.763} />
          <line x1={85.915} y1={182.394} x2={85.915} y2={834.763} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={153.069} y1={32.739} x2={153.069} y2={947.099} />
          <line x1={177.053} y1={32.739} x2={177.053} y2={947.099} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.061} y1={19.703} x2={165.061} y2={952.609} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.105,3.58-4.105h5.487c2.029,0,3.58,1.879,3.58,4.105V863.752z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.105,3.58-4.105h5.487c2.029,0,3.58,1.879,3.58,4.105V863.752z" />
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.352,3.102,4.577L94.858,775.854z" />
          <path d="M249.374,773.861c0.356,2.229-1.075,4.343-2.983,4.691 l-5.014,0.823c-2.027,0.353-3.816-1.174-4.175-3.405l-6.204-45.776c-0.357-2.23,1.072-4.345,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M94.858,776.088c-0.238,2.23-2.027,3.874-4.056,3.523 l-5.013-0.705c-2.028-0.235-3.339-2.35-3.101-4.577l5.248-46.014c0.24-2.23,2.029-3.871,4.057-3.521l5.011,0.703 c2.028,0.234,3.341,2.347,3.102,4.577L94.858,776.088z" />
          <path d="M249.374,774.093c0.356,2.229-1.075,4.345-2.983,4.697 l-5.014,0.818c-2.027,0.354-3.816-1.176-4.175-3.404l-6.204-45.779c-0.357-2.229,1.072-4.342,2.984-4.692l5.011-0.82 c1.909-0.352,3.817,1.175,4.176,3.403L249.374,774.093z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.352,3.102,4.577L94.858,775.854z" />
          <path d="M249.374,773.861c0.356,2.229-1.075,4.343-2.983,4.691 l-5.014,0.823c-2.027,0.353-3.816-1.174-4.175-3.405l-6.204-45.776c-0.357-2.23,1.072-4.345,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M171.58,879.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.481c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V879.979z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.352,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109V796.4c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.229-1.075,4.343-2.983,4.691 l-5.014,0.823c-2.027,0.353-3.816-1.174-4.175-3.405l-6.204-45.776c-0.357-2.23,1.072-4.345,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.253-1.878-4.253-4.105v-46.365c0-2.229,1.985-4.104,4.253-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.105,3.58-4.105h5.487c2.029,0,3.58,1.879,3.58,4.105V863.752z" />
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.352,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109V796.4c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.229-1.075,4.343-2.983,4.691 l-5.014,0.823c-2.027,0.353-3.816-1.174-4.175-3.405l-6.204-45.776c-0.357-2.23,1.072-4.345,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.253-1.878-4.253-4.105v-46.365c0-2.229,1.985-4.104,4.253-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.352,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109V796.4c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.229-1.075,4.343-2.983,4.691 l-5.014,0.823c-2.027,0.353-3.816-1.174-4.175-3.405l-6.204-45.776c-0.357-2.23,1.072-4.345,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.253-1.878-4.253-4.105v-46.365c0-2.229,1.985-4.104,4.253-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
          <path d="M171.58,879.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.481c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V879.979z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={535.58}
          width={96}
          height={92.504}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d="M165.492,928.228 c0,0-93.556-47.5-93.556-421.625c0-357.79,93.005-452.518,93.005-452.518s93.01,92.761,93.01,450.322 C257.951,885.728,165.492,928.228,165.492,928.228" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d="M165.061,952.609 c0,0-116.125-17.382-116.125-450.991c0-381.031,116.005-481.915,116.005-481.915s113.552,98.793,116.01,479.577 C283.742,931.728,165.061,952.609,165.061,952.609z" />
      </g>
    </svg>
  )
}
export default PerformancePinSvg
