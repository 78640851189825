import * as React from "react"

const HybridSwallowSvgOver9 = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 272.917
  let originalHeight = 1000

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M136.333,932.249c0,0,0,11.627-11.903,28.751 c-11.903,17.124-35.764,12.718-35.764,12.718S81.399,938,73.159,897c-8.241-41-17.705-168.091-19.227-232 c-1.787-75-2.674-279.91-0.169-327c2.649-49.807,2.563-137,23.974-213c18.477-65.586,58.598-110,58.598-110 s40.12,44.414,58.598,110c21.41,76,21.324,163.193,23.975,213c2.504,47.09,1.616,252-0.17,327c-1.521,63.909-10.986,191-19.229,232 c-8.24,41-16.84,76.718-16.84,76.718s-22.528,4.406-34.434-12.718C136.333,943.876,136.333,932.249,136.333,932.249z" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={18}
        opacity={overlapOpacity}
      >
        <path d=" M96.912,972.75c0,0-10.46-51.738-17.942-91.467c-7.483-39.729-16.077-162.871-17.459-224.796 c-1.622-72.673-2.428-271.221-0.153-316.849c2.405-48.261,2.328-132.747,21.769-206.387 c16.777-63.55,53.208-106.585,53.208-106.585s36.43,43.035,53.208,106.585c19.44,73.641,19.362,158.126,21.771,206.387 c2.272,45.628,1.467,244.176-0.153,316.849c-1.383,61.925-9.977,185.067-17.457,224.796C186.216,921.012,174,972.75,174,972.75" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M136.333,303.198l-37.442,37.95 l-45.83,48.678c0,0-0.124,6.315-1.082,40.937s0.811,242.552,0.811,242.552l38.114,31.699l45.429,40.153l44.693-40.153 l38.851-31.699c0,0,1.771-207.932,0.813-242.552c-0.959-34.62-1.082-40.937-1.082-40.937l-44.271-45.576L136.333,303.198z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M53.278,360.292 c0,0,27.082-48.83,82.722-49.619c55.641-0.79,83.113,45.804,83.113,45.804l1.206,169.036l-1.957,160.479 c0,0-38.239,49.626-81.824,51.996c-43.585,2.369-81.984-46.614-81.984-46.614S50.596,389.467,53.278,360.292z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M136.298,301.002 c0,0-0.221,19.629-22.972,55.411c-22.751,35.782-59.29,31.673-59.29,31.673s-0.123,6.351-1.071,41.153 c-0.948,34.802,0.803,243.827,0.803,243.827s12.058-2.396,41.732,11.853s40.796,60.248,40.796,60.248h0.069 c0,0,11.122-45.999,40.796-60.248c29.676-14.248,41.731-11.853,41.731-11.853s1.751-209.025,0.806-243.827 c-0.949-34.802-1.072-41.153-1.072-41.153s-36.539,4.108-59.289-31.673c-22.75-35.782-22.972-55.411-22.972-55.411" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M136.75,752.5l-75.767,49.277 c0,0,22.518,171.938,27.685,171.938c7.843,0,21.275,1.464,33.017-9.347c5.765-5.309,12.476-25.512,14.649-27.125 c1-0.742,8.908,20.511,16.333,27.924c10.435,10.413,30,8.548,30,8.548s4.416-19.03,13.527-62.907 c9.112-43.877,15.583-109.631,15.583-109.631L136.75,752.5z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M136.263,847.537l74.479-49.591 c0,0-5.611,91.96-15.074,121.396c-9.463,29.438-9.742,53.923-15.387,54.885c-33.947,5.784-44.614-38.216-44.614-38.216 c-6.333,40.666-47,37.707-47,37.707s-1.208-16.063-8.294-40.926c-10.581-37.121-18.449-134.846-18.449-134.846L136.263,847.537z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path d="M136.333,938.75c0,0-10,34-41.25,36.001 c-8.94,0.572-11.757-28.604-15.06-43.687C75.067,908.428,61.952,802.82,61.952,802.82s9.268,0.026,36.783-8.555 c27.515-8.583,38.237-41.768,38.237-41.768h0.314c0,0,9.227,33.185,36.74,41.768c27.518,8.581,36.783,8.555,36.783,8.555 S197.265,909,192.302,931.351c-4.238,19.091-9.635,42.367-12.021,42.876C146.901,981.342,136.333,938.75,136.333,938.75" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M135.85,861.134 c0,0,7.006-33.864,26.626-46.271c19.62-12.405,48.956-18.558,49.393-16.532c0.438,2.024-6.229,72.624-11.463,93.888 c-5.232,21.266-10.613,50.706-14.594,65.436c-2.729,10.098-5.531,16.572-5.531,16.572L153.083,969l-16.75-31.754l-16.571,31.705 l-31.095,4.767c0,0-1.98-15.131-5.833-32.218c-6.689-29.661-18.609-96.142-19.328-106.75c-0.718-10.607-3.318-36.804-3.318-36.804 s42.082,6.837,55.367,20.95C128.839,833.009,136.216,851.385,135.85,861.134z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M63.506,811.407 c0,0,30.948-19.413,72.015-18.908c41.066,0.504,73.639,15.429,73.639,15.429s-9.095,96.029-17.813,127.8 c-7.599,27.68-8.679,37.99-8.679,37.99l-15.778-0.968l-14.435-6.896l-16.121-27.104l-14.649,25.621l-14.52,8.379l-18.497,0.968 c0,0-1.97-17.463-8.294-40.926C70.267,895.304,64.694,822.188,63.506,811.407z" />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={202.333} y1={156.242} x2={202.333} y2={880.609} />
          <line x1={70.333} y1={156.242} x2={70.333} y2={880.609} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={126.333} y1={27.711} x2={126.333} y2={957.652} />
          <line x1={146.333} y1={27.711} x2={146.333} y2={957.652} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={136.333} y1={15} x2={136.333} y2={938.75} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M98.452,904.22c-0.05,1.612-1.408,2.883-3.033,2.837l-4.146-0.122 c-1.626-0.047-2.903-1.391-2.854-3.005l1.026-33.148c0.049-1.612,1.407-2.883,3.032-2.833l4.147,0.119 c1.625,0.048,2.903,1.391,2.853,3.005L98.452,904.22z" />
          <path d="M185.811,903.932c0.049,1.612-1.228,2.958-2.853,3.006 l-4.146,0.117c-1.626,0.046-2.982-1.224-3.033-2.837l-1.029-33.149c-0.049-1.612,1.227-2.958,2.853-3.006l4.146-0.117 c1.625-0.045,2.984,1.224,3.035,2.837L185.811,903.932z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M91.962,880.22c-0.05,1.612-1.408,2.883-3.033,2.837l-4.146-0.122 c-1.626-0.047-2.903-1.391-2.854-3.005l1.026-33.148c0.049-1.612,1.407-2.883,3.032-2.833l4.147,0.119 c1.625,0.048,2.903,1.391,2.853,3.005L91.962,880.22z" />
          <path d="M106.951,925.01c0,1.612-1.319,2.919-2.944,2.919h-4.149 c-1.627,0-2.945-1.307-2.945-2.919v-33.163c0-1.61,1.318-2.921,2.945-2.921h4.149c1.625,0,2.944,1.311,2.944,2.921V925.01z" />
          <path d="M191.343,879.932c0.049,1.612-1.229,2.958-2.852,3.006 l-4.148,0.117c-1.625,0.046-2.982-1.224-3.032-2.837l-1.028-33.149c-0.051-1.612,1.225-2.958,2.85-3.006l4.148-0.117 c1.623-0.045,2.983,1.224,3.034,2.837L191.343,879.932z" />
          <path d="M176.927,925.01c0,1.612-1.32,2.919-2.946,2.919h-4.149 c-1.625,0-2.942-1.307-2.942-2.919v-33.163c0-1.61,1.317-2.921,2.942-2.921h4.149c1.626,0,2.946,1.311,2.946,2.921V925.01z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          id="_x3C_Path_x3E__2_"
          x={116.332}
          y={724.152}
          width={40.001}
          height={42.028}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d="M106.951,972.801 c0,0-14.242-51.031-20.787-90.18c-6.543-39.146-14.061-160.486-15.27-221.505c-1.418-71.608-2.124-267.25-0.134-312.211 c2.104-47.555,2.036-130.804,19.039-203.367c14.672-62.62,46.534-105.024,46.534-105.024s31.86,42.405,46.534,105.024 c17.004,72.563,16.934,155.812,19.039,203.367c1.988,44.96,1.282,240.602-0.136,312.211 c-1.209,61.019-8.724,182.359-15.269,221.505c-6.543,39.146-22.812,89.708-22.812,89.708" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d="M136.333,932.249 c0,0,0,11.627-11.903,28.751c-11.903,17.124-35.764,12.718-35.764,12.718S81.399,938,73.159,897 c-8.241-41-17.705-168.091-19.227-232c-1.787-75-2.674-279.91-0.169-327c2.649-49.807,2.563-137,23.974-213 c18.477-65.586,58.598-110,58.598-110s40.12,44.414,58.598,110c21.41,76,21.324,163.193,23.975,213c2.504,47.09,1.616,252-0.17,327 c-1.521,63.909-10.986,191-19.229,232c-8.24,41-16.84,76.718-16.84,76.718s-22.528,4.406-34.434-12.718 C136.333,943.876,136.333,932.249,136.333,932.249z" />
      </g>
    </svg>
  )
}
export default HybridSwallowSvgOver9
