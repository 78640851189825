import React, { useState } from "react"
import SkeletonCard from "../../../core/SkeletonCard/SkeletonCard"
import Card from "@mui/material/Card"
import SoftBox from "../../../../soft_dashboard/components/SoftBox"
import SoftTypography from "../../../../soft_dashboard/components/SoftTypography"
import DescriptionItem from "../../../core/DescriptionItem/DescriptionItem"
import { getCurrencyStringFromNumber } from "../../../../Util/Util"
import Grid from "@mui/material/Grid"
import ColorBox from "../../../../MarlinSurf/CustomOrder/ColorBox"
import Button from "@mui/material/Button"
import AdditionalChargeModal from "../AdditionalChargeModal"
import OrderOptionJsx from "./OrderOptionJsx"
import AdditionalChargeJsx from "./AdditionalChargeJsx"

const OrderOptions = ({ orderData, setOrderData }) => {
  const [addAdditionalChargeModalActive, setAddAdditionalChargeModalActive] =
    useState(false)

  if (!orderData) {
    return <SkeletonCard />
  }
  const options = orderData?.options || []
  const additionalCharges = orderData?.additional_charges || []

  let objectsToMap = [...options, ...additionalCharges]

  if (objectsToMap.length === 0) {
    return <React.Fragment />
  }

  return (
    <Grid item>
      <Card>
        <SoftBox pt={3} px={2}>
          <DescriptionItem
            keyJsx={
              <SoftBox pl={1}>
                <SoftTypography
                  display="block"
                  fontWeight="medium"
                  variant="h6"
                >
                  Order Options
                </SoftTypography>
              </SoftBox>
            }
            valueJsx={
              <Button
                variant="contained"
                color={"primary"}
                sx={{ mt: 1, width: "100%" }}
                disabled={orderData?.status === "completed"}
                onClick={() => {
                  setAddAdditionalChargeModalActive(true)
                }}
              >
                <SoftTypography color={"white"} variant={"button"}>
                  Add Additional Charge
                </SoftTypography>
              </Button>
            }
          />
        </SoftBox>
        <SoftBox pt={1} pb={2} px={2}>
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            {options.map((optionData, i) => {
              return (
                <OrderOptionJsx
                  key={optionData.id}
                  optionData={optionData}
                  editable={false}
                />
              )
            })}
            {additionalCharges?.length > 0 && (
              <SoftBox pt={2}>
                <SoftBox pl={1}>
                  <SoftTypography>Additional Charges</SoftTypography>
                </SoftBox>
                {additionalCharges.map((additionalChargeData, i) => {
                  return (
                    <AdditionalChargeJsx
                      key={additionalChargeData.id}
                      additionalChargeData={additionalChargeData}
                      orderData={orderData}
                      setOrderData={setOrderData}
                    />
                  )
                })}
              </SoftBox>
            )}
          </SoftBox>
        </SoftBox>
      </Card>
      <AdditionalChargeModal
        active={addAdditionalChargeModalActive}
        setActive={setAddAdditionalChargeModalActive}
        orderData={orderData}
        setOrderData={setOrderData}
      />
    </Grid>
  )
}

export default OrderOptions
