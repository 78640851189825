import React, { useEffect, useState } from "react"
import "./Gallery.css"
import Axios from "../../Util/Axios"
import Page from "../Page/Page"
import InstagramGalleryWrapper from "../../components/core/InstagramGalleryWrapper/InstagramGalleryWrapper"

const Gallery = () => {
  const [galleryImages, setGalleryImages] = useState([])
  const dataUrl = "/api/site-configuration/1/action_get_gallery_images/"
  useEffect(() => {
    Axios.get(dataUrl).then(({ data }) => {
      setGalleryImages(data)
    })
  }, [])

  return (
    <Page>
      <div
        style={
          {
            // backgroundColor: "#1d2f3e",
          }
        }
      >
        <div className="grid-container">
          <div className="gallery-images-wrapper">
            {galleryImages.map((image, index) => (
              <div
                className="image-container"
                key={index}
                style={{
                  marginBottom: -4,
                  // border: "5px solid #1d2f3e",
                  // borderWidth: "5px 5px 0px 5px",
                }}
              >
                <img
                  src={image.url}
                  alt="Surfboard"
                  className="gallery-image"
                />
                <div className="centered-text">{image.text}</div>
              </div>
            ))}
          </div>
          <InstagramGalleryWrapper />
        </div>
      </div>
    </Page>
  )
}

export default Gallery
