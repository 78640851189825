import * as React from "react"

const PerformanceSquashSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 973.455

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M100.746,914.464 c0,0-51.81-176.452-51.81-412.846c0-381.031,116.005-481.915,116.005-481.915s116.01,98.786,116.01,479.577 c0,268.731-54.766,415.222-54.766,415.222s-8.443,23.393-45.776,23.393s-28.333,0-28.333,0S113.742,940.895,100.746,914.464z" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={18}
        opacity={overlapOpacity}
      >
        <path d=" M108.902,904.261c0,0-48.786-171.396-48.786-401.277c0-361.646,104.825-468.646,104.825-468.646s104.83,102.916,104.83,466.374 c0,261.334-51.696,402.52-51.696,402.52s-2.5,23.701-41.666,23.666c-13.177,0.058-28.167,0.026-28.167,0.026 S114.134,927.229,108.902,904.261z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M100.915,914.895 c0,0-59.673-195.5-49.672-518.375l113.178-80.528l114.226,80.528c11.43,372.041-52.569,518.375-52.569,518.375s-9.666,23-47,23 s-34.483,0-34.483,0S110.421,936.895,100.915,914.895z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M100.746,914.464 c0,0-61.504-204.735-49.503-517.944c0.001,0,102.697-181.19,227.403,0c13.596,318.709-52.461,517.982-52.461,517.982 s-9.443,23.393-45.776,23.393s-31.974,0.104-31.974,0.104S114.75,938.367,100.746,914.464z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M100.746,914.464 c0,0-63.504-223.235-49.15-517.944c0,0,41.487-9.652,72.761-34.167s40.918-60.931,40.918-60.931s12.188,36.501,38.186,59.53 c28.464,25.213,74.537,35.567,74.537,35.567c15.244,315.209-51.813,517.981-51.813,517.981s-9.109,23.394-45.776,23.394 s-35.817,0-35.817,0S111.75,938.367,100.746,914.464z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M101.542,914.473 c0,0-25.299-73.744-42.069-226.88l105.837-30.604l105.244,30.604c-15.313,153.136-44.943,226.909-44.943,226.909 s-10.18,23.393-45.688,23.393s-34.685,0-34.685,0S111.027,937.385,101.542,914.473z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M101.542,914.473 c0,0-26.299-79.744-41.135-226.88l104.903,44.734l104.309-44.734c-11.667,149.636-44.008,226.909-44.008,226.909 s-8.889,23.495-47.625,23.495s-29.026,0-29.026,0S112.318,940.051,101.542,914.473z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path d="M101.542,914.473 c0,0-25.299-68.778-42.069-226.88c0,0,49.373-2.226,76.566-15.644c27.193-13.416,29.271-22.224,29.271-22.224 s10.629,16.109,24.625,21.562c19.492,7.592,80.685,16.306,80.685,16.306c-12.668,141.796-47.009,226.909-47.009,226.909 s-7.533,23.393-45.625,23.393c-38.09,0-32.749,0-32.749,0S110.704,937.051,101.542,914.473z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M101.542,914.473 c0,0-27.299-78.744-41.597-227.553c0,0,72.533-3.903,86.53,16.223c13.995,20.128,19.501,34.094,19.501,34.094 s8.119-18.4,27.584-34.106c20.279-16.358,76.498-16.208,76.498-16.208c-9.315,142.468-44.447,227.582-44.447,227.582 s-7.92,23.393-51.176,23.393c-43.256,0-21.951,0-21.951,0S114.901,941.718,101.542,914.473z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M101.542,914.473 c0,0-23.799-64.744-40.62-205.85c-0.821-6.795-0.682-13.804-1.447-21.032c-0.001,0.002,101.658-68.765,210.144,0.002 c0,0,0.716,12.001,0,17.855c-17.376,148.778-44.008,209.054-44.008,209.054s-8.889,23.394-41.491,23.394 c-32.602,0-31.635,0-31.635,0S111.996,942.051,101.542,914.473z" />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={242.207} y1={182.394} x2={242.207} y2={847.082} />
          <line x1={83.915} y1={182.394} x2={83.915} y2={847.082} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={153.1} y1={33.1} x2={153.1} y2={937.9} />
          <line x1={177.1} y1={33.1} x2={177.1} y2={937.9} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.1} y1={19.7} x2={165.1} y2={937.9} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V863.752z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V863.752z" />
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M94.858,776.088c-0.238,2.23-2.027,3.874-4.056,3.523 l-5.013-0.705c-2.028-0.235-3.339-2.349-3.101-4.577l5.248-46.014c0.24-2.23,2.029-3.871,4.057-3.521l5.011,0.703 c2.028,0.234,3.341,2.347,3.102,4.577L94.858,776.088z" />
          <path d="M249.374,774.093c0.356,2.229-1.075,4.345-2.983,4.697 l-5.014,0.818c-2.027,0.354-3.816-1.175-4.175-3.404l-6.204-45.779c-0.357-2.229,1.072-4.342,2.984-4.693l5.011-0.82 c1.909-0.351,3.817,1.175,4.176,3.403L249.374,774.093z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M171.58,879.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.482c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V879.979z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V863.752z" />
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
          <path d="M171.58,879.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.482c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V879.979z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={535.58}
          width={96}
          height={92.504}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d="M118.652,894.511 c0,0-46.716-165.937-46.716-387.908c0-357.79,93.005-452.518,93.005-452.518s93.01,92.761,93.01,450.322 c0,252.341-48.051,389.819-48.051,389.819s-1.824,22.001-34.491,22.001s-23.667,0-23.667,0S122.873,919.252,118.652,894.511z" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path
          id="_x3C_Path_x3E__3_"
          className="st5"
          d="M100.7,914.5c0,0-51.8-176.5-51.8-412.8c0-381,116-481.9,116-481.9s116,98.8,116,479.6
		c0,268.7-54.8,415.2-54.8,415.2s-6.4,23.4-43.8,23.4s-43.7,0-43.7,0S106.4,934.1,100.7,914.5z"
        />
      </g>
    </svg>
  )
}
export default PerformanceSquashSvg
