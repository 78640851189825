export const getSurfboardVolume = (length, width, thickness, type) => {
  if (!length || !type) {
    return null
  }

  const cmPerInch = 2.54

  const typePercentages = {
    shortboard: 0.54,
    midLength: 0.56,
    longboard: 0.6,
  }

  let lengthCm = length * cmPerInch
  let widthCm = width * cmPerInch
  let thicknessCm = thickness * cmPerInch

  let volumeCubicCm = lengthCm * widthCm * thicknessCm

  let volumeLiters =
    (volumeCubicCm * typePercentages[type.toLowerCase()]) / 1000

  return volumeLiters.toFixed(1)
}
