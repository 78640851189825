import React, { useState } from "react"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"
import SkeletonCard from "../../core/SkeletonCard/SkeletonCard"
import { getCurrencyStringFromNumber } from "../../../Util/Util"
import DescriptionItem from "../../core/DescriptionItem/DescriptionItem"
import { Link, useNavigate } from "react-router-dom"
import SoftButton from "../../../soft_dashboard/components/SoftButton"
import Axios from "../../../Util/Axios"
import { updateToastData } from "../../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../../core/Toast/Toast"
import { useDispatch } from "react-redux"
import Box from "@mui/material/Box"

const LineItemsJsx = ({ orderData, setOrderData }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [markingAsComplete, setMarkingAsComplete] = useState(false)

  if (!orderData) {
    return <SkeletonCard />
  }

  const lineItems = orderData.line_items || []
  if (lineItems.length === 0) {
    return <React.Fragment />
  }

  return (
    <Grid item>
      <Card id="delete-account">
        <SoftBox pt={3} px={2}>
          <SoftTypography variant="h6" fontWeight="medium">
            Line Items
          </SoftTypography>
        </SoftBox>
        <SoftBox pt={1} pb={2} px={2}>
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            {lineItems.map((lineItem) => {
              let product =
                lineItem.product || lineItem.product_variant?.product
              return (
                <SoftBox
                  key={lineItem.id}
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor="grey-100"
                  borderRadius="lg"
                  p={3}
                  mb={0} // mb={1}
                  mt={2}
                >
                  <SoftBox
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    alignItems={"center"}
                  >
                    <SoftBox pr={3}>
                      <img
                        src={lineItem.display_image}
                        alt={"Display Image"}
                        style={{ width: "60px" }}
                      />
                    </SoftBox>
                    <SoftBox width="100%" display="flex" flexDirection="column">
                      <DescriptionItem
                        spacing={"tight"}
                        keyJsx={
                          <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            #{lineItem.id}{" "}
                            {lineItem?.complete ? " [COMPLETE]" : ""}
                          </SoftTypography>
                        }
                        valueJsx={
                          <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            {getCurrencyStringFromNumber(lineItem.total_price)}
                          </SoftTypography>
                        }
                      />
                      {/*</SoftBox>*/}
                      <SoftBox mb={1} lineHeight={0}>
                        <DescriptionItem
                          spacing={"tight"}
                          keyJsx={
                            <SoftTypography variant="caption" color="text">
                              Name
                            </SoftTypography>
                          }
                          valueJsx={
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                            >
                              <Link to={`/staff/products/${product?.id}/`}>
                                {product?.name}
                              </Link>
                            </SoftTypography>
                          }
                        />
                        <DescriptionItem
                          spacing={"tight"}
                          keyJsx={
                            <SoftTypography variant="caption" color="text">
                              Type
                            </SoftTypography>
                          }
                          valueJsx={
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                            >
                              {lineItem.type_display}
                            </SoftTypography>
                          }
                        />
                        <DescriptionItem
                          spacing={"tight"}
                          keyJsx={
                            <SoftTypography variant="caption" color="text">
                              Quantity
                            </SoftTypography>
                          }
                          valueJsx={
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                            >
                              {lineItem.quantity}
                            </SoftTypography>
                          }
                        />
                        <DescriptionItem
                          spacing={"tight"}
                          keyJsx={
                            <SoftTypography variant="caption" color="text">
                              Product Price
                            </SoftTypography>
                          }
                          valueJsx={
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                            >
                              {getCurrencyStringFromNumber(product?.price)}
                            </SoftTypography>
                          }
                        />
                        {lineItem.type === "apparel" && lineItem.tracking && (
                          <DescriptionItem
                            spacing={"tight"}
                            keyJsx={
                              <SoftTypography variant="caption" color="text">
                                Tracking
                              </SoftTypography>
                            }
                            valueJsx={
                              <SoftTypography
                                variant="caption"
                                fontWeight="medium"
                              >
                                {lineItem.tracking.carrier}{" "}
                                {lineItem.tracking.tracking_number}{" "}
                                {lineItem.tracking.tracking_url && (
                                  <Link
                                    variant="caption"
                                    fontWeight="medium"
                                    to={lineItem.tracking.tracking_url}
                                  >
                                    Tracking Link
                                  </Link>
                                )}
                              </SoftTypography>
                            }
                          />
                        )}
                        {lineItem.type === "inventory" &&
                          !lineItem.complete && (
                            <Box pt={5}>
                              <SoftButton
                                variant="contained"
                                disabled={markingAsComplete}
                                fullWidth
                                onClick={() => {
                                  setMarkingAsComplete(true)
                                  Axios.patch(
                                    `/api/staff-line-item/${lineItem.id}/`,
                                    { complete: true },
                                    navigate
                                  )
                                    .then(({ data }) => {
                                      lineItem.complete = data.complete
                                      orderData.status = data.order_status
                                      orderData.status_display =
                                        data.order_status_display
                                      setOrderData({ ...orderData })
                                      setMarkingAsComplete(false)
                                    })
                                    .catch((e) => {
                                      setMarkingAsComplete(false)
                                      dispatch(
                                        updateToastData({
                                          message:
                                            "Complete could not be updated.",
                                          severity: TOAST_SEVERITY_ERROR,
                                        })
                                      )
                                    })
                                }}
                              >
                                Mark as Complete
                              </SoftButton>
                            </Box>
                          )}

                        {product?.options?.length > 0 && (
                          <DescriptionItem
                            spacing={"tight"}
                            keyJsx={
                              <SoftTypography variant="caption" color="text">
                                Options
                              </SoftTypography>
                            }
                            valueJsx={
                              <React.Fragment>
                                {product.options.map((option, i) => {
                                  return (
                                    <SoftTypography
                                      key={i}
                                      variant="caption"
                                      fontWeight="medium"
                                    >
                                      &nbsp;&nbsp;&nbsp; {option.value}{" "}
                                      {getCurrencyStringFromNumber(
                                        option.price
                                      )}
                                    </SoftTypography>
                                  )
                                })}
                              </React.Fragment>
                            }
                          />
                        )}
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              )
            })}
          </SoftBox>
        </SoftBox>
      </Card>
    </Grid>
  )
}

export default LineItemsJsx
