import React, { lazy, useEffect, useRef, useState } from "react"

import TextField from "@mui/material/TextField"
import Axios from "../../../Util/Axios"
import { useNavigate } from "react-router-dom"
import Typography from "@mui/material/Typography"
import { updateToastData } from "../../../slices/toastSlice"
import { useDispatch } from "react-redux"
import Box from "@mui/material/Box"
import {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../../../components/core/Toast/Toast"
import SkeletonCard from "../../../components/core/SkeletonCard/SkeletonCard"
import CustomOrderButton from "../CustomOrderButton"
import IconButton from "@mui/material/IconButton"
import ImageIcon from "@mui/icons-material/Image"
import Button from "@mui/material/Button"
import ClearIcon from "@mui/icons-material/Clear"
import "./CustomOrderComment.css"
import "react-medium-image-zoom/dist/styles.css"
import { uploadFileToAws } from "../../../Util/Aws"
import { createFilePreview } from "../../../Util/Util"

// import Zoom from "react-medium-image-zoom"
const Zoom = lazy(() => import("react-medium-image-zoom")) // Necessary for sitemap

const CustomOrderComment = ({
  orderData,
  setOrderData,
  isStaff,
  requiresApproval,
  setRequiresApproval,
  customerFacingId,
  presetEditor,
  editingPresetId,
  presetComment,
  setPresetComment,
}) => {
  let comment = orderData?.comment || presetComment

  const [newMessage, setNewMessage] = useState(presetComment?.text || "")
  const dispatch = useDispatch()
  const fileUploadRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    setNewMessage(comment?.text || "")

    let existingFiles = []
    for (let messageAttachment of comment?.message_attachments || []) {
      existingFiles.push({
        ...messageAttachment,
      })
    }
    setFiles(existingFiles || [])
  }, [orderData, presetComment])

  const updateMessage = (updatedFiles) => {
    let url = `/api/order-detail/${orderData?.customer_facing_id}/action_update_comment/`
    if (isStaff) {
      url = `/api/staff-order-detail/${orderData?.customer_facing_id}/action_update_comment/`
    }
    if (presetEditor) {
      url = `/api/staff-preset-detail/${editingPresetId}/action_update_comment/`
    }
    if (newMessage === comment?.text && updatedFiles.length === 0) {
      return
    }

    let promises = []
    let existingFiles = []
    for (let file of updatedFiles) {
      if (file.file) {
        promises.push(uploadFileToAws(file.file))
      } else {
        existingFiles.push(file)
      }
    }

    setLoading(true)
    Axios.all(promises)
      .then((resps) => {
        Axios.post(
          url,
          {
            urls: resps,
            existing_files: existingFiles,
            text: newMessage,
          },
          navigate
        )
          .then(({ data }) => {
            if (presetEditor) {
              setPresetComment(data.comment)
            } else {
              orderData.comment = data.comment
              setRequiresApproval(data.requires_approval)
            }

            setFiles(data?.comment?.message_attachments || [])
            setLoading(false)
            if (orderData) {
              setOrderData({ ...orderData })
            }
          })
          .catch((e) => {
            setLoading(false)
            dispatch(
              updateToastData({
                message: e,
                severity: TOAST_SEVERITY_ERROR,
              })
            )
          })
      })
      .catch((e) => {
        setLoading(false)
        dispatch(
          updateToastData({
            message: "Error Creating Comment",
            severity: TOAST_SEVERITY_ERROR,
          })
        )
      })
  }

  // if (!orderData && !presetEditor) {
  //   return <SkeletonCard />
  // }
  // if (orderData?.type !== "custom" && !presetEditor) {
  //   return <React.Fragment />
  // }

  let disabled =
    loading ||
    (orderData?.locked && !presetEditor) ||
    (!orderData && !presetEditor)
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Comment</Typography>
        {!isStaff && (
          <IconButton
            color="primary"
            disabled={disabled}
            onClick={() => {
              fileUploadRef.current.click()
            }}
          >
            <ImageIcon />
          </IconButton>
        )}
      </div>
      <input
        style={{ display: "none" }}
        ref={fileUploadRef}
        type="file"
        multiple
        onChange={(event) => {
          const newFiles = Array.from(event.target.files)
          Promise.all(newFiles.map(createFilePreview)).then((previews) => {
            let filesWithPreviews = files.concat(
              newFiles.map((file, i) => ({
                file,
                preview: previews[i],
              }))
            )
            setFiles(filesWithPreviews)
            updateMessage(filesWithPreviews)
          })
        }}
      />

      <Box py={1} sx={{ display: "flex", flexDirection: "column" }}>
        <div>
          {!isStaff && (
            <TextField
              fullWidth
              placeholder={"Ask questions or specify what you want."}
              multiline={true}
              disabled={disabled}
              minRows={4}
              value={newMessage}
              onBlur={() => {
                updateMessage(files)
              }}
              onChange={(event) => {
                setNewMessage(event.target.value)
              }}
            />
          )}
          {isStaff && (
            <Typography variant="caption" fontWeight="regular" color="text">
              {comment.text}
            </Typography>
          )}
        </div>
        <Box pt={3}>
          <div className="file-previews">
            {files.map((fileObj, i) => (
              <div key={i} className="file-preview">
                <Zoom>
                  <img
                    src={fileObj.preview || fileObj.url}
                    alt="File preview"
                  />
                </Zoom>

                {!disabled && (
                  <IconButton
                    component={Button}
                    style={{
                      color: "#fc3158",
                    }}
                    onClick={() => {
                      // Remove the file from the array
                      let newFiles = files.filter((_, index) => index !== i)
                      setFiles(newFiles)
                      updateMessage(newFiles)
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        </Box>
      </Box>
    </div>
  )
}

export default CustomOrderComment
