import React, { useEffect, useState } from "react"
import { Grid, TableCell, TableRow, Typography } from "@mui/material"
import TextField from "@mui/material/TextField"
import { getCurrencyStringFromNumber } from "../../Util/Util"

import { TOAST_SEVERITY_ERROR } from "../../components/core/Toast/Toast"
import Axios from "../../Util/Axios"
import { updateToastData } from "../../slices/toastSlice"
import { useDispatch, useSelector } from "react-redux"
import Cookies from "js-cookie"
import {
  removeFromCartAction,
  updateCartItemQuantity,
  updateCartItemsAction,
} from "../../slices/cartSlice"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import Box from "@mui/material/Box"
const CartItemJsx = ({ cartItem }) => {
  let productVariant = cartItem.product_variant
  let product = cartItem.product
  const [loading, setLoading] = useState(false)
  const [quantity, setQuantity] = useState(cartItem.quantity)
  const [potentialQuantity, setPotentialQuantity] = useState(cartItem.quantity)
  const dispatch = useDispatch()
  const { cart } = useSelector((state) => state)
  const cartItems = cart.cart_items

  let image
  let title
  let description
  let price
  if (productVariant) {
    image = productVariant.product_variant_images[0].url
    title = productVariant.name
    description = productVariant.description
    price = productVariant.price
  } else if (product) {
    image = product.display_image
    title = product.name
    description = product.description
    price = product.price
  }
  useEffect(() => {
    setQuantity(cartItem.quantity)
  }, [])

  const isApparel = !!productVariant

  const postQuantity = (newQuantity) => {
    const cartId = Cookies.get("cart_id")
    if (quantity === newQuantity) {
      return
    }
    setLoading(true)
    Axios.post(`/api/cart-detail/${cartId}/update_cart_quantity/`, {
      cart_item_id: cartItem.id,
      quantity: newQuantity,
    })
      .then(({ data }) => {
        setLoading(false)
        if (newQuantity === "0") {
          dispatch(removeFromCartAction(cartItem.id))
        } else {
          dispatch(
            updateCartItemQuantity({
              cart_item_id: cartItem.id,
              quantity: newQuantity,
            })
          )
        }
        setQuantity(newQuantity)
      })
      .catch((e) => {
        setLoading(false)
        dispatch(
          updateToastData({
            message: e,
            severity: TOAST_SEVERITY_ERROR,
          })
        )
        setPotentialQuantity(quantity)
      })
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <img src={image} alt={title} style={{ width: "60px" }} />
          </Grid>
          <Grid item xs={10}>
            <Typography
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Josefin Sans",
              }}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left">{isApparel ? "Apparel" : "Inventory"}</TableCell>

      <TableCell align="left">
        <Grid container alignItems="center">
          {isApparel && (
            <Grid item xs>
              <TextField
                sx={{ width: "60px" }}
                margin="dense"
                disabled={loading}
                required
                type={"number"}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                value={potentialQuantity}
                onChange={(event) => {
                  setPotentialQuantity(event.target.value)
                }}
                onBlur={(event) => {
                  postQuantity(event.target.value)
                }}
              />
            </Grid>
          )}

          {!isApparel && (
            <Grid item xs>
              {quantity}
            </Grid>
          )}

          <Grid item>
            <Box>
              <IconButton
                edge="end"
                disabled={loading}
                aria-label="delete"
                onClick={() => {
                  postQuantity("0")
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </TableCell>

      <TableCell align="right">
        {getCurrencyStringFromNumber(price * quantity)}
      </TableCell>
    </TableRow>
  )
}

export default CartItemJsx
