import SoftBox from "soft_dashboard/components/SoftBox"
import SoftTypography from "soft_dashboard/components/SoftTypography"

import DashboardLayout from "soft_dashboard/examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "soft_dashboard/examples/Navbars/DashboardNavbar"

import React from "react"
import Time from "../../core/Time/Time"
import Footer from "../../core/Footer/Footer"
import OrderStatusBadge from "../OrderStatusBadge"
import StaffDataTable from "../../core/StaffDataTable/StaffDataTable"
import Axios from "../../../Util/Axios"
import { updateToastData } from "../../../slices/toastSlice"
import {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../../core/Toast/Toast"

const StaffOrderList = () => {
  const columns = [
    {
      name: "id",
      align: "left",
    },
    {
      name: "customer_facing_id",
      align: "left",
    },
    {
      name: "created_at",
      align: "left",
    },
    {
      name: "name",
      align: "left",
    },
    {
      name: "type",
      align: "left",
    },
    {
      name: "status",
      align: "left",
    },
    {
      name: "summary",
      align: "left",
    },
    {
      name: "complete_at",
      align: "left",
    },
    {
      name: "user_page",
      align: "left",
    },
    {
      name: "staff_page",
      align: "left",
    },
  ]
  const getRow = (orderData) => {
    return {
      id: (
        <SoftBox ml={2}>
          <SoftTypography variant="button" fontWeight="medium">
            {orderData.id}
          </SoftTypography>
        </SoftBox>
      ),
      customer_facing_id: (
        <SoftBox ml={2}>
          <SoftTypography variant="button" fontWeight="medium">
            {orderData.customer_facing_id}
          </SoftTypography>
        </SoftBox>
      ),
      name: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {orderData.address_full_name}
        </SoftTypography>
      ),
      created_at: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          <Time>{orderData.created_at}</Time>
        </SoftTypography>
      ),
      type: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {orderData.type_display}
        </SoftTypography>
      ),
      status: (
        <SoftBox>
          <OrderStatusBadge
            status={orderData.status}
            statusDisplay={orderData.status_display}
          />
        </SoftBox>
      ),
      summary: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {orderData.summary}
        </SoftTypography>
      ),
      complete_at: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {orderData.complete_at && <Time>{orderData.complete_at}</Time>}
        </SoftTypography>
      ),
      user_page: (
        <SoftBox ml={2}>
          <SoftTypography
            component="a"
            href={`/custom-surfboard-order-staff/${orderData?.customer_facing_id}/`}
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            View
          </SoftTypography>
        </SoftBox>
      ),
      staff_page: (
        <SoftBox ml={2}>
          <SoftTypography
            component="a"
            href={`/staff/orders/${orderData.customer_facing_id}`}
            variant="caption"
            color="primary"
            fontWeight="medium"
          >
            View
          </SoftTypography>
        </SoftBox>
      ),
    }
  }

  const dataUrl = "/api/staff-order-list/"

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <StaffDataTable
            title={"Orders"}
            columns={columns}
            getRow={getRow}
            dataUrl={dataUrl}
            actions={[
              {
                name: "Create Order",
                color: "primary",
                url: "/staff/orders/create/",
                onAction: () => {
                  Axios.post("/api/staff-order-detail/").then(({ data }) => {
                    console.log(data)
                    window.open(
                      `/custom-surfboard-order-staff/${data.customer_facing_id}/`
                    )
                  })
                },
              },
            ]}
            filters={[
              {
                title: "Status",
                title_key: "status",
                options: [
                  {
                    key: "paid_and_in_progress",
                    value: "Paid And In Progress",
                  },
                  {
                    key: "draft",
                    value: "Draft",
                  },
                  {
                    key: "not_draft",
                    value: "Not Draft",
                  },
                  {
                    key: "awaiting_confirmation",
                    value: "Awaiting Confirmation",
                  },
                  {
                    key: "confirmed",
                    value: "Confirmed",
                  },
                  {
                    key: "deposit_received_and_in_progress",
                    value: "Deposit Received and In Progress",
                  },
                  {
                    key: "ready_for_pickup",
                    value: "Ready for Pickup",
                  },
                  {
                    key: "complete_at",
                    value: "Complete At",
                  },
                ],
              },
            ]}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}

export default StaffOrderList
