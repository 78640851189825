import React, { useEffect, useState } from "react"
import { Box, IconButton, Typography } from "@mui/material"
import CustomOrderLogoSelect from "../CustomOrderLogoSelect"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Collapse } from "@mui/material"
const Logo = ({
  updateOptions,
  selectedLogoOption,
  setSelectedLogoOption,
  logoOptions,
  orderData,
  isStaff,
}) => {
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <Box
        pt={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Logo</Typography>
        <IconButton onClick={handleClick}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse in={open}>
        <CustomOrderLogoSelect
          isStaff={isStaff}
          selectedLogoOption={selectedLogoOption}
          setSelectedLogoOption={setSelectedLogoOption}
          logoOptions={logoOptions}
          updateOptions={updateOptions}
          orderData={orderData}
        />
      </Collapse>
    </React.Fragment>
  )
}

export default Logo
