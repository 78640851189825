import * as React from "react"

const FishSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  // let heightScale = 1

  let widthScale = getWidthScaleFactor(originalWidthInches)
  let height = 932.953 * heightScale
  let width = 328.484 * widthScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M164.899 829.386s0 11.875-25 46-57 38-57 38-58-130-61-495 143-401 143-401 146.001 36 143.001 401c-3.001 365-61.001 495-61.001 495s-32-3.875-57-38-25-46-25-46z"></path>
      </g>

      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path
          id="DeckColor_1_"
          d="M93.329 910.714S33.94 776.055 31.133 419.469C28.327 62.886 164.899 27.716 164.899 27.716s136.572 35.17 133.767 391.753c-2.808 356.585-62.196 491.245-62.196 491.245"
        ></path>
      </g>

      <g
        id="TailLine"
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        opacity={0.2}
        strokeMiterlimit={10}
        strokeWidth={3}
      >
        <path d="M246.898,913.386c0,0-32-3.875-57-38s-25-46-25-46 s0,11.875-25,46c-25,34.125-57,38-57,38" />

        <path d="M228.871,907.689c0,0-15.462-4.379-37.678-30.753 c-16.435-19.511-26.295-47.551-26.295-47.551s0.545,12.281-25,46c-21.156,27.925-39.683,32.622-39.683,32.622" />
      </g>

      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <path d="M264.587 130.486L264.587 856.992"></path>
          <path d="M65.503 130.486L65.503 856.992"></path>
        </g>
      )}

      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <path d="M149.963 22.726L149.963 860.221"></path>
          <path d="M180.127 22.726L180.127 860.221"></path>
        </g>
      )}

      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) &&
        selectedNumberOfStringersOption?.value && (
          <g
            transform={`scale(${widthScale}, ${heightScale})`}
            id="StringerSingle"
            stroke={stringerColor}
            strokeWidth="4"
            strokeMiterlimit="10"
          >
            <path d="M165.045 17.386L165.045 838.66"></path>
          </g>
        )}

      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M164.99 233.002s-.377 35.165-39.229 99.267-101.254 56.743-101.254 56.743-.209 11.377-1.829 73.724C21.06 525.083 43.595 743 43.595 743s27.813 171.033 39.598 170.386c50.543-2.777 81.048-80.576 81.048-80.576s24.184 67.508 82.658 80.576c7.542 1.686 37.296-158.577 37.296-158.577s24.845-229.726 23.228-292.072c-1.621-62.347-1.828-73.724-1.828-73.724s-62.403 7.36-101.252-56.743c-38.855-64.103-39.232-99.267-39.232-99.267"></path>
        </g>
      )}

      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M165.05 277.234l-63.071 60.256-77.201 77.289s-.208 10.03-1.823 64.998c-1.614 54.968 19.616 256.01 19.616 256.01l32.214 159.87 5.978 19.019 47.475-22.242 36.003-59.624 35.167 56.833 47.019 25.033 8.014-14.662 29.016-138.024s25.303-227.244 23.689-282.212c-1.616-54.968-1.823-64.998-1.823-64.998l-74.577-72.364-65.696-65.182z"></path>
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            id="RoundDeckPatch"
            d="M24.458 377.497s45.844-71.806 140.029-72.967c94.188-1.161 140.692 67.357 140.692 67.357v201.314l-27.771 235.776s-20.139 76.044-30.51 104.408c-.472 1.29-37.547-17.666-44.396-23.624-12.505-10.879-37.458-51.102-39.175-54.097 0 0-23.306 41.936-33.574 51.682-5.095 4.836-38.101 38.55-48.595 21.743-18.752-30.032-24.906-92.467-24.906-92.467S9.409 534.311 24.458 377.497z"
          ></path>
        </g>
      )}

      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M165.851 832.81c.656 17.3-54.688 80.576-82.658 80.576-11.701 0-43.956-205.299-43.956-205.299l125.662-76.826 125.955 76.826s-24.955 191.3-43.955 205.299c-19.157 10.258-83.315-63.276-82.658-80.576"></path>
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M162.127 723.585l134.504-73.639s-13.183 139.065-26.768 183.988c-13.173 43.561-18.243 80.218-28.242 77.773-57.524-14.063-77.38-78.898-77.38-78.898-36.167 86.169-83.673 80.576-83.673 80.576s-7.056-24.472-17.356-62.161C47.76 794.686 32.718 649.946 32.718 649.946l129.409 73.639z"></path>
        </g>
      )}

      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M165.851 832.81s-20.443 65.894-74.827 80.934c-15.323 4.238-21.122-39.101-27.055-59.716-8.904-30.941-28.782-175.5-28.782-175.5s12.965.241 62.396-11.488c49.43-11.732 68.692-57.093 68.692-57.093h.565s16.574 45.36 66.007 57.093c49.432 11.729 60.271 10.962 60.271 10.962S274.596 823.868 265.68 854.42c-7.613 26.093-17.51 59.929-21.597 58.608-63.956-20.67-78.232-80.218-78.232-80.218"></path>
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M164.723 745.584s11.967-49.104 45.481-67.093c33.512-17.987 83.621-26.905 84.368-23.972.744 2.936-8.045 110.377-16.982 141.21-8.938 30.833-14.245 68.504-21.04 89.858a612.452 612.452 0 01-9.651 27.798l-44.824-21.073-37.029-53.652-30.81 47.8-47.876 28.611s-12.338-30.073-18.919-54.851c-11.426-43.009-25.062-137.51-26.289-152.894-1.227-15.38-5.668-53.365-5.668-53.365s71.881 9.914 94.572 30.378c22.692 20.464 35.294 47.11 34.667 61.245z"></path>
        </g>
      )}

      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M36.419 682.968s54.541-27.712 126.918-26.992c72.375.719 129.781 22.025 129.781 22.025S277.09 815.088 261.72 860.442c-13.391 39.511-15.293 54.233-15.293 54.233l-28.754-11.62-25.514-21.293-27.918-48.953-26.311 46.832-25.513 23.414-31.654 11.62s-3.472-24.931-14.619-58.424c-17.809-53.517-27.631-157.893-29.725-173.283z"></path>
        </g>
      )}

      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M80.028,787.169c-0.087,2.8-2.444,5.006-5.264,4.925l-7.195-0.211 c-2.821-0.082-5.038-2.413-4.952-5.215l1.781-57.526c0.086-2.798,2.441-5,5.262-4.916l7.197,0.207 c2.821,0.083,5.037,2.411,4.951,5.214L80.028,787.169z" />
          <path d="M266.622,786.672c0.086,2.798-2.13,5.132-4.947,5.212 l-7.198,0.206c-2.822,0.081-5.177-2.123-5.265-4.923l-1.785-57.527c-0.087-2.797,2.125-5.132,4.946-5.214l7.199-0.207 c2.815-0.077,5.177,2.125,5.267,4.925L266.622,786.672z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M79.443,769.586c-0.075,2.842-2.141,5.08-4.612,5.001 l-6.306-0.218c-2.474-0.085-4.415-2.451-4.34-5.298l1.56-58.433c0.075-2.842,2.14-5.078,4.613-4.992l6.307,0.207 c2.472,0.087,4.417,2.454,4.34,5.3L79.443,769.586z" />
          <path d="M98.42,843.73c0,2.843-2.173,5.146-4.853,5.146h-6.84 c-2.682,0-4.854-2.303-4.854-5.146v-58.456c0-2.842,2.172-5.148,4.854-5.148h6.84c2.68,0,4.853,2.307,4.853,5.148V843.73z" />
          <path d="M265.598,769.076c0.074,2.843-1.868,5.215-4.336,5.299 l-6.308,0.208c-2.473,0.082-4.539-2.157-4.614-4.999l-1.564-58.44c-0.076-2.842,1.862-5.213,4.335-5.296l6.309-0.206 c2.471-0.079,4.538,2.155,4.614,4.999L265.598,769.076z" />
          <path d="M248.772,843.73c0,2.843-2.177,5.146-4.856,5.146h-6.842 c-2.677,0-4.854-2.303-4.854-5.146v-58.456c0-2.842,2.176-5.148,4.854-5.148h6.842c2.68,0,4.856,2.307,4.856,5.148V843.73z" />
        </g>
      )}
      <g id="Logo" transform={`scale(${widthScale}, ${heightScale})`}>
        <image href={logoUrl} x="116.899" y="518.219" width="96" height="96" />
      </g>

      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d="M98.979 905.788s-54.701-132.14-57.286-482.054C39.107 73.823 164.899 39.312 164.899 39.312S290.69 73.823 288.107 423.734c-2.587 349.913-57.287 482.054-57.287 482.054"></path>
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="4"
        opacity={outlineOpacity}
      >
        <path d="M164.899 829.386s0 11.875-25 46-57 38-57 38-58-130-61-495 143-401 143-401 146.001 36 143.001 401c-3.001 365-61.001 495-61.001 495s-32-3.875-57-38-25-46-25-46z"></path>
      </g>
    </svg>
  )
}
export default FishSvg
