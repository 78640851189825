import React, { useEffect, useState } from "react"
import DashboardLayout from "../../../soft_dashboard/examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "../../../soft_dashboard/examples/Navbars/DashboardNavbar"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import Grid from "@mui/material/Grid"
import Footer from "../../core/Footer/Footer"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom"
import Axios from "../../../Util/Axios"
import Address from "../../Address/Address"
import OrderMessages from "./OrderMessages"
import LineItemsJsx from "./LineItemsJsx"
import OrderInfo from "./OrderInfo"
import OrderOptions from "./OrderOptions/OrderOptions"
import Transactions from "../../Transactions/Transactions"
import { updateToastData } from "../../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../../core/Toast/Toast"
import { useDispatch } from "react-redux"
import CustomOrderChat from "../../../MarlinSurf/CustomOrder/CustomOrderChat/CustomOrderChat"
import CustomOrderComment from "../../../MarlinSurf/CustomOrder/CustomOrderComment/CustomOrderComment"
import Card from "@mui/material/Card"

const StaffOrderPage = ({ match }) => {
  const { customer_facing_id } = useParams()
  const [orderData, setOrderData] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const getData = () => {
    Axios.get(`/api/staff-order-detail/${customer_facing_id}/`, navigate)
      .then(({ data }) => {
        setOrderData(data)
      })
      .catch((e) => {
        dispatch(
          updateToastData({
            message: "Error getting order",
            severity: TOAST_SEVERITY_ERROR,
          })
        )
      })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={`Order #${orderData?.id || ""}`} />
      <SoftBox mt={4}>
        <SoftBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container direction="column" spacing={3}>
                {/*<OrderMessages*/}
                {/*  orderData={orderData}*/}
                {/*  setOrderData={setOrderData}*/}
                {/*  isStaff={true}*/}
                {/*/>*/}

                <OrderOptions
                  orderData={orderData}
                  setOrderData={setOrderData}
                />
                <LineItemsJsx
                  orderData={orderData}
                  setOrderData={setOrderData}
                />

                {orderData?.comment && (
                  <Grid item>
                    <Card>
                      <SoftBox p={2}>
                        <CustomOrderComment
                          orderData={orderData}
                          setOrderData={setOrderData}
                          isStaff={true}
                          // senderEmail={email}
                          // requiresApproval={requiresApproval}
                          // setRequiresApproval={setRequiresApproval}
                          // customerFacingId={customerFacingId}
                        />
                      </SoftBox>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <OrderInfo
                    orderData={orderData}
                    setOrderData={setOrderData}
                  />
                </Grid>
                <Grid item>
                  <Address orderData={orderData} />
                </Grid>
                {!["draft", "awaiting_confirmation"].includes(
                  orderData?.status
                ) && (
                  <Grid item>
                    <Transactions
                      setOrderData={setOrderData}
                      orderData={orderData}
                      getData={getData}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
      {/*<CustomOrderChat*/}
      {/*  orderData={orderData}*/}
      {/*  isStaff={true}*/}
      {/*  // senderEmail={email}*/}
      {/*/>*/}
    </DashboardLayout>
  )
}

export default StaffOrderPage
