import React, { useEffect, useState } from "react"
import { Box, Button, IconButton, Typography } from "@mui/material"
import CustomOrderColorOption from "../CustomOrderColorOption"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Collapse } from "@mui/material"
const Colors = ({
  updateOptions,
  selectedFinBoxColorOption,
  setSelectedFinBoxColorOption,
  setAddColorModalActive,
  finBoxColorOptions,
  selectedBottomColorOption,
  setSelectedBottomColorOption,
  bottomColorOptions,
  selectedDeckColorOption,
  setSelectedDeckColorOption,
  deckColorOptions,
  allowedDeckColorOptions,
  // selectedMidBandColorOption,
  // setSelectedMidBandColorOption,
  // midBandColorOptions,
  // selectedTailDipColorOption,
  // setSelectedTailDipColorOption,
  // tailDipColorOptions,
  selectedPinLineColorOption,
  setSelectedPinLineColorOption,
  pinLineColorOptions,
  selectedAbstractColorOption,
  setSelectedAbstractColorOption,
  abstractColorOption,
  open,
  setOpen,
  orderData,
  isStaff,
}) => {
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">Colors</Typography>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            disabled={orderData?.locked && !isStaff}
            onClick={() => {
              setAddColorModalActive(true)
            }}
          >
            Add Color
          </Button>
          <IconButton onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={open}>
        <CustomOrderColorOption
          orderData={orderData}
          isStaff={isStaff}
          updateOptions={updateOptions}
          title={"Fin Box Color"}
          helpText={"Color of the Futures Fin Box(s)"}
          colorOption={selectedFinBoxColorOption}
          setColorOption={setSelectedFinBoxColorOption}
          colorOptions={finBoxColorOptions}
        />

        <React.Fragment>
          <CustomOrderColorOption
            orderData={orderData}
            isStaff={isStaff}
            updateOptions={updateOptions}
            title={"Bottom Color"}
            helpText={
              "Choose the color for the bottom of the board, color will overlap to the deck"
            }
            colorOption={selectedBottomColorOption}
            setColorOption={setSelectedBottomColorOption}
            setChildColorOption={setSelectedDeckColorOption}
            childColorOption={selectedDeckColorOption}
            childColorOptions={deckColorOptions}
            colorOptions={bottomColorOptions}
            showColorPicker={true}
          />
          <CustomOrderColorOption
            isStaff={isStaff}
            orderData={orderData}
            updateOptions={updateOptions}
            title={"Deck Color"}
            helpText={
              "Choose the color for the deck of the board. Options are clear or same color as the bottom color"
            }
            parentColorOption={selectedBottomColorOption}
            colorOption={selectedDeckColorOption}
            setColorOption={setSelectedDeckColorOption}
            colorOptions={allowedDeckColorOptions}
            showColorPicker={false}
            showColorOptions={true}
          />
          {/*<CustomOrderColorOption*/}
          {/*  isStaff={isStaff}*/}
          {/*  orderData={orderData}*/}
          {/*  updateOptions={updateOptions}*/}
          {/*  title={"Mid Band"}*/}
          {/*  helpText={*/}
          {/*    "Choose the color for a band around the mid back of the board"*/}
          {/*  }*/}
          {/*  colorOption={selectedMidBandColorOption}*/}
          {/*  setColorOption={setSelectedMidBandColorOption}*/}
          {/*  colorOptions={midBandColorOptions}*/}
          {/*  showColorPicker={true}*/}
          {/*/>*/}
          {/*<CustomOrderColorOption*/}
          {/*  isStaff={isStaff}*/}
          {/*  orderData={orderData}*/}
          {/*  updateOptions={updateOptions}*/}
          {/*  title={"Tail Dip"}*/}
          {/*  helpText={*/}
          {/*    "Choose the color for for a color dip on the tail of the board."*/}
          {/*  }*/}
          {/*  colorOption={selectedTailDipColorOption}*/}
          {/*  setColorOption={setSelectedTailDipColorOption}*/}
          {/*  colorOptions={tailDipColorOptions}*/}
          {/*  showColorPicker={true}*/}
          {/*/>*/}
          <CustomOrderColorOption
            isStaff={isStaff}
            orderData={orderData}
            updateOptions={updateOptions}
            title={"Pin Line"}
            helpText={
              "Choose the color for for a pin line on the rails of the board."
            }
            colorOption={selectedPinLineColorOption}
            setColorOption={setSelectedPinLineColorOption}
            colorOptions={pinLineColorOptions}
            showColorPicker={true}
          />
          <CustomOrderColorOption
            isStaff={isStaff}
            orderData={orderData}
            updateOptions={updateOptions}
            title={"Abstract Color"}
            helpText={
              "Multi tone resin tint abstract on one or both sides, explain and/or upload an image to show what you want"
            }
            colorOption={selectedAbstractColorOption}
            setColorOption={setSelectedAbstractColorOption}
            colorOptions={abstractColorOption}
            showColorPicker={false}
          />
        </React.Fragment>
      </Collapse>
    </React.Fragment>
  )
}

export default Colors
