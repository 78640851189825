import * as React from "react"

const HybridSquashSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps
  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 932.953

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M90.146,864.644 c0,0-65.249-121.151-68.249-467.143s143-380.115,143-380.115S310.9,51.511,307.9,397.5 c-3.002,345.99-67.404,467.476-67.404,467.476s-21.086,47.334-39.42,47.334s-69.334,0-69.334,0S110.55,911.645,90.146,864.644z" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path d=" M100.813,862.31c0,0-64.752-125.44-67.514-462.592c-2.76-337.145,131.6-370.398,131.6-370.398S299.26,62.572,296.5,399.717 c-2.764,337.147-67.525,462.593-67.525,462.593s-17.232,38.534-30.232,38.534c-22.334,0-68.667,0-68.667,0 S120.217,897.976,100.813,862.31z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M90.146,864.643 c0,0-65.249-121.151-68.249-467.143l142.344-76.656L307.9,397.5c-3.002,345.99-67.404,467.476-67.404,467.476 s-21.419,47.334-39.42,47.334s-69.334,0-69.334,0S110.55,910.642,90.146,864.643z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M90.146,864.643 c0,0-65.249-121.151-68.249-467.143c0.001,0,129.162-172.476,286.003,0c-3.002,345.99-67.404,467.476-67.404,467.476 s-22.087,47.334-39.42,47.334c-41.112,0-70.667,0-70.667,0S110.883,910.642,90.146,864.643z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M90.591,864.643 c0,0-65.249-121.151-68.249-467.143c0,0,52.178-9.19,91.511-32.524s51.463-58,51.463-58s15.33,34.745,48.024,56.667 c35.8,24,95.004,33.857,95.004,33.857c-3.002,345.99-67.403,467.476-67.403,467.476s-20.866,47.941-42.199,47.379 c-24.658-0.65-65.333-0.045-65.333-0.045S114.106,915.642,90.591,864.643z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.146,864.646 c0,0-36.271-67.349-55.97-245.014l131.167-36.498l130.421,36.498c-19.649,171.482-55.268,245.345-55.268,245.345 s-21.139,47.333-40.754,47.333s-68,0-68,0S111.883,912.647,90.146,864.646z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.146,864.646 c0,0-36.271-67.349-55.97-245.014l131.167,53.345l130.421-53.345c-19.649,171.482-55.268,245.345-55.268,245.345 s-20.087,47.667-40.754,47.667c-25.666,0-68-0.334-68-0.334S110.216,911.314,90.146,864.646z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path d="M90.146,864.646 c0,0-36.271-67.349-55.97-245.014c0,0,60.565-2.655,94.565-18.655s36.602-26.5,36.602-26.5s13.287,19.209,30.787,25.709 c24.374,9.053,99.634,19.446,99.634,19.446c-19.649,171.482-55.268,245.345-55.268,245.345s-21.419,47.333-39.42,47.333 s-69.334,0-69.334,0S110.55,911.98,90.146,864.646z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.417,864.826 c0,0-37.271-67.349-56.97-245.014c0,0,89.565-4.653,107.065,19.347s24.384,40.653,24.384,40.653s10.149-21.942,34.49-40.674 c25.355-19.507,95.648-19.326,95.648-19.326c-17.293,171.498-54.268,245.347-54.268,245.347s-21.358,47.15-41.025,47.15s-68,0-68,0 S110.425,911.342,90.417,864.826z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.148,864.645 c0,0-32.837-60.974-52.992-219.934c-1.027-8.104-2.022-16.462-2.978-25.08c-0.001,0.001,125.939-82.006,261.585,0.001 c0,0-1.712,14.497-2.605,21.479c-20.026,156.188-52.66,223.864-52.66,223.864s-20.423,47.334-40.756,47.334s-68,0-68,0 S111.22,911.979,90.148,864.645z" />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={264.587} y1={127.928} x2={264.587} y2={794.595} />
          <line x1={65.503} y1={127.928} x2={65.503} y2={794.595} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={149.963} y1={22.376} x2={149.963} y2={911.184} />
          <line x1={180.127} y1={22.376} x2={180.127} y2={911.184} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.045} y1={17.386} x2={165.045} y2={912.31} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M173.396,831.474c0,2.892-2.129,5.319-4.56,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V688.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.56,2.434,4.56,5.318V831.474z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M173.396,831.474c0,2.892-2.129,5.319-4.56,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V688.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.56,2.434,4.56,5.318V831.474z" />
          <path d="M75.386,717.686c-0.304,2.889-2.583,5.015-5.166,4.56 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,717.686z" />
          <path d="M272.169,715.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064 c2.432-0.456,4.862,1.521,5.317,4.406L272.169,715.104z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M75.386,717.989c-0.304,2.887-2.583,5.015-5.166,4.559 l-6.384-0.912c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.013,5.167-4.559l6.381,0.913 c2.583,0.304,4.255,3.038,3.951,5.924L75.386,717.989z" />
          <path d="M272.169,715.404c0.455,2.889-1.37,5.624-3.8,6.079l-6.385,1.063 c-2.582,0.456-4.861-1.52-5.317-4.408l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.078l6.382-1.063 c2.432-0.455,4.862,1.521,5.317,4.405L272.169,715.404z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,717.686c-0.304,2.889-2.583,5.015-5.166,4.56 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,717.686z" />
          <path d="M272.169,715.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064 c2.432-0.456,4.862,1.521,5.317,4.406L272.169,715.104z" />
          <path d="M173.094,852.479c0,2.89-2.129,5.317-4.559,5.317H162 c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.316,4.559-5.316h6.535c2.583,0,4.559,2.43,4.559,5.316V852.479z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,717.686c-0.304,2.889-2.583,5.015-5.166,4.56 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,717.686z" />
          <path d="M106.658,804.299c0,2.892-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.432-5.415-5.319v-60.021c0-2.89,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V804.299z" />
          <path d="M272.169,715.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064 c2.432-0.456,4.862,1.521,5.317,4.406L272.169,715.104z" />
          <path d="M248.695,802.78c0,2.888-2.525,5.316-5.414,5.316h-7.762 c-3.066,0-5.414-2.431-5.414-5.316v-60.022c0-2.887,2.528-5.316,5.414-5.316h7.762c3.068,0,5.414,2.432,5.414,5.316V802.78z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M173.396,831.474c0,2.892-2.129,5.319-4.56,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V688.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.56,2.434,4.56,5.318V831.474z" />
          <path d="M75.386,717.686c-0.304,2.889-2.583,5.015-5.166,4.56 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,717.686z" />
          <path d="M106.658,804.299c0,2.892-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.432-5.415-5.319v-60.021c0-2.89,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V804.299z" />
          <path d="M272.169,715.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064 c2.432-0.456,4.862,1.521,5.317,4.406L272.169,715.104z" />
          <path d="M248.695,802.78c0,2.888-2.525,5.316-5.414,5.316h-7.762 c-3.066,0-5.414-2.431-5.414-5.316v-60.022c0-2.887,2.528-5.316,5.414-5.316h7.762c3.068,0,5.414,2.432,5.414,5.316V802.78z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,717.686c-0.304,2.889-2.583,5.015-5.166,4.56 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,717.686z" />
          <path d="M106.658,804.299c0,2.892-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.432-5.415-5.319v-60.021c0-2.89,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V804.299z" />
          <path d="M272.169,715.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064 c2.432-0.456,4.862,1.521,5.317,4.406L272.169,715.104z" />
          <path d="M248.695,802.78c0,2.888-2.525,5.316-5.414,5.316h-7.762 c-3.066,0-5.414-2.431-5.414-5.316v-60.022c0-2.887,2.528-5.316,5.414-5.316h7.762c3.068,0,5.414,2.432,5.414,5.316V802.78z" />
          <path d="M173.094,852.479c0,2.89-2.129,5.317-4.559,5.317H162 c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.316,4.559-5.316h6.535c2.583,0,4.559,2.43,4.559,5.316V852.479z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={492.133}
          width={96}
          height={91.001}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d=" M110.25,854.561c0,0-66.01-105.585-65.972-447.984C41.749,74.405,164.898,41.644,164.898,41.644S288.05,74.406,285.52,406.575 c-2.531,332.17-67.109,450.069-67.109,450.069s-16.335,33.093-22.668,33.093s-58-0.046-58-0.046S131.091,892.478,110.25,854.561z" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d=" M90.146,864.644c0,0-63.404-93.002-68.249-467.143c-4.479-345.974,143-380.115,143-380.115S310.9,51.511,307.9,397.5 c-3.002,345.99-67.404,467.476-67.404,467.476s-19.557,47.604-48.754,47.604s-52-0.193-52-0.193S118.742,918.311,90.146,864.644z" />
      </g>
    </svg>
  )
}
export default HybridSquashSvg
