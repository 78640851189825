import React, { useEffect, useState } from "react"

import TextField from "@mui/material/TextField"
import { Checkbox, FormControlLabel, InputAdornment } from "@mui/material"

import { useDispatch } from "react-redux"
import Modal from "../../core/Modal/Modal"
import { updateToastData } from "../../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../../core/Toast/Toast"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"
import { getCurrencyStringFromNumber } from "../../../Util/Util"
import Axios from "../../../Util/Axios"

const AdditionalChargeModal = ({
  orderData,
  setOrderData,
  active,
  setActive,
}) => {
  const [amount, setAmount] = useState(0)
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setAmount(0)
    setName("")
    setDescription("")
  }, [active])

  return (
    <Modal
      title={"Additional Charge"}
      confirmDisabled={loading}
      onConfirm={() => {
        setLoading(true)
        Axios.post(
          `/api/staff-order-detail/${orderData.customer_facing_id}/action_create_additional_charge/`,
          {
            name: name,
            amount: amount,
            description: description,
          }
        )
          .then(({ data }) => {
            setLoading(false)
            setOrderData(data)
            setActive(false)
          })
          .catch((e) => {
            setLoading(false)
            dispatch(
              updateToastData({
                message: e,
                severity: TOAST_SEVERITY_ERROR,
              })
            )
          })
      }}
      active={active}
      setActive={setActive}
      confirmButtonName={"Create"}
    >
      <SoftBox pb={3}>
        <SoftTypography variant={"h6"}>
          Add an additional charge for something complicated.
        </SoftTypography>
      </SoftBox>

      <TextField
        disabled={loading}
        margin="normal"
        fullWidth
        label={"Amount Subtotal"}
        required
        value={amount}
        type={"number"}
        InputProps={{
          // inputProps: { min: 0 },
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onChange={(event) => {
          // let newAmountSubtotal = event.target.value
          // if (newAmountSubtotal < 0) {
          //   setAmount(0)
          // } else {
          setAmount(event.target.value)
          // }
        }}
      />
      <TextField
        disabled={loading}
        margin="normal"
        fullWidth
        label={"Name"}
        required
        value={name}
        onChange={(event) => {
          setName(event.target.value)
        }}
      />
      <TextField
        disabled={loading}
        margin="normal"
        fullWidth
        label={"Description"}
        multiline={true}
        minRows={4}
        value={description}
        onChange={(event) => {
          setDescription(event.target.value)
        }}
      />
    </Modal>
  )
}

export default AdditionalChargeModal
