import React from "react"
import { ListManager } from "react-beautiful-dnd-grid"
import SoftBox from "../../../soft_dashboard/components/SoftBox"

const ImageDrag = ({
  setPositionUpdated,
  imageComponent,
  draggedImages,
  setDraggedImages,
  direction = "horizontal",
}) => {
  const sortList = () => {
    setDraggedImages(
      draggedImages
        .slice()
        .sort((first, second) => first.position - second.position)
    )
  }

  const reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return
    }

    const list = draggedImages

    if (destinationIndex === 0) {
      list[sourceIndex].position = list[0].position - 1
      sortList()
      setPositionUpdated(true)
      return
    }

    if (destinationIndex === list.length - 1) {
      list[sourceIndex].position = list[list.length - 1].position + 1
      sortList()
      setPositionUpdated(true)
      return
    }

    if (destinationIndex < sourceIndex) {
      list[sourceIndex].position =
        (list[destinationIndex].position +
          list[destinationIndex - 1].position) /
        2
      sortList()
      setPositionUpdated(true)
      return
    }

    list[sourceIndex].position =
      (list[destinationIndex].position + list[destinationIndex + 1].position) /
      2
    sortList()
    setPositionUpdated(true)
  }
  let display = "flex"
  if (direction === "vertical") {
    display = "block"
  }
  return (
    <SoftBox mb={2}>
      <ListManager
        items={draggedImages}
        direction={direction}
        maxItems={4}
        render={(imageObject) => (
          <div
            style={{
              display: display,
              flex: "1 1 auto",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px",
              minHeight: "100px",
              flexWrap: "wrap",
            }}
          >
            {imageComponent(imageObject)}
          </div>
        )}
        onDragEnd={reorderList}
      />
    </SoftBox>
  )
}

export default ImageDrag
