import * as React from "react"
const LongBoardDiamondSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 305
  let originalHeight = 922

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      overflow="visible"
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path
          className="st1"
          d="M154.9,19c0,0,45.3-1.1,63.4,38.6c18,39.8,38.6,144.5,38.6,373.1c0,169.5-1.2,223.4-16.2,320.9
		c-20.9,136.1-54.1,144.5-54.1,144.5l-32.4,10.6"
        />
        <path
          className="st1"
          d="M155.6,19c0,0-49.2-1.1-67.9,38.6S48,202.2,48,430.9c0,169.5,1.2,223.4,16.7,320.9
		c21.7,136.1,56.1,144.5,56.1,144.5l33.4,10.6"
        />
      </g>

      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="StringerTripleLeft"
            className="st2"
            x1="215.1"
            y1="52.8"
            x2="215.1"
            y2="854.5"
          />
          <line
            id="StringerTripleRight_1_"
            className="st2"
            x1="89.9"
            y1="52.8"
            x2="89.9"
            y2="854.5"
          />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="StringerDoubleLeft"
            className="st2"
            x1="143.6"
            y1="19.8"
            x2="143.6"
            y2="894.9"
          />
          <line
            id="StringerDoubleRight_1_"
            className="st2"
            x1="161.4"
            y1="19.8"
            x2="161.4"
            y2="894.9"
          />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="_x3C_Path_x3E_"
            className="st2"
            x1="152.5"
            y1="19.8"
            x2="152.5"
            y2="894.9"
          />
        </g>
      )}

      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path
          className="st3"
          d="M151.8,28c0,0,41.5-1.1,57.9,37.8c16.5,39,35.2,141.6,35.2,365.5c0,166-1.1,218.8-14.8,314.3
		c-19.1,133.2-49.6,141.5-49.6,141.5l-29.7,10.2"
        />
        <path
          className="st3"
          d="M152.6,28c0,0-42.4-1.1-58.5,37.8C78,104.9,60,207.4,60,431.4c0,166,1,218.8,14.3,314.3
		c18.5,133.2,50,141.5,50,141.5l32.6,10.2"
        />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            className="st1"
            d="M152,673l-55.6-37.1l-47.4-29.5c0,0-2.2-193.8-1-226.1c1.2-32.2,1.3-38.1,1.3-38.1l56.9-45.4l46.3-35.3
		l46.6,39.3l56.6,41.3c0,0,0.1,5.9,1.3,38.1c1.2,32.2-1,226.1-1,226.1l-48.3,29.5l-55.3,37.3"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path
            className="st1"
            d="M49.5,332.2c0,0,33.6-41.2,102.6-41.9c69-0.7,103,38.7,103,38.7l1.5,142.9l-2.4,135.6
		c0,0-47.4,41.9-101.5,43.9c-54,2-101.6-39.3-101.6-39.3S46.1,356.8,49.5,332.2z"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            className="st1"
            d="M152,261.3c0,0,0.3,18.3-28.3,51.5c-28.6,33.1-74.4,29.3-74.4,29.3s-0.1,5.9-1.3,38.1
		c-1.2,32.2,1,226.1,1,226.1s15.2-2.2,52.4,11c37.2,13.2,51.2,55.9,51.2,55.9l0,0c0,0,14-42.6,51.2-55.9c37.2-13.2,52.4-11,52.4-11
		s2.2-193.8,1-226.1c-1.2-32.2-1.3-38.1-1.3-38.1s-45.9,3.8-74.4-29.3c-28.5-33.1-28.9-51.4-28.9-51.4"
          />
        </g>
      )}

      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            className="st1"
            d="M153,643.6l-96,58c0,0,31,197.3,67.5,194.6c10.3,0.7,22.2,10.6,30.7,10.6c11.3,0,30.8-10.6,30.8-10.6
		s28.3-23.8,39.5-73.5c11.1-49.7,22.6-124,22.6-124L153,643.6z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            className="st1"
            d="M152.4,727.9l99.1-66c0,0-11.2,114.8-23.8,154c-12.6,39.1-10.4,50.1-25.8,65.2c-15.4,15.1-40.1,26.3-47,25.9
		c-10.7-0.6-35.8-10.9-35.8-10.9s-20.9-15.5-34.6-57.4c-13.7-41.8-31.1-176.9-31.1-176.9L152.4,727.9z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path
            className="st1"
            d="M155,907c0,0-24.5-10.9-35.8-10.9s-17.6-25.3-31.8-48.6C73.3,824.4,58,699.3,58,699.3s11.7,0,46.3-10
		c34.6-10,48.2-48.8,48.2-48.8s12,38.7,46.6,48.8c34.6,10,47.9,9.3,47.9,9.3s-16.9,125.8-29.4,150.2c-13,25.5-22.6,45.1-33.5,47.4
		C173.1,898.5,155,907,155,907z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            className="st1"
            d="M153.6,755.1c0,0,8.9-42,33.9-57.5c25.1-15.4,62.5-23,63-20.5c0.5,2.5-9.8,90.4-16.4,116.7
		c-6.7,26.4-20.8,72.9-31.9,88.4c-11.1,15.6-14.6,13.8-14.6,13.8L156.1,907l-35.5-10.9c0,0-15.2-7.8-29.4-43.2
		C77,817.3,59.9,723.3,59,710.2c-0.9-13.1-4.6-33.9-4.6-33.9s56.1,8.9,73.1,26.4C144.7,720.2,154,743,153.6,755.1z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            className="st1"
            d="M56.3,700.5c0,0,40.9-24.8,95.1-24.2c54.2,0.6,97.3,19.7,97.3,19.7s-15.2,123.7-32.4,157.2
		c-17.4,33.3-22,40-22,40L155.3,907l-35.2-10.8c0,0-17.8-7.7-32.8-48.7C72.5,806.6,57.8,714.3,56.3,700.5z"
          />
        </g>
      )}

      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            className="st4"
            d="M158.2,854.5c0,1.9-1.5,3.5-3.3,3.5h-5c-1.8,0-3.3-1.6-3.3-3.5v-94.3c0-1.9,1.5-3.5,3.3-3.5h5
		c1.8,0,3.3,1.6,3.3,3.5V854.5z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            className="st4"
            d="M157.7,854.5c0,1.9-1.5,3.5-3.3,3.5h-5c-1.8,0-3.3-1.6-3.3-3.5v-94.3c0-1.9,1.5-3.5,3.3-3.5h5
		c1.8,0,3.3,1.6,3.3,3.5V854.5z"
          />
          <path
            className="st4"
            d="M87.8,757.8c-0.2,1.9-1.8,3.3-3.7,3l-4.6-0.6c-1.8-0.2-3-2-2.8-3.9l4.8-39.2c0.2-1.9,1.8-3.3,3.7-3l4.6,0.6
		c1.8,0.2,3,2,2.8,3.9L87.8,757.8z"
          />
          <path
            className="st4"
            d="M228.2,756.1c0.3,1.9-1,3.7-2.7,4l-4.6,0.7c-1.8,0.3-3.5-1-3.8-2.9l-5.6-39c-0.3-1.9,1-3.7,2.7-4l4.6-0.7
		c1.7-0.3,3.5,1,3.8,2.9L228.2,756.1z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            className="st4"
            d="M87.8,758c-0.2,1.9-1.8,3.3-3.7,3l-4.6-0.6c-1.8-0.2-3-2-2.8-3.9l4.8-39.2c0.2-1.9,1.8-3.3,3.7-3l4.6,0.6
		c1.8,0.2,3,2,2.8,3.9L87.8,758z"
          />
          <path
            className="st4"
            d="M228.2,756.3c0.3,1.9-1,3.7-2.7,4L221,761c-1.8,0.3-3.5-1-3.8-2.9l-5.6-39c-0.3-1.9,1-3.7,2.7-4l4.6-0.7
		c1.7-0.3,3.5,1,3.8,2.9L228.2,756.3z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            className="st4"
            d="M87.8,757.8c-0.2,1.9-1.8,3.3-3.7,3l-4.6-0.6c-1.8-0.2-3-2-2.8-3.9l4.8-39.2c0.2-1.9,1.8-3.3,3.7-3l4.6,0.6
		c1.8,0.2,3,2,2.8,3.9L87.8,757.8z"
          />
          <path
            className="st4"
            d="M228.2,756.1c0.3,1.9-1,3.7-2.7,4l-4.6,0.7c-1.8,0.3-3.5-1-3.8-2.9l-5.6-39c-0.3-1.9,1-3.7,2.7-4l4.6-0.7
		c1.7-0.3,3.5,1,3.8,2.9L228.2,756.1z"
          />
          <path
            className="st4"
            d="M157.5,862.4c0,1.9-1.5,3.5-3.3,3.5h-4.7c-1.8,0-3.3-1.6-3.3-3.5v-39.6c0-1.9,1.5-3.5,3.3-3.5h4.7
		c1.8,0,3.3,1.6,3.3,3.5V862.4z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            className="st4"
            d="M87.8,757.8c-0.2,1.9-1.8,3.3-3.7,3l-4.6-0.6c-1.8-0.2-3-2-2.8-3.9l4.8-39.2c0.2-1.9,1.8-3.3,3.7-3l4.6,0.6
		c1.8,0.2,3,2,2.8,3.9L87.8,757.8z"
          />
          <path
            className="st4"
            d="M117.1,814.9c0,1.9-1.5,3.5-3.3,3.5h-4.7c-1.8,0-3.3-1.6-3.3-3.5v-39.5c0-1.9,1.5-3.5,3.3-3.5h4.7
		c1.8,0,3.3,1.6,3.3,3.5V814.9z"
          />
          <path
            className="st4"
            d="M228.2,756.1c0.3,1.9-1,3.7-2.7,4l-4.6,0.7c-1.8,0.3-3.5-1-3.8-2.9l-5.6-39c-0.3-1.9,1-3.7,2.7-4l4.6-0.7
		c1.7-0.3,3.5,1,3.8,2.9L228.2,756.1z"
          />
          <path
            className="st4"
            d="M202.4,813.9c0,1.9-1.5,3.5-3.3,3.5h-4.7c-1.8,0-3.3-1.6-3.3-3.5v-39.5c0-1.9,1.5-3.5,3.3-3.5h4.7
		c1.8,0,3.3,1.6,3.3,3.5V813.9z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            className="st4"
            d="M157.7,854.5c0,1.9-1.5,3.5-3.3,3.5h-5c-1.8,0-3.3-1.6-3.3-3.5v-94.3c0-1.9,1.5-3.5,3.3-3.5h5
		c1.8,0,3.3,1.6,3.3,3.5V854.5z"
          />
          <path
            className="st4"
            d="M87.8,757.8c-0.2,1.9-1.8,3.3-3.7,3l-4.6-0.6c-1.8-0.2-3-2-2.8-3.9l4.8-39.2c0.2-1.9,1.8-3.3,3.7-3l4.6,0.6
		c1.8,0.2,3,2,2.8,3.9L87.8,757.8z"
          />
          <path
            className="st4"
            d="M117.1,814.9c0,1.9-1.5,3.5-3.3,3.5h-4.7c-1.8,0-3.3-1.6-3.3-3.5v-39.5c0-1.9,1.5-3.5,3.3-3.5h4.7
		c1.8,0,3.3,1.6,3.3,3.5V814.9z"
          />
          <path
            className="st4"
            d="M228.2,756.1c0.3,1.9-1,3.7-2.7,4l-4.6,0.7c-1.8,0.3-3.5-1-3.8-2.9l-5.6-39c-0.3-1.9,1-3.7,2.7-4l4.6-0.7
		c1.7-0.3,3.5,1,3.8,2.9L228.2,756.1z"
          />
          <path
            className="st4"
            d="M202.4,813.9c0,1.9-1.5,3.5-3.3,3.5h-4.7c-1.8,0-3.3-1.6-3.3-3.5v-39.5c0-1.9,1.5-3.5,3.3-3.5h4.7
		c1.8,0,3.3,1.6,3.3,3.5V813.9z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            className="st4"
            d="M87.8,757.8c-0.2,1.9-1.8,3.3-3.7,3l-4.6-0.6c-1.8-0.2-3-2-2.8-3.9l4.8-39.2c0.2-1.9,1.8-3.3,3.7-3l4.6,0.6
		c1.8,0.2,3,2,2.8,3.9L87.8,757.8z"
          />
          <path
            className="st4"
            d="M117.1,814.9c0,1.9-1.5,3.5-3.3,3.5h-4.7c-1.8,0-3.3-1.6-3.3-3.5v-39.5c0-1.9,1.5-3.5,3.3-3.5h4.7
		c1.8,0,3.3,1.6,3.3,3.5V814.9z"
          />
          <path
            className="st4"
            d="M228.2,756.1c0.3,1.9-1,3.7-2.7,4l-4.6,0.7c-1.8,0.3-3.5-1-3.8-2.9l-5.6-39c-0.3-1.9,1-3.7,2.7-4l4.6-0.7
		c1.7-0.3,3.5,1,3.8,2.9L228.2,756.1z"
          />
          <path
            className="st4"
            d="M202.4,813.9c0,1.9-1.5,3.5-3.3,3.5h-4.7c-1.8,0-3.3-1.6-3.3-3.5v-39.5c0-1.9,1.5-3.5,3.3-3.5h4.7
		c1.8,0,3.3,1.6,3.3,3.5V813.9z"
          />
          <path
            className="st4"
            d="M157.5,862.4c0,1.9-1.5,3.5-3.3,3.5h-4.7c-1.8,0-3.3-1.6-3.3-3.5v-39.6c0-1.9,1.5-3.5,3.3-3.5h4.7
		c1.8,0,3.3,1.6,3.3,3.5V862.4z"
          />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image x={116.9} y={556.4} width={71.3} height={79.6} href={logoUrl} />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path
            className="st5"
            d="M151.8,38c0,0,35.4-0.3,50.1,37.7c14.6,38,33,137.5,33,356.2c0,162.2-3.5,212.4-15.6,305.6
		c-17,130.1-41.6,139.5-41.6,139.5l-23.8,7.2"
          />
          <path
            className="st5"
            d="M152.4,38c0,0-35-1-49.2,37c-14.3,38-33.1,138.2-33.1,356.9c0,162.2,3.9,214.6,15.7,307.8
		c16.6,130.1,41.9,137.8,41.9,137.8l26.1,6.7"
          />
        </g>
      )}

      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path
          id="Outline_2_"
          className="st6"
          d="M155.2,907l-34.3-10.9c0,0-34.4-8.4-56.1-144.5c-15.5-97.4-16.7-151.4-16.7-320.9
		C48.1,202.1,69,97.4,87.7,57.6c18.8-39.7,64.9-38.7,64.9-38.7s47.8-1,65.8,38.8c18,39.8,38.5,144.5,38.5,373.1
		c0,169.5-1.2,223.4-16.1,320.9c-20.9,136.1-54.1,144.5-54.1,144.5L155.2,907z"
        />
      </g>
    </svg>
  )
}
export default LongBoardDiamondSvg
