import React, { useEffect, useState } from "react"
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
} from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"

const CustomOrderTextField = ({
  title,
  text,
  setText,
  updateOptions,
  orderData,
  isStaff,
}) => {
  let disabled = orderData?.locked && !isStaff
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <TextField
        label={title}
        variant="outlined"
        required={true}
        disabled={disabled}
        value={text}
        onBlur={() => {
          updateOptions()
        }}
        onChange={(e) => {
          if (!disabled) {
            setText(e.target.value)
          }
        }}
        fullWidth
      />
    </Box>
  )
}

export default CustomOrderTextField
