import React from "react"
import { useSearchParams } from "react-router-dom"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

const StaffDataTablePaginate = ({ currentPage, totalPages, getData }) => {
  let [searchParams, setSearchParams] = useSearchParams()

  return (
    <>
      <IconButton
        onClick={() => {
          if (currentPage > 1) {
            searchParams.set("page", currentPage - 1)
            setSearchParams(searchParams)
            getData(currentPage - 1)
          }
        }}
        disabled={currentPage === 1}
      >
        <ChevronLeftIcon />
      </IconButton>
      <SoftTypography>{`${currentPage}/${totalPages}`}</SoftTypography>
      <IconButton
        onClick={() => {
          if (currentPage < totalPages) {
            searchParams.set("page", currentPage + 1)
            setSearchParams(searchParams)
            getData(currentPage + 1)
          }
        }}
        disabled={currentPage === totalPages}
      >
        <ChevronRightIcon />
      </IconButton>
    </>
  )
}

export default StaffDataTablePaginate
