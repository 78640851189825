import React, { useState } from "react"
import SoftBox from "../../../../soft_dashboard/components/SoftBox"
import DescriptionItem from "../../../core/DescriptionItem/DescriptionItem"
import SoftTypography from "../../../../soft_dashboard/components/SoftTypography"
import { getCurrencyStringFromNumber } from "../../../../Util/Util"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Axios from "../../../../Util/Axios"
import { updateToastData } from "../../../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../../../core/Toast/Toast"
import { useDispatch } from "react-redux"

const AdditionalChargeJsx = ({
  additionalChargeData,
  orderData,
  setOrderData,
}) => {
  let name = additionalChargeData.name
  let amount = additionalChargeData.amount
  let description = additionalChargeData.description
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  return (
    <SoftBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      p={3}
      mb={0}
      mt={2}
      position="relative" // Added relative position
    >
      <SoftBox width="100%" display="flex" flexDirection="column">
        <IconButton
          disabled={loading}
          style={{
            position: "absolute",
            right: -15,
            top: -18,
            color: loading ? "grey" : "red",
          }}
          onClick={() => {
            setLoading(true)
            Axios.post(
              `/api/staff-order-detail/${orderData.customer_facing_id}/action_delete_additional_charge/`,
              {
                additional_charge_id: additionalChargeData.id,
              }
            )
              .then(({ data }) => {
                setLoading(false)
                setOrderData(data)
              })
              .catch((e) => {
                setLoading(false)
                dispatch(
                  updateToastData({
                    message: "Error deleting additional charge",
                    severity: TOAST_SEVERITY_ERROR,
                  })
                )
              })
          }}
        >
          <CloseIcon />
        </IconButton>
        <DescriptionItem
          spacing={"tight"}
          keyJsx={
            <Grid>
              <Grid item>
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {name}
                </SoftTypography>
              </Grid>
              {description && (
                <Grid item>
                  <SoftTypography variant="button" fontWeight="regular">
                    {description}
                  </SoftTypography>
                </Grid>
              )}
            </Grid>
          }
          valueJsx={
            <SoftTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {getCurrencyStringFromNumber(amount)}
            </SoftTypography>
          }
        />
      </SoftBox>
    </SoftBox>
  )
}

export default AdditionalChargeJsx
