import * as React from "react"

const PerformanceCrescentSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 973.455

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M93.746,914.464 c0,0-44.81-176.452-44.81-412.846c0-381.031,116.005-481.915,116.005-481.915s116.01,98.786,116.01,479.577 c0,268.732-48.766,415.222-48.766,415.222S179.492,883.2,93.746,914.464z" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={18}
        opacity={overlapOpacity}
      >
        <path d=" M102.902,900.261c0,0-42.786-167.396-42.786-397.278c0-361.646,104.825-468.646,104.825-468.646s104.83,102.916,104.83,466.374 c0,261.334-45.696,398.519-45.696,398.519S176.742,876.728,102.902,900.261z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M93.915,914.895 c0,0-52.673-195.5-42.672-518.375l113.178-80.529l114.226,80.529c11.429,372.041-46.57,518.375-46.57,518.375 S179.83,882.062,93.915,914.895z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M93.746,914.464 c0,0-54.504-204.735-42.503-517.944c0.001,0,102.697-181.191,227.403,0c13.596,318.709-46.461,517.982-46.461,517.982 S179.492,882.2,93.746,914.464z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M93.746,914.464 c0,0-56.504-223.235-42.15-517.944c0,0,41.487-9.653,72.761-34.167s40.918-60.931,40.918-60.931s12.188,36.501,38.186,59.53 c28.464,25.213,74.537,35.567,74.537,35.567c15.244,315.209-45.813,517.982-45.813,517.982S183.75,882.7,93.746,914.464z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M94.537,914.473 c0,0-22.601-76.736-36.506-226.88l107.289-30.604l106.675,30.604c-14.044,154.635-40.474,226.909-40.474,226.909 S181.074,882.718,94.537,914.473z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M94.537,914.473 c0,0-22.601-76.736-36.542-226.88l107.325,33.734l106.709-33.734c-14.078,147.635-40.508,226.909-40.508,226.909 S184.332,883.718,94.537,914.473z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path d="M94.537,914.473 c0,0-20.295-70.245-36.506-226.88c0,0,48.983-2.226,77.063-15.644c28.08-13.416,30.227-22.223,30.227-22.223 s10.975,16.109,25.428,21.561c20.128,7.592,81.282,16.306,81.282,16.306c-14.079,158.635-40.509,226.909-40.509,226.909 S181.074,883.218,94.537,914.473z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M94.537,914.473 c0,0-22.601-76.736-36.018-227.553c0,0,72.898-3.903,87.351,16.223c14.452,20.127,19.138,23.093,19.138,23.093 s9.383-7.4,29.483-23.106c20.94-16.359,76.991-16.208,76.991-16.208c-11.24,143.307-39.961,227.582-39.961,227.582 S181.074,883.218,94.537,914.473z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M94.537,914.473 c0,0-20.295-65.611-35.009-205.85c-0.848-6.795-0.705-13.804-1.495-21.032c-0.001,0.002,100.973-68.765,212.996,0.002 c0,0,0.738,12.001,0,17.855c-17.287,148.279-39.508,209.054-39.508,209.054S181.074,883.718,94.537,914.473z" />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={243.207} y1={182.394} x2={243.207} y2={875.439} />
          <line x1={84.915} y1={182.394} x2={84.915} y2={875.439} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={153.069} y1={32.739} x2={153.069} y2={900.261} />
          <line x1={177.053} y1={32.739} x2={177.053} y2={900.261} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.061} y1={19.703} x2={165.061} y2={900.261} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M171.816,844.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V734.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V844.752z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,844.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V734.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V844.752z" />
          <path d="M94.858,756.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,756.854z" />
          <path d="M249.374,754.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,754.861z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M94.858,757.088c-0.238,2.23-2.027,3.874-4.056,3.523 l-5.013-0.705c-2.028-0.235-3.339-2.349-3.101-4.577l5.248-46.014c0.24-2.23,2.029-3.871,4.057-3.521l5.011,0.703 c2.028,0.234,3.341,2.347,3.102,4.577L94.858,757.088z" />
          <path d="M249.374,755.093c0.356,2.229-1.075,4.345-2.983,4.697 l-5.014,0.818c-2.027,0.354-3.816-1.175-4.175-3.404l-6.204-45.779c-0.357-2.229,1.072-4.342,2.984-4.693l5.011-0.82 c1.909-0.351,3.817,1.175,4.176,3.403L249.374,755.093z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,756.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,756.854z" />
          <path d="M249.374,754.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,754.861z" />
          <path d="M171.58,860.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.482c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V860.979z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,756.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,756.854z" />
          <path d="M119.414,823.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V823.761z" />
          <path d="M249.374,754.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,754.861z" />
          <path d="M230.941,822.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V822.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,844.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V734.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V844.752z" />
          <path d="M94.858,756.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,756.854z" />
          <path d="M119.414,823.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V823.761z" />
          <path d="M249.374,754.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,754.861z" />
          <path d="M230.941,822.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V822.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,756.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,756.854z" />
          <path d="M119.414,823.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V823.761z" />
          <path d="M249.374,754.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,754.861z" />
          <path d="M230.941,822.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V822.588z" />
          <path d="M171.58,860.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.482c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V860.979z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={535.58}
          width={96}
          height={92.504}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d="M111.652,885.511 c0,0-39.716-156.937-39.716-378.908c0-357.79,93.005-452.518,93.005-452.518s93.01,92.761,93.01,450.322 c0,252.341-42.051,380.819-42.051,380.819S180.562,869.794,111.652,885.511z" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d="M93.746,914.464 c0,0-44.81-176.452-44.81-412.846c0-381.031,116.005-481.915,116.005-481.915s116.01,98.786,116.01,479.577 c0,268.732-48.766,415.222-48.766,415.222S181.75,883.7,93.746,914.464z" />
      </g>
    </svg>
  )
}
export default PerformanceCrescentSvg
