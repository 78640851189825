import React from "react"
import { Box } from "@mui/material"
import NavBar from "../NavBar/NavBar"
import ProductTable from "../components/core/DataTable/ProductTable"
import Page from "../Page/Page"

const ApparelList = () => {
  const dataUrl = "/api/product-list/"

  return (
    <Page>
      <ProductTable
        title={"Merch"}
        // getRow={getRow}
        // bannerUrl={
        //   // "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/logobanner500.jpg"
        // }
        path={"merchandise"}
        dataUrl={dataUrl}
        extraParams={"&type=apparel"}
        description={"Get some goods shipped to your house."}
        showSearch={false}
      />
    </Page>
  )
}

export default ApparelList
