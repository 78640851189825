import * as React from "react"

const LongboardSquareTailSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 305
  let originalHeight = 922

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path
          d="M155,18.1c0,0-45.4-1.1-62.6,38.6S55.8,201.1,55.8,429.5c0,169.3,1.1,223.2,15.4,320.5
		c20,135.9,51.7,144.3,51.7,144.3h31.2h0.3h29.2c0,0,30.7-8.4,49.9-144.3c13.8-97.3,14.9-151.2,14.9-320.5
		c0-228.4-19-333-35.6-372.7c-16.6-39.8-58.5-38.6-58.5-38.6"
        />
      </g>

      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="_x3C_Stringer_x3E__1_"
            className="st4"
            x1="210.2"
            y1="49.9"
            x2="210.2"
            y2="852.7"
          />
          <line
            id="_x3C_Stringer_x3E__2_"
            className="st4"
            x1="94.7"
            y1="49.9"
            x2="94.7"
            y2="852.7"
          />
        </g>
      )}

      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="_x3C_Stringer_x3E__3_"
            className="st4"
            x1="143.3"
            y1="18.9"
            x2="143.3"
            y2="893"
          />
          <line
            id="_x3C_Stringer_x3E__4_"
            className="st4"
            x1="159.8"
            y1="18.9"
            x2="159.8"
            y2="893"
          />
        </g>
      )}

      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) &&
        selectedNumberOfStringersOption?.value && (
          <g
            transform={`scale(${widthScale}, ${heightScale})`}
            id="StringerSingle"
            stroke={stringerColor}
            strokeWidth="4"
            strokeMiterlimit="10"
          >
            <line x1="152" y1="18.875" x2="152" y2="892.952" />
          </g>
        )}
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            className="st2"
            d="M151.5,671.4l-51.3-37.1l-43.7-29.5c0,0-2-193.6-0.9-225.8s1.2-38.1,1.2-38.1l52.5-45.3
	c0,0,42.7-35.3,42.7-35.3l43,39.3l52.2,41.3c0,0,0.1,5.9,1.2,38.1c1.1,32.2-0.9,225.8-0.9,225.8L203,634.3l-51,37.3"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            id="RoundedPointDeckPatch"
            className="st2"
            d="M151.5,260.1c0,0,0.3,18.3-26.1,51.4s-68.6,29.3-68.6,29.3s-0.1,5.9-1.2,38.1
	s0.9,225.8,0.9,225.8s14-2.2,48.3,11c34.3,13.2,47.2,55.8,47.2,55.8v0c0,0,12.9-42.6,47.2-55.8c34.3-13.2,48.3-11,48.3-11
	s2-193.6,0.9-225.8c-1.1-32.2-1.2-38.1-1.2-38.1s-42.3,3.8-68.6-29.3c-26.3-33.1-26.6-51.3-26.6-51.3"
          />
        </g>
      )}

      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path
            id="RoundDeckPatch"
            className="st2"
            d="M57.2,330.9c0,0,31-41.2,94.6-41.9s95,38.7,95,38.7l1.4,142.7l-2.2,135.4
	c0,0-43.7,41.9-93.6,43.9c-49.8,2-93.7-39.3-93.7-39.3S54.1,355.5,57.2,330.9z"
          />
        </g>
      )}

      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            id="PointedTailPatch"
            className="st2"
            d="M151.7,642l-88.5,57.9c0,0,28.6,197.1,62.2,194.4c31.4-2.5,56.7-0.1,56.7-0.1
	s26.1-23.8,36.4-73.4S239.2,697,239.2,697L151.7,642z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            id="InvertedPointedTailPatch"
            className="st2"
            d="M152,726.2l91.4-65.9c0,0-10.3,114.7-21.9,153.8s-9.6,50-23.8,65.1
	c-14.2,15.1-5.8,15.1-41.1,15.1s-35.2,0-35.2,0s-19.3-15.5-31.9-57.3c-12.6-41.8-28.7-176.7-28.7-176.7L152,726.2z"
          />
        </g>
      )}

      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path
            id="CurvedPointedTailPatch"
            className="st2"
            d="M152,892.6c0,0-20.2,1.7-30.6,1.7c-10.4,0-16.2-25.3-29.3-48.5
	S64.9,697.6,64.9,697.6s10.8,0,42.7-10c31.9-10,44.4-48.7,44.4-48.7s11.1,38.7,43,48.7c31.9,10,44.2,9.3,44.2,9.3
	s-15.6,125.7-27.1,150c-12,25.5-20.5,47.3-30.9,47.3S152,892.6,152,892.6z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            className="st2"
            d="M152,753.4c0,0,8.2-42,31.3-57.4c23.1-15.4,57.6-23,58.1-20.5
	c0.5,2.5-9,90.3-15.1,116.6c-6.2,26.4-19.2,72.8-29.4,88.3s-13.5,13.8-13.5,13.8h-60.6c0,0-15.1-7.8-28.2-43.2
	c-13.1-35.5-28.9-129.4-29.7-142.5s-4.2-33.9-4.2-33.9s51.7,8.9,67.4,26.4C143.8,718.5,152.4,741.3,152,753.4z"
          />
        </g>
      )}

      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            className="st2"
            d="M63.2,698.8c0,0,37.7-24.8,87.7-24.2s89.7,19.7,89.7,19.7s-14.9,123-30.9,156.3
	c-16,33.3-27,43.6-27,43.6H122c0,0-16.4-7.7-30.2-48.6S64.6,712.6,63.2,698.8z"
          />
        </g>
      )}

      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            id="SingleFin"
            className="st6"
            d="M156.8,852.7c0,1.9-1.4,3.5-3,3.5h-4.6c-1.7,0-3-1.6-3-3.5v-94.2c0-1.9,1.4-3.5,3-3.5h4.6
	c1.7,0,3,1.6,3,3.5V852.7z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M156.791 852.734c0 1.921-1.353 3.479-3.024 3.479h-4.55c-1.67 0-3.025-1.559-3.025-3.479v-94.229c0-1.922 1.355-3.48 3.025-3.48h4.55c1.672 0 3.024 1.559 3.024 3.48v94.229z"></path>
          <path d="M92.292 756.143c-.215 1.906-1.733 3.252-3.39 3.005l-4.228-.633c-1.657-.247-2.826-1.994-2.611-3.899l4.421-39.185c.215-1.906 1.732-3.252 3.389-3.003l4.229.631c1.657.248 2.826 1.993 2.611 3.899l-4.421 39.185zM221.767 754.404c.255 1.9-.877 3.679-2.528 3.973l-4.213.748c-1.652.295-3.197-1.008-3.453-2.907l-5.247-39.049c-.255-1.9.876-3.679 2.528-3.973l4.212-.748c1.651-.294 3.197 1.008 3.453 2.907l5.248 39.049z"></path>
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M92.292 756.314c-.215 1.906-1.733 3.252-3.39 3.004l-4.228-.633c-1.657-.246-2.826-1.994-2.611-3.898l4.421-39.186c.215-1.906 1.732-3.252 3.389-3.002l4.229.631c1.657.248 2.826 1.992 2.611 3.898l-4.421 39.186zM221.767 754.576c.255 1.9-.877 3.678-2.528 3.973l-4.213.748c-1.652.295-3.197-1.008-3.453-2.908l-5.247-39.049c-.255-1.9.876-3.678 2.528-3.973l4.212-.748c1.651-.293 3.197 1.008 3.453 2.908l5.248 39.049z"></path>
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M92.292 756.143c-.215 1.906-1.733 3.252-3.39 3.005l-4.228-.633c-1.657-.247-2.826-1.994-2.611-3.899l4.421-39.185c.215-1.906 1.732-3.252 3.389-3.003l4.229.631c1.657.248 2.826 1.993 2.611 3.899l-4.421 39.185zM221.767 754.404c.255 1.9-.877 3.679-2.528 3.973l-4.213.748c-1.652.295-3.197-1.008-3.453-2.907l-5.247-39.049c-.255-1.9.876-3.679 2.528-3.973l4.212-.748c1.651-.294 3.197 1.008 3.453 2.907l5.248 39.049zM156.649 860.553c0 1.922-1.354 3.481-3.024 3.481h-4.265c-1.672 0-3.025-1.56-3.025-3.481V821.04c0-1.923 1.354-3.482 3.025-3.482h4.265c1.67 0 3.024 1.56 3.024 3.482v39.513z"></path>
        </g>
      )}

      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M92.292 756.143c-.215 1.906-1.733 3.252-3.39 3.005l-4.228-.633c-1.657-.247-2.826-1.994-2.611-3.899l4.421-39.185c.215-1.906 1.732-3.252 3.389-3.003l4.229.631c1.657.248 2.826 1.993 2.611 3.899l-4.421 39.185zM119.284 813.079c0 1.921-1.355 3.479-3.026 3.479h-4.263c-1.671 0-3.026-1.558-3.026-3.479v-39.515c0-1.922 1.355-3.48 3.026-3.48h4.263c1.671 0 3.026 1.559 3.026 3.48v39.515zM221.767 754.404c.255 1.9-.877 3.679-2.528 3.973l-4.213.748c-1.652.295-3.197-1.008-3.453-2.907l-5.247-39.049c-.255-1.9.876-3.679 2.528-3.973l4.212-.748c1.651-.294 3.197 1.008 3.453 2.907l5.248 39.049zM197.972 812.079c0 1.921-1.355 3.479-3.027 3.479h-4.264c-1.67 0-3.024-1.558-3.024-3.479v-39.515c0-1.922 1.354-3.48 3.024-3.48h4.264c1.672 0 3.027 1.559 3.027 3.48v39.515z"></path>
        </g>
      )}

      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M156.791 852.734c0 1.921-1.353 3.479-3.024 3.479h-4.55c-1.67 0-3.025-1.559-3.025-3.479v-94.229c0-1.922 1.355-3.48 3.025-3.48h4.55c1.672 0 3.024 1.559 3.024 3.48v94.229z"></path>
          <path d="M92.292 756.143c-.215 1.906-1.733 3.252-3.39 3.005l-4.228-.633c-1.657-.247-2.826-1.994-2.611-3.899l4.421-39.185c.215-1.906 1.732-3.252 3.389-3.003l4.229.631c1.657.248 2.826 1.993 2.611 3.899l-4.421 39.185zM119.284 813.079c0 1.921-1.355 3.479-3.026 3.479h-4.263c-1.671 0-3.026-1.558-3.026-3.479v-39.515c0-1.922 1.355-3.48 3.026-3.48h4.263c1.671 0 3.026 1.559 3.026 3.48v39.515zM221.767 754.404c.255 1.9-.877 3.679-2.528 3.973l-4.213.748c-1.652.295-3.197-1.008-3.453-2.907l-5.247-39.049c-.255-1.9.876-3.679 2.528-3.973l4.212-.748c1.651-.294 3.197 1.008 3.453 2.907l5.248 39.049zM197.972 812.079c0 1.921-1.355 3.479-3.027 3.479h-4.264c-1.67 0-3.024-1.558-3.024-3.479v-39.515c0-1.922 1.354-3.48 3.024-3.48h4.264c1.672 0 3.027 1.559 3.027 3.48v39.515z"></path>
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M92.292 756.143c-.215 1.906-1.733 3.252-3.39 3.005l-4.228-.633c-1.657-.247-2.826-1.994-2.611-3.899l4.421-39.185c.215-1.906 1.732-3.252 3.389-3.003l4.229.631c1.657.248 2.826 1.993 2.611 3.899l-4.421 39.185zM119.284 813.079c0 1.921-1.355 3.479-3.026 3.479h-4.263c-1.671 0-3.026-1.558-3.026-3.479v-39.515c0-1.922 1.355-3.48 3.026-3.48h4.263c1.671 0 3.026 1.559 3.026 3.48v39.515zM221.767 754.404c.255 1.9-.877 3.679-2.528 3.973l-4.213.748c-1.652.295-3.197-1.008-3.453-2.907l-5.247-39.049c-.255-1.9.876-3.679 2.528-3.973l4.212-.748c1.651-.294 3.197 1.008 3.453 2.907l5.248 39.049zM197.972 812.079c0 1.921-1.355 3.479-3.027 3.479h-4.264c-1.67 0-3.024-1.558-3.024-3.479v-39.515c0-1.922 1.354-3.48 3.024-3.48h4.264c1.672 0 3.027 1.559 3.027 3.48v39.515zM156.649 860.553c0 1.922-1.354 3.481-3.024 3.481h-4.265c-1.672 0-3.025-1.56-3.025-3.481V821.04c0-1.923 1.354-3.482 3.025-3.482h4.265c1.67 0 3.024 1.56 3.024 3.482v39.513z"></path>
        </g>
      )}
      <g id="Logo" transform={`scale(${widthScale}, ${heightScale})`}>
        <image href={logoUrl} x="119.5" y="554.9" width="65.7" height="79.5" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path
          className="st3"
          d="M152.4,29.1c0,0-37.1-2.1-51.9,36.8c-14.8,39-33.4,140.4-33.4,364.1c0,165.8,1.9,218.6,14.2,313.9
		c18.7,127.4,45.4,139.3,45.4,139.3h24.8l0.2,0h28.7c0,0,24.7-11.1,42.7-139.3c12.6-95.3,14.6-148.1,14.6-313.9
		c0-223.7-18.3-326.1-33.5-365.1c-15.2-39-52.4-35.8-52.4-35.8H152.4z"
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d="M151.794 37.12s39.288-1.087 54.883 36.962c15.598 38.049 33.392 138.064 33.392 356.575 0 161.981-.988 213.509-13.943 306.594-18.094 129.994-46.882 138.038-46.882 138.038h-27.45" />
          <path d="M152.499 37.12s-40.153-1.087-55.311 36.962c-15.155 38.049-32.199 138.064-32.199 356.575 0 161.981.96 213.509 13.552 306.594 17.583 129.994 45.558 138.038 45.558 138.038h27.537" />
        </g>
      )}

      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path
          className="st5"
          d="M152,18c0,0,44.1-1,60.7,38.8c16.6,39.8,35.5,144.3,35.5,372.7c0,169.3-1.1,223.2-14.8,320.5
		c-19.3,135.9-49.9,144.3-49.9,144.3h-30.9"
        />
        <path
          className="st5"
          d="M154,894.3h-31.1c0,0-31.7-8.4-51.7-144.3c-14.3-97.3-15.4-151.2-15.4-320.5c0-228.4,19.3-333,36.5-372.7
		C109.5,17,152,18,152,18"
        />
      </g>
    </svg>
  )
}
export default LongboardSquareTailSvg
