import React from "react"
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Page from "../Page/Page"
import Contact from "../Contact/Contact"
import { updateToastData } from "../../slices/toastSlice"
import { TOAST_SEVERITY_SUCCESS } from "../../components/core/Toast/Toast"
import { useDispatch } from "react-redux"

const About = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const sections = [
    {
      text: "I’m Marlin, growing up in Santa Barbara I spent a lot of time at the beach and in the ocean but dove more heavily into surfing as a teenager. While at SBCC and UCSB for Physics, I had the privilege of going to class next to some great right hand point breaks the area has become known for. My life began to revolve more and more around the ocean, surfing, and eventually making surfboards.",
      url: "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/_DSC0133.jpg",
    },
    {
      text: "After a few years into surfing, alternative equipment became more interesting and I fell down the rabbit hole of board design. That soon evolved into shaping and glassing surfboards for myself to try some leftfield ideas. It combined a lot of interests for me and that was it, I had to keep shaping. I started experimenting and making some legitimately weird boards.",
      url: "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/logo.jpg",
    },
    {
      text: "Eventually, I started selling boards to buy more blanks and making custom surfboards for my friends. The whole vibe was to create retro shapes, longboards, various shortboards and stuff that was outside of the typical surfing realm. I’ve always been against models and preset shapes that generalize the subjective surfing experience so I enjoy crafting custom boards that suit each individual's surfing style.",
      url: "",
    },
  ]
  return (
    <Page>
      <Box
        pb={isMobile ? 5 : 15}
        px={isMobile ? 2 : 10}
        sx={{ color: "black", minHeight: "100vh" }}
      >
        <Container>
          <Box pt={5} textAlign="center">
            {isMobile && (
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                sx={{ mb: 4, fontSize: "2rem" }}
              >
                About
              </Typography>
            )}

            {!isMobile && (
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                sx={{ mb: 4 }}
              >
                About
              </Typography>
            )}

            <img
              src="https://marlinsurf-assets.s3.us-west-1.amazonaws.com/shapingbluesmall.jpg"
              alt="Description for accessibility"
              style={{
                maxWidth: isMobile ? "100%" : "90%",
                margin: "16px auto",
                display: "block",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              }}
            />
            {sections.map((section, index) => (
              <Box key={index} mt={7}>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 3,
                    fontFamily: "Josefin Sans, Arial, sans-serif",
                    fontSize: isMobile ? "1.1rem" : null,
                  }}
                >
                  {section.text}
                </Typography>

                {section.url && (
                  <Box pt={2}>
                    <img
                      src={section.url}
                      alt={`Image for ${section.text}`}
                      style={{
                        maxWidth: isMobile ? "100%" : "90%",
                        margin: "16px auto",
                        display: "block",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                      }}
                    />
                  </Box>
                )}
              </Box>
            ))}
            <Box
              mt={7}
              sx={{
                color: "black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                pt: 5,
              }}
            >
              <Typography variant="h3" sx={{ marginBottom: 4 }}>
                Contact
              </Typography>
              <Contact
                p={3}
                ml={isMobile ? 2 : 15} // Reduce margin for mobile
                mr={isMobile ? 2 : 15} // Reduce margin for mobile
                onSuccess={() => {
                  dispatch(
                    updateToastData({
                      message: "Message Sent Successfully",
                      severity: TOAST_SEVERITY_SUCCESS,
                    })
                  )
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </Page>
  )
}

export default About
