import React, { useState } from "react"
import SoftBox from "../../../../soft_dashboard/components/SoftBox"
import DescriptionItem from "../../../core/DescriptionItem/DescriptionItem"
import SoftTypography from "../../../../soft_dashboard/components/SoftTypography"
import { getCurrencyStringFromNumber } from "../../../../Util/Util"
import ColorBox from "../../../../MarlinSurf/CustomOrder/ColorBox"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Axios from "../../../../Util/Axios"
import { updateToastData } from "../../../../slices/toastSlice"
import {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../../../core/Toast/Toast"
import { useDispatch } from "react-redux"
import { InputAdornment } from "@mui/material"
import { Box } from "@mui/system"
import CustomTextField from "../../../core/CustomTextField/CustomTextField"

const OrderOptionJsx = ({ optionData, editable }) => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(optionData.value)
  const [price, setPrice] = useState(optionData.price)
  const [cost, setCost] = useState(optionData.cost)
  const [helpText, setHelpText] = useState(optionData.help_text || "")
  let name = `${optionData.type_display}: ${optionData.value}`
  const dispatch = useDispatch()
  let amount = optionData.price || optionData.amount
  let special = false
  if (optionData?.value?.startsWith("#")) {
    name = (
      <React.Fragment>
        {optionData.type_display}: {optionData?.extra_data?.name}
        <ColorBox
          key={optionData.id}
          colorOption={optionData}
          clickable={false}
        />
      </React.Fragment>
    )
  } else if (optionData?.type === "logo") {
    special = true
    name = (
      <React.Fragment>
        <Grid>
          <Grid item>
            {optionData.type_display}: {optionData?.extra_data?.name}
          </Grid>
          <Grid item>
            <img width={60} src={optionData?.extra_data?.url} />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
  return (
    <SoftBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      p={3}
      mb={0}
      mt={2}
    >
      <SoftBox width="100%" display="flex" flexDirection="column">
        <DescriptionItem
          spacing={"tight"}
          keyJsx={
            <React.Fragment>
              {(special || !editable) && (
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {name}
                </SoftTypography>
              )}
              {editable && !special && (
                <React.Fragment>
                  <TextField
                    fullWidth
                    label={"Value"}
                    disabled={loading}
                    value={value}
                    onChange={(event) => {
                      setValue(event.target.value)
                    }}
                  />

                  <Box pt={3}>
                    <CustomTextField
                      fullWidth
                      label={"Help Text"}
                      disabled={loading}
                      value={helpText}
                      onChange={(event) => {
                        setHelpText(event.target.value)
                      }}
                    />
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
          }
          valueJsx={
            <React.Fragment>
              {!editable && (
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {getCurrencyStringFromNumber(amount)}
                </SoftTypography>
              )}
              {editable && (
                <React.Fragment>
                  <Box>
                    <TextField
                      disabled={loading}
                      margin="normal"
                      fullWidth
                      label={"Cost"}
                      type={"number"}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={cost}
                      onBlur={() => {
                        Axios.patch(`/api/staff-option/${optionData.id}/`, {
                          cost,
                        })
                          .then(({ data }) => {
                            optionData.cost = data.cost

                            dispatch(
                              updateToastData({
                                message: "Cost updated",
                                severity: TOAST_SEVERITY_SUCCESS,
                              })
                            )
                          })
                          .catch((e) => {
                            dispatch(
                              updateToastData({
                                message: "Cost update failed",
                                severity: TOAST_SEVERITY_ERROR,
                              })
                            )
                          })
                      }}
                      onChange={(event) => setCost(event.target.value)}
                    />
                  </Box>
                  <Box pl={2}>
                    <TextField
                      disabled={loading}
                      margin="normal"
                      fullWidth
                      label={"Price"}
                      value={price}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      onBlur={() => {
                        Axios.patch(`/api/staff-option/${optionData.id}/`, {
                          price,
                        })
                          .then(({ data }) => {
                            optionData.price = data.price

                            dispatch(
                              updateToastData({
                                message: "Price updated",
                                severity: TOAST_SEVERITY_SUCCESS,
                              })
                            )
                          })
                          .catch((e) => {
                            dispatch(
                              updateToastData({
                                message: "Price update failed",
                                severity: TOAST_SEVERITY_ERROR,
                              })
                            )
                          })
                      }}
                      onChange={(event) => setPrice(event.target.value)}
                    />
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
          }
        />
      </SoftBox>
    </SoftBox>
  )
}

export default OrderOptionJsx
