import React from "react"
import { Box, Grid } from "@mui/material"

const CustomOrderLogoSelect = ({
  selectedLogoOption,
  setSelectedLogoOption,
  logoOptions,
  updateOptions,
  orderData,
  isStaff,
}) => {
  let border = null
  let boxShadow = "1px 1px 10px rgba(0, 0, 0, 0.5)"
  let disabled = orderData?.locked && !isStaff
  return (
    <Box pt={3}>
      <Grid container spacing={2} direction={"row"}>
        {logoOptions.map((logoOption, i) => {
          const selected = selectedLogoOption?.id === logoOption?.id
          return (
            <Grid item key={i}>
              <img
                onClick={() => {
                  if (!disabled) {
                    // setSelectedLogoOption(logoOption)
                    updateOptions([logoOption])
                  }
                }}
                src={logoOption?.extra_data?.url}
                alt={logoOption.value}
                style={{
                  width: 100,
                  // height: 60,
                  // bgcolor: colorData.value,
                  border: border,
                  m: 1,
                  borderRadius: 4,
                  cursor: disabled ? "not-allowed" : "pointer",
                  transition: "0.3s",
                  boxShadow: selected ? boxShadow : null,
                  "&:hover": {
                    boxShadow: disabled ? null : boxShadow,
                  },
                  opacity: disabled ? 0.5 : 1, // Apply disabled style
                }}
              />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default CustomOrderLogoSelect
