import React from "react"
import SoftBadge from "../../soft_dashboard/components/SoftBadge"

export const ORDER_STATUS_DRAFT = "draft"
export const ORDER_STATUS_AWAITING_CONFIRMATION = "awaiting_confirmation"
export const ORDER_STATUS_CONFIRMED = "confirmed"
export const ORDER_STATUS_DEPOSIT_RECEIVED = "deposit_received"
export const ORDER_STATUS_COMPLETE = "complete"
export const ORDER_STATUS_SHIPPED = "shipped"

const OrderStatusBadge = ({ status, statusDisplay }) => {
  let color = "dark"
  if (status === ORDER_STATUS_DRAFT) {
    color = "light"
  } else if (status === ORDER_STATUS_AWAITING_CONFIRMATION) {
    color = "warning"
  } else if (status === ORDER_STATUS_CONFIRMED) {
    color = "info"
  } else if (status === ORDER_STATUS_DEPOSIT_RECEIVED) {
    color = "dark"
  } else if (status === ORDER_STATUS_COMPLETE) {
    color = "success"
  } else if (status === ORDER_STATUS_SHIPPED) {
    color = "success"
  }

  return (
    <SoftBadge
      variant="gradient"
      badgeContent={statusDisplay}
      color={color}
      size="xs"
      container
    />
  )
}

export default OrderStatusBadge
