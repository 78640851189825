import React, { useEffect } from "react"
import NavBar from "../NavBar/NavBar"
import { Box, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import Cookies from "js-cookie"
import ReactPixel from "react-facebook-pixel"
import Page from "../Page/Page"
const CheckoutStatus = ({ success }) => {
  const message = success
    ? "Checkout successful! You'll get an email soon with your order details."
    : "Unfortunately, something went wrong and I've been notified. Don't worry any charge has been reversed. Try again later."

  const imageSrc = success
    ? "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/success.png"
    : "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/cross.png"

  const location = useLocation()
  const navigate = useNavigate()
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const status = queryParams.get("status")

    if (success) {
      let purchaseCookie = Cookies.get("purchase_data")
      if (purchaseCookie) {
        try {
          purchaseCookie = purchaseCookie
            .replace(/\\(.)/g, "$1")
            .replace(/054/g, ",")
          const data = JSON.parse(purchaseCookie)
          ReactPixel.track("Purchase", data)
          Cookies.remove("purchase_data")
        } catch (exception) {}
      }
    }
  }, [])

  return (
    <Page>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "80vh" }}
      >
        <>
          <Box pb={10}>
            <img
              src={imageSrc}
              alt="status icon"
              style={{ width: "200px", height: "200px" }}
            />
          </Box>
          <Typography
            style={{ maxWidth: "50%", fontFamily: "Josefin Sans" }}
            variant="h4"
            align="center"
            gutterBottom
          >
            {message}
          </Typography>
        </>
      </Box>
    </Page>
  )
}

export default CheckoutStatus
