import React, { useEffect } from "react"
import Typography from "@mui/material/Typography"
import { blue } from "@mui/material/colors"
import { useNavigate } from "react-router-dom"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Page from "../../../MarlinSurf/Page/Page"

const PageNotFound = () => {
  const primary = blue[500] // #f44336
  const navigate = useNavigate()

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [])

  return (
    <Page>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          backgroundColor: "#1d2f3e",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          background={primary}
        >
          <Typography variant="h1" style={{ color: "white" }}>
            404
          </Typography>
          <Typography variant="h6" style={{ color: "white" }}>
            Page does not exist
          </Typography>
        </Grid>
      </Box>
    </Page>
  )
}

export default PageNotFound
