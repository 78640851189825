import React from "react"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

const StaffDataTableActionButton = ({ action }) => {
  return (
    <Button
      variant="contained"
      color={action.color}
      style={{ marginRight: 10 }}
      onClick={action.onAction}
      disabled={action.disabled || action.loading}
    >
      <Typography variant="subtitle" style={{ color: "white" }}>
        {action.name}
      </Typography>
    </Button>
  )
}

export default StaffDataTableActionButton
