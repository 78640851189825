import React, { useEffect, useState } from "react"
import Table from "../../../soft_dashboard/examples/Tables/Table"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import Card from "@mui/material/Card"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import Axios from "../../../Util/Axios"
import Toast, { TOAST_SEVERITY_ERROR } from "../Toast/Toast"
import { Skeleton } from "@mui/material"
import StaffDataTablePaginate from "./StaffDataTablePaginate"
import StaffDataTableActionButton from "./StaffDataTableActionButton"
import DataTableSearchBar from "../../../MarlinSurf/components/core/DataTable/DataTableSearchBar/DataTableSearchBar"
import { useDispatch } from "react-redux"
import { updateToastData } from "../../../slices/toastSlice"

const StaffDataTable = ({
  title,
  columns,
  getRow,
  dataUrl,
  actions = [],
  filters = [],
}) => {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState([])
  let [searchParams, setSearchParams] = useSearchParams()

  const [totalPages, setTotalPages] = useState(0)
  let currentPage = searchParams.get("page") || 1
  currentPage = parseInt(currentPage)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const getData = (page, searchTerm, filterTitleKey, filterOptionKey) => {
    if (!searchTerm) {
      searchTerm = searchParams.get("search") || ""
    }

    let additionalParams = ""
    let additionalParamsObj = {}
    for (let filter of filters) {
      if (searchParams.get(filter.title_key)) {
        additionalParamsObj[filter.title_key] = searchParams.get(
          filter.title_key
        )
      }
    }
    if (filterTitleKey && filterOptionKey) {
      additionalParamsObj[filterTitleKey] = filterOptionKey
    }

    if (Object.keys(additionalParamsObj).length > 0) {
      additionalParams =
        "&" + new URLSearchParams(additionalParamsObj).toString()
    }

    setLoading(true)
    setRows([])
    const url = `${dataUrl}?page=${page}&search=${searchTerm}${additionalParams}`

    Axios.get(url, navigate)
      .then(({ data }) => {
        setTotalPages(data.total_pages)
        setRows(data.results)
        setLoading(false)
        document.documentElement.scrollTop = 0
      })
      .catch((e) => {
        setLoading(false)
        dispatch(
          updateToastData({
            message: e,
            severity: TOAST_SEVERITY_ERROR,
          })
        )
      })
  }

  useEffect(() => {
    getData(currentPage)
  }, [location])

  let tableRows = rows.map((row) => {
    return getRow(row)
  })

  return (
    <Card>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <SoftTypography variant="h6">{title}</SoftTypography>
        <SoftBox display="flex" alignItems="center">
          {actions.map((action) => {
            return <StaffDataTableActionButton action={action} />
          })}
          <StaffDataTablePaginate
            currentPage={currentPage}
            getData={getData}
            searchParam={searchParams}
            setSearchParams={setSearchParams}
            totalPages={totalPages}
          />
        </SoftBox>
      </SoftBox>
      <SoftBox p={2}>
        <DataTableSearchBar getData={getData} filters={filters} />
      </SoftBox>
      <SoftBox
        sx={{
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            },
          },
        }}
      >
        {loading ? (
          <SoftBox p={2}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </SoftBox>
        ) : (
          <Table columns={columns} rows={tableRows} />
        )}
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        ></SoftBox>
      </SoftBox>
      <SoftBox display="flex" justifyContent="center" alignItems="center" p={2}>
        <StaffDataTablePaginate
          currentPage={currentPage}
          getData={getData}
          totalPages={totalPages}
        />
      </SoftBox>
    </Card>
  )
}

export default StaffDataTable
