import React, { useState } from "react"
import DashboardNavbar from "../../soft_dashboard/examples/Navbars/DashboardNavbar"
import DashboardLayout from "../../soft_dashboard/examples/LayoutContainers/DashboardLayout"
import SoftBox from "../../soft_dashboard/components/SoftBox"
import SoftTypography from "../../soft_dashboard/components/SoftTypography"
import Time from "../../components/core/Time/Time"
import StaffDataTable from "../../components/core/StaffDataTable/StaffDataTable"
import Footer from "../../components/core/Footer/Footer"
import Axios from "../../Util/Axios"
import { updateToastData } from "../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../../components/core/Toast/Toast"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

const StaffPresetList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const columns = [
    {
      name: "id",
      align: "left",
    },
    {
      name: "type",
      align: "left",
    },
    {
      name: "name",
      align: "left",
    },
    {
      name: "created_at",
      align: "left",
    },
    {
      name: "updated_at",
      align: "left",
    },
    {
      name: "action",
      align: "left",
    },
  ]
  const getRow = (data) => {
    return {
      id: (
        <SoftBox ml={2}>
          <SoftTypography variant="button" fontWeight="medium">
            {data.id}
          </SoftTypography>
        </SoftBox>
      ),
      type: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {data.type_display}
        </SoftTypography>
      ),
      name: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {data.name}
        </SoftTypography>
      ),
      created_at: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          <Time>{data.created_at}</Time>
        </SoftTypography>
      ),

      updated_at: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          <Time>{data.created_at}</Time>
        </SoftTypography>
      ),

      action: (
        <SoftBox ml={2}>
          <SoftTypography
            component="a"
            href={`/custom-surfboard-order-staff-preset/${data.id}/`}
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            View
          </SoftTypography>
        </SoftBox>
      ),
    }
  }

  const dataUrl = "/api/staff-preset-list/"

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <StaffDataTable
            actions={[
              {
                name: "Create Preset",
                color: "primary",
                loading: loading,
                onAction: () => {
                  setLoading(true)
                  Axios.post(
                    `/api/staff-preset-detail/action_create_new_preset/`
                  )
                    .then(({ data }) => {
                      setLoading(false)
                      navigate(
                        `/custom-surfboard-order-staff-preset/${data.id}/`
                      )
                    })
                    .catch((e) => {
                      setLoading(false)
                      dispatch(
                        updateToastData({
                          message: "Unable to create new preset",
                          severity: TOAST_SEVERITY_ERROR,
                        })
                      )
                    })
                },
              },
            ]}
            title={"Presets"}
            columns={columns}
            getRow={getRow}
            dataUrl={dataUrl}
            filters={[
              {
                title: "Type",
                title_key: "type",
                options: [
                  {
                    key: "display",
                    value: "Display",
                  },
                  {
                    key: "inventory",
                    value: "Inventory",
                  },
                ],
              },
            ]}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}

export default StaffPresetList
