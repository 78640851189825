/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react"

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom"

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"

// @mui material components
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import Link from "@mui/material/Link"
import Icon from "@mui/material/Icon"

// Soft UI Dashboard React components
import SoftBox from "soft_dashboard/components/SoftBox"
import SoftTypography from "soft_dashboard/components/SoftTypography"
import SoftButton from "soft_dashboard/components/SoftButton"

// Soft UI Dashboard React examples
import SidenavCollapse from "soft_dashboard/examples/Sidenav/SidenavCollapse"
import SidenavCard from "soft_dashboard/examples/Sidenav/SidenavCard"

// Custom styles for the Sidenav
import SidenavRoot from "soft_dashboard/examples/Sidenav/SidenavRoot"
import sidenavLogoLabel from "soft_dashboard/examples/Sidenav/styles/sidenav"

// Soft UI Dashboard React context
import { useSoftUIController, setMiniSidenav } from "soft_dashboard/context"
import routes from "../../../AppRouter/SideNavRoutes"

function Sidenav({ color, brandName, ...rest }) {
  const [controller, dispatch] = useSoftUIController()
  const { miniSidenav, transparentSidenav } = controller
  const location = useLocation()
  const { pathname } = location
  const collapseName = pathname.split("/").slice(1)[0]

  const closeSidenav = () => setMiniSidenav(dispatch, true)

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200)
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav)

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav)
  }, [dispatch, location])

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, key, route, href }) => {
      let returnValue

      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={key === collapseName}
            />
          </Link>
        ) : (
          <NavLink to={route} key={key}>
            <SidenavCollapse
              color={color}
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
            />
          </NavLink>
        )
      } else if (type === "title") {
        returnValue = (
          <SoftTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SoftTypography>
        )
      } else if (type === "divider") {
        returnValue = <Divider key={key} />
      }

      return returnValue
    }
  )

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, miniSidenav }}
    >
      <SoftBox pt={3} pb={1} px={4} textAlign="center">
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox
          component={NavLink}
          to="/staff"
          display="flex"
          alignItems="center"
        >
          <SoftBox
            component="img"
            src={
              "https://marlinsurf-assets.s3.us-west-1.amazonaws.com/marlinmedium.png"
            }
            alt="Soft UI Logo"
            width="2rem"
          />

          <SoftBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SoftTypography component="h6" variant="button" fontWeight="medium">
              {brandName}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Divider />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  )
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
}

export default Sidenav
