import React, { useState } from "react"
import SoftBox from "../../soft_dashboard/components/SoftBox"
import SoftTypography from "../../soft_dashboard/components/SoftTypography"
import SoftButton from "../../soft_dashboard/components/SoftButton"
import MoneyOffCsredIcon from "@mui/icons-material/MoneyOffCsred"
import DescriptionItem from "../core/DescriptionItem/DescriptionItem"
import { getCurrencyStringFromNumber } from "../../Util/Util"
import DeleteIcon from "@mui/icons-material/Delete"
import RefundOrDeleteModal from "./RefundOrDeleteModal"

const TransactionItem = ({ transactionData, setOrderData, orderData }) => {
  const [refundOrDeleteModalActive, setRefundOrDeleteModalActive] =
    useState(null)
  return (
    <SoftBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      p={3}
      mb={1}
      mt={2}
    >
      <SoftBox width="100%" display="flex" flexDirection="column">
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <SoftTypography
            variant="button"
            fontWeight="medium"
            textTransform="capitalize"
          >
            #{transactionData.id}{" "}
            {transactionData.fully_refunded && "[REFUNDED]"}
          </SoftTypography>

          {transactionData.type === "stripe" &&
            !transactionData.fully_refunded && (
              <SoftBox
                display="flex"
                alignItems="center"
                mt={{ xs: 2, sm: 0 }}
                ml={{ xs: -1.5, sm: 0 }}
              >
                <SoftBox>
                  <SoftButton
                    onClick={() => {
                      setRefundOrDeleteModalActive("refund")
                    }}
                    variant="text"
                    color="error"
                  >
                    <MoneyOffCsredIcon />
                    &nbsp;Refund
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            )}
          {transactionData.type === "manual" && (
            <SoftBox
              display="flex"
              alignItems="center"
              mt={{ xs: 2, sm: 0 }}
              ml={{ xs: -1.5, sm: 0 }}
            >
              <SoftBox>
                <SoftButton
                  onClick={() => {
                    setRefundOrDeleteModalActive("delete")
                  }}
                  variant="text"
                  color="error"
                >
                  <DeleteIcon />
                  &nbsp;Delete
                </SoftButton>
              </SoftBox>
            </SoftBox>
          )}
        </SoftBox>
        <SoftBox lineHeight={0}>
          <DescriptionItem
            spacing={"tight"}
            keyJsx={
              <SoftTypography variant="caption" color="text">
                Type
              </SoftTypography>
            }
            valueJsx={
              <SoftTypography variant="caption" fontWeight="medium">
                {transactionData.type_display}
              </SoftTypography>
            }
          />
        </SoftBox>

        <SoftBox lineHeight={0}>
          <DescriptionItem
            spacing={"tight"}
            keyJsx={
              <SoftTypography variant="caption" color="text">
                Is Deposit
              </SoftTypography>
            }
            valueJsx={
              <SoftTypography variant="caption" fontWeight="medium">
                {transactionData.is_deposit ? "Yes" : "No"}
              </SoftTypography>
            }
          />
        </SoftBox>
        <SoftBox lineHeight={0}>
          <DescriptionItem
            spacing={"tight"}
            keyJsx={
              <SoftTypography variant="caption" color="text">
                Amount Subtotal
              </SoftTypography>
            }
            valueJsx={
              <SoftTypography variant="caption" fontWeight="medium">
                {getCurrencyStringFromNumber(transactionData.amount_subtotal)}
              </SoftTypography>
            }
          />
        </SoftBox>
        <SoftBox lineHeight={0}>
          <DescriptionItem
            spacing={"tight"}
            keyJsx={
              <SoftTypography variant="caption" color="text">
                Amount Tax
              </SoftTypography>
            }
            valueJsx={
              <SoftTypography variant="caption" fontWeight="medium">
                {getCurrencyStringFromNumber(transactionData.amount_tax)}
              </SoftTypography>
            }
          />
        </SoftBox>
        <SoftBox lineHeight={0}>
          <DescriptionItem
            spacing={"tight"}
            keyJsx={
              <SoftTypography variant="caption" color="text">
                Total
              </SoftTypography>
            }
            valueJsx={
              <SoftTypography variant="caption" fontWeight="medium">
                {getCurrencyStringFromNumber(transactionData.amount_total)}
              </SoftTypography>
            }
          />
        </SoftBox>
        {transactionData.type === "stripe" && (
          <SoftBox lineHeight={0}>
            <DescriptionItem
              spacing={"tight"}
              keyJsx={
                <SoftTypography variant="caption" color="text">
                  Amount Refunded
                </SoftTypography>
              }
              valueJsx={
                <SoftTypography variant="caption" fontWeight="medium">
                  {getCurrencyStringFromNumber(transactionData.amount_refunded)}
                </SoftTypography>
              }
            />
          </SoftBox>
        )}
      </SoftBox>
      <RefundOrDeleteModal
        active={refundOrDeleteModalActive}
        setActive={setRefundOrDeleteModalActive}
        orderData={orderData}
        transactionData={transactionData}
        setOrderData={setOrderData}
      />
    </SoftBox>
  )
}

export default TransactionItem
