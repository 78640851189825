import React from "react"
import { Box, Checkbox, Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import { getCurrencyStringFromNumber } from "../../Util/Util"

const CustomOrderOptionCheckbox = ({
  selectedOption,
  setSelectedOption,
  option,
  updateOptions,
  orderData,
  isStaff,
}) => {
  let title = option?.type_display || ""
  if (option?.price > 0) {
    title += ` ${getCurrencyStringFromNumber(option.price)}`
  }

  return (
    <Box pb={5}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Box
          my={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
        <Checkbox
          checked={!!selectedOption}
          disabled={orderData?.locked && !isStaff}
          onChange={(event) => {
            if (event.target.checked) {
              updateOptions([option], [])
            } else {
              updateOptions([], [option])
            }
          }}
        />
      </Grid>
      {option?.help_text && (
        <Box>
          <Typography
            variant={"caption"}
            style={{
              fontFamily: "Josefin Sans",
            }}
          >
            {option.help_text}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default CustomOrderOptionCheckbox
