import React, { useEffect, useState } from "react"
import Modal from "../core/Modal/Modal"
import Axios from "../../Util/Axios"
import SoftTypography from "../../soft_dashboard/components/SoftTypography"
import SoftBox from "../../soft_dashboard/components/SoftBox"
import { updateToastData } from "../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../core/Toast/Toast"
import { useDispatch } from "react-redux"
import TextField from "@mui/material/TextField"
import { InputAdornment } from "@mui/material"

const RefundOrDeleteModal = ({
  orderData,
  transactionData,
  setOrderData,
  active,
  setActive,
}) => {
  let totalPossibleRefund =
    parseFloat(transactionData.amount_total) -
    parseFloat(transactionData.amount_refunded)

  const [loading, setLoading] = useState(false)
  const [amountToRefund, setAmountToRefund] = useState(totalPossibleRefund)
  const dispatch = useDispatch()
  let isRefund = active === "refund"
  useEffect(() => {
    setAmountToRefund(totalPossibleRefund)
  }, [active])
  return (
    <Modal
      title={isRefund ? "Refund Transaction" : "Delete Transaction"}
      confirmDisabled={loading}
      onConfirm={() => {
        setLoading(true)
        let action = "action_refund_transaction"
        if (!isRefund) {
          action = "action_delete_transaction"
        }

        Axios.post(
          `/api/staff-order-detail/${orderData.customer_facing_id}/${action}/`,
          {
            transaction_id: transactionData.id,
            amount_to_refund: amountToRefund,
          }
        )
          .then(({ data }) => {
            setLoading(false)
            setOrderData(data)
            setActive(false)
          })
          .catch((e) => {
            setLoading(false)
            dispatch(
              updateToastData({
                message: e,
                severity: TOAST_SEVERITY_ERROR,
              })
            )
          })
      }}
      active={active}
      setActive={setActive}
      confirmButtonName={isRefund ? "Refund" : "Delete"}
    >
      <SoftBox pb={3}>
        <SoftTypography variant={"h6"}>
          Are you sure you want to {isRefund ? "refund" : "delete"} this order?
        </SoftTypography>

        {isRefund && (
          <TextField
            disabled={loading}
            margin="normal"
            fullWidth
            label="Amount to Refund"
            value={amountToRefund}
            type={"number"}
            InputProps={{
              inputProps: { min: 0, max: totalPossibleRefund },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(event) => {
              setAmountToRefund(event.target.value)
            }}
          />
        )}
      </SoftBox>
    </Modal>
  )
}

export default RefundOrDeleteModal
