import React, { useState } from "react"
import Modal from "../../components/core/Modal/Modal"
import { Box } from "@mui/system"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import CustomOrderColorModal from "./CustomOrderColorModal"

const AddColorModal = ({
  colorsOpen,
  setColorsOpen,

  active,
  setActive,

  selectedBottomColorOption,
  setSelectedBottomColorOption,
  bottomColorOptions,

  selectedDeckColorOption,
  setSelectedDeckColorOption,
  deckColorOptions,

  // selectedMidBandColorOption,
  // setSelectedMidBandColorOption,
  // midBandColorOptions,

  // selectedTailDipColorOption,
  // setSelectedTailDipColorOption,
  // tailDipColorOptions,

  selectedPinLineColorOption,
  setSelectedPinLineColorOption,
  pinLineColorOptions,

  selectedAbstractColorOption,
  setSelectedAbstractColorOption,
  abstractColorOption,

  updateOptions,
}) => {
  const [customOrderColorModalActive, setCustomOrderColorModalActive] =
    useState(false)
  const [colorOptions, setColorOptions] = useState([])

  const handleColorSelect = (color) => {
    let newOptions = []
    if (color.type === "bottom_color") {
      setSelectedBottomColorOption(color)
      let newDeckOption = deckColorOptions.find((x) => x.value === "#ffffff")
      setSelectedDeckColorOption(newDeckOption)
      newOptions.push(newDeckOption)
    }

    setSelectedAbstractColorOption(null)
    setCustomOrderColorModalActive(false)
    newOptions.push(color)
    updateOptions(newOptions, [selectedAbstractColorOption])
    setColorsOpen(true)
  }

  return (
    <React.Fragment>
      <Modal title={"Add Color"} active={active} setActive={setActive}>
        <Box pb={3} width={1}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Button
                variant="contained"
                color={"info"}
                fullWidth
                onClick={() => {
                  setActive(false)
                  setColorOptions(bottomColorOptions)
                  setCustomOrderColorModalActive(true)
                }}
              >
                Single Tone
              </Button>
            </Grid>

            {/*<Grid item>*/}
            {/*  <Button*/}
            {/*    variant="contained"*/}
            {/*    color={"info"}*/}
            {/*    fullWidth*/}
            {/*    onClick={() => {*/}
            {/*      setColorOptions(midBandColorOptions)*/}
            {/*      setCustomOrderColorModalActive(true)*/}
            {/*      setActive(false)*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Mid Color Band*/}
            {/*  </Button>*/}
            {/*</Grid>*/}
            {/*<Grid item>*/}
            {/*  <Button*/}
            {/*    variant="contained"*/}
            {/*    color={"info"}*/}
            {/*    fullWidth*/}
            {/*    onClick={() => {*/}
            {/*      setColorOptions(tailDipColorOptions)*/}
            {/*      setCustomOrderColorModalActive(true)*/}
            {/*      setActive(false)*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Tail Dip*/}
            {/*  </Button>*/}
            {/*</Grid>*/}
            <Grid item>
              <Button
                variant="contained"
                color={"info"}
                fullWidth
                onClick={() => {
                  setColorOptions(pinLineColorOptions)
                  setCustomOrderColorModalActive(true)
                  setActive(false)
                }}
              >
                Pin Line
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color={"info"}
                fullWidth
                onClick={() => {
                  setSelectedBottomColorOption(null)
                  setSelectedDeckColorOption(null)
                  // setSelectedMidBandColorOption(null)
                  // setSelectedTailDipColorOption(null)
                  setSelectedPinLineColorOption(null)

                  updateOptions(
                    [abstractColorOption],
                    [
                      selectedBottomColorOption,
                      selectedDeckColorOption,
                      // selectedMidBandColorOption,
                      // selectedTailDipColorOption,
                      selectedPinLineColorOption,
                    ]
                  )
                  setActive(false)
                }}
              >
                Abstract
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <CustomOrderColorModal
        active={customOrderColorModalActive}
        setActive={setCustomOrderColorModalActive}
        colorOptions={colorOptions}
        handleColorSelect={handleColorSelect}
      />
    </React.Fragment>
  )
}

export default AddColorModal
