import * as React from "react"

const HybridSquareSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps
  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 932.953

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M90.146,907.617 c0,0-65.249-127.296-68.249-490.836c-3-363.54,143-399.395,143-399.395S310.9,53.242,307.9,416.78 c-3.002,363.54-67.404,491.186-67.404,491.186L90.146,907.617z" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path d=" M96.813,896.165c0,0-60.752-122.803-63.514-477.055c-2.76-354.245,131.6-389.185,131.6-389.185S299.26,64.864,296.5,419.109 c-2.764,354.249-62.525,477.056-62.525,477.056H96.813z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M90.146,907.616 c0,0-65.249-127.296-68.249-490.836l142.344-80.544L307.9,416.78c-3.002,363.54-67.404,491.186-67.404,491.186" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M90.146,907.616 c0,0-65.249-127.296-68.249-490.836c0.001,0,129.162-181.224,286.003,0c-3.002,363.54-67.404,491.186-67.404,491.186" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M90.591,907.616 c0,0-65.249-127.296-68.249-490.836c0,0,52.178-9.656,91.511-34.173c39.333-24.518,51.463-60.942,51.463-60.942 s15.33,36.507,48.024,59.541c35.8,25.217,95.004,35.574,95.004,35.574c-3.002,363.54-67.403,491.186-67.403,491.186" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.146,907.619 c0,0-36.271-70.765-55.97-257.44l131.167-38.35l130.421,38.35c-19.649,180.18-55.268,257.788-55.268,257.788" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.146,907.619 c0,0-36.271-70.765-55.97-257.44l131.167,56.05l130.421-56.05c-19.649,180.18-55.268,257.788-55.268,257.788" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path d="M90.146,907.619 c0,0-36.271-70.765-55.97-257.44c0,0,60.565-2.79,94.565-19.602s36.602-27.845,36.602-27.845s13.287,20.184,30.787,27.014 c24.374,9.512,99.634,20.433,99.634,20.433c-19.649,180.18-55.268,257.788-55.268,257.788" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.417,907.81 c0,0-37.271-70.765-56.97-257.441c0,0,89.565-4.89,107.065,20.328c17.5,25.217,24.384,42.715,24.384,42.715 s10.149-23.055,34.49-42.736c25.355-20.496,95.648-20.307,95.648-20.307c-17.293,180.196-54.268,257.791-54.268,257.791" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path d="M90.148,907.618 c0,0-32.837-64.066-52.992-231.089c-1.027-8.515-2.022-17.297-2.978-26.352c-0.001,0.001,125.939-86.166,261.585,0.001 c0,0-1.712,15.232-2.605,22.568c-20.026,164.11-52.66,235.219-52.66,235.219" />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={264.587} y1={127.928} x2={264.587} y2={794.595} />
          <line x1={65.503} y1={127.928} x2={65.503} y2={794.595} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={149.963} y1={22.631} x2={149.963} y2={906.976} />
          <line x1={180.127} y1={22.631} x2={180.127} y2={906.976} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.045} y1={17.386} x2={165.045} y2={908.159} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M173.396,842.474c0,2.892-2.129,5.319-4.56,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V699.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.56,2.434,4.56,5.318V842.474z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M173.396,842.474c0,2.892-2.129,5.319-4.56,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V699.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.56,2.434,4.56,5.318V842.474z" />
          <path d="M75.386,728.686c-0.304,2.889-2.583,5.015-5.166,4.56 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,728.686z" />
          <path d="M272.169,726.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064 c2.432-0.456,4.862,1.521,5.317,4.406L272.169,726.104z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M75.386,728.989c-0.304,2.887-2.583,5.015-5.166,4.559 l-6.384-0.912c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.013,5.167-4.559l6.381,0.913 c2.583,0.304,4.255,3.038,3.951,5.924L75.386,728.989z" />
          <path d="M272.169,726.404c0.455,2.889-1.37,5.624-3.8,6.079l-6.385,1.063 c-2.582,0.456-4.861-1.52-5.317-4.408l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.078l6.382-1.063 c2.432-0.455,4.862,1.521,5.317,4.405L272.169,726.404z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,728.686c-0.304,2.889-2.583,5.015-5.166,4.56 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,728.686z" />
          <path d="M272.169,726.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064 c2.432-0.456,4.862,1.521,5.317,4.406L272.169,726.104z" />
          <path d="M173.094,863.479c0,2.89-2.129,5.317-4.559,5.317H162 c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.316,4.559-5.316h6.535c2.583,0,4.559,2.43,4.559,5.316V863.479z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,728.686c-0.304,2.889-2.583,5.015-5.166,4.56 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,728.686z" />
          <path d="M106.658,815.299c0,2.892-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.432-5.415-5.319v-60.021c0-2.89,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V815.299z" />
          <path d="M272.169,726.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064 c2.432-0.456,4.862,1.521,5.317,4.406L272.169,726.104z" />
          <path d="M248.695,813.78c0,2.888-2.525,5.316-5.414,5.316h-7.762 c-3.066,0-5.414-2.431-5.414-5.316v-60.022c0-2.887,2.528-5.316,5.414-5.316h7.762c3.068,0,5.414,2.432,5.414,5.316V813.78z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M173.396,842.474c0,2.892-2.129,5.319-4.56,5.319h-6.99 c-2.583,0-4.559-2.43-4.559-5.319V699.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.56,2.434,4.56,5.318V842.474z" />
          <path d="M75.386,728.686c-0.304,2.889-2.583,5.015-5.166,4.56 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,728.686z" />
          <path d="M106.658,815.299c0,2.892-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.432-5.415-5.319v-60.021c0-2.89,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V815.299z" />
          <path d="M272.169,726.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064 c2.432-0.456,4.862,1.521,5.317,4.406L272.169,726.104z" />
          <path d="M248.695,813.78c0,2.888-2.525,5.316-5.414,5.316h-7.762 c-3.066,0-5.414-2.431-5.414-5.316v-60.022c0-2.887,2.528-5.316,5.414-5.316h7.762c3.068,0,5.414,2.432,5.414,5.316V813.78z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M75.386,728.686c-0.304,2.889-2.583,5.015-5.166,4.56 l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911 c2.583,0.306,4.255,3.04,3.951,5.926L75.386,728.686z" />
          <path d="M106.658,815.299c0,2.892-2.529,5.319-5.415,5.319h-7.759 c-3.072,0-5.415-2.432-5.415-5.319v-60.021c0-2.89,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V815.299z" />
          <path d="M272.169,726.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066 c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064 c2.432-0.456,4.862,1.521,5.317,4.406L272.169,726.104z" />
          <path d="M248.695,813.78c0,2.888-2.525,5.316-5.414,5.316h-7.762 c-3.066,0-5.414-2.431-5.414-5.316v-60.022c0-2.887,2.528-5.316,5.414-5.316h7.762c3.068,0,5.414,2.432,5.414,5.316V813.78z" />
          <path d="M173.094,863.479c0,2.89-2.129,5.317-4.559,5.317H162 c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.316,4.559-5.316h6.535c2.583,0,4.559,2.43,4.559,5.316V863.479z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={516.213}
          width={96}
          height={95.616}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d=" M110.25,880.022c0,0-66.01-93.939-65.972-453.706C41.749,77.297,164.898,42.875,164.898,42.875S288.05,77.298,285.52,426.315 c-2.531,349.018-67.109,453.897-67.109,453.897L110.25,880.022z" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d=" M90.146,907.617c0,0-63.404-97.719-68.249-490.836c-4.479-363.522,143-399.395,143-399.395S310.9,53.242,307.9,416.78 c-3.002,363.54-67.404,491.186-67.404,491.186L90.146,907.617z" />
      </g>
    </svg>
  )
}
export default HybridSquareSvg
