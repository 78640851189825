import * as React from "react"

const PerformanceRoundSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 973.455

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path d="M87.836,855.037 c0,0-38.9-117.025-38.9-353.419c0-381.031,116.005-481.915,116.005-481.915s116.01,98.786,116.01,479.577 c0,268.732-36.123,355.052-36.123,355.052S173.742,1075.084,87.836,855.037z" />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={18}
        opacity={overlapOpacity}
      >
        <path d=" M96.992,846.834c0,0-36.876-113.969-36.876-343.853c0-361.646,104.825-468.646,104.825-468.646s104.83,102.916,104.83,466.374 c0,261.334-33.779,344.875-33.779,344.875S174.619,1060.82,96.992,846.834z" />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M277.646,396.52 c16.596,383.14-46.703,491.916-46.703,491.916s-58.422,145.431-127.471,2.283c0,0-67.73-117.491-51.229-494.199l112.178-80.529 L277.646,396.52z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path d="M277.646,396.52 c16.596,382.903-46.703,491.916-46.703,491.916s-58.422,145.431-127.471,2.283c0,0-69.23-133.491-51.229-494.199 C52.244,396.52,152.94,215.328,277.646,396.52z" />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path d="M277.998,396.52 c15.078,388.219-47.057,491.916-47.057,491.916s-58.949,144.898-128,1.755c0,0-66.699-119.463-51.346-493.669 c0,0,41.487-9.654,72.761-34.167s40.917-60.931,40.917-60.931s12.188,36.501,38.187,59.53 C231.925,386.165,277.998,396.52,277.998,396.52z" />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M274,680.2C262.7,840,227.7,898,227.7,898s-55.5,123.3-121-1.2c0,0-36-48.6-49.7-216.6
		l108.3-32.5L274,680.2z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M274,680.2c-8.7,158.2-47,218.5-47,218.5s-55.1,121.7-120.3,0c0,0-36-56.1-49.7-218.5
		l108.3,47.6L274,680.2z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path
            d="M274,680.2c-10.6,161.1-46.5,218.4-46.5,218.4s-56.9,122.5-120.3,0.1
		c0,0-35.9-52.8-50.2-218.5c0,0,50-2.4,78.1-16.6c28.1-14.3,30.2-23.6,30.2-23.6s11,17.1,25.4,22.9C210.9,670.9,274,680.2,274,680.2
		z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M274.4,680.3c-8.8,154.5-47,218.3-47,218.3s-54.5,122-120.8,0.1
		c0,0-35.6-54.6-49.2-218.5c0,0,72.9-4.2,87.4,17.3c14.4,21.4,20.1,36.3,20.1,36.3s8.4-19.6,28.5-36.3
		C214.4,680.2,274.4,680.3,274.4,680.3z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            d="M274,680.2c0,0-0.3,12.8-1,19c-12.9,150.9-45.6,199.5-45.6,199.5s-56.5,122-120.2,0
		c0,0-32.6-45.4-47.8-196.1c-0.8-7.2-1.7-14.7-2.5-22.4C57,680.2,162,607,274,680.2z"
          />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={244.207} y1={182.394} x2={244.207} y2={848.406} />
          <line x1={85.915} y1={182.394} x2={85.915} y2={848.406} />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={153.069} y1={32.739} x2={153.069} y2={949.766} />
          <line x1={177.053} y1={32.739} x2={177.053} y2={949.766} />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line x1={165.061} y1={19.703} x2={165.061} y2={952.609} />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V863.752z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V863.752z" />
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path d="M94.858,776.088c-0.238,2.23-2.027,3.874-4.056,3.523 l-5.013-0.705c-2.028-0.235-3.339-2.349-3.101-4.577l5.248-46.014c0.24-2.23,2.029-3.871,4.057-3.521l5.011,0.703 c2.028,0.234,3.341,2.347,3.102,4.577L94.858,776.088z" />
          <path d="M249.374,774.093c0.356,2.229-1.075,4.345-2.983,4.697 l-5.014,0.818c-2.027,0.354-3.816-1.175-4.175-3.404l-6.204-45.779c-0.357-2.229,1.072-4.342,2.984-4.693l5.011-0.82 c1.909-0.351,3.817,1.175,4.176,3.403L249.374,774.093z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M171.58,879.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.482c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V879.979z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M171.816,863.752c0,2.233-1.672,4.109-3.58,4.109h-5.487 c-2.028,0-3.58-1.878-3.58-4.109V753.181c0-2.229,1.67-4.106,3.58-4.106h5.487c2.029,0,3.58,1.879,3.58,4.106V863.752z" />
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path d="M94.858,775.854c-0.238,2.232-2.027,3.875-4.056,3.521 l-5.013-0.703c-2.028-0.234-3.339-2.349-3.101-4.576l5.248-46.013c0.24-2.229,2.029-3.874,4.057-3.523l5.011,0.703 c2.028,0.238,3.341,2.351,3.102,4.577L94.858,775.854z" />
          <path d="M119.414,842.761c0,2.233-1.986,4.109-4.252,4.109h-6.093 c-2.412,0-4.252-1.879-4.252-4.109v-46.361c0-2.232,1.984-4.109,4.252-4.109h6.093c2.409,0,4.252,1.877,4.252,4.109V842.761z" />
          <path d="M249.374,773.861c0.356,2.23-1.075,4.343-2.983,4.692 l-5.014,0.823c-2.027,0.352-3.816-1.174-4.175-3.405l-6.204-45.777c-0.357-2.23,1.072-4.344,2.984-4.695l5.011-0.82 c1.909-0.354,3.817,1.175,4.176,3.402L249.374,773.861z" />
          <path d="M230.941,841.588c0,2.229-1.981,4.105-4.25,4.105h-6.096 c-2.406,0-4.252-1.878-4.252-4.105v-46.365c0-2.229,1.985-4.104,4.252-4.104h6.096c2.408,0,4.25,1.877,4.25,4.104V841.588z" />
          <path d="M171.58,879.979c0,2.231-1.672,4.105-3.58,4.105h-5.131 c-2.029,0-3.58-1.876-3.58-4.105v-46.482c0-2.229,1.67-4.107,3.58-4.107H168c2.027,0,3.58,1.878,3.58,4.107V879.979z" />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={535.58}
          width={96}
          height={92.504}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path d="M105.742,839.084 c0,0-33.806-110.51-33.806-332.481c0-357.79,93.005-452.518,93.005-452.518s93.01,92.761,93.01,450.322 c0,252.341-28.961,333.394-28.961,333.394S174.616,1045.707,105.742,839.084z" />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path d=" M87.836,855.037c0,0-38.9-117.025-38.9-353.419c0-381.031,116.005-481.915,116.005-481.915s116.01,98.786,116.01,479.577 c0,268.732-36.123,355.052-36.123,355.052S173.742,1075.084,87.836,855.037z" />
      </g>
    </svg>
  )
}
export default PerformanceRoundSvg
