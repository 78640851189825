import React, { useEffect, useState } from "react"
import {
  AppBar,
  Badge,
  Drawer,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material"
import { Link } from "react-router-dom"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import MenuIcon from "@mui/icons-material/Menu"
import { useSelector } from "react-redux"
import Axios from "../../Util/Axios"
import { darkBlue } from "../../assets/userTheme"

const NavBar = ({
  background = "transparent",
  position = "absolute",
  opacity = 1,
}) => {
  let textShadow = "3px 3px 4px #000000"
  const [mobileOpen, setMobileOpen] = useState(false)
  const [customOrderAnchorEl, setCustomOrderAnchorEl] = useState(null)
  const [shopAnchorEl, setShopAnchorEl] = useState(null)
  const [displayPresets, setDisplayPresets] = useState([])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleCustomOrderMenuOpen = (event) => {
    setCustomOrderAnchorEl(event.currentTarget)
  }
  const handleShopMenuOpen = (event) => {
    setShopAnchorEl(event.currentTarget)
  }

  const handleCustomOrderMenuClose = () => {
    setCustomOrderAnchorEl(null)
  }

  const handleShopMenuClose = () => {
    setShopAnchorEl(null)
  }

  useEffect(() => {
    Axios.get("/api/preset-list/?type=display").then(({ data }) => {
      setDisplayPresets(data.results)
    })
  }, [])

  const { cart } = useSelector((state) => state)
  const cartItems = cart.cart_items || []

  const navigationItems = [
    // { name: "Custom Order", path: "/custom-surfboard-order" },
    // { name: "Inventory", path: "/surfboard-inventory" },
    { name: "Gallery", path: "/gallery" },
    { name: "About", path: "/about" },
    { name: "FAQ", path: "/faq" },
    // { name: "Merch", path: "/merchandise" },
  ]

  const drawer = (
    <div>
      <List>
        <ListItem button onClick={handleCustomOrderMenuOpen}>
          <Typography
            // sx={{ textShadow: textShadow, textDecoration: "none" }}
            variant="h6"
            edge="end"
            color="inherit"
            sx={{
              fontFamily: "Lobster",
            }}
          >
            Custom Order
          </Typography>
        </ListItem>
        <ListItem button onClick={handleShopMenuOpen}>
          <Typography
            // sx={{ textShadow: textShadow, textDecoration: "none" }}
            variant="h6"
            edge="end"
            color="inherit"
            sx={{
              fontFamily: "Lobster",
            }}
          >
            Shop
          </Typography>
        </ListItem>
        {navigationItems.map((item, index) => {
          let to = item.path
          if (window.location.pathname.includes(to)) {
            to = window.location.pathname
          }
          return (
            <ListItem
              button
              key={index}
              component={Link}
              to={to}
              onClick={handleDrawerToggle}
            >
              <Typography
                sx={{ mr: 3, textDecoration: "none", fontFamily: "Lobster" }}
                variant="h6"
                edge="end"
                color="inherit"
              >
                {item.name}
              </Typography>
            </ListItem>
          )
        })}
      </List>
    </div>
  )

  return (
    <AppBar
      position="sticky" // Make the AppBar sticky
      style={{ background, opacity }}
    >
      <Toolbar
        variant="dense"
        style={{ background: darkBlue, boxShadow: "none" }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { xs: "block", sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          variant="temporary"
          PaperProps={{
            sx: {
              backgroundColor: darkBlue,
              color: "white",
            },
          }}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          component={Link}
          to={"/"}
        >
          <img
            height={30}
            width={50}
            src="https://marlinsurf-assets.s3.us-west-1.amazonaws.com/marlinmedium.png"
            className="App-logo"
            alt="logo"
          />
        </IconButton>
        <Typography
          variant="h6"
          component={Link}
          color="inherit"
          to="/"
          sx={{
            flexGrow: 1,
            textShadow: textShadow,
            textDecoration: "none",
            fontFamily: "Lobster",
          }}
        >
          Marlin Surfboards
        </Typography>

        <React.Fragment>
          <Typography
            sx={{
              mr: 0.5,
              textShadow: textShadow,
              textDecoration: "none",
              display: { xs: "none", sm: "block" },
              fontFamily: "Lobster",
            }}
            variant="h6"
            edge="end"
            color="inherit"
            component={IconButton}
            onClick={handleCustomOrderMenuOpen}
            to={"/custom-surfboard-order"}
          >
            Custom Order
          </Typography>
          <Typography
            sx={{
              mr: 2,
              textShadow: textShadow,
              textDecoration: "none",
              display: { xs: "none", sm: "block" },
              fontFamily: "Lobster",
            }}
            variant="h6"
            edge="end"
            color="inherit"
            component={IconButton}
            onClick={handleShopMenuOpen}
            to={"/custom-surfboard-order"}
          >
            Shop
          </Typography>
          <Menu
            anchorEl={customOrderAnchorEl}
            open={Boolean(customOrderAnchorEl)}
            onClose={handleCustomOrderMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {displayPresets.map((preset) => {
              return (
                <MenuItem
                  key={preset.id}
                  sx={{
                    fontFamily: "Lobster",
                    color: "black",
                    fontSize: "1.2rem",
                    paddingLeft: 5,
                    paddingRight: 5,
                    "&:hover": {
                      color: "white",
                      backgroundColor: darkBlue,
                    },
                  }}
                  component={Link}
                  to={`/custom-surfboard-order?preset_id=${preset.id}`}
                  onClick={handleCustomOrderMenuClose}
                >
                  {preset.name}
                </MenuItem>
              )
            })}

            {/* Add additional dropdown items here */}
          </Menu>
          <Menu
            anchorEl={shopAnchorEl}
            open={Boolean(shopAnchorEl)}
            onClose={handleShopMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              key={"Surfboards"}
              sx={{
                fontFamily: "Lobster",
                color: "black",
                fontSize: "1.2rem",
                paddingLeft: 5,
                paddingRight: 5,
                "&:hover": {
                  color: "white",
                  backgroundColor: darkBlue,
                },
              }}
              component={Link}
              to={`/surfboard-inventory`}
              onClick={handleShopMenuClose}
            >
              Surfboards
            </MenuItem>
            <MenuItem
              key={"Merch"}
              sx={{
                fontFamily: "Lobster",
                color: "black",
                fontSize: "1.2rem",
                paddingLeft: 5,
                paddingRight: 5,
                "&:hover": {
                  color: "white",
                  backgroundColor: darkBlue,
                },
              }}
              component={Link}
              to={`/merchandise/`}
              onClick={handleShopMenuClose}
            >
              Merch
            </MenuItem>

            {/* Add additional dropdown items here */}
          </Menu>
        </React.Fragment>

        {navigationItems.map((item, index) => (
          <Typography
            key={`desktop-${index}`}
            sx={{
              mr: 3,
              textShadow: textShadow,
              textDecoration: "none",
              display: { xs: "none", sm: "block" },
              fontFamily: "Lobster",
            }}
            variant="h6"
            edge="end"
            color="inherit"
            component={Link}
            to={item.path}
          >
            {item.name}
          </Typography>
        ))}

        <IconButton
          size="large"
          edge="end"
          color="inherit"
          component={Link}
          to="/cart"
          aria-label={"Shopping Cart"}
        >
          {cartItems.length > 0 && (
            <Badge badgeContent={cartItems.length} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          )}
          {cartItems.length === 0 && <ShoppingCartIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
