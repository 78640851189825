import * as React from "react"

const HybridPinSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps
  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 328.484
  let originalHeight = 932.953

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
      >
        <path
          id="_x3C_Path_x3E__5_"
          d="M165.544,907.966
		c0,0-143.647,6.339-143.647-491.185c0-363.552,143-399.395,143-399.395S310.9,53.242,307.9,416.78
		C307.9,906.976,165.544,907.966,165.544,907.966L165.544,907.966z"
        />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={22}
        opacity={overlapOpacity}
      >
        <path
          id="_x3C_Path_x3E__6_"
          d="
                M165.544,896.165c0,0-132.287,0-132.245-477.055c-2.76-354.245,131.6-389.185,131.6-389.185S299.26,64.864,296.5,419.109
                C296.5,888.311,165.544,896.165,165.544,896.165L165.544,896.165z"
        />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            id="_x3C_Path_x3E__23_"
            d="M165.544,907.616
                c0,0-143.647,8.86-143.647-490.836l142.344-80.544L307.9,416.78c0,485.197-142.356,491.186-142.356,491.186"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path
            id="_x3C_Path_x3E__22_"
            d="M165.544,907.616
                c0,0-143.647,7.36-143.647-490.836c0.001,0,129.162-181.224,286.003,0c0,487.89-142.356,491.186-142.356,491.186"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            id="_x3C_Path_x3E__21_"
            d="M165.544,907.616
                c0,0-143.202,5.86-143.202-490.836c0,0,52.178-9.656,91.511-34.173c39.333-24.518,51.463-60.942,51.463-60.942
                s15.33,36.507,48.024,59.541c35.8,25.217,95.004,35.574,95.004,35.574c0,487.89-142.801,491.186-142.801,491.186"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            id="_x3C_Path_x3E__20_"
            d="M165.544,907.619
                c0,0-94.565,3.702-130.003-257.902l129.802-37.888l128.666,37.834C256.742,907.97,165.544,907.967,165.544,907.967"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            id="_x3C_Path_x3E__19_"
            d="M165.544,907.619
                c0,0-100.041,6.373-133.263-283.936L164.8,683.655l132.519-59.972c-34.909,284.476-131.774,284.283-131.774,284.283"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path
            id="_x3C_Path_x3E__18_"
            d="M165.544,907.619
                c0,0-94.565,3.706-129.951-257.517c0,0,59.148-2.714,93.148-19.525s36.602-27.845,36.602-27.845s13.287,20.184,30.787,27.014
                c24.374,9.512,97.844,20.168,97.844,20.168c-37.231,258.053-128.43,258.053-128.43,258.053"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            id="_x3C_Path_x3E__17_"
            d="M165.544,907.81
                c0,0-100.041,4.181-133.567-286.812c0,0,88.099,6.322,109.765,31.989c19.799,23.455,22.5,32.666,22.5,32.666s6.487-12.567,28.5-33
                c28.537-28.536,104.886-31.655,104.886-31.655c-33.041,282.325-132.084,287.161-132.084,287.161"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            id="_x3C_Path_x3E__16_"
            d="M165.544,907.618
                c0,0-88.468,2.372-126.468-231.295c-1.027-8.515-2.712-18.523-3.668-27.578c-0.001,0.001,123.029-85.789,258.675,0.378
                c0,0-2.78,15.864-3.674,23.2c-39,235.643-124.865,235.643-124.865,235.643"
          />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="StringerTripleRight"
            x1="264.587"
            y1="127.928"
            x2="264.587"
            y2="784.084"
          />

          <line
            id="StringerTripleLeft_1_"
            x1="65.503"
            y1="127.928"
            x2="65.503"
            y2="784.084"
          />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="StringerDoubleLeft_1_"
            x1="149.963"
            y1="22.631"
            x2="149.963"
            y2="904.67"
          />

          <line
            id="StringerDoubleRight"
            x1="180.127"
            y1="22.631"
            x2="180.127"
            y2="904.67"
          />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="StringerSingle_2_"
            x1="165.045"
            y1="17.386"
            x2="165.045"
            y2="908.159"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Single" && (
        <g
          id="FinSetupSingle"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            d="M173.396,838.474c0,2.892-2.129,5.319-4.56,5.319h-6.99
                c-2.583,0-4.559-2.43-4.559-5.319V695.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.56,2.434,4.56,5.318V838.474z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "2+1" && (
        <g
          id="FinSetup2+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M173.396,838.474c0,2.892-2.129,5.319-4.56,5.319h-6.99
                c-2.583,0-4.559-2.43-4.559-5.319V695.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.56,2.434,4.56,5.318V838.474z"
          />
          <path
            d="M75.386,724.686c-0.304,2.889-2.583,5.015-5.166,4.56
                l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911
                c2.583,0.306,4.255,3.04,3.951,5.926L75.386,724.686z"
          />
          <path
            d="M272.169,722.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066
                c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064
                c2.432-0.456,4.862,1.521,5.317,4.406L272.169,722.104z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            d="M75.386,724.989c-0.304,2.887-2.583,5.015-5.166,4.559
                l-6.384-0.912c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.013,5.167-4.559l6.381,0.913
                c2.583,0.304,4.255,3.038,3.951,5.924L75.386,724.989z"
          />
          <path
            d="M272.169,722.404c0.455,2.889-1.37,5.624-3.8,6.079l-6.385,1.063
                c-2.582,0.456-4.861-1.52-5.317-4.408l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.078l6.382-1.063
                c2.432-0.455,4.862,1.521,5.317,4.405L272.169,722.404z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Thruster" && (
        <g
          id="FinSetupThruster"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M75.386,724.686c-0.304,2.889-2.583,5.015-5.166,4.56
                l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911
                c2.583,0.306,4.255,3.04,3.951,5.926L75.386,724.686z"
          />
          <path
            d="M272.169,722.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066
                c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064
                c2.432-0.456,4.862,1.521,5.317,4.406L272.169,722.104z"
          />
          <path
            d="M173.094,859.479c0,2.89-2.129,5.317-4.559,5.317H162
                c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.316,4.559-5.316h6.535c2.583,0,4.559,2.43,4.559,5.316V859.479z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M75.386,724.686c-0.304,2.889-2.583,5.015-5.166,4.56
                l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911
                c2.583,0.306,4.255,3.04,3.951,5.926L75.386,724.686z"
          />
          <path
            d="M106.658,811.299c0,2.892-2.529,5.319-5.415,5.319h-7.759
                c-3.072,0-5.415-2.432-5.415-5.319v-60.021c0-2.89,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V811.299z"
          />
          <path
            d="M272.169,722.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066
                c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064
                c2.432-0.456,4.862,1.521,5.317,4.406L272.169,722.104z"
          />
          <path
            d="M248.695,809.78c0,2.888-2.525,5.316-5.414,5.316h-7.762
                c-3.066,0-5.414-2.431-5.414-5.316v-60.022c0-2.887,2.528-5.316,5.414-5.316h7.762c3.068,0,5.414,2.432,5.414,5.316V809.78z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "4+1" && (
        <g
          id="FinSetup4+1"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M173.396,838.474c0,2.892-2.129,5.319-4.56,5.319h-6.99
                c-2.583,0-4.559-2.43-4.559-5.319V695.332c0-2.887,2.127-5.318,4.559-5.318h6.99c2.583,0,4.56,2.434,4.56,5.318V838.474z"
          />
          <path
            d="M75.386,724.686c-0.304,2.889-2.583,5.015-5.166,4.56
                l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911
                c2.583,0.306,4.255,3.04,3.951,5.926L75.386,724.686z"
          />
          <path
            d="M106.658,811.299c0,2.892-2.529,5.319-5.415,5.319h-7.759
                c-3.072,0-5.415-2.432-5.415-5.319v-60.021c0-2.89,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V811.299z"
          />
          <path
            d="M272.169,722.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066
                c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064
                c2.432-0.456,4.862,1.521,5.317,4.406L272.169,722.104z"
          />
          <path
            d="M248.695,809.78c0,2.888-2.525,5.316-5.414,5.316h-7.762
                c-3.066,0-5.414-2.431-5.414-5.316v-60.022c0-2.887,2.528-5.316,5.414-5.316h7.762c3.068,0,5.414,2.432,5.414,5.316V809.78z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "5-Fin" && (
        <g
          id="FinSetup5"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            d="M75.386,724.686c-0.304,2.889-2.583,5.015-5.166,4.56
                l-6.384-0.911c-2.583-0.304-4.253-3.039-3.949-5.925l6.684-59.568c0.305-2.887,2.584-5.014,5.167-4.559l6.381,0.911
                c2.583,0.306,4.255,3.04,3.951,5.926L75.386,724.686z"
          />
          <path
            d="M106.658,811.299c0,2.892-2.529,5.319-5.415,5.319h-7.759
                c-3.072,0-5.415-2.432-5.415-5.319v-60.021c0-2.89,2.526-5.319,5.415-5.319h7.759c3.068,0,5.415,2.432,5.415,5.319V811.299z"
          />
          <path
            d="M272.169,722.104c0.455,2.889-1.37,5.622-3.8,6.076l-6.385,1.066
                c-2.582,0.454-4.861-1.521-5.317-4.409l-7.901-59.263c-0.455-2.888,1.367-5.622,3.801-6.077l6.382-1.064
                c2.432-0.456,4.862,1.521,5.317,4.406L272.169,722.104z"
          />
          <path
            d="M248.695,809.78c0,2.888-2.525,5.316-5.414,5.316h-7.762
                c-3.066,0-5.414-2.431-5.414-5.316v-60.022c0-2.887,2.528-5.316,5.414-5.316h7.762c3.068,0,5.414,2.432,5.414,5.316V809.78z"
          />
          <path
            d="M173.094,859.479c0,2.89-2.129,5.317-4.559,5.317H162
                c-2.584,0-4.559-2.43-4.559-5.317v-60.175c0-2.888,2.127-5.316,4.559-5.316h6.535c2.583,0,4.559,2.43,4.559,5.316V859.479z"
          />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          id="_x3C_Path_x3E__2_"
          x={116.899}
          y={516.213}
          width={96}
          height={95.616}
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path
            id="_x3C_Path_x3E__4_"
            d="
                M165.544,880.022c0,0-121.266-32.229-121.266-453.706C41.749,77.297,164.898,42.875,164.898,42.875S288.05,77.298,285.52,426.315
                c0,412.66-119.32,453.283-119.32,453.283L165.544,880.022z"
          />
        </g>
      )}
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path
          id="_x3C_Path_x3E__3_"
          d="
                M165.544,907.966c0,0-143.647,12.341-143.647-491.185c-4.479-363.522,143-399.395,143-399.395S310.9,53.242,307.9,416.78
                C307.9,911.64,165.544,907.966,165.544,907.966L165.544,907.966z"
        />
      </g>
    </svg>
  )
}
export default HybridPinSvg
