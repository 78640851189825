import React, { useEffect, useState } from "react"
import { Box, IconButton, Typography } from "@mui/material"
import CustomOrderOptionCheckbox from "../CustomOrderOptionCheckbox"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import Collapse from "@mui/material/Collapse"

const Extras = ({
  updateOptions,
  selectedGlassOnFinOption,
  setSelectedGlassOnFinOption,
  glassOnFinsOption,
  orderData,
  isStaff,
}) => {
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <React.Fragment>
      <Box
        // pt={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Extras</Typography>
        <IconButton onClick={handleClick}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse in={open}>
        <CustomOrderOptionCheckbox
          isStaff={isStaff}
          orderData={orderData}
          selectedOption={selectedGlassOnFinOption}
          setSelectedOption={setSelectedGlassOnFinOption}
          option={glassOnFinsOption}
          updateOptions={updateOptions}
        />
      </Collapse>
    </React.Fragment>
  )
}

export default Extras
